/**
 * FAMFAMFAM flag icons CSS.
 *
 * Examples:
 * <i class="famfamfam-flag-fr"> France</i>
 * <i class="famfamfam-flag-us"> United States</i>
 */

[class^="famfamfam-flag"] {
  display: inline-block;
  width: 0.08333rem;
  height: 0.05729rem;
  line-height: 0.05729rem;
  /* vertical-align: text-top; */
  background-image: url(../img/famfamfam-flags.fb3c8fff.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 1.16667rem 1.04167rem
}

.famfamfam-flag-zw { background-position: 0 0; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-zm { background-position: -0.08333rem 0; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-za { background-position: 0 -0.05729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-yt { background-position: -0.08333rem -0.05729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ye { background-position: -0.16667rem 0; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ws { background-position: -0.16667rem -0.05729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-wf { background-position: 0 -0.11458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-wales { background-position: -0.08333rem -0.11458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-vu { background-position: -0.16667rem -0.11458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-vn { background-position: 0 -0.17188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-vi { background-position: -0.08333rem -0.17188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-vg { background-position: -0.16667rem -0.17188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ve { background-position: -0.25rem 0; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-vc { background-position: -0.25rem -0.05729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-va { background-position: -0.25rem -0.11458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-uz { background-position: -0.25rem -0.17188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-uy { background-position: 0 -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-us { background-position: -0.08333rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-um { background-position: -0.08333rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ug { background-position: -0.16667rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ua { background-position: -0.25rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-tz { background-position: -0.33333rem 0; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-tw { background-position: -0.33333rem -0.05729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-tv { background-position: -0.33333rem -0.11458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-tt { background-position: -0.33333rem -0.17188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-tr { background-position: -0.33333rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-to { background-position: 0 -0.28646rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-tn { background-position: -0.08333rem -0.28646rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-tm { background-position: -0.16667rem -0.28646rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-tl { background-position: -0.25rem -0.28646rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-tk { background-position: -0.33333rem -0.28646rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-tj { background-position: 0 -0.34375rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-th { background-position: -0.08333rem -0.34375rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-tg { background-position: -0.16667rem -0.34375rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-tf { background-position: -0.25rem -0.34375rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-td { background-position: -0.33333rem -0.34375rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-tc { background-position: -0.41667rem 0; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sz { background-position: -0.41667rem -0.05729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sy { background-position: -0.41667rem -0.11458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sx { background-position: -0.41667rem -0.17188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sv { background-position: -0.41667rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-st { background-position: -0.41667rem -0.28646rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ss { background-position: -0.41667rem -0.34375rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sr { background-position: 0 -0.40104rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-so { background-position: -0.08333rem -0.40104rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sn { background-position: -0.16667rem -0.40104rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sm { background-position: -0.25rem -0.40104rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sl { background-position: -0.33333rem -0.40104rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sk { background-position: -0.41667rem -0.40104rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-si { background-position: -0.5rem 0; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sh { background-position: -0.5rem -0.05729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sg { background-position: -0.5rem -0.11458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-se { background-position: -0.5rem -0.17188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sd { background-position: -0.5rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-scotland { background-position: -0.5rem -0.28646rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sc { background-position: -0.5rem -0.34375rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sb { background-position: -0.5rem -0.40104rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sa { background-position: 0 -0.45833rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-rw { background-position: -0.08333rem -0.45833rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ru { background-position: -0.16667rem -0.45833rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-rs { background-position: -0.25rem -0.45833rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ro { background-position: -0.33333rem -0.45833rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-qa { background-position: -0.41667rem -0.45833rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-py { background-position: -0.5rem -0.45833rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-pw { background-position: 0 -0.51563rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-pt { background-position: -0.08333rem -0.51563rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ps { background-position: -0.16667rem -0.51563rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-pr { background-position: -0.25rem -0.51563rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-pn { background-position: -0.33333rem -0.51563rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-pm { background-position: -0.41667rem -0.51563rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-pl { background-position: -0.5rem -0.51563rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-pk { background-position: -0.58333rem 0; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ph { background-position: -0.58333rem -0.05729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-pg { background-position: -0.58333rem -0.11458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-pf { background-position: -0.58333rem -0.17188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-pe { background-position: -0.58333rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-pa { background-position: -0.58333rem -0.28646rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-om { background-position: -0.58333rem -0.34375rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-nz { background-position: -0.58333rem -0.40104rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-nu { background-position: -0.58333rem -0.45833rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-nr { background-position: -0.58333rem -0.51563rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-no { background-position: 0 -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bv { background-position: 0 -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-sj { background-position: 0 -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-nl { background-position: -0.08333rem -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ni { background-position: -0.16667rem -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ng { background-position: -0.25rem -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-nf { background-position: -0.33333rem -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ne { background-position: -0.41667rem -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-nc { background-position: -0.5rem -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-na { background-position: -0.58333rem -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mz { background-position: -0.66667rem 0; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-my { background-position: -0.66667rem -0.05729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mx { background-position: -0.66667rem -0.11458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mw { background-position: -0.66667rem -0.17188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mv { background-position: -0.66667rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mu { background-position: -0.66667rem -0.28646rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mt { background-position: -0.66667rem -0.34375rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ms { background-position: -0.66667rem -0.40104rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mr { background-position: -0.66667rem -0.45833rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mq { background-position: -0.66667rem -0.51563rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mp { background-position: -0.66667rem -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mo { background-position: 0 -0.63021rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mn { background-position: -0.08333rem -0.63021rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mm { background-position: -0.16667rem -0.63021rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ml { background-position: -0.25rem -0.63021rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mk { background-position: -0.33333rem -0.63021rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mh { background-position: -0.41667rem -0.63021rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mg { background-position: -0.5rem -0.63021rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-me { background-position: 0 -0.6875rem; width: 0.08333rem; height: 0.0625rem; }
.famfamfam-flag-md { background-position: -0.58333rem -0.63021rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mc { background-position: -0.66667rem -0.63021rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ma { background-position: -0.08333rem -0.6875rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ly { background-position: -0.16667rem -0.6875rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-lv { background-position: -0.25rem -0.6875rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-lu { background-position: -0.33333rem -0.6875rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-lt { background-position: -0.41667rem -0.6875rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ls { background-position: -0.5rem -0.6875rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-lr { background-position: -0.58333rem -0.6875rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-lk { background-position: -0.66667rem -0.6875rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-li { background-position: -0.75rem 0; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-lc { background-position: -0.75rem -0.05729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-lb { background-position: -0.75rem -0.11458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-la { background-position: -0.75rem -0.17188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-kz { background-position: -0.75rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ky { background-position: -0.75rem -0.28646rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-kw { background-position: -0.75rem -0.34375rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-kr { background-position: -0.75rem -0.40104rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-kp { background-position: -0.75rem -0.45833rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-kn { background-position: -0.75rem -0.51563rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-km { background-position: -0.75rem -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ki { background-position: -0.75rem -0.63021rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-kh { background-position: -0.75rem -0.6875rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-kg { background-position: 0 -0.75rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ke { background-position: -0.08333rem -0.75rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-jp { background-position: -0.16667rem -0.75rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-jo { background-position: -0.25rem -0.75rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-jm { background-position: -0.33333rem -0.75rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-je { background-position: -0.41667rem -0.75rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-it { background-position: -0.5rem -0.75rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-is { background-position: -0.58333rem -0.75rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ir { background-position: -0.66667rem -0.75rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-iq { background-position: -0.75rem -0.75rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-io { background-position: -0.83333rem 0; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-in { background-position: -0.83333rem -0.05729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-im { background-position: -0.83333rem -0.11458rem; width: 0.08333rem; height: 0.04688rem; }
.famfamfam-flag-il { background-position: -0.83333rem -0.16146rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ie { background-position: -0.83333rem -0.21875rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-id { background-position: -0.83333rem -0.27604rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-hu { background-position: -0.83333rem -0.33333rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ht { background-position: -0.83333rem -0.39063rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-hr { background-position: -0.83333rem -0.44792rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-hn { background-position: -0.83333rem -0.50521rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-hk { background-position: -0.83333rem -0.5625rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gy { background-position: -0.83333rem -0.61979rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gw { background-position: -0.83333rem -0.67708rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gu { background-position: -0.83333rem -0.73438rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gt { background-position: 0 -0.80729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gs { background-position: -0.08333rem -0.80729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gr { background-position: -0.16667rem -0.80729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gq { background-position: -0.25rem -0.80729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gp { background-position: -0.33333rem -0.80729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gn { background-position: -0.41667rem -0.80729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gm { background-position: -0.5rem -0.80729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gl { background-position: -0.58333rem -0.80729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gi { background-position: -0.66667rem -0.80729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gh { background-position: -0.75rem -0.80729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gg { background-position: -0.83333rem -0.80729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ge { background-position: -0.91667rem 0; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gd { background-position: -0.91667rem -0.05729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gb { background-position: -0.91667rem -0.11458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ga { background-position: -0.91667rem -0.17188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-fr { background-position: -0.91667rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-gf { background-position: -0.91667rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-re { background-position: -0.91667rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-mf { background-position: -0.91667rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bl { background-position: -0.91667rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-fo { background-position: -0.91667rem -0.28646rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-fm { background-position: -0.91667rem -0.34375rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-fk { background-position: -0.91667rem -0.40104rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-fj { background-position: -0.91667rem -0.45833rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-fi { background-position: -0.91667rem -0.51563rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-fam { background-position: -0.91667rem -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-eu { background-position: -0.91667rem -0.63021rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-et { background-position: -0.91667rem -0.6875rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-es { background-position: -0.91667rem -0.74479rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-er { background-position: -0.91667rem -0.80208rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-england { background-position: 0 -0.86458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-eh { background-position: -0.08333rem -0.86458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-eg { background-position: -0.16667rem -0.86458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ee { background-position: -0.25rem -0.86458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ec { background-position: -0.33333rem -0.86458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-dz { background-position: -0.41667rem -0.86458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-do { background-position: -0.5rem -0.86458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-dm { background-position: -0.58333rem -0.86458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-dk { background-position: -0.66667rem -0.86458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-dj { background-position: -0.75rem -0.86458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-de { background-position: -0.83333rem -0.86458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cz { background-position: -0.91667rem -0.86458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cy { background-position: 0 -0.92188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cx { background-position: -0.08333rem -0.92188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cw { background-position: -0.16667rem -0.92188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cv { background-position: -0.25rem -0.92188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cu { background-position: -0.33333rem -0.92188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cs { background-position: -0.41667rem -0.92188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cr { background-position: -0.5rem -0.92188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-co { background-position: -0.58333rem -0.92188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cn { background-position: -0.66667rem -0.92188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cm { background-position: -0.75rem -0.92188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cl { background-position: -0.83333rem -0.92188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ck { background-position: -0.91667rem -0.92188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ci { background-position: -1rem 0; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cg { background-position: -1rem -0.05729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cf { background-position: -1rem -0.11458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cd { background-position: -1rem -0.17188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-cc { background-position: -1rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-catalonia { background-position: -1rem -0.28646rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ca { background-position: -1rem -0.34375rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bz { background-position: -1rem -0.40104rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-by { background-position: -1rem -0.45833rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bw { background-position: -1rem -0.51563rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bt { background-position: -1rem -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bs { background-position: -1rem -0.63021rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-br { background-position: -1rem -0.6875rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bq { background-position: -1rem -0.74479rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bo { background-position: -1rem -0.80208rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bn { background-position: -1rem -0.85938rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bm { background-position: -1rem -0.91667rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bj { background-position: 0 -0.97917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bi { background-position: -0.08333rem -0.97917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bh { background-position: -0.16667rem -0.97917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bg { background-position: -0.25rem -0.97917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bf { background-position: -0.33333rem -0.97917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-be { background-position: -0.41667rem -0.97917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bd { background-position: -0.5rem -0.97917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-bb { background-position: -0.58333rem -0.97917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ba { background-position: -0.66667rem -0.97917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-az { background-position: -0.75rem -0.97917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ax { background-position: -0.83333rem -0.97917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-aw { background-position: -0.91667rem -0.97917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-au { background-position: -1rem -0.97917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-hm { background-position: -1rem -0.97917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-at { background-position: -1.08333rem 0; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-as { background-position: -1.08333rem -0.05729rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ar { background-position: -1.08333rem -0.11458rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ao { background-position: -1.08333rem -0.17188rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-an { background-position: -1.08333rem -0.22917rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-am { background-position: -1.08333rem -0.28646rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-al { background-position: -1.08333rem -0.34375rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ai { background-position: -1.08333rem -0.40104rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ag { background-position: -1.08333rem -0.45833rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-af { background-position: -1.08333rem -0.51563rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ae { background-position: -1.08333rem -0.57292rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-ad { background-position: -1.08333rem -0.63021rem; width: 0.08333rem; height: 0.05729rem; }
.famfamfam-flag-np { background-position: -1.08333rem -0.6875rem; width: 0.04688rem; height: 0.05729rem; }
.famfamfam-flag-ch { background-position: -1.08333rem -0.74479rem; width: 0.05729rem; height: 0.05729rem; }
