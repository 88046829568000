.member_box[data-v-949b7884] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.member_box .member_set[data-v-949b7884] {
  width: 1.97917rem;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  left: calc(60% - 2.08333rem);
  top: calc(50% - 2.34375rem);
  margin-top: 0.83333rem;
  background-color: #2a293e;
  height: 2.60417rem;
  border-radius: 10px;
  position: relative;
}
.member_box .member_top[data-v-949b7884] {
  width: 1.19792rem;
  height: 1.14583rem;
  margin-left: 0.26042rem;
}
.member_box .member_top img[data-v-949b7884] {
  width: 100%;
  height: 100%;
  margin-top: 0.18229rem;
  margin-left: 0.10417rem;
}
.member_box .member_down[data-v-949b7884] {
  width: 1.97917rem;
  margin-top: 0;
}
.member_box .member_down p[data-v-949b7884]:nth-child(1) {
  text-align: center;
  margin-top: 0.26042rem;
  font-size: 0.09375rem;
  color: #fff;
  word-break: normal;
}
.member_box .member_down p[data-v-949b7884]:nth-child(2) {
  text-align: center;
  margin-top: 0.10417rem;
  font-size: 0.08333rem;
  color: #fff;
  word-break: normal;
}
.member_box .bind-store p[data-v-949b7884]:nth-child(1) {
  color: #fff;
  font-size: 0.07292rem;
  margin-top: 0.15625rem;
  text-align: center;
  line-height: 0.20833rem;
  word-break: normal;
}
.member_box .member_btn[data-v-949b7884] {
  width: 100%;
  margin-left: -0.13021rem;
  width: 0.78125rem;
  height: 0.23438rem;
  line-height: 0.23438rem;
  border-radius: 50px;
  margin-top: 0.10417rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.member_box .member_btn .el-button[data-v-949b7884]:nth-child(1) {
  margin-left: 0.44271rem;
  height: 0.23438rem;
  margin-bottom: -0.78125rem;
  border-radius: 50px;
  font-size: 0.08333rem;
  color: #fff;
  background: #93df89;
  border: 1px solid #93df89;
}
.member_box .member_btn .el-button[data-v-949b7884]:nth-child(1):hover {
  background: #bff4b8;
  color: #fff;
}
.member_box .member_btn .el-button[data-v-949b7884]:nth-child(2) {
  margin-left: 0.15625rem;
  height: 0.23438rem;
  border-radius: 50px;
  font-size: 0.08333rem;
  color: #fff;
  background: #93df89;
  border: 1px solid #93df89;
}
.member_box .member_btn .el-button[data-v-949b7884]:nth-child(2):hover {
  background: #bff4b8;
  color: #fff;
}