
.el-select-dropdown__item.selected[data-v-c89a1480] {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.el-button--primary[data-v-c89a1480] {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.el-button--success[data-v-c89a1480] {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.particulars[data-v-c89a1480] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
}
.main[data-v-c89a1480] {
  width: 6.875rem;
}
.activeName[data-v-c89a1480] {
  margin-top: 0.16667rem;
  width: 6.875rem;
  border-radius: 10px !important;
  position: relative;
  background-color: #fff;
  padding-bottom: 0.15625rem;
}
[data-v-c89a1480] .el-table .cell {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  word-break: break-word !important;
}
[data-v-c89a1480] .el-table th > .cell {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
[data-v-c89a1480] .el-table th,
.el-table tr[data-v-c89a1480] {
  height: 0.33333rem;
  font-weight: 500;
}
.el-table[data-v-c89a1480]::before {
  background: #dbdbe4;
}
[data-v-c89a1480] .el-table__row {
  height: 0.44792rem !important;
}
[data-v-c89a1480] .el-tabs__nav-wrap::after {
  background: #eff1f3 !important;
}
[data-v-c89a1480] .el-tabs__item.is-active {
  color: #6788ff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.activeName .orders[data-v-c89a1480] {
  position: absolute;
  top: 0.10417rem;
  right: 0.16667rem;
  width: 0.92708rem;
  height: 0.20833rem;
  background: -webkit-linear-gradient(318deg, #34c2eb, #69d3e3);
  background: linear-gradient(132deg, #34c2eb, #69d3e3);
  border-radius: 10px;
  font-size: 0.07292rem;
  font-weight: 500;
  color: #ffffff;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.btn[data-v-c89a1480] {
  display: inline-block;
  width: 0.70313rem;
  height: 0.19792rem;
  background: rgb(191, 204, 249, 0.15);
  border-radius: 10px;
  font-size: 0.07292rem;
  font-family: Poppins;
  font-weight: 400;
  color: #6788ff;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
