
.particulars[data-v-59efe49f] {
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
}
.particulars.dark[data-v-59efe49f] {
  background: #1f1e2e !important;
  background-image: url(../img/XueHua.07010e22.png);
}
