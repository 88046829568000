
.particulars[data-v-540a97ba] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  /*background-image: linear-gradient(to bottom right, #373296, #7665d2);
    background-image: url('../../../assets/imgs/Christmas/XueHua.png');*/
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
}
.activeName[data-v-540a97ba] {
  height: auto;
  width: 6.875rem;
  height: 100%;
  border-radius: 10px;
  position: relative;
}
.check_type[data-v-540a97ba] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 6.875rem;
  height: 0.3125rem;
  /*margin-top: 25px;*/
}
.check_type_store[data-v-540a97ba] {
  width: 100%;
  height: 0.3125rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  font-weight: 600;
  font-size: 0.07292rem;
  padding-left: 0.05208rem;
}
.Aliexpress_Product[data-v-540a97ba] {
  width: 1.04167rem;
  height: 0.20833rem;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
}
.Aliexpress_Product a[data-v-540a97ba] {
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
  color: #000;
}
.Aliexpress_Product[data-v-540a97ba]:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.Aliexpress_Product span[data-v-540a97ba]:nth-child(1) {
  line-height: 0.20833rem;
  margin-left: 0.20833rem;
  color: #15104b;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 0.07292rem;
}
.Aliexpress_Product span[data-v-540a97ba]:nth-child(2) {
  margin-left: 0.10417rem;
  color: #15104b;
  line-height: 0.20833rem;
}
.Import_List[data-v-540a97ba] {
  width: 1.04167rem;
  height: 0.20833rem;
  background: #fff;
  margin-left: 0.07813rem;
  border-radius: 50px;
  cursor: pointer;
}
.Import_List a[data-v-540a97ba] {
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
  color: #000;
}
.Import_List[data-v-540a97ba]:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.Import_List span[data-v-540a97ba]:nth-child(1) {
  line-height: 0.20833rem;
  margin-left: 0.26042rem;
  color: #15104b;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 0.07292rem;
}
.Import_List span[data-v-540a97ba]:nth-child(2) {
  margin-left: 0.14583rem;
  color: #15104b;
}
.My_Product[data-v-540a97ba] {
  width: 1.04167rem;
  height: 0.20833rem;
  background: #15104b;
  margin-left: 0.18229rem;
  border-radius: 50px;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.My_Product a[data-v-540a97ba] {
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
  color: #000;
}
.My_Product[data-v-540a97ba]:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.My_Product span[data-v-540a97ba]:nth-child(1) {
  line-height: 0.20833rem;
  margin-left: 0.07813rem;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 0.07292rem;
}
.My_Product span[data-v-540a97ba]:nth-child(2) {
  margin-left: 0.02604rem;
  color: #fff;
}
/* 搜索 */
.Filter[data-v-540a97ba] {
  width: 6.35417rem;
  height: 0.36458rem;
  background: #f9fafb;
  box-shadow: 0 0 0.10417rem 0 rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 0.36458rem;
  /* margin-top: 30px; */
  margin-left: 0.07813rem;
}
[data-v-540a97ba] .Filter .input-with-select {
  width: 2.60417rem;
  height: 0.20833rem;
  background: #f5f7fa !important;
  border-radius: 50px;
  margin-left: 0.15625rem;
}
[data-v-540a97ba] .Filter .el-input-group__prepend {
  border: 0;
  background-color: #f1f1f2;
  height: 0.23958rem;
}
[data-v-540a97ba] .Filter .el-input-group > .el-input__inner {
  border: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  background-color: #f1f1f2;
  color: #000;
  text-align: center;
  padding-right: 0.52083rem;
  height: 0.23958rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
[data-v-540a97ba] .Filter [class*=' el-icon-'],
[class^='el-icon-'][data-v-540a97ba] {
  color: #000;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Filter .search[data-v-540a97ba] {
  width: 0.75521rem;
  height: 0.23958rem;
  margin-left: 0.15625rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Filter .Advanced[data-v-540a97ba] {
  display: inline-block;
  width: 0.75521rem;
  height: 0.23958rem;
  background: rgba(102, 135, 255, 0.1);
  border-radius: 10px;
  color: rgb(102, 135, 255);
  font-size: 0.08333rem;
  line-height: 0.23958rem;
  text-align: center;
  margin-left: 0.15625rem;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

/* ago */
.bigbox[data-v-540a97ba] {
  /*width: 1320px;*/
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  /* padding-bottom: 23px; */
  /* margin-bottom: 30px; */
}
.box[data-v-540a97ba] {
  margin-top: 0.11458rem;
  margin-left: 0.05208rem;
  width: 1.5625rem;
  height: 2.00521rem;
  background: #f6f6fa;
  box-shadow: 0 0 0.09375rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.box .images[data-v-540a97ba] {
  width: 1.51042rem;
  height: 1.21354rem;
  text-align: center;
}
.images img[data-v-540a97ba] {
  width: 1.04167rem;
  height: 1.04167rem;
  margin-top: 0.16667rem;
}
.box .simple[data-v-540a97ba] {
  margin-top: 0.15625rem;
  width: 1.51042rem;
  height: 0.125rem;
  line-height: 0.125rem;
  text-align: center;
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: rgb(25, 25, 25, 0.8);
  padding: 0 0.08333rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.box .btn[data-v-540a97ba] {
  margin-top: 0.10417rem;
  width: 1.51042rem;
  text-align: center;
}
.box .btn button[data-v-540a97ba] {
  width: 0.9375rem;
  height: 0.15625rem;
  background: #474882;
  border-radius: 50px;
  border: 0;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #ffffff;
}
.box .btn button[data-v-540a97ba]:hover {
  background: #3c3772 !important;
  color: #fff;
}
.box .remove[data-v-540a97ba] {
  margin-top: 0.08333rem;
  font-size: 0.08333rem;
  width: 1.51042rem;
  line-height: 1;
  text-align: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #d42727;
  cursor: pointer;
}
.pagebox[data-v-540a97ba] {
  margin-top: 0.05208rem;
  width: 6.51042rem;
}
