@charset "UTF-8";
.particulars[data-v-12262fb4] {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.activeName[data-v-12262fb4] {
  width: 6.875rem;
  border-radius: 10px;
  line-height: 0.10417rem;
  position: relative;
  padding-bottom: 0.16667rem;
}
.title_title[data-v-12262fb4] {
  text-align: center;
  padding-top: 0.26042rem;
}
.title_title h1[data-v-12262fb4] {
  font-size: 0.20833rem;
  color: #3b3663;
}
.title_title .insights_title[data-v-12262fb4] {
  font-size: 0.20833rem;
  color: #3b3663;
  margin-top: 0.26042rem;
}
.exhibition_img[data-v-12262fb4] {
  text-align: center;
  margin-top: 0.65104rem;
}
.exhibition_img img[data-v-12262fb4] {
  width: 5.72917rem;
  height: 2.08333rem;
  border-radius: 8px;
  object-fit: cover;
}
.left_image_right_text[data-v-12262fb4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.65104rem;
}
.left_image_right_text .left_img_box[data-v-12262fb4] {
  margin-left: 0.83333rem;
}
.left_image_right_text .left_img_box img[data-v-12262fb4] {
  width: 1.30208rem;
  height: 1.30208rem;
  border-radius: 8px;
  object-fit: cover;
}
.left_image_right_text .right_text_title[data-v-12262fb4] {
  width: 3.64583rem;
  margin-top: 0;
}
.left_image_right_text .right_text_title p[data-v-12262fb4] {
  font-size: 0.08333rem;
  line-height: 0.26042rem;
  margin-left: 0.3125rem;
  color: #666666;
}
.keyinsights_box[data-v-12262fb4] {
  text-align: left;
  margin-left: 0.83333rem;
  margin-top: 0.15625rem;
}
.keyinsights_box .key_insights_box[data-v-12262fb4] {
  font-size: 0.11458rem;
  font-weight: 600;
}
.keyinsights_box .keyinsights_title p[data-v-12262fb4]:nth-child(1) {
  font-size: 0.08333rem;
  font-weight: 600;
  margin-top: 0.15625rem;
}
.keyinsights_box .keyinsights_title p[data-v-12262fb4]:nth-child(2) {
  font-size: 0.08333rem;
  color: #666666;
  width: 5.20833rem;
  margin-top: 0.07813rem;
  line-height: 0.15625rem;
}
.keyinsights_box .google_ads_track[data-v-12262fb4] {
  margin-top: 0.07813rem;
}
.keyinsights_box .google_ads_track .google_ads_title[data-v-12262fb4] {
  font-size: 0.08333rem;
  font-weight: 600;
}
.keyinsights_box .google_ads_track .left_image_right_text_two[data-v-12262fb4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.10417rem;
}
.keyinsights_box .google_ads_track .left_image_right_text_two .google_ads_img img[data-v-12262fb4] {
  width: 0.78125rem;
  height: 0.78125rem;
}
.keyinsights_box .google_ads_track .left_image_right_text_two .google_ads_words_one p[data-v-12262fb4]:nth-child(1) {
  font-size: 0.0625rem;
  line-height: 0.20833rem;
  margin-left: 0.20833rem;
}
.keyinsights_box .google_ads_track .left_image_right_text_two .google_ads_words_one p[data-v-12262fb4]:nth-child(2) {
  font-size: 0.0625rem;
}
.keyinsights_box .google_ads_track .left_image_right_text_two .google_ads_words_one p[data-v-12262fb4]:nth-child(3) {
  font-size: 0.0625rem;
  line-height: 0.20833rem;
  margin-left: 0.26042rem;
}
.keyinsights_box .google_ads_track .explorepo_tential_title[data-v-12262fb4] {
  font-size: 0.08333rem;
  color: #666666;
  margin-top: 0.05208rem;
  width: 3.64583rem;
  line-height: 0.18229rem;
  margin-left: 0.41667rem;
}
.keyinsights_box .google_ads_track_two[data-v-12262fb4] {
  margin-top: 0.05208rem;
}
.keyinsights_box .google_ads_track_two .google_ads_title[data-v-12262fb4] {
  font-size: 0.08333rem;
  font-weight: 600;
}
.keyinsights_box .google_ads_track_two .google_ads_title_two[data-v-12262fb4] {
  font-size: 0.08333rem;
  font-weight: 600;
  margin-top: 0.15625rem;
}
.keyinsights_box .google_ads_track_two .left_image_right_text_two[data-v-12262fb4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.10417rem;
}
.keyinsights_box .google_ads_track_two .left_image_right_text_two .google_ads_img_two img[data-v-12262fb4] {
  width: 0.78125rem;
  height: 0.78125rem;
  margin-left: 0.67708rem;
}
.keyinsights_box .google_ads_track_two .left_image_right_text_two .google_ads_words p[data-v-12262fb4]:nth-child(1) {
  font-size: 0.0625rem;
  line-height: 0.20833rem;
  margin-left: 0.88542rem;
}
.keyinsights_box .google_ads_track_two .left_image_right_text_two .google_ads_words p[data-v-12262fb4]:nth-child(2) {
  font-size: 0.0625rem;
  line-height: 0.20833rem;
  margin-left: 0.625rem;
}
.keyinsights_box .google_ads_track_two .explorepo_tential_title_two p[data-v-12262fb4]:nth-child(1) {
  font-size: 0.08333rem;
  font-weight: 600;
  margin-top: 0.07813rem;
}
.keyinsights_box .google_ads_track_two .explorepo_tential_title_two p[data-v-12262fb4]:nth-child(2) {
  font-size: 0.08333rem;
  color: #666666;
  margin-top: 0.05208rem;
  width: 3.64583rem;
  line-height: 0.20833rem;
}
.keyinsights_box .native_advertising_track[data-v-12262fb4] {
  margin-top: 0.05208rem;
}
.keyinsights_box .native_advertising_track .native_advertising_title[data-v-12262fb4] {
  font-size: 0.08333rem;
  font-weight: 600;
}
.keyinsights_box .native_advertising_track .native_advertising_words[data-v-12262fb4] {
  font-size: 0.08333rem;
  color: #666666;
  margin-top: 0.07813rem;
  width: 5.20833rem;
  line-height: 0.15625rem;
}
.key_takeaways_sellers_box[data-v-12262fb4] {
  text-align: left;
  margin-left: 0.83333rem;
}
.key_takeaways_sellers_box .key_takeaway_big_title[data-v-12262fb4] {
  font-size: 0.11458rem;
  font-weight: 600;
  margin-top: 0.15625rem;
}
.key_takeaways_sellers_box .key_takeaway_img img[data-v-12262fb4] {
  width: 5.33333rem;
  height: 3.55208rem;
  margin-top: 0.15625rem;
  border-radius: 8px;
}
.key_takeaways_sellers_box .key_takeaways_sellers .key_takeaways_title[data-v-12262fb4] {
  font-size: 0.08333rem;
  font-weight: 600;
  margin-top: 0.10417rem;
}
.key_takeaways_sellers_box .key_takeaways_sellers .key_takeaways_words[data-v-12262fb4] {
  font-size: 0.08333rem;
  color: #666666;
  margin-top: 0.05208rem;
  width: 5.20833rem;
  line-height: 0.15625rem;
}
.success_stories_box[data-v-12262fb4] {
  text-align: left;
  margin-left: 0.83333rem;
}
.success_stories_box .case_study_box_one[data-v-12262fb4] {
  margin-top: 0.20833rem;
}
.success_stories_box .case_study_box_one .case_study_title[data-v-12262fb4] {
  font-size: 0.09375rem;
  font-weight: 600;
}
.success_stories_box .case_study_box_one .case_study_box[data-v-12262fb4] {
  margin-top: 0.10417rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.success_stories_box .case_study_box_one .case_study_box .toma_img img[data-v-12262fb4] {
  width: 1.30208rem;
  height: 1.30208rem;
  border-radius: 8px;
  object-fit: cover;
}
.success_stories_box .case_study_box_one .case_study_box .toma_content[data-v-12262fb4] {
  font-size: 0.08333rem;
  color: #666666;
  margin-left: 0.20833rem;
  width: 3.64583rem;
  line-height: 0.26042rem;
  margin-top: 0.13021rem;
}
.success_stories_box .case_study_box_one .case_study_box .toma_content_two[data-v-12262fb4] {
  font-size: 0.08333rem;
  color: #666666;
  width: 3.64583rem;
  line-height: 0.26042rem;
  margin-top: 0.13021rem;
}
.success_stories_box .case_study_box_one .case_study_box .toma_img_two img[data-v-12262fb4] {
  width: 1.30208rem;
  height: 1.30208rem;
  border-radius: 8px;
  object-fit: cover;
  margin-left: 0.26042rem;
}
.success_stories_box .case_study_box_two[data-v-12262fb4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.15625rem;
}
.success_stories_box .case_study_box_two .case_study_img img[data-v-12262fb4] {
  width: 1.30208rem;
  height: 1.30208rem;
  border-radius: 8px;
  object-fit: cover;
}
.success_stories_box .case_study_box_two .case_study_content[data-v-12262fb4] {
  font-size: 0.08333rem;
  color: #666666;
  margin-left: 0.26042rem;
  margin-top: 0.15625rem;
}
.success_stories_box .case_study_box_two .case_study_content .service-list[data-v-12262fb4] {
  list-style: none;
  padding: 0;
  margin: 0;
}
.success_stories_box .case_study_box_two .case_study_content .service-list li[data-v-12262fb4] {
  position: relative;
  padding-left: 0.15625rem;
  margin-top: 0.07813rem;
}
.success_stories_box .case_study_box_two .case_study_content .service-list li[data-v-12262fb4]::before {
  content: "●";
  position: absolute;
  left: 0;
  color: #666666;
  font-size: 0.08333rem;
  line-height: 1.2;
}
.success_stories_box .fulfillment_rate_content[data-v-12262fb4] {
  font-size: 0.08333rem;
  color: #666666;
  line-height: 0.15625rem;
  width: 5.20833rem;
  margin-top: 0.10417rem;
}
.visual_insights_box[data-v-12262fb4] {
  text-align: left;
  margin-left: 0.83333rem;
  margin-top: 0.15625rem;
}
.visual_insights_box .visual_insights_big_title[data-v-12262fb4] {
  font-size: 0.11458rem;
  font-weight: 600;
  margin-top: 0.15625rem;
}
.visual_insights_box .customer_testimonial_title[data-v-12262fb4] {
  font-size: 0.09375rem;
  font-weight: 600;
  margin-top: 0.15625rem;
}
.visual_insights_box .fulfillment_services[data-v-12262fb4] {
  font-size: 0.08333rem;
  color: #666666;
  width: 5.20833rem;
  line-height: 0.15625rem;
  margin-top: 0.10417rem;
}
.visual_insights_box .fashion_direct[data-v-12262fb4] {
  font-size: 0.07292rem;
  color: #666666;
  margin-top: 0.05208rem;
  text-align: right;
  margin-right: 0.96354rem;
}
.whats_next_box[data-v-12262fb4] {
  text-align: left;
  margin-left: 0.83333rem;
}
.whats_next_box .whats_next_content_one[data-v-12262fb4] {
  font-size: 0.08333rem;
  color: #666666;
  margin-top: 0.10417rem;
  width: 5.20833rem;
  line-height: 0.15625rem;
}
.img_box[data-v-12262fb4] {
  text-align: left;
  margin-left: 0.52083rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.15625rem;
}
.img_box p img[data-v-12262fb4] {
  width: 1.5625rem;
  height: 2.08333rem;
  margin-left: 0.26042rem;
  object-fit: cover;
  border-radius: 8px;
}