.podDetail[data-v-6ed64274] {
  width: 7.29167rem;
  padding-bottom: 0.125rem;
}
.podDetail .infoContent[data-v-6ed64274] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  background: #f9fafb;
  border-radius: 10px;
  margin-bottom: 0.10417rem;
  padding: 0.20833rem 0.3125rem 0.125rem 0.20833rem;
}
.podDetail .infoContent .leftPics[data-v-6ed64274] {
  width: 2.91667rem;
  height: 100%;
  margin-right: 0.3125rem;
}
.podDetail .infoContent .leftPics .mainPic[data-v-6ed64274] {
  width: 2.91667rem;
  height: 2.91667rem;
  margin-bottom: 0.20833rem;
}
.podDetail .infoContent .leftPics .thumbCard[data-v-6ed64274] {
  width: 100%;
}
.podDetail .infoContent .rightSkuAConfigure[data-v-6ed64274] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.podDetail .infoContent .rightSkuAConfigure .skuInfo[data-v-6ed64274] {
  min-height: 1.5625rem;
  width: 100%;
}
.podDetail .infoContent .rightSkuAConfigure .skuInfo .title[data-v-6ed64274] {
  width: 3.64583rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.125rem;
  font-weight: 600;
  color: #15104b;
}
.podDetail .infoContent .rightSkuAConfigure .skuInfo .price[data-v-6ed64274] {
  font-size: 0.16667rem;
  font-weight: 700;
  margin: 0.11458rem 0;
  color: #3b9c39;
}
.podDetail .infoContent .rightSkuAConfigure .skuInfo .skusContent[data-v-6ed64274] {
  margin-bottom: 0.10417rem;
}
.podDetail .infoContent .rightSkuAConfigure .skuInfo .skusContent .labelTitle[data-v-6ed64274] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  font-size: 0.09375rem;
  color: #333333;
  margin-bottom: 0.05208rem;
}
.podDetail .infoContent .rightSkuAConfigure .skuInfo .skusContent .skuItems[data-v-6ed64274] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  gap: 0.10417rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.podDetail .infoContent .rightSkuAConfigure .skuInfo .skusContent .skuItems .skuPic[data-v-6ed64274] {
  cursor: pointer;
  width: 0.35417rem;
  height: 0.35417rem;
  min-width: 0.35417rem;
  border-radius: 4px;
}
.podDetail .infoContent .rightSkuAConfigure .skuInfo .skusContent .skuItems .skuTxt[data-v-6ed64274] {
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: #ffffff;
  border-radius: 14px;
  padding: 0.02604rem 0.10417rem;
  font-size: 0.07292rem;
}
.podDetail .infoContent .rightSkuAConfigure .skuInfo .skusContent .skuItems .active[data-v-6ed64274] {
  border: 1px solid #15104b;
}
.podDetail .infoContent .rightSkuAConfigure .podShowConfigure[data-v-6ed64274] {
  height: 2.23958rem;
  margin-top: 0.10417rem;
  overflow: auto;
}
.podDetail .configureSave[data-v-6ed64274] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 0.20833rem;
}
.podDetail .configureSave .saveBtn[data-v-6ed64274] {
  font-size: 0.08333rem;
  font-weight: 500;
  width: 1.77083rem;
  height: 0.20833rem;
}
.podDetail .senseSelect[data-v-6ed64274] {
  width: 1.4375rem;
}
.podDetail .configureContent[data-v-6ed64274] {
  width: 100%;
  border-radius: 10px;
  background: #fff;
}