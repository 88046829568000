
.Agent_box[data-v-48f1a5b0] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Agent_set[data-v-48f1a5b0] {
  width: 1.97917rem;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  left: calc(60% - 2.08333rem);
  top: calc(50% - 1.5625rem);
  margin-top: 0.83333rem;
  background-color: #5c5a86;
  height: 1.30208rem;
  border-radius: 10px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Agent_top[data-v-48f1a5b0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.Agent_top p:nth-child(1) img[data-v-48f1a5b0] {
  margin-top: 0.33854rem;
  width: 1.79688rem;
  margin-left: 0.07813rem;
}
.Agent_top p[data-v-48f1a5b0]:nth-child(2) {
  margin-top: 0.33854rem;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Agent_down[data-v-48f1a5b0] {
  width: 1.97917rem;
  height: 1.04167rem;
  margin-top: 0.15625rem;
}
.Agent_down p[data-v-48f1a5b0]:nth-child(1) {
  text-align: center;
  margin-top: 0.10417rem;
  font-size: 0.10417rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
}
.Agent_down p[data-v-48f1a5b0]:nth-child(2) {
  text-align: center;
  font-size: 0.10417rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
}
.member_Close_btn[data-v-48f1a5b0] {
  position: absolute;
  top: 0.04167rem;
  right: 0.05208rem;
}
[data-v-48f1a5b0] .member_Close_btn .el-icon-remove-outline {
  color: #fff;
  font-size: 0.11458rem;
  cursor: pointer;
}
.IconQuestion[data-v-48f1a5b0] {
  position: absolute;
  top: 0.01042rem;
  left: 1.22396rem;
}
[data-v-48f1a5b0] .IconQuestion .el-icon-question {
  font-size: 0.10417rem;
  color: #3f6aff;
}
.PromptStatement[data-v-48f1a5b0] {
  position: absolute;
  top: 0.05208rem;
  right: 0.20833rem;
}
.PromptStatement p[data-v-48f1a5b0] {
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  line-height: 0.15625rem;
}
.DisplayInfo[data-v-48f1a5b0] {
  width: 1.875rem;
  height: 0.28646rem;
  background: #93df89;
  border-radius: 10px;
  position: absolute;
  left: 1.38021rem;
  top: -0.05208rem;
}
.DisplayInfo p[data-v-48f1a5b0]:nth-child(1) {
  font-size: 0.07292rem;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-top: 0.02604rem;
  padding-left: 0.05208rem;
}
.DisplayInfo p[data-v-48f1a5b0]:nth-child(2) {
  font-size: 0.07292rem;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-left: 0.05208rem;
}
.member_title[data-v-48f1a5b0] {
  width: 0.0625rem;
  height: 0.0625rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOBAMAAADtZjDiAAAALVBMVEUAAACfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoapgOQNlAAAADnRSTlMAmIGmkqvxuolo93l4dQWiX7wAAABSSURBVAjXY0hlAAExhjgBIMX0nIHlIZDWM2Bg8BNgYHoMZAMFQFygwMnHYLW87xrANOc7BTCt1/0QRDE9BWoB6wVpAesFCcgFAGnGpwzpYLVWANBXEYKHOC/hAAAAAElFTkSuQmCC) no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: right;
  margin-top: 0;
  cursor: pointer;
  position: absolute;
  top: 0.07813rem;
  right: 0.07813rem;
  color: #fff;
}
.MonthlyRed[data-v-48f1a5b0] {
  position: absolute;
  top: 0;
  left: 1.06771rem;
  cursor: pointer;
}
.MonthlyRed span img[data-v-48f1a5b0] {
  width: 0.11458rem;
  height: 0.11458rem;
}
.MonthlyRed span img[data-v-48f1a5b0]:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.el-select-dropdown__item[data-v-48f1a5b0] {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.member_box[data-v-48f1a5b0] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.member_set[data-v-48f1a5b0] {
  width: 1.97917rem;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  left: calc(60% - 2.08333rem);
  top: calc(50% - 2.34375rem);
  margin-top: 0.83333rem;
  background-color: #2a293e;
  height: 2.60417rem;
  border-radius: 10px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.member_top[data-v-48f1a5b0] {
  width: 1.19792rem;
  height: 1.14583rem;
  margin-left: 0.26042rem;
}
.member_top img[data-v-48f1a5b0] {
  width: 100%;
  height: 100%;
  margin-top: 0.18229rem;
  margin-left: 0.10417rem;
}
.member_down[data-v-48f1a5b0] {
  width: 1.97917rem;
  margin-top: 0;
}
.member_down p[data-v-48f1a5b0]:nth-child(1) {
  text-align: center;
  margin-top: 0.26042rem;
  font-size: 0.09375rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
}
.member_down p[data-v-48f1a5b0]:nth-child(2) {
  text-align: center;
  margin-top: 0.10417rem;
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
}
.Bindstore p[data-v-48f1a5b0]:nth-child(1) {
  color: #fff;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.15625rem;
  text-align: center;
  line-height: 0.20833rem;
  word-break: normal;
}
.member_btn[data-v-48f1a5b0] {
  margin-left: 0.28646rem;
  width: 0.78125rem;
  height: 0.23438rem;
  line-height: 0.23438rem;
  border-radius: 50px;
  margin-top: 0.10417rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.member_btn[data-v-48f1a5b0] .el-button:nth-child(1) {
  margin-left: 0.02604rem;
  height: 0.23438rem;
  margin-bottom: -0.78125rem;
  border-radius: 50px;
  font-size: 0.08333rem;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  background: #93df89;
  border: 1px solid #93df89;
}
.member_btn[data-v-48f1a5b0] .el-button:nth-child(2) {
  margin-left: 0.15625rem;
  height: 0.23438rem;
  border-radius: 50px;
  font-size: 0.08333rem;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  background: #93df89;
  border: 1px solid #93df89;
}
.member_btn[data-v-48f1a5b0] .el-button:nth-child(1):hover {
  background: #bff4b8;
  color: #fff;
}
.member_btn[data-v-48f1a5b0] .el-button:nth-child(2):hover {
  background: #bff4b8;
  color: #fff;
}
.AliexpressBtn[data-v-48f1a5b0] {
  position: absolute;
  top: 0.10417rem;
  right: 0.26042rem;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected[data-v-48f1a5b0] {
  color: #4c8bf5;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
[data-v-48f1a5b0] .el-input--suffix input {
  border: none;
  background: none;
  display: block;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.dialog_footer[data-v-48f1a5b0] {
  margin-top: 0.18229rem;
  margin-left: 2.09896rem;
}
.CheckAttribute[data-v-48f1a5b0] {
  margin-top: 0.10417rem;
  position: relative;
}
.Cancel[data-v-48f1a5b0] {
  width: 1.04167rem;
  height: 0.25rem;
  border: 2px solid #409efe !important;
  border-radius: 10px !important;
  font-size: 0.08333rem !important;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400 !important;
  color: #4c8bf5 !important;
  background-color: #fff;
}
.Save[data-v-48f1a5b0] {
  margin-left: 0.4375rem !important;
  width: 1.11458rem;
  height: 0.25rem;
  border: none;
  border-radius: 50px !important;
  font-size: 0.08333rem !important;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #474882;
}
.Save[data-v-48f1a5b0]:hover {
  background: #3c3772 !important;
  color: #fff;
}
