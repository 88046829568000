
.particulars[data-v-778d3325] {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
}
.activeName[data-v-778d3325] {
  width: 6.875rem;
  border-radius: 10px;
  line-height: 0.10417rem;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
}
[data-v-778d3325] .el-tabs__nav-scroll {
  height: 0.41667rem;
  padding: 0.07813rem 0;
  background: -webkit-linear-gradient(bottom, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}
[data-v-778d3325] .el-tabs__nav {
  margin-left: 0.16667rem;
}
[data-v-778d3325] .el-tabs__item.is-active {
  font-size: 0.08333rem;
  color: #3F6AFF;
  font-family: "Regular";
  font-weight: 500;
}
/* 标签页下划线 */
[data-v-778d3325] .el-tabs__active-bar {
  height: 0.01563rem !important;
  background: #3F6AFF;
  width: 50%!important;
  left: 25%;
  border-radius: 10px;
}
[data-v-778d3325] .el-tabs__header {
  margin: 0;
}
[data-v-778d3325] .el-tabs__nav-wrap::after {
  background: #eff1f3;
}
[data-v-778d3325] .el-table th,
.el-table tr[data-v-778d3325] {
  height: 0.33333rem;
  font-weight: 500;
}
[data-v-778d3325] .el-table__row {
  height: 0.44792rem !important;
}
[data-v-778d3325] .el-table thead {  /* 头部颜色 */
  font-size: 0.07292rem;
  font-family: "Regular";
  font-weight: 500;
  color: #000000;
  color: rgb(0, 0, 0, 0.8);
}
[data-v-778d3325] .el-table .cell { /* 列表字体颜色 */
  font-size: 0.07292rem;
  font-family: "Regular";
  font-weight: 400;
  color: rgb(0, 0, 0, 0.8);
}
[data-v-778d3325] .element-style {
  font-size: 0.07292rem;
  font-family: "Regular";
}
/* 分页 */
[data-v-778d3325] .el-pager li.active {
  font-family: "Regular";
}
.pagebox[data-v-778d3325] {
  width: 6.875rem;
  padding-top:0.20833rem
}
/* 按钮 */
.activeName .orders[data-v-778d3325] {
  position: absolute;
  top: 0.10417rem;
  right: 0.16667rem;
  width: 0.92708rem;
  height: 0.20833rem;
  background: -webkit-linear-gradient(318deg, #34c2eb, #69d3e3);
  background: linear-gradient(132deg, #34c2eb, #69d3e3);
  border-radius: 10px;
  font-size: 0.08333rem;
  font-family: "Regular";
  font-weight: 500;
  color: #ffffff;
  border: 0;
}
.btn[data-v-778d3325] {
  width: 0.70313rem;
  height: 0.19792rem;
  background: rgb(191, 204, 249, 0.15);
  border-radius: 10px;
  font-size: 0.07292rem;
  font-family: "Regular";
  font-weight: 400;
  color: #3F6AFF;
  border: 0;
}
