.bind_store_box[data-v-7d45a254] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.bind_store_content_box[data-v-7d45a254] {
  width: 5.20833rem;
  height: 3.64583rem;
  background-color: #ffffff;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.close_btn_icon[data-v-7d45a254] {
  text-align: right;
  margin-right: 0.07813rem;
  margin-top: 0.13021rem;
  width: 95%;
  height: 0;
}
.close_btn_icon > > > .el-icon-circle-close[data-v-7d45a254] {
  font-size: 0.13542rem;
  color: #333333;
  cursor: pointer;
}
.header_Copywriting[data-v-7d45a254] {
  width: 100%;
  height: 0.3125rem;
  margin-top: 0.15625rem;
}
.header_Copywriting p[data-v-7d45a254] {
  font-size: 0.15625rem;
  color: #333333;
  text-align: center;
  font-family: "Bold";
  -webkit-font-smoothing: antialiased;
}
.Dashed_line[data-v-7d45a254] {
  width: 4.79167rem;
  height: 0.01042rem;
  opacity: 1;
  border: 1px dashed #333333;
  text-align: center;
  margin-left: 0.20833rem;
}
.Cneter_content_box[data-v-7d45a254] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 0.26042rem;
}
.left_img_box img[data-v-7d45a254] {
  width: 1.09375rem;
  height: 1.09375rem;
  margin-top: 0.23438rem;
}
.center_img_box img[data-v-7d45a254] {
  width: 0.33854rem;
  height: 0.33854rem;
  margin-left: 0.41667rem;
  margin-top: 0.59896rem;
}
.right_img_box img[data-v-7d45a254] {
  width: 1.25rem;
  height: 1.45833rem;
  margin-left: 0.41667rem;
}
.bottom_btn_copywriting[data-v-7d45a254] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 0.26042rem;
}
.bottom_btn_copywriting .link_my_store_btn[data-v-7d45a254] {
  width: 1.04167rem;
  height: 0.26042rem;
  background: #55d494;
  border-radius: 6px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  cursor: pointer;
}
.bottom_btn_copywriting .link_my_store_btn p:nth-child(1) img[data-v-7d45a254] {
  width: 0.13021rem;
  height: 0.13021rem;
  margin-left: 0.07813rem;
  margin-top: 0.06771rem;
}
.bottom_btn_copywriting .link_my_store_btn p[data-v-7d45a254]:nth-child(2) {
  color: #ffffff;
  font-size: 0.09375rem;
  margin-top: 0.06771rem;
  margin-left: 0.05208rem;
}
.bottom_btn_copywriting .add_shopify[data-v-7d45a254] {
  margin-left: 0.26042rem;
  width: 1.04167rem;
  height: 0.26042rem;
  background: #93df89;
  border-radius: 6px;
}
.bottom_btn_copywriting .add_shopify p[data-v-7d45a254] {
  font-size: 0.09375rem;
  color: #ffffff;
  margin-top: 0.05208rem;
  text-align: center;
}
.bottom_Copywriting[data-v-7d45a254] {
  text-align: center;
  margin-top: 0.07813rem;
}
.bottom_Copywriting p[data-v-7d45a254] {
  font-size: 0.08333rem;
  color: #64748e;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}