@charset "UTF-8";
.dividedDropItem[data-v-4926cc32] {
  border-top: 1px solid #ebeef5;
}
.el-dropdown-menu__item[data-v-4926cc32] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  color: #15104b;
  font-size: 0.07292rem;
}
.el-dropdown-menu__item img[data-v-4926cc32] {
  width: 0.10417rem;
  height: 0.10417rem;
  margin-right: 0.05208rem;
}
.el-dropdown-menu__item--divided[data-v-4926cc32]:before {
  margin: 0;
}
.header[data-v-4926cc32] {
  width: 100%;
  height: 0.36458rem;
  background: #eff1f3;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-right: 0.52083rem;
  position: relative;
}
.header[data-v-4926cc32]::after {
  content: "";
  position: absolute;
  height: 0.00521rem;
  width: calc(100% - 0.3125rem);
  background: #dedede;
  left: 0.3125rem;
  bottom: 0;
}
.header .headerContent[data-v-4926cc32] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.header .headerContent .breadContent[data-v-4926cc32] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  padding-left: 0.29167rem;
  height: 100%;
  padding-bottom: 0.07813rem;
}
.header .headerContent .breadContent[data-v-4926cc32] .el-breadcrumb {
  min-width: 1.5625rem;
}
.el-icon-arrow-down[data-v-4926cc32] {
  font-size: 0.0625rem;
}
.HeaderTitle[data-v-4926cc32] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.HeaderTitleTop[data-v-4926cc32] {
  top: 0.07813rem;
}
.multitudinous_icon[data-v-4926cc32] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 0.05729rem;
  position: relative;
}
.multitudinous_icon .Co_Funder[data-v-4926cc32] {
  width: 0.52083rem;
  margin-right: 0.15625rem;
  cursor: pointer;
}
.multitudinous_icon .Co_Funder p[data-v-4926cc32] {
  font-size: 0.08333rem;
  color: #3f6aff;
}
.multitudinous_icon .nypayment-btn[data-v-4926cc32] {
  width: 0.78125rem;
  cursor: pointer;
}
.multitudinous_icon .nypayment-btn p[data-v-4926cc32] {
  font-size: 0.08333rem;
  color: #3f6aff;
}
.multitudinous_icon .UsaDropProductBtn[data-v-4926cc32] {
  width: 0.78125rem;
  margin-right: 0.15625rem;
  cursor: pointer;
}
.multitudinous_icon .UsaDropProductBtn p[data-v-4926cc32] {
  font-size: 0.08333rem;
  color: #3f6aff;
}
.multitudinous_icon .Payment[data-v-4926cc32] {
  width: 0.52083rem;
  margin-right: 0.15625rem;
  cursor: pointer;
}
.multitudinous_icon .Payment p[data-v-4926cc32] {
  font-size: 0.08333rem;
  color: #3f6aff;
}
.multitudinous_icon .exhibition[data-v-4926cc32] {
  width: 0.52083rem;
  margin-right: 0.15625rem;
  cursor: pointer;
}
.multitudinous_icon .exhibition p[data-v-4926cc32]:nth-child(1) {
  font-size: 0.09375rem;
  color: #d9534f;
  font-weight: 600;
}
.multitudinous_icon .el_menu[data-v-4926cc32] {
  margin-left: 0.05208rem;
}
.multitudinous_icon .el_menu[data-v-4926cc32] .el-dropdown {
  width: 100%;
  height: 0.125rem;
}
.multitudinous_icon .el_menu[data-v-4926cc32] .el-dropdown .el-dropdown-link {
  cursor: pointer;
  color: #15104b;
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.SliceSmallBell_img[data-v-4926cc32] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 0.22917rem;
  margin-right: 0;
}
.toggleNoticeBlock[data-v-4926cc32] {
  width: 0.02604rem;
  height: 0.02604rem;
  background: red;
  border-radius: 50px;
}
.SliceSmallBell_img img[data-v-4926cc32] {
  width: 0.14583rem;
  height: 0.14583rem;
  cursor: pointer;
}
.SliceSmallBell_img_member[data-v-4926cc32] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 0.22917rem;
  margin-right: 0;
}
.SliceSmallBell_img_member img[data-v-4926cc32] {
  width: 0.14583rem;
  height: 0.14583rem;
  cursor: pointer;
}
.SliceProfile_img_GradeIdOne[data-v-4926cc32] {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCCAMAAADUivDaAAAApVBMVEUAAAArh/wogvwrhv0rhvsrhvwsh/wvgP4rh/wrh/wlhPoie/krgvssh/srhvwrhvsrh/srhvsphf0ohv8rhvsrh/wqhfwqhPkog/wrh/wrhvsrhvssh/sshvsrhvsrhf4rh/wrhvwrh/srhvsrhvssh/wqhPsrhvwrh/wshvsrh/wrhvwqhvsshvsrhvwshvwrhvwrh/wqhfwrhPosh/wphforh/z6S9Q+AAAANnRSTlMA/Q0dbz7uCcCdEAYT3mJDx34oF/iyOCwidl3y082IGeji142CeR+topJYUki3p5hnLk0zUDJgb6pGAAACTUlEQVRYw+2U6bKiMBCFEQIYEFAYQRiX6+WC+4Ko7/9oc8QaKyBLMj+n/JCy0t3nJOlUkD58EEUltG9p97tm9WOi/oOear17r3e/41eg0auI3hhh8qf69Q87a5QKGPSgwPuQ4nmhjQyuLVi9Jxol1zSFZaaS1d+gFXUvYf6r4CtWq835fmbmHQ7p8PeDZWLUuEfTIjlt7Ygy0x6EckM6LNIzpc3hCwyDzgqlMT9cAipLLch0CRo9+hYYSR0QC4zr50m+AWkRs3VJXeY6BRuJg80U5DWbHM9ms7r18ZfaQ7CQuJgMgV2Nen2AKB82ig/VZYQIrosg31ZQXr0t68Ph4EjcRChf6aWQMx6PqcSPfoTAL+9jtVoRSQAbgp9ShB6Px1TEIoCgtGx3vV7HkhAnSFy2FZRSImZhQ8L2/3w6nTAWwYGEPVay2WwmYhYZJCF7+eI4NsQsBpCw13UOJDH0iuYHcAmbNSPApWvWhGGSmIIWYRKy7bRJSGQxB5kQYjPjLcaCh7qAZMeM1fP5HIhZBJDs2bFt26qYhQoJO6sXRdFVzOIKiceMzd12u0WAH2MLysva7XauiEUOgV+KKJfLZS90USFQShHT2e/3qcCRorza/4njOD6/hY9y9y2oqqrC66CowHyL+r6fI8qD6YOabWf5Lec8lOCW34Ka6eQsC4IFVy9RmBm1GRcMOD55LvDqc+kEwL0dYwKUxhUqwOhwUMCiue/eg0HrLrwFntY5Bg/0prRepDvWKRfUe5j6Myu1gzrd1PG+7/aV+PC/8wdPQzwzgQIuUgAAAABJRU5ErkJggg==);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 0.29688rem;
  height: 0.29688rem;
  position: relative;
}
.SliceProfile_img_GradeIdOne .avater[data-v-4926cc32] {
  width: 0.20833rem;
  height: 0.20833rem;
  box-shadow: 0 0 0.00521rem 0 rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-radius: 50%;
  margin-right: 0.02604rem;
  cursor: pointer;
  position: absolute;
  top: 0.05208rem;
  left: 0.05208rem;
}
.SliceProfile_GradeId_img[data-v-4926cc32] {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCCAMAAADUivDaAAABblBMVEUAAADQPznMODDPPjTRPzfUST7QOTXRPzjRQTnQQDjRQTjSPzbNPTbRQDjRQTjRPzjOPjXRQTnRQDnRQDnRQDjQQDfQQDjQPzXRQDnRQTjSQDjSPjjRQDnRQDjRQDjRQDjQPzjOPzbSOzbRQDnRQTnRQDjRQDjRQDjRQDnRQDnRQDjRQDjQQDjRPzfQQDbSQTnRQDnRQTnRPzfSPzjRQDjRQDjQQTjRQTnQPzjRQjr5r6/RQTnRQDjSQTrTQDj1ra3RQDnRQTjRQTjngn/QPzjSQDjyp6bvmprTRD3mgX3ul5Tngn71raz1razkfnnRQDf2rKzOQDfRQTv1ra3jfHjnj4/RQjr1rq3UTUX2rq3gbmjfaWXleXXnfXrcYl3XUEjPQDn0ra3zq6v2r6/hc2znf3vRQDjjdXDcYl3bXlf1razgbWjfaWTZWVPXUkvTRz/le3fhcWzVTkfpiobeZmDVS0Psk5HngX3jeHTYVk9rDrl8AAAAZnRSTlMAHAcSLQ4EYt53PRgMbV03Iv3TnIJxQifYx4cf7czCp0cXEPfnt7KtopiSjVJNMv7xvSkK4p99eldAELNoWEMfz8CwgH5nTyD579/fr5+QfG9TUD8gEPrv5N/fv6+fn5+Qj4Bvb0CH3sYGAAADEUlEQVRYw7TT+U/aYBwG8IeEpmVaNqgoDGoT5F4WZEoYssi14Dm3TN19HxSLCgw59t/vW1hMsba2NX6a9If2+7TP+zbF3Sjv/4AzG/vfMfFTUeBMQ1HK0xYvXjltQcnb22psATiu1+HU8e5wt36Eg678y3GJ4bDf39kb99of4VS/Pxp15V77osUgWgzkK1yTcNVaoGhhLzdA9kbdsdw+vWidfK1Gms0sHepZFal5Ye69cghgR61w2jo5+zto6nH5sFmJt0oDvz+P5Z5a4ez8T0ebzV6enps8pHyIg8sK54NO1ueL5AOvw1EAYdoVzjfFeWHik9z+X2HQeRN7eQ8z3EJwZeIhA0NHHyYVaBG+WCEKPa9/VeU3WQzzZVphxWP0Ik9EVXHBkOsdVdiuuE0mxBgRXYb3Q+ux7dVvDEwwwjpZdhncrXKExw14jpQYXKcQJDxuxAdJAdd47CcZWLDgJ0XozJVEUSzNwwJGHU3oR9kQmYMlSyHC6kosExYWsTQcv1rDk8vlJFg1X6NxL2al4vH4Jizz0nhytsZmIpEQYN2aRIH70PIkk0keNjylwAK08pIkhWHDEwoIa9oLqVTqEWxJU2RJuxWCILCwhaWIdv+fpdPpRdiySBHtZ+UzmcwD2OKmiEf7k/5rn451E4aBMAD/tuNDsgNhKEEqS5wVk4FIKJXIVuaKvv/D9GgXNw0kN1bikzJccn8k63z7/b6GiOFIuq6vDDLtIPPCIDPMnN5PJwh9cCi9nExD5pZJh7zZbCxELEfSq9Qvl0vhUBVHYlK/Xc6XDCLZ+XKu0h1ZLBYOIo4jx6QOq9Wqg8gnR0JS69j3XYBA3XddRMpdYzxCoInxWiDlD4dDRZJF5YBCisqqqgRjVdzu8Nu6LEtHmKvg9uHBqXDOKcy0c0xjQBWsxSy6YApDlDVNk2GW71aNP2zG1KxjZMxgRNgyg0lmywJGqTUzmFCv2Q7jyCs28Q+jmCfcE7wPPuA+Mp4ZPFCbG407WnNT46HcWpvbljBC53lu+cEE0j8IQ8mHOYhGXxGe/oEvLyOJ/cLyXtUAAAAASUVORK5CYII=);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 0.30208rem;
  height: 0.30208rem;
  position: relative;
}
.SliceProfile_GradeId_img .avater[data-v-4926cc32] {
  width: 0.20833rem;
  height: 0.20833rem;
  box-shadow: 0 0 0.00521rem 0 rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-radius: 50%;
  margin-right: 0.05208rem;
  cursor: pointer;
  position: absolute;
  top: 0.05729rem;
  left: 0.05208rem;
}
.SliceProfile_img[data-v-4926cc32] {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCCAMAAADUivDaAAABVlBMVEUAAAD/tTH8sSv2qCf7sCv5ryX8sCn/sC39sCv8sSv9sSv7ryr8rin8sSr8sSv9sCv8sCv8sCv8ryv9ryr5rSf8sSz8sCv8sCv9sSz8sSz8sSv8sCr8sSv8sCv8sCz8sSz8sCv8sSv7ryv8sCv8ryv8sCv9sSz9sSv8sCv/1oP/1Ij/tCr8sSv8sCv8sSz8sCv7sCr9sSv8sSv9sCr9sSv6rib8sCv8sSz9sSv9sSz7ryr9sSv9sCv8sSz8sSz8sCv9siv+yWf/2Yz/14b+yGX9si37sCv8siz/2Y3/2I3+w1b8sCz9x2L8sS3/2Y38sCz/w2D/2o39tjj/2Y7/0n3/zGr+x2H+wVX/0HX8v1D+yF/9szL/2o3/2Y39w1f7x2T/tyz8sSv+xl7+xFj9uD39szH9ukP8tjf+wFD9vkv9vUf/2Y3+wlT9sSz+zXD+y2r+yGT+0HfocnbxAAAAYXRSTlMADh4HLBEXBN6zGz0oFP2cgn1CNyLNeFL47tdsYlfSx8KnjVxHkm5nMh8QCuO9t6KHdnJNPwzyrp+XY+esXkTTiIBvT+/oxr+vn5+YkE8/JSDv5N/f39/f3r+vno+Ab0AuPhfjRwAAAu5JREFUWMO00etvmmAUBvAHYxdwDTgXjFTGShtZsF5C0g7JbJ21t/W++/2i1rmVVe3//2XntTPiIhRY9kv89pyHc17xv5gHnxFP8eATxrZdF/G0XNcEYz7bjrsFTf47s2WyrmYTcRVfXb9rnuO40/0Se4nrwWC486bT/fUecQ2Gw1GHGn785LCwbtRkvk34fdtYD3FDEeT1aDQuuPz+cV+5al/RPPsxir2JYC/dUwA7nT8NF/32xLSGryWClnjutvD1cFJw0e95Cjwt6YAS8xTH0xX6PVEUlZpxklgAkKBX4cUbfOA5H7pdVkANvV5u995dzPgmLeXGnnLwdf52coRYys7LrTYeM42AY7jDy5sVLL8PWQojB3QkXrAGLRmQkJ8QOQUfqb3Sbk5Z4RCAk0pkz6eDW+aJgVsYPBHmfye7RPK4VZ7lspjjRCMFhJDWtIaWmfMQgizLtF8InE9UfUhSCOWMZVX85c4yURGSSmFhA7OscrmsI6wNgeKbmFWrVCpbCG2V4vU1eGUEQZAQ3ppOA/fhZdl2PY8IHtj1ehpekq7rCUSQpAHJe8litVpdQSRHNLKIqS1JklREotKI9/0fHTlOBpFkHMfx/q1GoVA4QyRJGrEw9btdOuZNGAaiAPwS41iysVSSqB4gC0QiUpYWyJKh4v//qj42Qx05t1Z828n3LjrldsfjUUNEM7KL6i+CzHi/P2U+CAKJzI4gkMh8koLMNzNROV8ulwIiBSNzfPH7/b6FiGUkRPXtfD5vhHfBSB/X82F2EHHz4RB/tTqdTh1EAiNVVKvQdZ2HgGYgIOZCCFsIDAw0iFXX67U3WK9moETM/PR93wp+KdtveNbWdX0zWMux/XVx0zjnSqxUOlJ4YRsasYpqyCbObRiG3IXGrQp/FBuyq9bYkEZCtSWPLL+lCkm2JY0M3VKJNGNL8pkJJVmDBcZX9JiR6TBYprX3XissUJp8ZtepoGk0yQHT460YkWEUkVl+ePvvfgHtsIRoJyoAGwAAAABJRU5ErkJggg==);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 0.29688rem;
  height: 0.30208rem;
  position: relative;
}
.SliceProfile_img .avater[data-v-4926cc32] {
  width: 0.20833rem;
  height: 0.20833rem;
  box-shadow: 0 0 0.00521rem 0 rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-radius: 50%;
  margin-right: 0.02604rem;
  cursor: pointer;
  position: absolute;
  top: 0.05729rem;
  left: 0.05208rem;
}
.Slicelgout_img img[data-v-4926cc32] {
  width: 0.10417rem;
  height: 0.10417rem;
  cursor: pointer;
}
.Welcome_Copywriting[data-v-4926cc32] {
  width: 0.52083rem;
  height: 0.125rem;
  line-height: 0.125rem;
  font-size: 0.10417rem;
  font-family: Inter-Semi Bold, Inter;
  color: #15104b;
  margin-right: 0.02604rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.VIPBox[data-v-4926cc32] {
  width: 1.79688rem;
  height: 1.04167rem;
  background: #ffffff;
  box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.16);
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  position: absolute;
  top: 0.22396rem;
  right: -0.05208rem;
  z-index: 99;
}
.VIPBox_title[data-v-4926cc32] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.VIPBox_title p:nth-child(1) img[data-v-4926cc32] {
  margin-left: 0.23438rem;
  margin-top: 0.10417rem;
}
.VIPBox_title p[data-v-4926cc32]:nth-child(2) {
  font-size: 0.10417rem;
  color: #333333;
  margin-left: 0.07813rem;
  margin-top: 0.14583rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.HeaderMember_membershipExpire p[data-v-4926cc32] {
  font-size: 0.07292rem;
  color: #333333;
  margin-left: 0.28646rem;
  margin-top: 0.05208rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.memberVo_EffectiveEndDate_box[data-v-4926cc32] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.memberVo_EffectiveEndDate[data-v-4926cc32] {
  color: #d14139;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.07813rem;
}

/* SVIP */
.memberVo_EffectiveEndDate_SVIP[data-v-4926cc32] {
  color: #fcb12c;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.07813rem;
}
.HeaderMember_Membership span[data-v-4926cc32]:nth-child(1) {
  color: #333333;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.HeaderMember_Membership span[data-v-4926cc32]:nth-child(2) {
  color: #d14139;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* SVIP */
.HeaderMember_Membership_SVIP span[data-v-4926cc32]:nth-child(1) {
  color: #333333;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.HeaderMember_Membership_SVIP span[data-v-4926cc32]:nth-child(2) {
  color: #fcb12c;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Automatically_uponExpiration[data-v-4926cc32] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-left: 0.28646rem;
}
.Automatically_uponExpiration p[data-v-4926cc32]:nth-child(1) {
  color: #d14139;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Automatically_uponExpiration p[data-v-4926cc32]:nth-child(2) {
  color: #333333;
  font-size: 0.07292rem;
  margin-left: 0.02604rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* SVIP */
.Automatically_uponExpiration_SVIP[data-v-4926cc32] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-left: 0.28646rem;
}
.Automatically_uponExpiration_SVIP p[data-v-4926cc32]:nth-child(1) {
  color: #fcb12c;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Automatically_uponExpiration_SVIP p[data-v-4926cc32]:nth-child(2) {
  color: #333333;
  font-size: 0.07292rem;
  margin-left: 0.02604rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.HeaderMember_Downgrade_btn[data-v-4926cc32] {
  text-align: center;
  margin-top: 0.05208rem;
}
.el-button[data-v-4926cc32] {
  border-radius: 50px !important;
}
.ShowMemberPopVIP_Box[data-v-4926cc32] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.6);
  /* 基础 */
}
.ShowMemberPopVIP_Box .ShowMemberPopVIP_Content[data-v-4926cc32] {
  width: 5.78125rem;
  height: 3.90625rem;
  background: #ffffff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  margin-left: 2.23958rem;
  margin-top: 0.26042rem;
  position: relative;
  overflow-x: auto;
  scrollbar-width: none;
  padding-bottom: 0.13021rem;
}
.ShowMemberPopVIP_Box .closeBtn[data-v-4926cc32] {
  position: absolute;
  top: 0.05208rem;
  right: 0.15625rem;
}
.ShowMemberPopVIP_Box .closeBtn .el-icon-close[data-v-4926cc32] {
  font-size: 0.125rem;
}
.ShowMemberPopVIP_Box .membershipFee_membershipFollows[data-v-4926cc32] {
  text-align: center;
}
.ShowMemberPopVIP_Box .membershipFee_membershipFollows p[data-v-4926cc32]:nth-child(1) {
  font-size: 0.09375rem;
  font-weight: 500;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  padding-top: 0.15625rem;
}
.ShowMemberPopVIP_Box .membershipFee_membershipFollows p[data-v-4926cc32]:nth-child(2) {
  font-size: 0.09375rem;
  font-weight: 500;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.ShowMemberPopVIP_Box .VIPMembershipBenefits[data-v-4926cc32] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.ShowMemberPopVIP_Box .memberInfoVIP_box[data-v-4926cc32] {
  width: 1.35417rem;
  height: auto;
  background: #fff3f2;
  border-radius: 16px;
  margin-top: 0.20833rem;
  margin-left: 0.59896rem;
}
.ShowMemberPopVIP_Box .memberInfoVIP_box .BigMemberImgVIPSliceImg img[data-v-4926cc32] {
  width: 0.29167rem;
  height: 0.29167rem;
  margin-left: 0.52083rem;
  margin-top: 0.15625rem;
}
.ShowMemberPopVIP_Box .memberInfoVIP_box .Winner_Product_Quote p[data-v-4926cc32] {
  font-size: 0.07292rem;
  line-height: 0.20833rem;
  text-align: center;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.ShowMemberPopVIP_Box .memberInfoVIP_box .Winner_Product_Quote p .el-icon-check[data-v-4926cc32] {
  font-size: 0.10417rem;
}
.ShowMemberPopVIP_Box .MemberBenefitsDescription[data-v-4926cc32] {
  width: 1.35417rem;
  height: auto;
  margin-top: 0.20833rem;
  margin-left: 0.26042rem;
}
.ShowMemberPopVIP_Box .MemberBenefitsDescription .ArrowSliceImg img[data-v-4926cc32] {
  width: 0.83333rem;
  height: 0.13021rem;
  margin-left: 0.26042rem;
  margin-top: 0.26042rem;
}
.ShowMemberPopVIP_Box .MemberBenefitsDescription .Winner_Product_Quote[data-v-4926cc32] {
  margin-top: 0.02604rem;
}
.ShowMemberPopVIP_Box .MemberBenefitsDescription .Winner_Product_Quote p[data-v-4926cc32] {
  font-size: 0.07292rem;
  line-height: 0.18229rem;
  text-align: center;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-top: 0.03125rem;
}
.ShowMemberPopVIP_Box .BasicMembership[data-v-4926cc32] {
  width: 1.35417rem;
  height: auto;
  background: #edf4fe;
  border-radius: 16px;
  margin-top: 0.20833rem;
  margin-left: 0.26042rem;
}
.ShowMemberPopVIP_Box .BasicMembership .BigMemberImgVIPSliceImg img[data-v-4926cc32] {
  width: 0.29167rem;
  height: 0.29167rem;
  margin-left: 0.52083rem;
  margin-top: 0.15625rem;
}
.ShowMemberPopVIP_Box .BasicMembership .Winner_Product_Quote p[data-v-4926cc32] {
  font-size: 0.07292rem;
  line-height: 0.20833rem;
  text-align: center;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.ShowMemberPopVIP_Box .BasicMembership .Winner_Product_Quote p .el-icon-check[data-v-4926cc32] {
  font-size: 0.10417rem;
}
.ShowMemberPopVIP_Box .Back_Downgrade_Btn_Box[data-v-4926cc32] {
  text-align: center;
}
.ShowMemberPopVIP_Box .HeaderMember_Back[data-v-4926cc32] {
  width: 1.04167rem;
  height: 0.22917rem;
  background: #ffffff;
  border-radius: 22px 22px 22px 22px;
  opacity: 1;
  border: 2px solid #15104b;
  margin-top: 0.18229rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.ShowMemberPopVIP_Box .HeaderMember_Back[data-v-4926cc32]:hover {
  background: #15104b;
  color: #ffffff !important;
}
.ShowMemberPopVIP_Box .HeaderMember_Downgrade[data-v-4926cc32] {
  width: 1.04167rem;
  height: 0.22917rem;
  background: #15104b;
  border-radius: 22px 22px 22px 22px;
  opacity: 1;
  color: #ffffff;
  margin-top: 0.18229rem;
  margin-left: 0.3125rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.ShowMemberPopVIP_Box .HeaderMember_Downgrade[data-v-4926cc32]:hover {
  opacity: 0.93;
  color: #ffffff !important;
}
.cancellation_box[data-v-4926cc32] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.6);
  /* VIP */
  /* SVIP */
}
.cancellation_box .cancellation_vip_box[data-v-4926cc32] {
  width: 2.91667rem;
  height: 1.46354rem;
  background: #ffffff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  margin: 15% auto;
  position: relative;
}
.cancellation_box .cancellation_vip_box .closeBtn[data-v-4926cc32] {
  position: absolute;
  top: 0.05208rem;
  right: 0.10417rem;
  font-size: 0.10417rem;
}
.cancellation_box .cancellation_vip_box .HeaderMember_YouCancelled[data-v-4926cc32] {
  margin-left: 0.15625rem;
  padding-top: 0.18229rem;
}
.cancellation_box .cancellation_vip_box .HeaderMember_YouCancelled p[data-v-4926cc32] {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.cancellation_box .cancellation_vip_box .membershipFeeYour_thismonthwill[data-v-4926cc32] {
  margin-left: 0.15625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.02604rem;
}
.cancellation_box .cancellation_vip_box .membershipFeeYour_thismonthwill p[data-v-4926cc32]:nth-child(1) {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.cancellation_box .cancellation_vip_box .membershipFeeYour_thismonthwill p[data-v-4926cc32]:nth-child(2) {
  color: #d14139;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.02604rem;
}
.cancellation_box .cancellation_vip_box .chargednormal_wewill[data-v-4926cc32] {
  margin-left: 0.15625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.02604rem;
}
.cancellation_box .cancellation_vip_box .chargednormal_wewill p[data-v-4926cc32]:nth-child(1) {
  color: #d14139;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.cancellation_box .cancellation_vip_box .chargednormal_wewill p[data-v-4926cc32]:nth-child(2) {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.02604rem;
}
.cancellation_box .cancellation_vip_box .HeaderMember_automaticallyfees[data-v-4926cc32] {
  margin-left: 0.15625rem;
  margin-top: 0.02604rem;
}
.cancellation_box .cancellation_vip_box .HeaderMember_automaticallyfees p[data-v-4926cc32] {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.cancellation_box .cancellation_vip_box .HeaderMember_nextmonth[data-v-4926cc32] {
  margin-left: 0.15625rem;
  margin-top: 0.02604rem;
}
.cancellation_box .cancellation_vip_box .HeaderMember_nextmonth p[data-v-4926cc32] {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  color: #2c87fb;
}
.cancellation_box .cancellation_vip_box .HeaderMember_OK[data-v-4926cc32] {
  text-align: center;
}
.cancellation_box .cancellation_vip_box .HeaderMember_OK .el-button[data-v-4926cc32] {
  width: 1.04167rem;
  height: 0.22917rem;
  background: #15104b;
  border-radius: 22px 22px 22px 22px;
  opacity: 1;
  color: #ffffff;
  margin-top: 0.18229rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.cancellation_box .cancellation_Svip_box[data-v-4926cc32] {
  width: 2.91667rem;
  height: 1.46354rem;
  background: #ffffff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  margin: 15% auto;
  position: relative;
}
.cancellation_box .cancellation_Svip_box .closeBtn[data-v-4926cc32] {
  position: absolute;
  top: 0.05208rem;
  right: 0.10417rem;
  font-size: 0.10417rem;
}
.cancellation_box .cancellation_Svip_box .HeaderMember_YouCancelled[data-v-4926cc32] {
  margin-left: 0.15625rem;
  padding-top: 0.18229rem;
}
.cancellation_box .cancellation_Svip_box .HeaderMember_YouCancelled p[data-v-4926cc32] {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.cancellation_box .cancellation_Svip_box .membershipFeeYour_thismonthwill[data-v-4926cc32] {
  margin-left: 0.15625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.02604rem;
}
.cancellation_box .cancellation_Svip_box .membershipFeeYour_thismonthwill p[data-v-4926cc32]:nth-child(1) {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.cancellation_box .cancellation_Svip_box .membershipFeeYour_thismonthwill p[data-v-4926cc32]:nth-child(2) {
  color: #fcb12c;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.02604rem;
}
.cancellation_box .cancellation_Svip_box .chargednormal_wewill[data-v-4926cc32] {
  margin-left: 0.15625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.02604rem;
}
.cancellation_box .cancellation_Svip_box .chargednormal_wewill p[data-v-4926cc32]:nth-child(1) {
  color: #fcb12c;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.cancellation_box .cancellation_Svip_box .chargednormal_wewill p[data-v-4926cc32]:nth-child(2) {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.02604rem;
}
.cancellation_box .cancellation_Svip_box .HeaderMember_automaticallyfees[data-v-4926cc32] {
  margin-left: 0.15625rem;
  margin-top: 0.02604rem;
}
.cancellation_box .cancellation_Svip_box .HeaderMember_automaticallyfees p[data-v-4926cc32] {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.cancellation_box .cancellation_Svip_box .HeaderMember_nextmonth[data-v-4926cc32] {
  margin-left: 0.15625rem;
  margin-top: 0.02604rem;
}
.cancellation_box .cancellation_Svip_box .HeaderMember_nextmonth p[data-v-4926cc32] {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  color: #2c87fb;
}
.cancellation_box .cancellation_Svip_box .HeaderMember_OK[data-v-4926cc32] {
  text-align: center;
}
.cancellation_box .cancellation_Svip_box .HeaderMember_OK_Btn[data-v-4926cc32] {
  width: 1.04167rem;
  height: 0.22917rem;
  background: #15104b;
  border-radius: 22px 22px 22px 22px;
  opacity: 1;
  color: #ffffff;
  margin-top: 0.18229rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}