
.el-empty[data-v-5dd92b76] {
  background: #fff;
}
.questionIcon[data-v-5dd92b76] {
  position: absolute;
  bottom: 0.67708rem;
  right: 0.26042rem;
}
.questionIcon[data-v-5dd92b76] .el-icon-question {
  font-size: 0.11458rem;
}

/* 查询订单对话框 */
.IncludedDiscount[data-v-5dd92b76] {
  width: 1.53646rem;
  height: 0.20833rem;
  background: #aeaeae;
  z-index: 21474899 !important;
  cursor: pointer;
  border-radius: 10px;
  position: absolute;
  bottom: 0.26042rem;
  left: 1.45833rem;
}
.IncludedDiscount p[data-v-5dd92b76] {
  /*color: #0000EE;*/
  color: #fff;
  margin-left: 0.07813rem;
  margin-top: 0.02604rem;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  line-height: 0.13021rem;
}
.IncludedDiscount[data-v-5dd92b76]::before {
  content: '';
  position: absolute;
  bottom: -15%;
  right: 0.20833rem;
  width: 0.11458rem;
  height: 0.11458rem;
  margin-top: -0.05208rem;
  background: inherit; /*自动继承父元素的背景*/
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.IncludedDiscount[data-v-5dd92b76] {
  position: absolute;
  bottom: 0.65104rem;
  right: 0.15625rem;
}
.AddStoreJiaHao[data-v-5dd92b76] {
  width: 1.5625rem;
  height: 0.26042rem;
  position: relative;
  margin-top: 0;
}

/* .AddStoreJiaHao:hover {
  background: #93df89;
  border: 1px solid #93df89;
} */
.AddStoreJiaHao p[data-v-5dd92b76]:nth-child(1) {
  position: absolute;
  top: 0.02604rem;
  left: 0.41667rem;
}
.AddStoreJiaHao p:nth-child(1) img[data-v-5dd92b76] {
  width: 0.20833rem;
  height: 0.20833rem;
}
.AddStoreJiaHao p[data-v-5dd92b76]:nth-child(2) {
  position: absolute;
  top: 0.0625rem;
  left: 0.67708rem;
  font-size: 0.11458rem;
  color: #fff;
}
.list[data-v-5dd92b76] .el-empty__description p {
  display: none;
}
.pagebox[data-v-5dd92b76] .el-pagination .el-select .el-input .el-input__inner {
  border: 1px solid #ccc;
  height: 0.18229rem;
  border-radius: 10px;
  margin-left: 0.05208rem;
}
.MonthlyRed[data-v-5dd92b76] {
  position: absolute;
  top: 0;
  left: 1.04167rem;
  cursor: pointer;
}
.MonthlyRed img[data-v-5dd92b76] {
  width: 0.10417rem;
  height: 0.10417rem;
}
.MonthlyRed span img[data-v-5dd92b76]:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

/* 线上导入订单 */
.UploadOrderBox[data-v-5dd92b76] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.UploadOrderContent[data-v-5dd92b76] {
  width: 3.125rem;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  /* overflow: hidden;*/
  left: calc(50% - 1.5625rem);
  top: calc(50% - 2.08333rem);
  margin-top: 0.83333rem;
  /*background-color: #fff;*/
  background-color: #ffffff;
  height: auto;
  border-radius: 10px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-bottom: 0.10417rem;
}
.uploadOrderTitle[data-v-5dd92b76] {
  background: #21224e;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 0.23438rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.uploadOrderTitle p[data-v-5dd92b76]:nth-child(1) {
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 0.09375rem;
  line-height: 0.20833rem;
  margin-left: 0.15625rem;
}
.uploadOrderTitle p[data-v-5dd92b76]:nth-child(2) .el-icon-close {
  color: #fff;
  font-size: 0.125rem;
  margin-right: 0.07813rem;
  line-height: 0.20833rem;
  cursor: pointer;
}
.Upload_order_template[data-v-5dd92b76] {
  width: 0.85938rem;
  height: 0.67708rem;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 2px solid #d1d1d1;
  text-align: center;
  margin-left: 1.19792rem;
  margin-top: 0.26042rem;
}
.Upload_order_template p[data-v-5dd92b76]:nth-child(1) {
  width: 0.26042rem;
  height: 0.26042rem;
  border: 2px dashed #999999;
  border-style: dashed;
  margin-left: 0.3125rem;
  margin-top: 0.15625rem;
}
.Upload_order_template p[data-v-5dd92b76]:nth-child(2) {
  color: #999999;
  font-size: 0.07292rem;
  margin-top: 0.02604rem;
}
.UploadFileBtn[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-left: 0.59896rem;
  margin-top: 0.10417rem;
}
.UploadFileBtn[data-v-5dd92b76] .el-button:nth-child(1) {
  width: 0.9375rem;
  height: 0.22917rem;
  background: #ffffff;
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  border: 2px solid #15104b;
  background: #fff !important;
  color: #15104b !important;
  font-weight: 600;
  font-size: 0.08333rem;
}
[data-v-5dd92b76] .upload-demo {
  width: 0.9375rem;
}
[data-v-5dd92b76] .el-upload-list__item-name {
  width: auto;
}
.UploadFileBtn[data-v-5dd92b76] .el-button:nth-child(2) {
  width: 0.9375rem;
  height: 0.22917rem;
  background: #15104b;
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  color: #ffffff;
  margin-left: 0.26042rem !important;
  font-size: 0.08333rem;
}
.Please_download[data-v-5dd92b76] {
  margin-left: 0.10417rem;
  margin-top: 0.10417rem;
}
.Please_download p[data-v-5dd92b76] {
  color: #666666;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.TextareaContent[data-v-5dd92b76] {
  width: 3.02083rem;
  margin-left: 0.05208rem;
  margin-top: 0.05208rem;
  word-break: normal;
}
.TextareaContent p[data-v-5dd92b76] {
}
.member_box[data-v-5dd92b76] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.member_set[data-v-5dd92b76] {
  width: 1.97917rem;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  /* overflow: hidden;*/
  left: calc(60% - 2.08333rem);
  top: calc(50% - 2.34375rem);
  margin-top: 0.83333rem;
  /*background-color: #fff;*/
  background-color: #2a293e;
  height: 2.60417rem;
  border-radius: 10px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.member_top[data-v-5dd92b76] {
  width: 1.19792rem;
  height: 1.14583rem;
  /* background: orangered; */
  margin-left: 0.26042rem;
}
.member_top img[data-v-5dd92b76] {
  width: 100%;
  height: 100%;
  margin-top: 0.18229rem;
  margin-left: 0.10417rem;
}
.member_down[data-v-5dd92b76] {
  width: 1.97917rem;
  /*height: 200px;
    background: orange; */
  margin-top: 0;
}
.member_down p[data-v-5dd92b76]:nth-child(1) {
  text-align: center;
  margin-top: 0.26042rem;
  font-size: 0.09375rem;
  /* font-weight: 600;*/
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
}
.member_down p[data-v-5dd92b76]:nth-child(2) {
  text-align: center;
  margin-top: 0.10417rem;
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
}
.Bindstore p[data-v-5dd92b76]:nth-child(1) {
  color: #fff;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.15625rem;
  text-align: center;
  line-height: 0.20833rem;
  word-break: normal;
}
.member_btn[data-v-5dd92b76] {
  margin-left: 0.28646rem;
  width: 0.78125rem;
  height: 0.23438rem;
  line-height: 0.23438rem;
  border-radius: 50px;
  margin-top: 0.10417rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.member_btn[data-v-5dd92b76] .el-button:nth-child(1) {
  margin-left: 0.02604rem;
  height: 0.23438rem;
  margin-bottom: -0.78125rem;
  border-radius: 50px;
  font-size: 0.08333rem;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  background: #93df89;
  border: 1px solid #93df89;
}
.member_btn[data-v-5dd92b76] .el-button:nth-child(2) {
  margin-left: 0.26042rem;
  height: 0.23438rem;
  margin-bottom: -0.78125rem;
  border-radius: 50px;
  font-size: 0.08333rem;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  background: #93df89;
  border: 1px solid #93df89;
}
.member_btn[data-v-5dd92b76] .el-button:hover {
  background: #bff4b8;
  color: #fff;
}
[data-v-5dd92b76] .el-message-box__message p {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.speed-progress[data-v-5dd92b76] {
  width: 1.14583rem;
  position: relative;
  left: 2.60417rem;
  top: -0.26042rem;
}
.progress-fulfilled span[data-v-5dd92b76]:nth-child(1) {
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.progress-fulfilled span[data-v-5dd92b76]:nth-child(2) {
  font-size: 0.07292rem;
  margin-left: 0.15625rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.progress-fulfilled span[data-v-5dd92b76]:nth-child(3) {
  font-size: 0.07292rem;
  margin-left: 0.02604rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.progress-fulfilled span[data-v-5dd92b76]:nth-child(4) {
  font-size: 0.07292rem;
  margin-left: 0.03125rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
[data-v-5dd92b76] .el-progress__text {
  font-size: 0.07292rem;
  color: #606266;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.05208rem;
  line-height: 1;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  /* display: none; */
}
[data-v-5dd92b76] .element.style {
  margin-top: -0.07813rem;
}
.fulfillment[data-v-5dd92b76] {
  width: 0.78125rem;
  height: 0;
}
[data-v-5dd92b76] .pro_detail.el-message-box__wrapper.el-message-box {
  display: inline-block;
  width: 2.60417rem;
  padding-bottom: 0.05208rem;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  font-size: 0.09375rem;
  box-shadow: 0 0.01042rem 0.0625rem 0 rgb(0 0 0 / 10%);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 1.04167rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.asteriskContact[data-v-5dd92b76] {
  width: 0.05208rem;
  height: 0.05208rem;
  line-height: 0.07813rem;
  color: red;
  position: absolute;
  left: 0.96354rem;
  top: 0.0625rem;
}
.asteriskTelePhone[data-v-5dd92b76] {
  width: 0.05208rem;
  height: 0.05208rem;
  line-height: 0.07813rem;
  color: red;
  position: absolute;
  right: 2.17708rem;
  top: 0.05208rem;
}
.asteriskAddress1[data-v-5dd92b76] {
  width: 0.05208rem;
  height: 0.05208rem;
  line-height: 0.07813rem;
  color: red;
  position: absolute;
  right: 2.10938rem;
  top: 0.05208rem;
}
.asteriskProvince[data-v-5dd92b76] {
  width: 0.05208rem;
  height: 0.05208rem;
  line-height: 0.07813rem;
  color: red;
  position: absolute;
  left: 0.73958rem;
  top: 0.05208rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.asteriskCity[data-v-5dd92b76] {
  width: 0.05208rem;
  height: 0.05208rem;
  line-height: 0.07813rem;
  color: red;
  position: absolute;
  right: 2.26563rem;
  top: 0.05208rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.SpecBox[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.SpecBox span[data-v-5dd92b76]:nth-child(1) {
  height: 0.46875rem;
  font-size: 0.07292rem;
  line-height: 0.46875rem;
  color: #858585;
  margin-right: 0.05208rem;
}
.SpecBox span[data-v-5dd92b76]:nth-child(2) {
  height: 0.46875rem;
  font-size: 0.07292rem;
  line-height: 0.46875rem;
  color: #858585;
  margin-right: 1.04167rem;
}
.particulars[data-v-5dd92b76] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
}
.main[data-v-5dd92b76] {
  width: 7.29167rem;
  padding: 0 0 0.16667rem;
}
.breadCrumb[data-v-5dd92b76] {
  padding-top: 0.3125rem;
  padding-left: 0.10417rem;
}
.Contact_input[data-v-5dd92b76] {
  width: 2.08333rem;
  height: 0.20833rem;
  border: 1px solid #ccc;
  float: left;
  color: #585858;
  text-align: center;
  border-radius: 50px;
  padding: 0.02604rem 0.10417rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Contact_input input[data-v-5dd92b76],[data-v-5dd92b76] .el-input__inner,[data-v-5dd92b76] .el-input {
  width: 100%;
  height: 100%;
  display: block;
  line-height: 0.15625rem;
  background: none;
  color: #585858;
  padding: 0;
  border: none;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.myselect[data-v-5dd92b76],[data-v-5dd92b76] .myselect.el-date-editor {
  width: 2.08333rem;
  height: 0.23958rem;
  background: #f1f1f2;
  padding: 0 0.07813rem;
  border-radius: 50px;
  color: #585858;
  display: inline-block;
  margin-right: 0.13021rem;
}
[data-v-5dd92b76] .myselect.el-date-editor {
  width: 2.08333rem;
}
[data-v-5dd92b76] .myselect input {
  font-size: 0.08333rem;
  background: #f1f1f2;
  cursor: pointer;
}

/* ::v-deep . */
[data-v-5dd92b76] .myselect input::-webkit-input-placeholder {
  color: #858585;
}
[data-v-5dd92b76] .el-input--suffix .el-input__inner {
  padding-right: 0.05208rem;
}
[data-v-5dd92b76] .myselect input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #858585;
}
[data-v-5dd92b76] .myselect input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #858585;
}
[data-v-5dd92b76] .myselect input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #858585;
}
[data-v-5dd92b76] .el-date-editor .el-range-separator {
  width: 10%;
}
.typebox[data-v-5dd92b76] {
  width: 100%;
  padding: 0 0.16667rem;
  background: -webkit-linear-gradient(bottom, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  overflow: hidden;
  position: relative;
}
.type[data-v-5dd92b76]:hover {
  color: #3f6aff !important;
}
.CanceledBtn[data-v-5dd92b76] {
  border-radius: 25px;
  min-width: 0.36458rem;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: none;
  color: #000;
  height: 0.20833rem;
  font-weight: 400;
  cursor: pointer;
  padding: 0.05208rem 0.09375rem;
  font-size: 0.08333rem;
  text-align: center;
  background-color: #dfe2e7;
}
.CanceledBtn[data-v-5dd92b76]:hover {
  opacity: 0.9;
}

/* 绑定店铺 */
.allStore[data-v-5dd92b76] {
  width: 0.67708rem;
  height: 0.20833rem;
  background: rgb(147, 223, 137);
  border-radius: 10px;
  line-height: 0.20833rem;
  font-size: 0.07292rem;
  color: #fff;
  text-align: center;
  float: right;
  margin-top: 0.10417rem;
  cursor: pointer;
  margin-right: 0.05208rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.allStore[data-v-5dd92b76]:hover {
  background: #bff4b8;
  color: #fff;
}
.list[data-v-5dd92b76] {
  background: #ffffff;
  box-shadow: 0 0 0.10417rem 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.list li[data-v-5dd92b76] {
  background: #fff;
  border-bottom: 2px solid #f2f2f2;
}

/* .list li:last-child { */
/* border: none; */
/* } */
.productListContentImg[data-v-5dd92b76] {
  margin-top: 0.07813rem;
}
.productListContentBox[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.productListContent[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: auto;
&:hover .cancel-btn[data-v-5dd92b76] {
    display: block;
}
}
.cancel-btn[data-v-5dd92b76] {
  display: none;
}
.productListProductName[data-v-5dd92b76] {
  width: 0.52083rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 0.05208rem;
  margin-top: 0.09375rem;
}
.ShowProductListProductName[data-v-5dd92b76] {
  width: auto;
  height: auto;
  border: 1px solid #fff;
  border-radius: 5px;
  padding-left: 0.02604rem;
  padding-right: 0.02604rem;
  margin-left: 0.3125rem;
  position: absolute;
  background: #fff;
}
.productListProductNumber[data-v-5dd92b76] {
  margin-left: 0.05208rem;
  margin-top: 0.02604rem;
}
.podTag[data-v-5dd92b76] {
  width: 0.25rem;
  height: 0.125rem;
  margin-left: 0.05208rem;
  margin-top: 0.02604rem;
  background: #ffeeee;
  font-size: 0.0625rem;
  font-weight: 600;
  color: #d40000;
  border-radius: 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0.02604rem;
}
.NoNeedToShip[data-v-5dd92b76] {
  margin-left: 0.05208rem;
  margin-top: 0.02604rem;
}
.content[data-v-5dd92b76] {
  width: 100%;
  height: 0.42708rem;
  line-height: 0.42708rem;
  /* font-size: 16px; */
  font-size: 0.07292rem;
  color: #000000;
  text-align: center;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
}
[data-v-5dd92b76]::-webkit-scrollbar {
  display: none;
}
.copper_coin[data-v-5dd92b76] {
  text-decoration: line-through;
  color: red;
}
.info_down[data-v-5dd92b76] {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.USAdrop_payment[data-v-5dd92b76] {
  width: 2.08333rem;
  height: 0.26042rem;
  /* background: pink; */
  position: absolute;
  left: 1.14583rem;
  bottom: 0.05208rem;
}
.USAdrop_payment span[data-v-5dd92b76]:nth-child(1) {
  margin-left: -0.33854rem;
  color: #898989;
  font-size: 0.07292rem;
}
.USAdrop_payment span[data-v-5dd92b76]:nth-child(2) {
  margin-left: 0.28646rem;
  color: #898989;
  font-size: 0.08333rem;
}
.order_status .order_btn[data-v-5dd92b76] {
  float: none;
  display: inline-block;
}
.order_status .success[data-v-5dd92b76] {
  /* min-width: 135px; */
  min-width: 0.52083rem;
  height: 0.19792rem;
  line-height: 0.19792rem;
  font-size: 0.07292rem;
  padding: 0 0.10417rem;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  display: inline-block;
  color: #209a76;
  float: none;
}
.order_status .cancelbtn[data-v-5dd92b76] {
  min-width: 0.52083rem;
  height: 0.19792rem;
  line-height: 0.19792rem;
  font-size: 0.07292rem;
  padding: 0 0.10417rem;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  display: inline-block;
  background: #fedddc;
  float: none;
  color: #f81b17;
}
.cancelbtn[data-v-5dd92b76]:hover {
  background: #fed1d1;
}
.pagebox[data-v-5dd92b76] {
  width: 100%;
  height: 0.36458rem;
  padding-bottom: 0.02083rem;
  background-color: #fff;
  margin-top: 0.19792rem;
  border-radius: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  justify-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
[data-v-5dd92b76] .el-pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.page_sign[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 0.07813rem;
}
.page_sign input[data-v-5dd92b76] {
  width: 0.23438rem;
  height: 0.14583rem;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.page_sign span[data-v-5dd92b76] {
  display: inline-block;
  margin-right: 0.05208rem;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.detail[data-v-5dd92b76] {
  width: 100%;
  padding: 0.0625rem 1.32292rem 0.05208rem 1.51042rem;
  position: relative;
  background: #f9f9fa;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.pro[data-v-5dd92b76] {
  width: 6.35417rem;
  padding: 0.10417rem 0.07813rem 0.10417rem 0.07813rem;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #cccccc;
  margin-top: -0.00521rem;
  margin-left: -1.30208rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.pro_img[data-v-5dd92b76] {
  width: 0.3125rem;
  height: 0.3125rem;
  display: block;
  float: left;
  margin-top: 0.07813rem;
}
.pro_detail[data-v-5dd92b76] {
  width: 2.34375rem;
  height: 0.46875rem;
  float: left;
  margin-left: 0.26042rem;
  padding-top: 0.01563rem;
}
.pro_name[data-v-5dd92b76] {
  width: 100%;
  height: 0.20833rem;
  line-height: 0.26042rem;
  word-wrap: break-word;
  font-size: 0.07292rem;
  color: #585858;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  /* margin-bottom: 16px; */
  /* background: orange; */
  margin-top: 0.08333rem;
}
.pro_size[data-v-5dd92b76] {
  width: 100%;
  line-height: 0.33854rem;
  font-size: 0.07292rem;
  color: #858585;
}
.pro_size span[data-v-5dd92b76] {
  color: #ff7768;
  float: right;
  cursor: pointer;
}
.Spec[data-v-5dd92b76] {
  display: inline-block;
  height: 0.46875rem;
  font-size: 0.07292rem;
  line-height: 0.46875rem;
  color: #858585;
  margin-left: 1.30208rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.pro_money[data-v-5dd92b76] {
  height: 0.46875rem;
  font-size: 0.08333rem;
  line-height: 0.46875rem;
  float: right;
  color: #858585;
  margin-right: 0.9375rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.cancel .pro_img[data-v-5dd92b76] {
  -webkit-filter: grayscale(50%);
          filter: grayscale(50%);
}
.cancel .productListProductNumber .cheapPrice[data-v-5dd92b76] {
  color: #c9c9ca;
  text-decoration: line-through;
}
.cancel .pro_size[data-v-5dd92b76] {
  color: #c6c6c6;
}
.cancel .pro_size span[data-v-5dd92b76] {
  color: #ff7768;
}
.cancel .pro_money[data-v-5dd92b76] {
  color: #c6c6c6;
}
.infobox[data-v-5dd92b76] {
  width: 100%;
  background: #f9f9fa;
  color: #585858;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.info_title[data-v-5dd92b76] {
  width: 100%;
  height: 0.20833rem;
  /*background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);*/
  line-height: 0.41667rem;
  font-size: 0.08333rem;
  padding: 0 0.21875rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.info_title .save[data-v-5dd92b76] {
  width: auto;
  height: 0.20833rem;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  /*position: absolute;
  top: 50px;
  right: 90px;*/
}
.info_title .save[data-v-5dd92b76]:hover {
  background: #8ce580;
  color: #fff;
}
.Save_btn[data-v-5dd92b76] {
  text-align: center;
  padding-bottom: 0.18229rem;
}
.options[data-v-5dd92b76] {
  width: 100%;
  overflow: hidden;
  padding-left: 0.39063rem;
  padding-top: 0.125rem;
  padding-bottom: 0.04167rem;
  position: relative;
}
.option[data-v-5dd92b76] {
  width: 2.89063rem;
  height: 0.21875rem;
  float: left;
  margin-bottom: 0.08333rem;
  font-size: 0.09375rem;
  color: #898989;
  line-height: 0.21875rem;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.inputname[data-v-5dd92b76] {
  width: 1.22396rem;
  height: 0.21875rem;
  padding-right: 0.21875rem;
  font-size: 0.07292rem;
  text-align: left;
  float: left;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-left: 0.41667rem;
}
.option .inputbox .countrys[data-v-5dd92b76] {
  height: 0.18229rem !important;
}
[data-v-5dd92b76] .el-input.is-disabled .el-input__inner {
  /* background: orangered; */
  margin-right: 0.39063rem;
  background: none;
  color: #585858;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
[data-v-5dd92b76] .el-form-item__error {
  width: 1.51042rem;
  padding-right: 0.21875rem;
  margin-left: 1.30208rem;
  margin-top: -0.02604rem;
  text-align: left;
}
.inputbox[data-v-5dd92b76] {
  width: 1.5625rem;
  height: 0.18229rem;
  border: 1px solid #878787;
  float: left;
  color: #585858;
  text-align: center;
  border-radius: 21px;
  padding: 0.02604rem 0.10417rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.inputbox input[data-v-5dd92b76],[data-v-5dd92b76] .el-input__inner,[data-v-5dd92b76] .el-input {
  width: 100%;
  height: 100%;
  display: block;
  line-height: 0.15625rem;
  background: none;
  color: #585858;
  padding: 0;
  border: none;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Member_exclusive[data-v-5dd92b76] {
  text-align: center;
  margin-top: -0.15625rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #4169e1;
  font-size: 0.07292rem;
}
.pay_left[data-v-5dd92b76] {
  text-align: right;
}
.recharge[data-v-5dd92b76] {
  width: 70%;
  height: 100%;
  border: 2px solid #409efe;
  border-radius: 50px;
  text-align: center;
  font-size: 0.08333rem;
  color: #4c8bf5;
  line-height: 0.22917rem;
  cursor: pointer;
  margin-left: 0.11458rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.05208rem;
}
.paybtn[data-v-5dd92b76] {
  width: 70%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  font-size: 0.08333rem;
  color: #fff;
  background: #474882;
  line-height: 0.25rem;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.05208rem;
}
.paybtn[data-v-5dd92b76]:hover {
  background: #67689a;
  color: #fff;
}
[data-v-5dd92b76] .el-pager li {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* 查询订单对话框 */
.DialogBoxOrder[data-v-5dd92b76] {
  width: 2.1875rem;
  height: 0.3125rem;
  background: #aeaeae;
  z-index: 21474899 !important;
  cursor: pointer;
  border-radius: 10px;
  position: absolute;
  top: 0.46875rem;
  left: 0.9375rem;
  font-size: 0.07292rem;
}
.DialogBoxOrder p[data-v-5dd92b76] {
  /*color: #0000EE;*/
  color: #fff;
  margin-left: 0.15625rem;
  margin-top: 0.02604rem;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.DialogBoxOrder[data-v-5dd92b76]::before {
  content: '';
  position: absolute;
  bottom: 28%;
  left: -0.05208rem;
  width: 0.11458rem;
  height: 0.11458rem;
  margin-top: -0.05208rem;
  background: inherit; /*自动继承父元素的背景*/
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  font-size: 0.07292rem;
}
.BackTop[data-v-5dd92b76] {
  /*border-radius: 50px;
  background: #15104B;*/
  position: fixed;
  bottom: 0;
  right: 0.83333rem;
  cursor: pointer;
}
.BackTop img[data-v-5dd92b76] {
  width: 0.18229rem;
  height: 0.18229rem;
}
/* From表单 */
.Form_big_box[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.LeftForm[data-v-5dd92b76] {
  margin-top: 0.15625rem;
  margin-left: 0.9375rem;
}
.RightForm[data-v-5dd92b76] {
  margin-top: 0.15625rem;
  margin-left: 0.41667rem;
}
.contact_name_input[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.REQUIRED[data-v-5dd92b76] {
  color: red;
  margin-left: 0.02604rem;
}
.contact_name_words p[data-v-5dd92b76] {
  font-size: 0.07292rem;
}
.order_user_Contact[data-v-5dd92b76] {
  width: 1.5625rem;
}
.order_user_Contact[data-v-5dd92b76] .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 0.10417rem;
  margin-left: 0.05208rem;
  height: 0.18229rem;
}
.PleaseSelect_Country[data-v-5dd92b76] .el-select {
  border: 1px solid #ccc;
  height: 0.18229rem;
  border-radius: 50px;
  padding-left: 0.10417rem;
  margin-left: 0.38021rem;
  width: 1.5625rem;
}
.contact_Province_input[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.contact_Province_words p[data-v-5dd92b76] {
  font-size: 0.07292rem;
}
.order_user_Province[data-v-5dd92b76] {
  width: 1.5625rem;
}
.order_user_Province[data-v-5dd92b76] .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 0.10417rem;
  margin-left: 0.38021rem;
  height: 0.18229rem;
}
.contact_Tel_input[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.contact_Tel_words p[data-v-5dd92b76] {
  font-size: 0.07292rem;
}
.order_user_TelePhone[data-v-5dd92b76] {
  width: 1.5625rem;
}
.order_user_TelePhone[data-v-5dd92b76] .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 0.10417rem;
  margin-left: 0.05208rem;
  height: 0.18229rem;
}
.TrackingNo[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.orders_TrackingNo_words p[data-v-5dd92b76] {
  font-size: 0.07292rem;
}
.order_TrackingNumber[data-v-5dd92b76] {
  padding-left: 0.10417rem;
  margin-left: 0.20833rem;
  height: 0.18229rem;
  width: 1.5625rem;
  border-radius: 50px;
  font-size: 0.07292rem;
}
.contact_Address_input[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-left: 0.26042rem;
}
.orders_Address_words p[data-v-5dd92b76] {
  font-size: 0.07292rem;
}
.order_user_Address[data-v-5dd92b76] {
  width: 1.5625rem;
}
.order_user_Address[data-v-5dd92b76] .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 0.10417rem;
  margin-left: 0.05208rem;
  height: 0.18229rem;
}
.contact_Address2_input[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-left: 0.26042rem;
}
.orders_Address2_words p[data-v-5dd92b76] {
  font-size: 0.07292rem;
}
.order_user_Address2[data-v-5dd92b76] {
  width: 1.5625rem;
}
.order_user_Address2[data-v-5dd92b76] .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 0.10417rem;
  margin-left: 0.07813rem;
  height: 0.18229rem;
}
.contact_ZipCode_input[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.orders_Post_code[data-v-5dd92b76] {
}
.orders_Post_code p[data-v-5dd92b76] {
  font-size: 0.07292rem;
}
.order_user_ZipCode[data-v-5dd92b76] {
  width: 1.5625rem;
}
.order_user_ZipCode[data-v-5dd92b76] .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 0.10417rem;
  margin-left: 0.27604rem;
  height: 0.18229rem;
}

/* Phone */
.contact_TelePhone_input[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-left: 0.26042rem;
}
.orders_TelePhone_words p[data-v-5dd92b76] {
  font-size: 0.07292rem;
}
.order_user_TelePhone[data-v-5dd92b76] {
  width: 1.5625rem;
}
.order_user_TelePhone[data-v-5dd92b76] .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 0.10417rem;
  margin-left: 0.13021rem;
  height: 0.18229rem;
}
/* City */
.contact_City_input[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-left: 0.26042rem;
}
.orders_City_words p[data-v-5dd92b76] {
  font-size: 0.07292rem;
}
.order_user_City[data-v-5dd92b76] {
  width: 1.5625rem;
}
.order_user_City[data-v-5dd92b76] .el-input {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding-left: 0.10417rem;
  margin-left: 0.20833rem;
  height: 0.18229rem;
}
.OrderTrackingNumber[data-v-5dd92b76] {
  width: 1.5625rem;
  height: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
}
.ShowOrderTrackingNumber[data-v-5dd92b76] {
  position: absolute;
  top: 0;
}
