
button[data-v-a76d30e6] {
  width: 0.78125rem;
  height: 0.20833rem;
  background: #15104b;
  color: #fff;
  font-size: 0.09375rem;
  border-radius: 8px;
  line-height: 0.20833rem;
  text-align: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  border: 1px solid #15104b;
  margin-top: 0.13021rem;
  margin-left: 1.04167rem;
}
