.printImagesContent .upload-content[data-v-01be8521] {
  padding-left: 0.10417rem;
  height: calc(100% - 0.52083rem);
  overflow: hidden;
}
.printImagesContent .upload-content .searchBar[data-v-01be8521] {
  margin-bottom: 0.10417rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}
.printImagesContent .upload-content .searchBar .input-search[data-v-01be8521] {
  width: 1.45833rem;
}
.printImagesContent .upload-content .searchBar .input-search[data-v-01be8521] .el-input__inner {
  border-radius: 20px;
}
.printImagesContent .upload-content .upload-pic-content[data-v-01be8521] {
  width: 100%;
  overflow-y: hidden;
  height: 2.5rem;
}
.printImagesContent .upload-content .upload-pic-content .upload-pic-flex[data-v-01be8521] {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  gap: 0.10417rem;
}
.printImagesContent .upload-content .upload-pic-content .upload-pic-flex .print-div[data-v-01be8521] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.printImagesContent .upload-content .upload-pic-content .upload-pic-flex .print-div .imgsCheck[data-v-01be8521] {
  position: relative;
  width: 0.9375rem;
  height: 0.9375rem;
  margin-bottom: 0.05208rem;
}
.printImagesContent .upload-content .upload-pic-content .upload-pic-flex .print-div .imgsCheck .del[data-v-01be8521] {
  display: none;
  font-size: 0.07292rem;
  cursor: pointer;
  width: 0.15625rem;
  height: 0.15625rem;
  position: absolute;
  right: 0.05208rem;
  top: 0.05208rem;
}
.printImagesContent .upload-content .upload-pic-content .upload-pic-flex .print-div .imgsCheck:hover .showDel[data-v-01be8521] {
  display: block;
}
.printImagesContent .upload-content .upload-pic-content .upload-pic-flex .print-div .imgsCheck .img[data-v-01be8521] {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
}
.printImagesContent .upload-content .upload-pic-content .upload-pic-flex .print-div .imgsCheck .checkbox[data-v-01be8521] {
  position: absolute;
  right: 0.05208rem;
  top: 0.80729rem;
}
.printImagesContent .upload-content .upload-pic-content .upload-pic-flex .print-div .imgsCheck .checkbox[data-v-01be8521] .el-checkbox__input .el-checkbox__inner {
  border-color: #15104b;
}
.printImagesContent .upload-content .upload-pic-content .upload-pic-flex .print-div .pic-names[data-v-01be8521] {
  font-size: 0.07813rem;
  font-weight: 600;
  color: #333333;
}
.printImagesContent .upload-content .upload-pic-content .upload-pic-flex .print-div .pic-names .name[data-v-01be8521] {
  width: 0.9375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.printImagesContent .upload-content .upload-pic-content .empty-pic[data-v-01be8521] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.07292rem;
  color: #666666;
}
.printImagesContent .upload-content .upload-pic-content .empty-pic img[data-v-01be8521] {
  width: 1.875rem;
  height: 1.875rem;
  margin-bottom: 0.25rem;
}