
.liBackground[data-v-d091ac7a] {
  border: 1px solid #30539c;
}
.SideBarBigBox[data-v-d091ac7a] {
  position: relative;
}
/* 绑定店铺 */
.LinkMyStore[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 0.41667rem;
  cursor: pointer;
  background: #93df89;
  position: relative;
  top: 0;
  left: 0;
  z-index: 99;
  padding-bottom: 0;
}
.LinkMyStore p[data-v-d091ac7a]:nth-child(1) {
  text-align: center;
  line-height: 0.18229rem;
  color: #fff;
  font-size: 0.10417rem;
  font-weight: 600;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.LinkMyStore p[data-v-d091ac7a]:nth-child(2) {
  text-align: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 0.07292rem;
  font-style: italic;
  color: rgb(76, 139, 245);
  margin-top: -0.03125rem;
}
.SideBarBox[data-v-d091ac7a] {
  position: fixed;
  top: 0;
  left: 0;
  width: 1.03125rem;
  height: 100%;
  background: #15104b;
}
.headerCommon[data-v-d091ac7a] {
  position: absolute;
  left: 1.19792rem;
  width: 80%;
  height: 0.3125rem;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0.00521rem 0 0 rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.HeaderTitle[data-v-d091ac7a] {
  top: 0.3125rem;
}
.HeaderTitleTop[data-v-d091ac7a] {
  top: 0.07813rem;
}
.HeaderContent[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.SideBarBoxStoreCommon[data-v-d091ac7a] {
  position: fixed;
  left: 0;
  width: 1.10938rem;
  height: 100%;
  background: #15104b;
  z-index: 99;
}
.SideBarTitle[data-v-d091ac7a] {
  top: 0;
}
.SideBarBoxStoreTop[data-v-d091ac7a] {
  top: 0;
}
.HeaderContent[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.multitudinous_icon[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
}
.contact_agent[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.contact_agent p[data-v-d091ac7a]:nth-child(1) {
  font-size: 0.10417rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  line-height: 0.36458rem;
  font-weight: bold;
  color: #15104b;
  word-break: normal;
}
.contact_agent p[data-v-d091ac7a]:nth-child(2) {
  font-size: 0.10417rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  line-height: 0.36458rem;
  font-weight: bold;
  color: #15104b;
  margin-left: 0.15625rem;
  word-break: normal;
}
.SliceSmallBell_img[data-v-d091ac7a] {
  width: 0.10417rem;
  height: 0.10417rem;
  position: absolute;
  top: 0.00521rem;
  right: 1.22396rem;
  cursor: pointer;
}
.SliceSmallBell_img img[data-v-d091ac7a] {
  width: 0.10417rem;
  height: 0.10417rem;
  margin-top: 0.09375rem;
  margin-right: 0.15625rem;
  display: block;
}
.SliceProfile_img img[data-v-d091ac7a] {
  width: 0.10417rem;
  height: 0.10417rem;
  margin-top: 0.09375rem;
  margin-right: 0.15625rem;
  cursor: pointer;
  display: block;
}
.Slicelgout_img img[data-v-d091ac7a] {
  width: 0.10417rem;
  height: 0.10417rem;
  margin-top: 0.09375rem;
  margin-right: 0.15625rem;
  cursor: pointer;
  display: block;
}
.Welcome_Copywriting p[data-v-d091ac7a] {
  margin-top: 0.06771rem;
  color: #15104b;
  font-size: 0.11979rem;
  margin-right: 0.02604rem;
}
.head_sculpture[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.15625rem;
}
.head_sculpture img[data-v-d091ac7a] {
  width: 0.28646rem;
  height: 0.28646rem;
  border-radius: 50px;
  margin-left: 0.05208rem;
  /* display: block; */
}
.GradeId_Img p span img[data-v-d091ac7a] {
  width: 0.10417rem;
  height: 0.10417rem;
  border-radius: 0px;
}
.userInfoRealName[data-v-d091ac7a] {
  font-size: 0.08333rem;
  color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.02604rem;
  margin-left: 0.05208rem;
  width: 0.52083rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.active[data-v-d091ac7a] {
  font-size: 0.09375rem;
  color: #fff;
}
.MyStore_menu[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.10417rem;
  margin-left: 0;
}
.MyStore_menu img[data-v-d091ac7a] {
  width: 0.13021rem;
  height: 0.13021rem;
}
.MyStore_menu p[data-v-d091ac7a]:nth-child(2) {
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.01563rem;
  margin-left: 0.05208rem;
}
.Products_menu[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0;
  margin-left: 0.07552rem;
}
.Products_menu img[data-v-d091ac7a] {
  width: 0.11979rem;
  height: 0.11979rem;
  margin-left: 0.04167rem;
}
.Products_menu p[data-v-d091ac7a]:nth-child(2) {
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.01563rem;
  margin-left: 0.05208rem;
}
.Sourcing_menu[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0;
  margin-left: 0;
}
.Sourcing_menu img[data-v-d091ac7a] {
  width: 0.13021rem;
  height: 0.13021rem;
}
.Sourcing_menu p[data-v-d091ac7a]:nth-child(2) {
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.01563rem;
  margin-left: 0.05208rem;
}
[data-v-d091ac7a] .el-menu-item.is-active {
  color: #fff !important;
}
[data-v-d091ac7a] .el-menu {
  background-color: transparent;
  border-right: none;
}
[data-v-d091ac7a] .el-menu:hover {
  background-color: transparent !important;
  font-size: 0.10417rem;
}
[data-v-d091ac7a] .el-submenu__title {
  padding-left: 0 !important;
  height: 0.23438rem;
  line-height: 0.23438rem;
}
[data-v-d091ac7a] .el-submenu__title:hover {
  background-color: transparent !important;
}
[data-v-d091ac7a] .el-icon-arrow-down {
  margin-left: 0.05208rem !important;
}
[data-v-d091ac7a] .el-menu:hover {
  background-color: transparent;
}
[data-v-d091ac7a] .el-menu-vertical-demo .el-menu-item:hover {
  background-color: transparent;
  background: none;
  -webkit-transform: scale(1.1, 1);
  -moz-transform: scale(1.1, 1);
  -o-transform: scale(1.1, 1);
}
[data-v-d091ac7a] .el-menu-item {
  height: 0.26042rem;
  line-height: 0.26042rem;
}
[data-v-d091ac7a] .tac .el-menu-item:hover {
  background-color: transparent !important;
  background: none !important;
}
[data-v-d091ac7a] .el-submenu__title:hover {
  background: none;
}
[data-v-d091ac7a] .el-submenu .el-menu-item {
  background-color: transparent;
  font-size: 0.0625rem !important;
}
[data-v-d091ac7a] .el-col-12 {
  width: 80%;
}
[data-v-d091ac7a] .el-col-12:hover {
  background: none;
  background-color: transparent;
}
[data-v-d091ac7a] .el-submenu__icon-arrow {
  position: absolute;
  top: 53%;
  right: 0.02604rem;
  margin-top: -0.03646rem;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  font-size: 0.0625rem;
}
.Agent[data-v-d091ac7a] {
  position: fixed;
  bottom: 1.06771rem;
  right: 0.15625rem;
  z-index: 99 !important;
  cursor: pointer;
  background: none;
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
.Agent img[data-v-d091ac7a] {
  width: 0.36458rem;
  height: 0.36458rem;
}
.Agent img[data-v-d091ac7a]:hover {
  width: 0.39063rem;
  height: 0.39063rem;
}
.headPortraitImg[data-v-d091ac7a] {
  position: fixed;
  bottom: 1.10417rem;
  right: 0.11458rem;
  /*border-radius: 30px;*/
  z-index: 99 !important;
  cursor: pointer;
  background: none;
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
.headPortraitImg img[data-v-d091ac7a] {
  width: 0.41667rem;
  height: 0.46875rem;
}
.Contact_agent[data-v-d091ac7a] {
  width: auto;
  height: 0.26042rem;
  background: #fff;
  line-height: 0.26042rem;
  position: fixed;
  bottom: 1.45833rem;
  right: 0.23438rem;
  z-index: 99 !important;
  cursor: pointer;
  border-radius: 10px;
  padding-right: 0.05208rem;
}
.Contact_agent_three[data-v-d091ac7a] {
  width: auto;
  height: 0.28646rem;
  background: #fff;
  line-height: 0.26042rem;
  position: fixed;
  bottom: 1.45833rem;
  right: 0.18229rem;
  z-index: 99 !important;
  cursor: pointer;
  border-radius: 10px;
  padding-right: 0.05208rem;
}
.Contact_agent_three p[data-v-d091ac7a] {
  line-height: 0.10417rem;
  color: #000;
  margin-left: 0.05208rem;
  margin-top: 0.02604rem;
  font-size: 0.0625rem;
}
.Contact_agent_three[data-v-d091ac7a]::before {
  content: '';
  position: absolute;
  bottom: -15%;
  right: 0.3125rem;
  width: 0.07813rem;
  height: 0.07813rem;
  margin-top: -0.05208rem;
  background: inherit; /*自动继承父元素的背景*/
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.remove_outline[data-v-d091ac7a] {
  position: fixed;
  bottom: 1.5625rem;
  right: 0.17188rem;
  /*border-radius: 30px;*/
  z-index: 9999999999999 !important;
  cursor: pointer;
  font-size: 0.0625rem;
}
.Contact_agent p[data-v-d091ac7a] {
  line-height: 0.10417rem;
  color: #000;
  margin-left: 0.05208rem;
  margin-top: 0.02604rem;
  font-size: 0.0625rem;
}
.Contact_agent[data-v-d091ac7a]::before {
  content: '';
  position: absolute;
  bottom: -15%;
  left: 0.46875rem;
  width: 0.07813rem;
  height: 0.07813rem;
  margin-top: -0.05208rem;
  background: inherit; /*自动继承父元素的背景*/
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.Contact_agent_Pssp[data-v-d091ac7a] {
  width: 1.04167rem;
  height: 0.26042rem;
  background: #fff;
  line-height: 0.26042rem;
  position: fixed;
  bottom: 1.45833rem;
  right: 0.18229rem;
  z-index: 99 !important;
  cursor: pointer;
  border-radius: 10px;
  padding-right: 0.05208rem;
}
.Contact_agent_Pssp p[data-v-d091ac7a] {
  line-height: 0.10417rem;
  color: #000;
  margin-left: 0.05208rem;
  margin-top: 0.02604rem;
  font-size: 0.0625rem;
  word-break: normal;
}
.Contact_agent_Pssp[data-v-d091ac7a]::before {
  content: '';
  position: absolute;
  bottom: -15%;
  left: 0.46875rem;
  width: 0.07813rem;
  height: 0.07813rem;
  margin-top: -0.05208rem;
  background: inherit; /*自动继承父元素的背景*/
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.member_el-icon-close[data-v-d091ac7a] {
  width: 0.10417rem;
  height: 0.10417rem;
  position: absolute;
  right: 0.07813rem;
  top: 0.02604rem;
  font-size: 0.11458rem;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
.headportrait img[data-v-d091ac7a] {
  width: 0.41667rem;
  height: 0.41667rem;
  /*box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.3);*/
  border-radius: 50%;
  margin-top: 0.10417rem;
  margin-left: 0.13021rem;
}
.member_box[data-v-d091ac7a] {
  /* width: 100%;
    height: 100%; */
  position: fixed;
  top: 1.5625rem;
  right: 1.04167rem;
  z-index: 99999;
  /*background: rgba(0, 0, 0, 0.6);*/
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.member_set[data-v-d091ac7a] {
  width: 3.33333rem;
  height: 1.14583rem;
  left: calc(50% - 3.4375rem);
  top: calc(50% - 2.60417rem);
  /*background: #000;
    opacity: 0.5;
    background: #fff;*/
  background-image: -webkit-linear-gradient(#1f378b, #445790);
  background-image: linear-gradient(#1f378b, #445790);
  opacity: 0.8;
  border-radius: 20px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.member_RealName[data-v-d091ac7a] {
  position: absolute;
  top: 0.13021rem;
  left: 0.625rem;
}
.member_RealName p[data-v-d091ac7a] {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-size: 0.09375rem;
}
.member_AgentNames[data-v-d091ac7a] {
  position: absolute;
  top: 0.3125rem;
  left: 0.625rem;
}
.member_AgentNames p[data-v-d091ac7a] {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-size: 0.07292rem;
}
.member_hello[data-v-d091ac7a] {
  position: absolute;
  top: 0.46875rem;
  left: 0.625rem;
}
.member_hello p[data-v-d091ac7a] {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-size: 0.07292rem;
}
.member_Email[data-v-d091ac7a] {
  position: absolute;
  top: 0.44271rem;
  left: 0.10417rem;
}
.member_Email img[data-v-d091ac7a] {
  width: 0.15625rem;
  height: 0.15625rem;
}
.member_Email span[data-v-d091ac7a]:nth-child(2) {
  position: absolute;
  left: 0.20833rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  word-wrap: break-word;
  height: 0.26042rem;
  width: 0.65104rem;
}
.member_Whatsapp[data-v-d091ac7a] {
  width: 0.85938rem;
  height: 0.26042rem;
  /*background: #ccc;*/
  position: absolute;
  top: 0.78125rem;
  left: 1.22396rem;
  line-height: 0.18229rem;
  border-radius: 10px;
  background: #2bb51b;
}
.member_Whatsapp img[data-v-d091ac7a] {
  width: 0.15625rem;
  height: 0.15625rem;
  margin-top: 0.05208rem;
  margin-left: 0.04167rem;
}
.member_Whatsapp span[data-v-d091ac7a]:nth-child(2) {
  position: absolute;
  left: 0.26042rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  width: 1.04167rem;
  margin-top: 0.04167rem;
  font-size: 0.09375rem;
}
.member_Skype[data-v-d091ac7a] {
  width: 0.85938rem;
  height: 0.26042rem;
  /*background: #ccc;*/
  position: absolute;
  top: 0.78125rem;
  left: 0.15625rem;
  line-height: 0.23438rem;
  border-radius: 10px;
  background: #15ace5;
}
.member_Skype img[data-v-d091ac7a] {
  width: 0.17188rem;
  height: 0.17188rem;
  margin-top: 0.05208rem;
  margin-left: 0.13021rem;
}
.member_Skype span[data-v-d091ac7a]:nth-child(2) {
  position: absolute;
  left: 0.41667rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  height: 0.26042rem;
  width: 0.52083rem;
  margin-top: 0.01042rem;
  word-wrap: break-word;
  font-size: 0.09375rem;
  /*  background: red; */
}

/* Telegram */
.member_Telegram[data-v-d091ac7a] {
  width: 0.85938rem;
  height: 0.26042rem;
  /*background: #ccc;*/
  position: absolute;
  top: 0.78125rem;
  left: 2.29167rem;
  line-height: 0.23438rem;
  border-radius: 10px;
  background: #15ace5;
}
.member_Telegram img[data-v-d091ac7a] {
  width: 0.16146rem;
  height: 0.16146rem;
  margin-top: 0.05208rem;
  margin-left: 0.07813rem;
}
.member_Telegram span[data-v-d091ac7a]:nth-child(2) {
  position: absolute;
  left: 0.3125rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  height: 0.26042rem;
  width: 0.52083rem;
  margin-top: 0.01042rem;
  word-wrap: break-word;
  font-size: 0.09375rem;
  /*  background: red; */
}
/* 问卷调查 */
.SurveyFileBox[data-v-d091ac7a] {
  width: 0.3125rem;
  height: 0.3125rem;
  position: fixed;
  bottom: 1.82292rem;
  right: 0.23438rem;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}
.SurveyFileContent img[data-v-d091ac7a] {
  width: 100%;
  height: 100%;
}
/* 问卷调查弹窗 */
.Question_box[data-v-d091ac7a] {
  /* width: 100%;
    height: 100%; */
  position: fixed;
  top: 1.5625rem;
  right: 1.04167rem;
  z-index: 99999;
  /*background: rgba(0, 0, 0, 0.6);*/
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Question_content[data-v-d091ac7a] {
  width: 2.94271rem;
  height: 1.14583rem;
  left: calc(50% - 3.4375rem);
  top: calc(50% - 2.60417rem);
  /*background: #000;
    opacity: 0.5;*/
  background: #fff;
  border-radius: 20px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.QuestionBox[data-v-d091ac7a] {
  width: 1.40625rem;
  height: auto;
  position: fixed;
  right: 0.05208rem;
  bottom: 1.35417rem;
  background: #ffffff;
  z-index: 99;
  border-radius: 8px;
  padding-bottom: 0.05208rem;
}
.QuestionTopBox[data-v-d091ac7a] {
  /*background: #6e9ae3;*/
  background: #bc0c33;
  width: 1.40625rem;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
}
.DontMissOut_Img[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.Group_img img[data-v-d091ac7a] {
  margin-left: 0.15625rem;
}
.DontMissOut_Word p[data-v-d091ac7a] {
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  margin-top: 0.07813rem;
  margin-left: 0.10417rem;
}
.CloseRulesBtn_Pop p[data-v-d091ac7a] {
  font-size: 0.11458rem;
  color: #fff;
  cursor: pointer;
  margin-top: 0.07813rem;
  margin-left: 0.15625rem;
}
.ProgressBarBox[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.ProgressBar_Pop[data-v-d091ac7a] {
  margin-top: 0.05208rem;
  margin-left: 0.05208rem;
}
.GroupIcon[data-v-d091ac7a] {
  margin-left: 0.10417rem;
}
.GroupIcon img[data-v-d091ac7a] {
  width: 0.38021rem;
  height: 0.36458rem;
}
.elProgressBox[data-v-d091ac7a] {
  width: 0.78125rem;
}
.elProgressBox[data-v-d091ac7a] .el-progress-bar {
  width: 100%;
}
.SurpriseJackpot p[data-v-d091ac7a] {
  font-size: 0.06771rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-left: 0.05208rem;
  word-break: normal;
  margin-top: 0.05208rem;
}
.RulesCompletedBox[data-v-d091ac7a] {
}
.CompletedBox[data-v-d091ac7a] {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-size: 0.07292rem;
  margin-top: 0.05208rem;
}
.elProgressBoxImg[data-v-d091ac7a] {
  position: absolute;
  top: 0.15625rem;
  left: 0.05208rem;
}
.elProgressBoxImg span img[data-v-d091ac7a]:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0.67708rem;
}
.ContentBigBox[data-v-d091ac7a] {
  position: relative;
}
.ghostBox[data-v-d091ac7a] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.ghostBox p:nth-child(1) img[data-v-d091ac7a] {
  width: 0.36458rem;
  height: 0.36458rem;
  position: absolute;
  top: 0.05208rem;
  left: 0.10417rem;
  z-index: 99;
}
.ghostBox p:nth-child(2) img[data-v-d091ac7a] {
  width: 0.36458rem;
  height: 0.36458rem;
  position: absolute;
  top: 0.15625rem;
  left: 0.67708rem;
  z-index: 99;
}
.HalloweenBox[data-v-d091ac7a] {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
}
.HalloweenBox img[data-v-d091ac7a] {
  width: 0.67708rem;
  height: 0.26042rem;
}
.NanGuaSpiderWeb[data-v-d091ac7a] {
  position: absolute;
  top: 0.70313rem;
  right: 0;
}
.NanGuaSpiderWeb img[data-v-d091ac7a] {
  width: 0.67708rem;
  height: 0.78125rem;
}
.PumpkinDynamics[data-v-d091ac7a] {
  position: absolute;
  top: 0.85938rem;
  right: 0;
}
.PumpkinDynamics img[data-v-d091ac7a] {
  width: 0.52083rem;
  height: 0.41667rem;
}
.SurveyDetailListBox[data-v-d091ac7a] {
  margin-left: 0.41667rem;
  margin-top: 0.44271rem;
}
.SurveyDetailListTitle[data-v-d091ac7a] {
  padding-top: 0;
}
.SurveyDetailListTitle p[data-v-d091ac7a] {
  padding-top: 0.05208rem;
}
.SurveyListContent p[data-v-d091ac7a] {
  margin-left: 0.07813rem;
  margin-top: 0.15625rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.SurveyDetailListTitle[data-v-d091ac7a] {
  width: 3.64583rem;
  margin-top: -0.02604rem;
}
.SurveyDetailListTitle[data-v-d091ac7a] .el-input__inner {
  width: 3.64583rem;
  margin-top: 0.05208rem;
  border-radius: 50px;
}
.SurveyDetailListTitle[data-v-d091ac7a] .el-radio__label {
  line-height: 0.20833rem;
}
.SurveyFileDetailsBox[data-v-d091ac7a] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.SurveyDetailsContent[data-v-d091ac7a] {
  width: 4.42708rem;
  left: calc(50% - 2.23958rem);
  top: calc(50% - 2.34375rem);
  margin-top: 0.9375rem;
  background-color: #fff;
  /*height: 450px;*/
  height: 2.76042rem;
  border-radius: 10px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  overflow-x: auto;
  padding-bottom: 0.26042rem;
}
.SurveyDetailsContent[data-v-d091ac7a]::-webkit-scrollbar {
  display: none;
}
.SurveyFileTitle[data-v-d091ac7a] {
  width: 4.42708rem;
  height: 0.41667rem;
  background: #bc0c34;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: fixed;
  z-index: 99;
}
.SurveyFileTitle p[data-v-d091ac7a]:nth-child(1) {
  text-align: center;
  color: #fff;
  font-size: 0.10417rem;
  padding-top: 0.04167rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.SurveyFileTitle p[data-v-d091ac7a]:nth-child(2) {
  text-align: center;
  color: #fff;
  font-size: 0.08333rem;
  padding-top: 0.04167rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.CloseSurveyDetails[data-v-d091ac7a] {
  position: absolute;
  top: 0.0625rem;
  right: 0.07813rem;
}
.CloseSurveyDetails[data-v-d091ac7a] .el-icon-circle-close {
  color: #fff;
  font-size: 0.13542rem;
  cursor: pointer;
}
.CloseRulesBtn[data-v-d091ac7a] {
  text-align: center;
}
.CloseRulesBtn[data-v-d091ac7a] .el-icon-circle-close {
  font-size: 0.20833rem;
  color: #fff;
  margin-top: 0.20833rem;
  cursor: pointer;
}
.RulesDescriptionTitle[data-v-d091ac7a] {
  width: 100%;
  height: 0.20833rem;
  background: #bc0c34;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.RulesDescriptionTitle p[data-v-d091ac7a]:nth-child(1) {
  font-size: 0.10417rem;
  color: #fff;
  line-height: 0.20833rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-right: 1.43229rem;
}
.RulesDescriptionTitle p[data-v-d091ac7a]:nth-child(2) {
  font-size: 0.11458rem;
  color: #fff;
  line-height: 0.20833rem;
  margin-right: 0.10417rem;
  cursor: pointer;
}
.USAdropCampaignTitle p[data-v-d091ac7a] {
  text-align: center;
  color: #bc0c34;
  font-size: 0.09375rem;
  margin-top: 0.07813rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.WeHaveTitle p[data-v-d091ac7a] {
  font-size: 0.08333rem;
  margin-left: 0.26042rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.05208rem;
}
.RuleOneTitle p[data-v-d091ac7a] {
  font-size: 0.08333rem;
  margin-left: 0.26042rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.05208rem;
}
.RuleTwoTitle p[data-v-d091ac7a] {
  font-size: 0.08333rem;
  margin-left: 0.26042rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.05208rem;
}
.RuleThreeTitle p[data-v-d091ac7a] {
  font-size: 0.08333rem;
  margin-left: 0.26042rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.05208rem;
}
.RuleForuTitle p[data-v-d091ac7a] {
  font-size: 0.08333rem;
  margin-left: 0.26042rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.05208rem;
}
.EveryParticipant p[data-v-d091ac7a] {
  font-size: 0.08333rem;
  margin-left: 0.26042rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.05208rem;
  color: #52c593;
}
.RulesPopUp_box[data-v-d091ac7a] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.RulesPopUp_Content_Box[data-v-d091ac7a] {
  width: 4.16667rem;
  left: calc(50% - 2.08333rem);
  top: calc(50% - 2.34375rem);
  margin-top: 1.19792rem;
  background-color: #fff;
  /*height: 450px;
    height: 360px;*/
  height: auto;
  border-radius: 10px;
  position: relative;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-bottom: 0.13021rem;
}
.RulesTitle[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-left: 0.04167rem;
  margin-top: 0.04167rem;
}
.RulesTitle p[data-v-d091ac7a]:nth-child(1) {
  color: #000;
  font-size: 0.09375rem;
}
.RulesTitle p[data-v-d091ac7a]:nth-child(2) {
  color: #000;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  margin-left: 0.02604rem;
}
.FinishBox[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.05208rem;
}
.FinishBox[data-v-d091ac7a] .el-button {
  background: none;
  border: none;
  font-size: 0.07292rem;
  margin-left: 0.05208rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 0.96354rem;
  cursor: pointer;
  padding-top: 0.04167rem;
  padding-left: 0;
}
.FinishBox[data-v-d091ac7a] .el-button.is-disabled {
  background: none;
  border: none;
}
.FinishSwitch[data-v-d091ac7a] .el-switch__core {
  margin-top: 0.05208rem;
  margin-left: 0.10417rem;
}
.ConnectBox[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.05208rem;
}
.ConnectBox[data-v-d091ac7a]:hover {
  background: #cccccc;
  width: 100%;
  height: 0.20833rem;
}
.ConnectQuestionnaire[data-v-d091ac7a] {
  font-size: 0.08333rem;
  margin-left: 0.05208rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.04167rem;
  width: 1.14583rem;
  cursor: pointer;
}
.ConnectSwitch[data-v-d091ac7a] .el-switch__core {
  margin-top: 0.07813rem;
}
.CloseSubmitBtn[data-v-d091ac7a] {
  margin-left: 0.9375rem;
  margin-top: 0.15625rem;
}
.CloseSubmitBtn[data-v-d091ac7a] .el-button:nth-child(1) {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 0.08333rem;
  width: 0.78125rem;
  margin-left: 0.26042rem;
  border-radius: 50px;
}
.CloseSubmitBtn[data-v-d091ac7a] .el-button:nth-child(2) {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-size: 0.08333rem;
  width: 0.78125rem;
  margin-left: 0.26042rem;
  background: #bc0c34;
  color: #fff;
  border-radius: 50px;
}
.CloseSubmitBtn[data-v-d091ac7a] .el-button:nth-child(2):hover {
  width: 0.80729rem;
  height: 0.23438rem;
}
.CloseSubmitBtn[data-v-d091ac7a] .el-button:nth-child(1):hover {
  width: 0.80729rem;
  height: 0.23438rem;
}
.NavPopBigBox[data-v-d091ac7a] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.6);
}
.NavPopContentSourcing[data-v-d091ac7a] {
  width: 2.08333rem;
  height: 0.75521rem;
  /* background: #fff; */
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  position: absolute;
  left: calc(50% - 3.38542rem);
  top: calc(50% - 2.60417rem);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  background: #fff;
}
.NavPopContentOneWord p[data-v-d091ac7a] {
  text-align: center;
  padding-top: 0.10417rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.NavPopContentOneWord[data-v-d091ac7a] .el-button {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 0.67708rem;
  height: 0.18229rem;
  margin-left: 0.67708rem;
  line-height: 0.02604rem;
  margin-top: 0.10417rem;
}
/* Sourcing引导 */
.NavGuideSourcing[data-v-d091ac7a] {
  background: url(../img/Group_Pop_Nav.b032dbda.png) no-repeat;
  background-size: 100% 100%;
  width: 2.60417rem;
  height: 0.83333rem;
  position: absolute;
  left: calc(50% - 4.0625rem);
  top: 11em;
}
.NavGuideSourcing p[data-v-d091ac7a] {
  position: absolute;
  top: 0.18229rem;
  left: 0.67708rem;
  text-align: center;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 1.71875rem;
  word-break: normal;
}
.NavGuideSourcing[data-v-d091ac7a] .el-button {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 0.67708rem;
  height: 0.18229rem;
  margin-left: 1.19792rem;
  line-height: 0.02604rem;
  margin-top: 0.51042rem;
  border-radius: 50px;
}
/* Orders引导 */
.NavGuideOrders[data-v-d091ac7a] {
  background: url(../img/Group_Pop_Nav.b032dbda.png) no-repeat;
  background-size: 100% 100%;
  width: 2.60417rem;
  height: 0.83333rem;
  position: absolute;
  left: calc(50% - 4.0625rem);
  top: 15.5em;
  line-height: 0.15625rem;
}
.NavGuideOrders p[data-v-d091ac7a] {
  position: absolute;
  top: 0.15625rem;
  left: 0.67708rem;
  text-align: center;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-left: 0.05208rem;
  width: 1.71875rem;
  word-break: normal;
}
.NavGuideOrdersBtn[data-v-d091ac7a] .el-button:nth-child(1) {
  background: #cdcdcd;
  border: 1px solid #cdcdcd;
  color: #000;
  margin-left: 0.9375rem;
  height: 0.19792rem;
  width: 0.41667rem;
  line-height: 0.02604rem;
  border-radius: 50px;
}
.NavGuideOrdersBtn[data-v-d091ac7a] .el-button:nth-child(2) {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 0.52083rem;
  height: 0.19792rem;
  margin-left: 0.26042rem;
  line-height: 0.02604rem;
  margin-top: 0.49479rem;
  border-radius: 50px;
}
/* Winner引导 */
.NavGuideWinner[data-v-d091ac7a] {
  background: url(../img/Group_Pop_Nav.b032dbda.png) no-repeat;
  background-size: 100% 100%;
  width: 2.60417rem;
  height: 0.83333rem;
  position: absolute;
  left: calc(50% - 4.0625rem);
  /*left: calc(50% - 450px);*/
  top: 20em;
}
.NavGuideWinner p[data-v-d091ac7a] {
  position: absolute;
  top: 0.15625rem;
  left: 0.67708rem;
  text-align: center;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 1.71875rem;
  word-break: normal;
}
.NavGuideWinnerBtn[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.NavGuideWinnerBtn[data-v-d091ac7a] .el-button:nth-child(1) {
  background: #cdcdcd;
  border: 1px solid #cdcdcd;
  color: #000;
  width: 0.41667rem;
  height: 0.18229rem;
  line-height: 0.02604rem;
  margin-left: 0.91146rem;
  margin-top: 0.49479rem;
  border-radius: 50px;
}
.NavGuideWinnerBtn[data-v-d091ac7a] .el-button:nth-child(2) {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 0.52083rem;
  height: 0.18229rem;
  margin-left: 0.26042rem;
  line-height: 0.02604rem;
  margin-top: 0.49479rem;
  border-radius: 50px;
}
.line[data-v-d091ac7a] {
  width: 0.52083rem;
  height: 0.02604rem;
  background: #fff;
  border-radius: 50px;
  border: #fff;
  position: absolute;
  top: 0;
  left: 0.65104rem;
}

/* Product引导 */
.NavGuideProduct[data-v-d091ac7a] {
  background: url(../img/Group_Pop_Nav.b032dbda.png) no-repeat;
  background-size: 100% 100%;
  width: 2.60417rem;
  height: 0.83333rem;
  position: absolute;
  left: calc(50% - 4.0625rem);
  /*left: calc(50% - 450px);*/
  top: 24.8em;
}
.NavGuideProduct p[data-v-d091ac7a] {
  position: absolute;
  top: 0.15625rem;
  left: 0.67708rem;
  text-align: center;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 1.71875rem;
  word-break: normal;
}
.NavGuideProductBtn[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.NavGuideProductBtn[data-v-d091ac7a] .el-button:nth-child(1) {
  background: #cdcdcd;
  border: 1px solid #cdcdcd;
  color: #000;
  margin-left: 0.91146rem;
  width: 0.41667rem;
  margin-top: 0.46875rem;
  height: 0.18229rem;
  line-height: 0.02604rem;
  border-radius: 50px;
}
.NavGuideProductBtn[data-v-d091ac7a] .el-button:nth-child(2) {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 0.52083rem;
  height: 0.18229rem;
  margin-left: 0.26042rem;
  line-height: 0.02604rem;
  margin-top: 0.46875rem;
  border-radius: 50px;
}
.line[data-v-d091ac7a] {
  width: 0.52083rem;
  height: 0.02604rem;
  background: #fff;
  border-radius: 50px;
  border: #fff;
  position: absolute;
  top: 0;
  left: 0.65104rem;
}

/* 2023Summer引导 */
.NavGuide2023Summer[data-v-d091ac7a] {
  background: url(../img/Group_Pop_Nav.b032dbda.png) no-repeat;
  background-size: 100% 100%;
  width: 2.60417rem;
  height: 0.83333rem;
  position: absolute;
  left: calc(50% - 3.90625rem);
  /*left: calc(50% - 450px);*/
  top: 25.2em;
}
.NavGuide2023Summer p[data-v-d091ac7a] {
  position: absolute;
  top: 0.20833rem;
  left: 0.67708rem;
  text-align: center;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 1.71875rem;
  word-break: normal;
}
.NavGuide2023SummerBtn[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.NavGuide2023SummerBtn[data-v-d091ac7a] .el-button:nth-child(1) {
  background: #cdcdcd;
  border: 1px solid #cdcdcd;
  color: #000;
  margin-left: 0.85938rem;
  width: 0.52083rem;
  height: 0.18229rem;
  line-height: 0.02604rem;
  margin-top: 0.49479rem;
  border-radius: 50px;
}
.NavGuide2023SummerBtn[data-v-d091ac7a] .el-button:nth-child(2) {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 0.52083rem;
  height: 0.18229rem;
  margin-left: 0.26042rem;
  line-height: 0.02604rem;
  margin-top: 0.49479rem;
  border-radius: 50px;
}
/* Pricing */
.NavGuidePricing[data-v-d091ac7a] {
  background: url(../img/Group_Pop_Nav.b032dbda.png) no-repeat;
  background-size: 100% 100%;
  width: 2.60417rem;
  height: 0.83333rem;
  position: absolute;
  left: calc(50% - 4.0625rem);
  /*left: calc(50% - 325px);*/
  top: 34.5em;
}
.NavGuidePricing p[data-v-d091ac7a] {
  position: absolute;
  top: 0.20833rem;
  left: 0.67708rem;
  text-align: center;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-left: 0.05208rem;
  width: 1.71875rem;
  word-break: normal;
}
.NavGuidePricingBtn[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.NavGuidePricing[data-v-d091ac7a] .el-button:nth-child(1) {
  background: #cdcdcd;
  border: 1px solid #cdcdcd;
  color: #000;
  margin-left: 0.96354rem;
  height: 0.18229rem;
  line-height: 0.02604rem;
  margin-top: 0.52083rem;
  border-radius: 50px;
}
.NavGuidePricing[data-v-d091ac7a] .el-button:nth-child(2) {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 0.52083rem;
  height: 0.18229rem;
  margin-left: 0.26042rem;
  line-height: 0.02604rem;
  margin-top: 0.52083rem;
  border-radius: 50px;
}
/* Wallet */
.NavGuideWallet[data-v-d091ac7a] {
  background: url(../img/Group_Pop_Nav6.82150a5f.png);
  background-size: 100% 100%;
  width: 2.08333rem;
  height: 0.97917rem;
  position: absolute;
  right: 0.18229rem;
  top: 0.67708rem;
}
.NavGuideWallet p[data-v-d091ac7a] {
  position: absolute;
  top: 0.36458rem;
  left: 0.16667rem;
  text-align: center;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  padding-left: 0.05208rem;
  width: 1.71875rem;
  word-break: normal;
}
.NavGuideWalletBtn[data-v-d091ac7a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.NavGuideWalletBtn[data-v-d091ac7a] .el-button:nth-child(1) {
  background: #cdcdcd;
  border: 1px solid #cdcdcd;
  color: #000;
  margin-left: 0.40104rem;
  width: 0.52083rem;
  height: 0.18229rem;
  line-height: 0.02604rem;
  margin-top: 0.65625rem;
  border-radius: 50px;
}
.NavGuideWalletBtn[data-v-d091ac7a] .el-button:nth-child(2) {
  background: #15104b;
  border: 1px solid #15104b;
  color: #fff;
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  width: 0.52083rem;
  height: 0.18229rem;
  margin-left: 0.26042rem;
  line-height: 0.02604rem;
  margin-top: 0.65625rem;
  border-radius: 50px;
}
.Customer_WeAreDelighted[data-v-d091ac7a] {
  margin-left: 0.26042rem;
  margin-top: 0.05208rem;
}
.Customer_WeAreDelighted p[data-v-d091ac7a] {
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Customer_newCustomer[data-v-d091ac7a] {
  margin-left: 0.26042rem;
  margin-top: 0.01563rem;
}
.Customer_newCustomer p[data-v-d091ac7a] {
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Customer_newCustomer p span[data-v-d091ac7a]:nth-child(2) {
  font-size: 0.08333rem;
  font-weight: 600;
}
.Customer_InAddition[data-v-d091ac7a] {
  margin-left: 0.26042rem;
  margin-top: 0.01563rem;
}
.Customer_InAddition p[data-v-d091ac7a] {
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Customer_InAddition p span[data-v-d091ac7a]:nth-child(2) {
  font-size: 0.08333rem;
  font-weight: 600;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Customer_TaskOne[data-v-d091ac7a] {
  text-align: center;
  margin-top: 0.10417rem;
}
.Customer_TaskOne p[data-v-d091ac7a] {
  font-size: 0.08333rem;
  font-weight: 600;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Customer_TaskTwo[data-v-d091ac7a] {
  text-align: center;
  margin-top: 0.02604rem;
}
.Customer_TaskTwo p[data-v-d091ac7a] {
  font-size: 0.08333rem;
  font-weight: 600;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Customer_OnceSuccessfully[data-v-d091ac7a] {
  margin-left: 0.26042rem;
  margin-top: 0.13021rem;
}
.Customer_OnceSuccessfully p[data-v-d091ac7a] {
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Customer_OnceSuccessfully p span[data-v-d091ac7a]:nth-child(2) {
  font-size: 0.08333rem;
  font-weight: 600;
}
.Customer_considerableSavings[data-v-d091ac7a] {
  margin-left: 0.26042rem;
  margin-top: 0.01563rem;
}
.Customer_considerableSavings p[data-v-d091ac7a] {
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
