
.el-message-box__header .el-message-box__headerbtn {
  display: none;
}
.el-message-box__btns .el-button--primary {
  background: #fff !important;
  border: 1px solid #dcdfe6 !important;
  color: #606266 !important;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
