@charset "UTF-8";
.particulars[data-v-5c032f94] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
  font-family: "Regular";
}
.activeName[data-v-5c032f94] {
  width: 6.875rem;
  background: #f9fafb;
  box-shadow: 0 0 0.10417rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  padding-bottom: 0.26042rem;
  font-family: "Regular";
}
.Details-figure[data-v-5c032f94] {
  /* 详情图 */
  width: 6.875rem;
  border-radius: 10px;
  margin: auto;
  position: relative;
  background: #f9fafb;
  overflow: hidden;
}
.AliexpressLink_SourceUrl[data-v-5c032f94] {
  margin-left: 2.03125rem;
  margin-top: 0.05208rem;
  word-break: normal;
  width: 1.04167rem;
}
.focus[data-v-5c032f94] {
  padding-top: 0.26042rem;
  width: 3.25rem;
  float: left;
}
.banner .img[data-v-5c032f94] {
  width: 2.91667rem;
  height: 2.91667rem;
  position: relative;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.img[data-v-5c032f94] {
  float: left;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.banner[data-v-5c032f94] {
  width: 2.91667rem;
  height: 2.91667rem;
  margin-left: 0.33333rem;
  position: relative;
  z-index: 9;
}
[data-v-5c032f94] .el-carousel__container {
  height: 2.91667rem;
}
.trans[data-v-5c032f94] {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.indicators[data-v-5c032f94] {
  margin-left: 0.3125rem;
  margin-top: 0.11458rem;
  height: 0.52083rem;
}
.indicators img[data-v-5c032f94] {
  width: 0.52083rem;
  height: 0.52083rem;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  margin-left: 0.18229rem;
  margin-top: 0.05208rem;
}
.indicators .con[data-v-5c032f94] {
  width: 0.52083rem;
  height: 0.52083rem;
  margin-left: 0.05729rem;
}
.indicators .displayX[data-v-5c032f94] {
  display: none;
}
.prev[data-v-5c032f94] {
  position: absolute;
  top: 3.53125rem;
  left: 0.15625rem;
  background-color: rgba(245, 247, 250, 0.01);
  border: 0;
}
.next[data-v-5c032f94] {
  position: absolute;
  top: 3.53125rem;
  left: 3.28125rem;
  background-color: rgba(245, 247, 250, 0.01);
  border: 0;
}
.synopsis[data-v-5c032f94] {
  /* <!-- 简介 --> */
  font-size: 0.10417rem;
  width: 2.91667rem;
  float: right;
  margin-right: 0.33854rem;
  padding-top: 0.26042rem;
  font-family: "Regular";
}
.intro[data-v-5c032f94] {
  font-size: 0.09375rem;
  font-family: "Regular";
  font-weight: 300;
  color: #191919;
}
.price[data-v-5c032f94] {
  margin-top: 0.1875rem;
  font-family: "Regular";
}
.price span[data-v-5c032f94]:nth-child(1) {
  font-size: 0.09375rem;
  font-weight: 300;
  color: #555656;
  line-height: 0.13021rem;
}
.price span[data-v-5c032f94]:nth-child(2) {
  font-size: 0.15625rem;
  font-weight: 500;
  color: #000000;
  line-height: 0.13021rem;
}
.measure[data-v-5c032f94] {
  margin-top: 0.1875rem;
  font-family: "Regular";
}
.measure span[data-v-5c032f94] {
  font-size: 0.09375rem;
  font-weight: 300;
  color: #555656;
}
.labelname[data-v-5c032f94] {
  display: inline-block;
}
[data-v-5c032f94] .measure .el-radio-button__inner {
  display: inline-block;
  height: 0.15625rem;
  line-height: 0.14583rem !important;
  border-radius: 50px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  border: 1px solid #dcdfe6;
  margin-right: 0.125rem;
  font-family: "Regular";
}
[data-v-5c032f94] .measure .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #606266;
  background-color: #fff;
  border-color: #6788ff;
  box-shadow: none;
  border-width: 2px;
  font-family: "Regular";
}
[data-v-5c032f94] .measure .el-radio-button__inner:hover {
  color: #6788ff;
  font-family: "Regular";
}
[data-v-5c032f94] .measure .el-radio-button__inner {
  -webkit-transition: all 0s linear;
  transition: all 0s linear;
}
[data-v-5c032f94] .measure .el-radio-button:first-child .el-radio-button__inner {
  border-width: 2px;
  font-family: "Regular";
}
.picture[data-v-5c032f94] {
  margin-top: 0.10938rem;
  font-family: "Regular";
}
.picture span[data-v-5c032f94] {
  font-size: 0.09375rem;
  font-weight: 300;
  color: #555656;
}
[data-v-5c032f94] .picture .el-radio-button__inner {
  display: inline-block;
  width: 0.44271rem;
  height: 0.44271rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  border: 2px solid #dcdfe6;
  margin-right: 0.125rem;
  font-family: "Regular";
}
.picture .el-radio-button__inner img[data-v-5c032f94] {
  width: 0.41667rem;
  height: 0.41667rem;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
[data-v-5c032f94] .picture .el-radio-group {
  margin-top: 0.05208rem;
  font-family: "Regular";
}
.picture .labelname[data-v-5c032f94] {
  font-family: "Regular";
}
[data-v-5c032f94] .picture .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #606266;
  background-color: #fff;
  border-color: #6788ff;
  box-shadow: none;
  border-width: 2px;
  font-family: "Regular";
}
[data-v-5c032f94] .picture .el-radio-button__inner:hover {
  color: #6788ff;
  font-family: "Regular";
}
[data-v-5c032f94] .picture .el-radio-button__inner {
  -webkit-transition: all 0s linear;
  transition: all 0s linear;
}
[data-v-5c032f94] .picture .el-radio-button:first-child .el-radio-button__inner {
  border-width: 2px;
}
.address[data-v-5c032f94] {
  margin-top: 0.10938rem;
  font-family: "Regular";
}
.address span[data-v-5c032f94] {
  font-size: 0.09375rem;
  font-weight: 300;
  color: #555656;
}
[data-v-5c032f94] .address .el-radio-button__inner {
  display: inline-block;
  width: 0.39063rem;
  height: 0.15625rem;
  line-height: 0.14583rem !important;
  border-radius: 50px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  border: 1px solid #dcdfe6;
}
.address .sellcountry[data-v-5c032f94] {
  margin-right: 0.07292rem;
}
.storyline[data-v-5c032f94] {
  font-size: 0.07292rem;
  font-weight: 300;
  color: #000000;
  margin-top: 0.36458rem;
  line-height: 0.15625rem;
  border-top: 1px solid #000000;
}
[data-v-5c032f94] .storyline .detailmodule_html {
  width: 100% !important;
  text-align: center;
  font-family: "Regular";
}
[data-v-5c032f94] .storyline img {
  max-width: 2.86458rem;
  height: auto;
  text-align: left;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
[data-v-5c032f94] .storyline p {
  width: 100% !important;
  text-align: left;
  padding: 0 1rem;
  font-family: "Regular";
}
[data-v-5c032f94] .storyline span {
  width: 100% !important;
  text-align: center;
  font-size: 13pt !important;
  word-break: keep-all;
  font-family: "Regular";
}
.deschide[data-v-5c032f94] {
  overflow: hidden;
  width: 100%;
}
[data-v-5c032f94] .deschide .detailmodule_html {
  text-align: left;
  font-family: "Regular";
}
[data-v-5c032f94] .deschide .detailmodule_html img {
  text-align: left;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
[data-v-5c032f94] .deschide .detailmodule_html p {
  width: 100% !important;
  text-align: left;
  font-family: "Regular";
}
[data-v-5c032f94] .deschide .detailmodule_html span {
  width: 100% !important;
  text-align: left;
  font-family: "Regular";
}
.Product button[data-v-5c032f94] {
  display: inline-block;
  margin-top: 0.36458rem;
  margin-left: 0.9375rem;
  width: 1.04167rem;
  height: 0.28646rem;
  background: -webkit-linear-gradient(318deg, #5e5ee0, #6a8aff);
  background: linear-gradient(132deg, #5e5ee0, #6a8aff);
  border-radius: 10px;
  border: 0;
  font-size: 0.08333rem;
  font-family: "Regular";
  font-weight: 400;
  color: #ffffff;
}
[data-v-5c032f94] .Product .el-button.is-disabled,
.el-button.is-disabled[data-v-5c032f94]:focus,
.el-button.is-disabled[data-v-5c032f94]:hover {
  background: #f2f2f2;
  color: #b6b6b6;
  font-family: "Regular";
}
.product-details[data-v-5c032f94] {
  width: 6.875rem;
  border-radius: 10px;
  margin: auto;
  margin-top: 0.16667rem;
  position: relative;
  background: #f9fafb;
}
.product-details img[data-v-5c032f94] {
  width: 6.16667rem;
  height: 10.88021rem;
  margin: 0.35417rem;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.el-tabs__active-bar[data-v-5c032f94] {
  top: 0.15625rem;
  border-radius: 20px !important;
}