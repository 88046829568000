.main-card-box[data-v-01954d6d] {
  width: 6.82292rem;
  margin-top: 0.15625rem;
}
.main-footer[data-v-01954d6d] {
  margin-top: 0.15625rem;
  width: 6.82292rem;
  height: 0.36458rem;
  background: #ffffff;
  border-radius: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}