
.Air_Convergence_box button[data-v-99072f3a] {
  margin-top: 0.15625rem;
  margin-left: 0.33854rem;
  display: block;
  width: 1.41667rem;
  height: 0.27083rem;
  line-height: 0.27083rem;
  background: #ffffff;
  border-radius: 26px;
  border: 0;
  font-size: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #616ae8;
}
