.login-info[data-v-41e42ab8] {
  margin-right: 0.41667rem;
  text-align: left;
  margin-top: -6%;
}
.login-info .logo1[data-v-41e42ab8] {
  width: 1.17188rem;
  height: 0.98438rem;
  margin-top: 0.15625rem;
}
.login-info .logo1 img[data-v-41e42ab8] {
  width: 100%;
}
.login-info .WelcomeTo[data-v-41e42ab8] {
  margin-top: 0.26042rem;
}
.login-content[data-v-41e42ab8] {
  width: 2.61979rem;
  height: 3.6875rem;
  margin-top: -6%;
  background: #fff;
  border-radius: 30px;
  padding: 0.10417rem 0.20833rem;
  position: relative;
}
.login-content .logo2[data-v-41e42ab8] {
  width: 0.46875rem;
  height: 0.33333rem;
  position: absolute;
  left: 0.20833rem;
  margin-bottom: 0.15625rem;
}
.login-content .login-head[data-v-41e42ab8] {
  font-size: 0.125rem;
  color: #000000;
  font-weight: 500;
  margin-top: 0.54167rem;
  margin-bottom: 0.20833rem;
  text-align: center;
}
.login-content .forget[data-v-41e42ab8] {
  width: 100%;
  text-align: right;
  position: absolute;
  top: 0.22917rem;
}
.login-content .option[data-v-41e42ab8] {
  width: 2.1875rem;
  height: 0.52083rem;
  margin: 0 auto 0.05208rem;
  padding-left: 0.01563rem;
}
.login-content .option[data-v-41e42ab8] .el-input__inner {
  border-radius: 50px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 0.21875rem;
  border: 1px solid #cccccc;
}
.login-content .login-bottom[data-v-41e42ab8] {
  margin-top: 0.41667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.login-content .login-bottom .el-button[data-v-41e42ab8] {
  width: 1.17708rem;
  height: 0.22917rem;
  background: #15104b;
  font-size: 0.08333rem;
  margin-bottom: 0.04167rem;
  color: #ffffff;
  border-radius: 30px;
}
.login-content .login-bottom .small[data-v-41e42ab8] {
  font-size: 0.07292rem;
  color: #999999;
}
.login-content .demo-ruleForm[data-v-41e42ab8] .el-form-item {
  margin-bottom: 0 !important;
}
.login-content .demo-ruleForm[data-v-41e42ab8] .el-form-item .el-form-item__content {
  margin-left: 0 !important;
}
.btnContent[data-v-41e42ab8] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 4.16667rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.btnContent .btnInfo[data-v-41e42ab8] {
  width: 2.08333rem;
  margin-bottom: 0.20833rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.btnContent .btnInfo .content-icon[data-v-41e42ab8] {
  width: 0.20833rem;
  height: 0.20833rem;
  background: #15104b;
}
.btnContent .btnInfo .content-icon[data-v-41e42ab8] .el-icon-check {
  font-size: 0.13542rem;
  color: #fff;
  line-height: 0.20833rem;
  margin-left: 0.03646rem;
  font-weight: 800;
}
.btnContent .btnInfo .content-words[data-v-41e42ab8] {
  font-size: 0.125rem;
  font-weight: 600;
  margin-left: 0.10417rem;
}