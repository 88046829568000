
.particulars[data-v-0adf9d74] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
}
.activeName[data-v-0adf9d74] {
  width: 6.875rem;
  position: relative;
}
.FAQTitle[data-v-0adf9d74] {
  text-align: center;
  margin-left: 0.72917rem;
}
.FAQTitle p[data-v-0adf9d74] {
  font-size: 0.15625rem;
  font-weight: 600;
  color: #21224e;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.AgentBox[data-v-0adf9d74] {
  width: 7.03125rem;
  height: 1.45833rem;
  border-radius: 10px;
  background: #fff;
  margin-top: 0.10417rem;
  margin-left: 0.26042rem;
  position: relative;
}
.AgentMessage[data-v-0adf9d74] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.AgentImgLeft img[data-v-0adf9d74] {
  width: 0.72917rem;
  height: 0.72917rem;
  margin-top: 0.26042rem;
}
.AgentMessageRight[data-v-0adf9d74] {
  margin-left: 0.15625rem;
  margin-top: 0.23438rem;
}
.AgentMessageRight p[data-v-0adf9d74]:nth-child(1) {
  font-size: 0.08333rem;
  font-family: "Regular";
}
.AgentMessageRight p[data-v-0adf9d74]:nth-child(2) {
  font-size: 0.08333rem;
  font-family: "Regular";
  margin-top: 0.04167rem;
}
.AgentMessageRight p[data-v-0adf9d74]:nth-child(3) {
  font-size: 0.08333rem;
  font-family: "Regular";
  margin-top: 0.04167rem;
}
.MessageSlogan[data-v-0adf9d74] {
  background: #EFF1F3;
  width: 1.5625rem;
  height: 0.70313rem;
  border-radius: 10px;
  font-size: 0.07292rem;
  position: absolute;
  right: 0.15625rem;
  top: 0.26042rem;
  padding-left: 0.05208rem;
  padding-right: 0.05208rem;
  line-height: 0.10417rem;
  padding-top: 0.05208rem;
  color: #333333;
  font-family: "Regular";
}
.MessageSlogan p[data-v-0adf9d74] {
  font-size: 0.07292rem;
  font-family: "Regular";
}
.AgentMethods[data-v-0adf9d74] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  position: relative;
  margin-top: 0.07813rem;
}
.member_Whatsapp[data-v-0adf9d74] {
  width: 1.04167rem;
  height: 0.26042rem;
  line-height: 0.18229rem;
  border-radius: 50px;
  background: #2bb51b;
}
.member_Whatsapp img[data-v-0adf9d74] {
  width: 0.15625rem;
  height: 0.15625rem;
  margin-top: 0.05208rem;
  margin-left: 0.18229rem;
}
.member_Whatsapp span[data-v-0adf9d74]:nth-child(2) {
  font-family: "Regular";
  color: #fff;
  width: 1.04167rem;
  font-size: 0.09375rem;
  margin-left: 0.05208rem;
  position: absolute;
  top: 0.05208rem;
}
.member_Skype[data-v-0adf9d74] {
  width: 1.04167rem;
  height: 0.26042rem;
  line-height: 0.23438rem;
  border-radius: 50px;
  background: #15ace5;
}
.member_Skype img[data-v-0adf9d74] {
  width: 0.17188rem;
  height: 0.17188rem;
  margin-top: 0.05208rem;
  margin-left: 0.18229rem;
}
.member_Skype span[data-v-0adf9d74]:nth-child(2) {
  font-family: "Regular";
  color: #fff;
  height: 0.26042rem;
  width: 1.04167rem;
  word-wrap: break-word;
  font-size: 0.09375rem;
  margin-left: 0.07813rem;
  position: absolute;
  top: 0.01563rem;
}
.member_Telegram[data-v-0adf9d74] {
  width: 1.04167rem;
  height: 0.26042rem;
  line-height: 0.23438rem;
  border-radius: 50px;
  background: #15ace5;
}
.member_Telegram img[data-v-0adf9d74] {
  width: 0.16146rem;
  height: 0.16146rem;
  margin-top: 0.05208rem;
  margin-left: 0.18229rem;
}
.member_Telegram span[data-v-0adf9d74]:nth-child(2) {
  font-family: "Regular";
  color: #fff;
  height: 0.26042rem;
  width: 1.04167rem;
  word-wrap: break-word;
  font-size: 0.09375rem;
  margin-left: 0.07813rem;
  position: absolute;
  top: 0.01563rem;
}
.member_Zalo[data-v-0adf9d74] {
  width: 1.04167rem;
  height: 0.26042rem;
  line-height: 0.23438rem;
  border-radius: 50px;
  background: #63a3ff;
  cursor: pointer;
}
.member_Zalo img[data-v-0adf9d74] {
  width: 0.16146rem;
  height: 0.16146rem;
  margin-top: 0.05208rem;
  margin-left: 0.18229rem;
}
.member_Zalo span[data-v-0adf9d74]:nth-child(2) {
  font-family: "Regular";
  color: #fff;
  height: 0.26042rem;
  width: 1.04167rem;
  word-wrap: break-word;
  font-size: 0.09375rem;
  margin-left: 0.05208rem;
  position: absolute;
  top: 0.02604rem;
}
.CustomerReviews[data-v-0adf9d74] {
  background: #3F6AFF;
  width: 1.04167rem;
  height: 0.26042rem;
  line-height: 0.23438rem;
  border-radius: 50px;
  cursor: pointer;
}
.CustomerReviews img[data-v-0adf9d74] {
  width: 0.16146rem;
  height: 0.16146rem;
  margin-top: 0.05208rem;
  margin-left: 0.18229rem;
}
.CustomerReviews span[data-v-0adf9d74]:nth-child(2) {
  font-family: "Regular";
  color: #fff;
  height: 0.26042rem;
  width: 1.04167rem;
  word-wrap: break-word;
  font-size: 0.09375rem;
  margin-left: 0.05208rem;
  position: absolute;
  top: 0.01563rem;
}
.FAQMessageBox[data-v-0adf9d74] {
  width: 7.03125rem;
  height: 1.5625rem;
  border-radius: 10px;
  background: #fff;
  margin-top: 0.10417rem;
  margin-left: 0.26042rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.HowUSAdrop_words[data-v-0adf9d74] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 6.77083rem;
  margin-left: 0.13021rem;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.HowUSAdrop_words p[data-v-0adf9d74]:nth-child(1) {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.15625rem;
  margin-top: 0.10417rem;
  line-height: 0.18229rem;
  color: #21224e;
}
.HowUSAdrop_words p[data-v-0adf9d74]:nth-child(2) {
  font-size: 0.08333rem;
  margin-top: 0.11458rem;
  margin-right: 0;
}
.shippingTime_words[data-v-0adf9d74] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 6.77083rem;
  margin-left: 0.13021rem;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.shippingTime_words p[data-v-0adf9d74]:nth-child(1) {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.15625rem;
  margin-top: 0.10417rem;
  line-height: 0.18229rem;
  color: #21224e;
}
.shippingTime_words p[data-v-0adf9d74]:nth-child(2) {
  font-size: 0.08333rem;
  margin-top: 0.11458rem;
  margin-right: 0;
}
.shippingCost_words[data-v-0adf9d74] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 6.77083rem;
  margin-left: 0.13021rem;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.shippingCost_words p[data-v-0adf9d74]:nth-child(1) {
  font-size: 0.08333rem;
  font-family: "Regular";
  margin-left: 0.15625rem;
  margin-top: 0.10417rem;
  line-height: 0.18229rem;
  color: #21224e;
}
.shippingCost_words p[data-v-0adf9d74]:nth-child(2) {
  font-size: 0.08333rem;
  margin-top: 0.11458rem;
  margin-right: 0;
}
.productItem_words[data-v-0adf9d74] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 6.77083rem;
  margin-left: 0.13021rem;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.productItem_words p[data-v-0adf9d74]:nth-child(1) {
  font-size: 0.08333rem;
  font-family: "Regular";
  margin-left: 0.15625rem;
  margin-top: 0.10417rem;
  line-height: 0.18229rem;
  color: #21224e;
}
.productItem_words p[data-v-0adf9d74]:nth-child(2) {
  font-size: 0.08333rem;
  margin-top: 0.11458rem;
  margin-right: 0;
}
.lookingFor_words[data-v-0adf9d74] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 6.77083rem;
  margin-left: 0.13021rem;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.lookingFor_words p[data-v-0adf9d74]:nth-child(1) {
  font-size: 0.08333rem;
  font-family: "Regular";
  margin-left: 0.15625rem;
  margin-top: 0.10417rem;
  line-height: 0.18229rem;
  color: #21224e;
}
.lookingFor_words p[data-v-0adf9d74]:nth-child(2) {
  font-size: 0.08333rem;
  margin-top: 0.11458rem;
  margin-right: 0;
}
.zalo_box[data-v-0adf9d74] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: "Regular";
}
.zalo_box_bgImg[data-v-0adf9d74] {
  width: 2.08333rem;
  height: 3.125rem;
  background-image: url(../img/Slice348zaloAgent.6011a172.png);
  background-repeat: no-repeat;
  background-size: cover;
  left: calc(60% - 2.08333rem);
  top: calc(50% - 1.82292rem);
  position: relative;
  border-radius: 15px;
}
.Zalo_Img img[data-v-0adf9d74] {
  width: 1.5625rem;
  height: 1.5625rem;
  margin-left: 0.28646rem;
  margin-top: 0.26042rem;
}
.CenterList_AgentName[data-v-0adf9d74] {
  text-align: center;
  color: #fff !important;
  font-family: "Regular";
  font-size: 0.125rem;
  margin-top: 0.05208rem;
}
.SideBar_ScanZalo[data-v-0adf9d74] {
  text-align: center;
  color: #fff !important;
  font-family: "Regular";
  font-size: 0.09375rem;
  margin-top: 0.05208rem;
}
.zalo_Icon[data-v-0adf9d74] {
  position: absolute;
  top: -0.07813rem;
  right: -0.05208rem;
  cursor: pointer;
}
.zalo_Icon[data-v-0adf9d74] .el-icon-circle-close {
  color: #fff;
  font-size: 0.15625rem;
}
.MessageBoardBox[data-v-0adf9d74] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: "Regular";
}
.MessageBoardContent[data-v-0adf9d74] {
  width: 4.16667rem;
  height: 2.60417rem;
  background: #FFFFFF;
  left: calc(56% - 2.60417rem);
  top: calc(50% - 1.40625rem);
  position: relative;
  border-radius: 15px;
}
.header_title[data-v-0adf9d74] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.SideBarComment[data-v-0adf9d74] {
  margin-left: 0.26042rem;
  margin-top: 0.10417rem;
}
.SideBarComment p[data-v-0adf9d74]{
  font-family: "Regular";
  font-size: 0.09375rem;
}
.SideBarIconClose[data-v-0adf9d74] {
  margin-top: 0.13021rem;
  margin-right: 0.15625rem;
  cursor: pointer;
}
.SideBarIconClose[data-v-0adf9d74] .el-icon-close {
  font-size: 0.11458rem;
}
.line[data-v-0adf9d74] {
  height: 0.00573rem;
  width: 100%;
  background: #E5E5E5;
  margin-top: 0.05208rem;
}
.Message_content[data-v-0adf9d74] {
  margin-top: 0.15625rem;
  width: 95%;
  margin-left: 0.10417rem;
  height: 1.5625rem;
}
.Message_content[data-v-0adf9d74] .el-textarea__inner {
  height: 1.5625rem;
}
.footerbox[data-v-0adf9d74] {
  text-align: center;
}
.Cancel[data-v-0adf9d74] {
  width: 1.04167rem;
  height: 0.26042rem;
  border: 2px solid #15104B;
  font-size: 0.09375rem;
  margin-right: 0.3125rem;
}
.Save[data-v-0adf9d74] {
  width: 1.04167rem;
  height: 0.26042rem;
  border-style: solid;
  margin-left: 0.10417rem;
  margin-top: 0.15625rem;
}
