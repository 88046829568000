.dialog-header[data-v-5bd09aa4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #e4e6ef;
  padding: 0.0625rem 0.08333rem;
}
.dialog-header .header-title[data-v-5bd09aa4] {
  background-color: #ffffff;
  color: #333333;
  font-size: 0.08333rem;
  padding-right: 0.04167rem;
}
.dialog-body[data-v-5bd09aa4] {
  padding: 0.125rem;
}
.dialog-body .no-shopify-store-title[data-v-5bd09aa4] {
  text-align: center;
}
.dialog-body .no-shopify-store-title p[data-v-5bd09aa4] {
  font-style: italic;
  color: #4c8bf5;
}
.dialog-body .my-shopify[data-v-5bd09aa4] {
  height: 0.09896rem;
  font-size: 0.08333rem;
  font-weight: 500;
  color: #000000;
  line-height: 0.09896rem;
  opacity: 0.8;
  margin-left: 0.05208rem;
}
.dialog-body[data-v-5bd09aa4] .el-input__inner {
  height: 0.20833rem;
  border-radius: 24px;
  font-size: 0.07292rem;
  overflow: hidden;
}
.dialog-footer[data-v-5bd09aa4] {
  padding: 0.07292rem 0 0.125rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.dialog-footer .cancel-button[data-v-5bd09aa4] {
  width: 1.04167rem;
}
.dialog-footer .confirm-button[data-v-5bd09aa4] {
  width: 1.04167rem;
  margin-left: 0.52083rem;
}