@charset "UTF-8";
.image-viewer__wrapper[data-v-4b9dcf40] {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 设置背景颜色为半透明黑色 */
  z-index: 999;
  /* 设置层级，保证遮罩位于其他内容之上 */
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  /* 使用 Flex 布局使内容垂直水平居中 */
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  /* 添加 opacity 属性的过渡效果 */
}
.image-viewer__wrapper .image-viewer__close[data-v-4b9dcf40] {
  right: 0.20833rem;
  top: 0.20833rem;
  width: 0.22917rem;
  height: 0.22917rem;
  color: #fff;
  font-size: 0.14583rem;
  background-color: #606266;
}
.image-viewer__wrapper .image-viewer__canvas[data-v-4b9dcf40] {
  position: static;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-user-select: none;
          user-select: none;
}
.image-viewer__wrapper .image-viewer__actions[data-v-4b9dcf40] {
  left: 50%;
  bottom: 0.15625rem;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  width: 1.46875rem;
  height: 0.22917rem;
  padding: 0 0.11979rem;
  background-color: #606266;
  border-color: #fff;
  border-radius: 22px !important;
}
.image-viewer__wrapper .image-viewer__actions .image-viewer__actions__inner[data-v-4b9dcf40] {
  width: 100%;
  height: 100%;
  cursor: default;
  font-size: 0.11979rem;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.image-viewer__wrapper .image-viewer__actions .image-viewer__actions__inner i[data-v-4b9dcf40] {
  cursor: pointer;
}
.image-viewer__wrapper .image-viewer__btn[data-v-4b9dcf40] {
  position: absolute;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 50%;
  opacity: 0.8;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-user-select: none;
          user-select: none;
}
.image-viewer__wrapper .image-viewer__prev[data-v-4b9dcf40] {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0.20833rem;
  width: 0.22917rem;
  height: 0.22917rem;
  font-size: 0.125rem;
  color: #fff;
  background-color: #606266;
  border-color: #fff;
}
.image-viewer__wrapper .image-viewer__next[data-v-4b9dcf40] {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0.20833rem;
  text-indent: 0.01042rem;
  width: 0.22917rem;
  height: 0.22917rem;
  font-size: 0.125rem;
  color: #fff;
  background-color: #606266;
  border-color: #fff;
}