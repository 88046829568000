.title[data-v-396b4ffa] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  color: #000;
  font-size: 0.08333rem;
}
.is-gray[data-v-396b4ffa] {
  opacity: 0.5;
}
.is-gray[data-v-396b4ffa]:hover {
  border-color: transparent !important;
}
.wrap[data-v-396b4ffa] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  max-height: 2.60417rem;
  box-sizing: border-box;
  margin-top: 0.125rem;
  color: #000;
}
.wrap .middle[data-v-396b4ffa] {
  width: 0.29167rem;
  padding-top: 0.20833rem;
  box-sizing: border-box;
}
.wrap .middle .m-li[data-v-396b4ffa] {
  height: 0.46875rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.wrap .middle .m-li > img[data-v-396b4ffa] {
  width: 0.16667rem;
  height: 0.16667rem;
  cursor: pointer;
}
.wrap .middle .m-li + .m-li[data-v-396b4ffa] {
  margin-top: 0.04167rem;
}
.wrap .left[data-v-396b4ffa],
.wrap .right[data-v-396b4ffa] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
}
.wrap .caption[data-v-396b4ffa] {
  height: 0.20833rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  background-color: #eff1f3;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 10;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.list .list-inner[data-v-396b4ffa] {
  position: relative;
}
.list .list-inner + .list-inner[data-v-396b4ffa] {
  margin-top: 0.04167rem;
}
.list .item-mask[data-v-396b4ffa] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  border: 1px solid transparent;
}
.list .card[data-v-396b4ffa] {
  height: 0.46875rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  padding: 0.04167rem;
  font-size: 0.07292rem;
  box-sizing: border-box;
  border: 1px solid #eff1f3;
}
.list .card[data-v-396b4ffa]:hover {
  border-color: #3f6aff;
}
.list .card .card-img[data-v-396b4ffa] {
  width: 0.375rem;
  height: 0.375rem;
}
.list .card .card-description[data-v-396b4ffa] {
  width: 0.88542rem;
  margin: 0.05208rem;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  word-break: keep-all;
}
.list .card .card-info[data-v-396b4ffa] {
  width: 0.72917rem;
  margin: 0.05208rem 0.10417rem;
  box-sizing: border-box;
}
.list .card .card-info .card-specs[data-v-396b4ffa] {
  overflow: hidden;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
}
.list .card .card-info > div + div[data-v-396b4ffa] {
  margin-top: 0.04167rem;
}
.list .card .card-info .card-connect[data-v-396b4ffa] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background: #dfe9ff;
}
.list .card .card-info .info-img[data-v-396b4ffa] {
  width: 0.08333rem;
  height: 0.08333rem;
}
.list .card .card-price[data-v-396b4ffa] {
  margin-top: 0.05208rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-right: 0.125rem;
}
.list .is-active[data-v-396b4ffa] {
  border: 1px solid #3f6aff;
}
.dialog-wrap[data-v-396b4ffa] {
  height: 3.02083rem;
  overflow: auto;
}
.sub-title[data-v-396b4ffa] {
  color: #999999;
  font-size: 0.08333rem;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 4.53125rem;
  text-align: center;
  margin-top: 0.05208rem;
  word-break: keep-all;
}
.dialog-footer[data-v-396b4ffa] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.dialog-footer .wid200[data-v-396b4ffa] {
  width: 1.04167rem;
}
.dialog-footer .cancelBtn[data-v-396b4ffa] {
  background-color: #fff;
  border-radius: 25px;
  color: #15104b;
  height: 0.20833rem;
  font-weight: 400;
  cursor: pointer;
  font-size: 0.08333rem;
  text-align: center;
  margin-right: 0.20833rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.dialog-footer .cancelBtn[data-v-396b4ffa]:hover {
  opacity: 0.9;
}