.modalDialog[data-v-51750735] .el-dialog {
  border-radius: 26px;
}
.pod-configure[data-v-51750735] {
  width: 100%;
  font-family: Open Sans;
}
.pod-configure .formInput[data-v-51750735] .el-input__inner {
  border-radius: 36px;
  border: 1px solid #e5e5e5;
}
.pod-configure .design-title[data-v-51750735] {
  width: 100%;
  height: 0.375rem;
  font-size: 0.11458rem;
  font-weight: 600;
  padding: 0 0.20833rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
}
.pod-configure .configure-main[data-v-51750735] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  overflow: auto;
}
.pod-configure .configure-main .left[data-v-51750735] {
  width: 1.40625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.pod-configure .configure-main .left .title[data-v-51750735] {
  height: 0.25rem;
  padding: 0 0.08333rem;
  font-weight: 600;
  font-size: 0.08333rem;
  color: #333333;
  border-bottom: 1px solid #e5e5e5;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.pod-configure .configure-main .left .left-item[data-v-51750735] {
  height: 0.4375rem;
  padding: 0 0.07292rem 0 0.16667rem;
  cursor: pointer;
  border-bottom: 1px solid #e5e5e5;
  background: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.pod-configure .configure-main .left .left-item .move[data-v-51750735] {
  widows: 0.09375rem;
  height: 0.09375rem;
}
.pod-configure .configure-main .left .left-item .type-pic[data-v-51750735] {
  width: 0.3125rem;
  height: 0.3125rem;
  margin: 0 0.05208rem;
  min-width: 0.3125rem;
}
.pod-configure .configure-main .left .left-item .left-title[data-v-51750735] {
  color: #333333;
  width: 0.36458rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pod-configure .configure-main .left .active[data-v-51750735] {
  background: #e5e5e5;
}
.pod-configure .configure-main .right[data-v-51750735] {
  width: calc(100% - 1.40625rem);
  border-left: 1px solid #e5e5e5;
}
.pod-configure .configure-main .right .collapse[data-v-51750735] .el-collapse-item__header {
  background: #f4f7ff;
  padding: 0 0.0625rem 0 0.16667rem;
  font-weight: 600;
  font-size: 0.08333rem;
}
.pod-configure .configure-main .right .collapse .personal-main[data-v-51750735] {
  padding: 0.10417rem 0.16667rem 0 0.16667rem;
}
.pod-configure .configure-main .right .collapse .personal-main .setting_title[data-v-51750735] {
  font-weight: 600;
  font-size: 0.08333rem;
  color: #333333;
}
.pod-configure .configure-main .right .collapse .personal-main .sub_title[data-v-51750735], .pod-configure .configure-main .right .collapse .personal-main .positions div[data-v-51750735]:nth-child(2) {
  color: #666666;
  font-weight: 400;
  font-size: 0.07292rem;
  max-width: 4.6875rem;
}
.pod-configure .configure-main .right .collapse .personal-main .marTB16[data-v-51750735] {
  margin: 0.08333rem 0;
}
.pod-configure .configure-main .right .collapse .personal-main .positions[data-v-51750735] {
  width: 4.6875rem;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
.pod-configure .configure-main .right .collapse .personal-main .positions div[data-v-51750735] {
  height: 0.22917rem;
  padding-left: 0.16667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.pod-configure .configure-main .right .collapse .personal-main .positions div[data-v-51750735]:nth-child(1) {
  background: #f4f7ff;
  font-weight: 600;
  font-size: 0.08333rem;
}
.pod-configure .configure-main .right .collapse .personal-main .how .imgchecks[data-v-51750735] {
  margin-bottom: 0.08333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.pod-configure .configure-main .right .collapse .personal-main .how .imgchecks .checkbox[data-v-51750735] {
  color: #333333;
}
.pod-configure .configure-main .right .collapse .personal-main .how .printimgs[data-v-51750735] {
  width: 4.6875rem;
  border-radius: 4px;
  min-height: 0.41667rem;
  border: 1px solid #e5e5e5;
  padding: 0.10417rem 0.16667rem;
}
.pod-configure .configure-main .right .collapse .personal-main .how .printimgs .print-hover[data-v-51750735] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.pod-configure .configure-main .right .collapse .personal-main .how .printimgs .shown-print-imgs[data-v-51750735] {
  margin-top: 0.05208rem;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.pod-configure .configure-main .right .collapse .personal-main .how .printimgs .shown-print-imgs .print-files[data-v-51750735] {
  width: 25%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-bottom: 0.08333rem;
  position: relative;
}
.pod-configure .configure-main .right .collapse .personal-main .how .printimgs .shown-print-imgs .print-files .img-btns[data-v-51750735] {
  width: 0.3125rem;
  height: 0.3125rem;
  position: relative;
  margin-right: 0.04167rem;
  border-radius: 6px;
}
.pod-configure .configure-main .right .collapse .personal-main .how .printimgs .shown-print-imgs .print-files .img-btns .print-img-url[data-v-51750735] {
  width: 100%;
  height: 100%;
  min-width: 100%;
}
.pod-configure .configure-main .right .collapse .personal-main .how .printimgs .shown-print-imgs .print-files .img-btns .del[data-v-51750735] {
  display: none;
  font-size: 0.07292rem;
  cursor: pointer;
  width: 0.10417rem;
  height: 0.10417rem;
  position: absolute;
  left: calc(50% - 0.05208rem);
  top: calc(50% - 0.05208rem);
}
.pod-configure .configure-main .right .collapse .personal-main .how .printimgs .shown-print-imgs .print-files .img-btns:hover .showDel[data-v-51750735] {
  display: block;
}
.pod-configure .configure-main .right .collapse .personal-main .how .printimgs .shown-print-imgs .print-files .del[data-v-51750735] {
  position: absolute;
}
.pod-configure .configure-main .right .collapse .personal-main .how .printimgs .shown-print-imgs .print-files .img-name[data-v-51750735] {
  color: #333333;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.pod-configure .configure-main .right .collapse .personal-main .how .printimgs .shown-print-imgs .print-files .img-name .image-name-text[data-v-51750735] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 0.41667rem;
}
.pod-configure .configure-main .right .collapse .personal-main .how .printimgs .shown-print-imgs .print-files .img-name .icon-edit[data-v-51750735] {
  width: 0.08333rem;
  height: 0.08333rem;
  cursor: pointer;
  margin-left: 0.04167rem;
}
.pod-configure .configure-form[data-v-51750735] {
  padding-right: 1.40625rem !important;
}