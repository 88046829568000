.particulars[data-v-37c59e2f] {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  font-family: "Regular";
}
.activeName[data-v-37c59e2f] {
  width: 6.875rem;
  border-radius: 10px;
  line-height: 0.10417rem;
  position: relative;
  padding-bottom: 0.16667rem;
  font-family: "Regular";
}
.display_area[data-v-37c59e2f] {
  height: 1.82292rem;
  overflow: hidden;
  position: relative;
}
.focus[data-v-37c59e2f] {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 1.82292rem;
  border-radius: 10px;
}
.img[data-v-37c59e2f] {
  float: left;
}
.trans[data-v-37c59e2f] {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
[data-v-37c59e2f] .el-carousel__arrow {
  z-index: 5;
}
.swiper[data-v-37c59e2f] {
  width: 100%;
  height: 1.66667rem;
  border-radius: 10px;
}
.swiper img[data-v-37c59e2f] {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.chart[data-v-37c59e2f] {
  width: 5.20833rem;
  height: 2.08333rem;
  background: #ffffff;
  box-shadow: 0 0 0.10417rem 0 rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  position: relative;
}
.chart .seven[data-v-37c59e2f]:hover {
  background: #15104b;
  color: #fff;
}
.chart .month[data-v-37c59e2f]:hover {
  background: #15104b;
  color: #fff;
}
.chart .seven[data-v-37c59e2f] {
  position: absolute;
  top: 0.05208rem;
  right: 0.88542rem;
  width: 0.625rem;
  height: 0.19792rem;
  border-radius: 50px;
  text-align: center;
  line-height: 0.19792rem;
  padding: 0;
  z-index: 1;
  border: 0;
  background: #f5f5f5;
  color: #858585;
  font-size: 0.07292rem;
}
.chart .month[data-v-37c59e2f] {
  position: absolute;
  top: 0.05208rem;
  right: 0.15625rem;
  width: 0.625rem;
  height: 0.19792rem;
  background: #f5f5f5;
  color: #858585;
  border-radius: 50px;
  text-align: center;
  line-height: 0.19792rem;
  padding: 0;
  z-index: 1;
  border: 0;
  font-size: 0.07292rem;
}
.chart .paid_orders_number[data-v-37c59e2f] {
  position: absolute;
  top: 0.05208rem;
  left: 0.15625rem;
  height: 0.19792rem;
  color: #000;
  text-align: center;
  line-height: 0.19792rem;
  padding: 0;
  font-size: 0.07292rem;
}
.chart .paid_days[data-v-37c59e2f] {
  position: absolute;
  bottom: 0;
  right: 0.02604rem;
  width: 0.625rem;
  height: 0.19792rem;
  color: #000;
  text-align: center;
  line-height: 0.19792rem;
  padding: 0;
  font-size: 0.07292rem;
}
.chart .selected[data-v-37c59e2f] {
  background: #15104b;
  color: #ffffff;
}
.chart .all[data-v-37c59e2f] {
  position: absolute;
  top: 0.16667rem;
  left: 6.30208rem;
  width: 0.26042rem;
  height: 0.19792rem;
  background: #f5f5f5;
  border-radius: 12px;
  text-align: center;
  line-height: 0.19792rem;
  padding: 0;
  z-index: 111111;
  border: 0;
}
.el-carousel__item h3[data-v-37c59e2f] {
  color: #475669;
  font-size: 0.09375rem;
  opacity: 0.75;
  line-height: 1.5625rem;
  margin: 0;
}
.el-carousel__item[data-v-37c59e2f]:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item[data-v-37c59e2f]:nth-child(2n+1) {
  background-color: #d3dce6;
}
.chart .echarts[data-v-37c59e2f] {
  padding-top: 0.20833rem;
}