
.particulars[data-v-93e6baf6] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
}
.activeName[data-v-93e6baf6] {
  width: 6.875rem;
  position: relative;
}
.HelpCenterTitle[data-v-93e6baf6] {
  font-size: 0.15625rem;
  font-weight: 600;
  text-align: center;
  color: #15104B;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.help_content[data-v-93e6baf6] {
  margin-top: 0.10417rem;
  font-size: 0.08333rem;
  font-weight: 600;
  text-align: center;
  color: #7677c2;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.HelpBigContentBox[data-v-93e6baf6] {
  width: 4.6875rem;
  height: 2.08333rem;
  background: #fff;
  border-radius: 10px;
  position: relative;
  margin-left: 1.09375rem;
  margin-top: 0.15625rem;
}
.LeftContentBox[data-v-93e6baf6] {
  width: 1.84896rem;
  height: 2.08333rem;
  position: absolute;
  top: 0;
  left: 0.26042rem;
}
.RightContentBox[data-v-93e6baf6] {
  width: 1.82292rem;
  height: 2.08333rem;
  position: absolute;
  top: 0;
  right: 0.3125rem;
}
/* Basic Functions */
.BasicFunctionsBox[data-v-93e6baf6] {
  position: absolute;
  top: 0.3125rem;
  left: 0.3125rem;
}
.BasicFunctionsImg p[data-v-93e6baf6]{
  width: 0.3125rem;
  height: 0.3125rem;
  border: 3px solid #9394cf;
  border-radius: 50%;
  cursor: pointer;
}
.BasicFunctionsImg p[data-v-93e6baf6]:hover{
  width: 0.32292rem;
  height: 0.32292rem;
  border: 3px solid #9394cf;
  border-radius: 50%;
  cursor: pointer;
}
.BasicFunctionsImg p img[data-v-93e6baf6]{
  width: 0.21875rem;
  height: 0.21875rem;
  margin-left: 0.02604rem;
  margin-top: 0.03125rem;
}
.BasicFunctionsImg p img[data-v-93e6baf6]:hover{
  width: 0.23438rem;
  height: 0.23438rem;
  margin-left: 0.03125rem;
  margin-top: 0.04167rem;
}
.BasicFunctionsTitle p[data-v-93e6baf6] {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0.09375rem;
  left: 0.41667rem;
  width: 0.75521rem;
  font-size: 0.09375rem;
  cursor: pointer;
}
.BasicFunctionsTitle p[data-v-93e6baf6]:hover{
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0.09375rem;
  left: 0.41667rem;
  width: 0.80729rem;
  font-size: 0.10417rem;
  cursor: pointer;
}
/* Store Connection */
.StoreConnection[data-v-93e6baf6] {
  position: absolute;
  top: 0.88542rem;
  left: 0.3125rem;
}
.StoreConnectionImg p[data-v-93e6baf6]{
  width: 0.3125rem;
  height: 0.3125rem;
  border: 3px solid #9394cf;
  border-radius: 50%;
  cursor: pointer;
}
.StoreConnectionImg p[data-v-93e6baf6]:hover{
  width: 0.32292rem;
  height: 0.32292rem;
  border: 3px solid #9394cf;
  border-radius: 50%;
  cursor: pointer;
}
.StoreConnectionImg p img[data-v-93e6baf6]{
  width: 0.21875rem;
  height: 0.21875rem;
  margin-left: 0.03125rem;
  margin-top: 0.04167rem;
}
.StoreConnectionImg p img[data-v-93e6baf6]:hover{
  width: 0.23438rem;
  height: 0.23438rem;
  margin-left: 0.03125rem;
  margin-top: 0.04167rem;
}
.StoreConnectionTitle p[data-v-93e6baf6] {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0.09375rem;
  left: 0.41667rem;
  width: 0.83333rem;
  font-size: 0.09375rem;
  cursor: pointer;
}
.StoreConnectionTitle p[data-v-93e6baf6]:hover{
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0.09375rem;
  left: 0.41667rem;
  width: 0.9375rem;
  font-size: 0.10417rem;
  cursor: pointer;
}
/* Message */
.Message[data-v-93e6baf6] {
  position: absolute;
  bottom: 0.28646rem;
  left: 0.3125rem;
  cursor: pointer;
}
.MessageImg p[data-v-93e6baf6]{
  width: 0.3125rem;
  height: 0.3125rem;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.MessageImg p[data-v-93e6baf6]:hover{
  width: 0.32292rem;
  height: 0.32292rem;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.MessageImg p img[data-v-93e6baf6]{
  width: 0.21875rem;
  height: 0.21875rem;
  margin-left: 0.03125rem;
  margin-top: 0.03125rem;
}
.MessageImg p img[data-v-93e6baf6]:hover{
  width: 0.23438rem;
  height: 0.23438rem;
  margin-left: 0.03125rem;
  margin-top: 0.04167rem;
}
.MessageTitle p[data-v-93e6baf6] {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0.09375rem;
  left: 0.41667rem;
  width: 1.30208rem;
  font-size: 0.09375rem;
}
.MessageTitle p[data-v-93e6baf6]:hover{
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0.09375rem;
  left: 0.41667rem;
  width: 1.45833rem;
  font-size: 0.10417rem;
}
/* PricingPlan */
.PricingPlan[data-v-93e6baf6] {
  position: absolute;
  top: 0.3125rem;
  left: 0;
  cursor: pointer;
}
.PricingPlanImg p[data-v-93e6baf6]{
  width: 0.3125rem;
  height: 0.3125rem;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.PricingPlanImg p[data-v-93e6baf6]:hover{
  width: 0.32292rem;
  height: 0.32292rem;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.PricingPlanImg p img[data-v-93e6baf6]{
  width: 0.21875rem;
  height: 0.21875rem;
  margin-left: 0.03125rem;
  margin-top: 0.03125rem;
}
.PricingPlanImg p img[data-v-93e6baf6]:hover{
  width: 0.23438rem;
  height: 0.23438rem;
  margin-left: 0.03125rem;
  margin-top: 0.04167rem;
}
.PricingPlanTitle p[data-v-93e6baf6] {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0.09375rem;
  left: 0.41667rem;
  width: 0.75521rem;
  font-size: 0.09375rem;
}
.PricingPlanTitle p[data-v-93e6baf6]:hover{
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0.09375rem;
  left: 0.41667rem;
  width: 0.80729rem;
  font-size: 0.10417rem;
}
/* Order and Refund */
.OrderRefund[data-v-93e6baf6] {
  position: absolute;
  top: 0.88542rem;
  left: 0;
  cursor: pointer;
}
.OrderRefundImg p[data-v-93e6baf6]{
  width: 0.3125rem;
  height: 0.3125rem;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.OrderRefundImg p[data-v-93e6baf6]:hover{
  width: 0.32292rem;
  height: 0.32292rem;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.OrderRefundImg p img[data-v-93e6baf6]{
  width: 0.21875rem;
  height: 0.21875rem;
  margin-left: 0.03125rem;
  margin-top: 0.03125rem;
}
.OrderRefundImg p img[data-v-93e6baf6]:hover{
  width: 0.23438rem;
  height: 0.23438rem;
  margin-left: 0.03125rem;
  margin-top: 0.04167rem;
}
.OrderRefundTitle p[data-v-93e6baf6] {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0.09375rem;
  left: 0.41667rem;
  width: 0.85938rem;
  font-size: 0.09375rem;
}
.OrderRefundTitle p[data-v-93e6baf6]:hover{
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0.09375rem;
  left: 0.41667rem;
  width: 0.96354rem;
  font-size: 0.10417rem;
}

/* Shipping and Tracking */
.ShippingTracking[data-v-93e6baf6] {
  position: absolute;
  bottom: 0.28646rem;
  left: 0;
  cursor: pointer;
}
.ShippingTrackingImg p[data-v-93e6baf6]{
  width: 0.3125rem;
  height: 0.3125rem;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.ShippingTrackingImg p[data-v-93e6baf6]:hover{
  width: 0.32292rem;
  height: 0.32292rem;
  border: 3px solid #9394cf;
  border-radius: 50%;
}
.ShippingTrackingImg p img[data-v-93e6baf6]{
  width: 0.21875rem;
  height: 0.21875rem;
  margin-left: 0.03125rem;
  margin-top: 0.04167rem;
}
.ShippingTrackingImg p img[data-v-93e6baf6]:hover{
  width: 0.23438rem;
  height: 0.23438rem;
  margin-left: 0.03125rem;
  margin-top: 0.03125rem;
}
.ShippingTrackingTitle p[data-v-93e6baf6] {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0.09375rem;
  left: 0.41667rem;
  width: 1.06771rem;
  font-size: 0.09375rem;
}
.ShippingTrackingTitle p[data-v-93e6baf6]:hover{
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0.09375rem;
  left: 0.41667rem;
  width: 1.17188rem;
  font-size: 0.10417rem;
}
/* 暗夜 */
body .dark[data-v-93e6baf6] {
  background: #1f1e2e;
}
