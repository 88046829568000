.page-box[data-v-05851d8e] {
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  height: 0.36458rem;
  background: #FFFFFF;
  border-radius: 10px;
}
.page-box .page-sign[data-v-05851d8e] {
  height: 0.14583rem;
  line-height: 0.14583rem;
  color: #585858;
  font-size: 0.07292rem;
  float: right;
  padding-right: 0.18229rem;
  font-family: "Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
}
.page-box .page-sign input[data-v-05851d8e] {
  width: 0.23438rem;
  height: 0.14583rem;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-family: "Regular", serif;
  -webkit-font-smoothing: antialiased;
}
.page-box .page-sign span[data-v-05851d8e] {
  display: inline-block;
  cursor: pointer;
  padding-right: 0.07813rem;
  font-family: "Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
}