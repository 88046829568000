.payWay[data-v-2c33a498] .payDialog {
  width: 3.75rem;
  border-radius: 20px;
}
.payWay[data-v-2c33a498] .payDialog .el-dialog__body {
  padding: 0;
}
.payWay[data-v-2c33a498] .payDialog .payContent {
  padding: 0.14583rem 0.15625rem 0.10417rem;
  font-size: 0.125rem;
  color: #15104b;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 100%;
}
.payWay[data-v-2c33a498] .payDialog .payContent .content {
  width: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.payWay[data-v-2c33a498] .payDialog .payContent .content .title {
  height: auto;
}
.payWay[data-v-2c33a498] .payDialog .payContent .content img {
  width: 0.33333rem;
  height: 0.33333rem;
  margin: 0.20833rem 0;
}
.payWay[data-v-2c33a498] .payDialog .payContent .content .twoIcon {
  width: 0.90625rem;
  height: 0.35417rem;
}
.payWay[data-v-2c33a498] .payDialog .payContent .content .button {
  width: 1.04167rem;
  height: 0.22917rem;
  cursor: pointer;
  border-radius: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: #fff;
  color: #15104b;
  border: 2px solid #15104b;
}
.payWay[data-v-2c33a498] .payDialog .payContent .content .button:hover {
  border-color: #15104b;
  background: #15104b;
  color: #fff;
  cursor: pointer;
}
.payWay[data-v-2c33a498] .payDialog .payContent .content .button:hover i {
  color: #fff;
}
.payWay[data-v-2c33a498] .payDialog .payContent .diver {
  height: 1.30208rem;
  border: 1px dashed #999999;
}