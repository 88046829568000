@charset "UTF-8";
.flex[data-v-0662c759], .paybox .copyright[data-v-0662c759], .paybox .payContent .title[data-v-0662c759], .paybox[data-v-0662c759] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}

/* 详情弹出框 */
.paybox[data-v-0662c759] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.paybox .main[data-v-0662c759] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 40%;
}
.paybox .bg[data-v-0662c759] {
  width: 5.78125rem;
  height: 1.04167rem;
  background: #15104b;
  position: absolute;
  z-index: 100;
  bottom: 0.3125rem;
  border-radius: 20px;
}
.paybox .payContent[data-v-0662c759] {
  width: 5.40625rem;
  height: 4.16667rem;
  background: #ffffff;
  position: relative;
  border-radius: 20px;
  position: absolute;
  z-index: 102;
  bottom: 0;
  /*overflow: hidden;
  overflow-y: scroll;
  white-space: nowrap;*/
}
.paybox .payContent .close img[data-v-0662c759] {
  width: 0.10417rem;
  height: 0.10417rem;
  position: absolute;
  right: 0.10417rem;
  top: 0.10417rem;
  cursor: pointer;
}
.paybox .payContent .title[data-v-0662c759] {
  width: 100%;
  font-size: 0.15625rem;
  font-weight: 500;
  color: #333333;
  padding-top: 0.15625rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.paybox .payContent .title .subTitle[data-v-0662c759] {
  font-size: 0.07292rem;
  font-weight: 400;
  margin-top: 0.05208rem;
  opacity: 0.8;
}
.paybox .payContent .content[data-v-0662c759] {
  width: 100%;
  height: 3.38542rem;
  padding: 0.15625rem 0.52083rem 0.78125rem;
  overflow-y: auto;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.paybox .payContent .content p[data-v-0662c759] {
  word-wrap: break-word;
}
.paybox .payContent .content[data-v-0662c759]::-webkit-scrollbar {
  display: none;
}
.paybox .copyright[data-v-0662c759] {
  background: url(../img/notice_YS.3a9dca86.png) no-repeat;
  background-size: contain;
  width: 5.78125rem;
  height: 1.14583rem;
  padding-bottom: 0.10417rem;
  z-index: 103;
  color: #ffffff;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  z-index: 103;
  bottom: 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}