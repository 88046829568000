.particulars[data-v-1e429d8b] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
  font-family: "Regular";
}
.activeName[data-v-1e429d8b] {
  width: 6.875rem;
  border-radius: 10px !important;
  position: relative;
  padding-bottom: 0.10417rem;
  font-family: "Regular";
}
.ContentBigContent[data-v-1e429d8b] {
  width: 6.875rem;
  height: 2.86458rem;
  background: #fff;
  border-radius: 10px;
  margin-top: 0;
  position: relative;
}
.HeaderTop[data-v-1e429d8b] {
  position: relative;
  height: 0.41667rem;
}
.BaseInfoWord[data-v-1e429d8b] {
  position: absolute;
  top: 0.22396rem;
  left: 0.15625rem;
  font-size: 0.10417rem;
}
.SaveBtn[data-v-1e429d8b] {
  position: absolute;
  top: 0.15625rem;
  right: 0.15625rem;
}
[data-v-1e429d8b] .SaveBtn button {
  width: 0.53125rem;
  border-radius: 25px;
  color: #333333;
  background-color: #F9FAFB;
  border: 1px solid #DBDBDB;
}
[data-v-1e429d8b] .SaveBtn button:hover {
  background: #3C3772 !important;
  color: #fff;
}
.line[data-v-1e429d8b] {
  width: 6.71875rem;
  height: 0.00521rem;
  background: #999999;
  margin-left: 0.07813rem;
}
.FormContentBox[data-v-1e429d8b] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.optionsLeft[data-v-1e429d8b] {
  width: 2.60417rem;
  height: 2.39583rem;
  margin-left: 0.41667rem;
}
.optionsRight[data-v-1e429d8b] {
  width: 3.125rem;
  height: 2.39583rem;
}
.StoreBox[data-v-1e429d8b] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-left: 0.15625rem;
  margin-top: 0.15625rem;
}
.ZipCodeBox[data-v-1e429d8b] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-left: 0.15625rem;
  margin-top: 0.15625rem;
}
.CityBox[data-v-1e429d8b] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-left: 0.15625rem;
  margin-top: 0.15625rem;
}
.TaxnumberBox[data-v-1e429d8b] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-left: 0.15625rem;
  margin-top: 0.15625rem;
}
.ZipCodeName[data-v-1e429d8b] {
  margin-top: 0;
  margin-left: 0.05208rem;
}
.StoreName[data-v-1e429d8b] {
  margin-left: 0.39583rem;
}
.CountryName[data-v-1e429d8b] {
  margin-left: 0.28646rem;
}
.CityName[data-v-1e429d8b] {
  margin-left: 0.41667rem;
}
.PhoneName[data-v-1e429d8b] {
  margin-left: 0.67708rem;
}
.TaxnumbeName[data-v-1e429d8b] {
  margin-left: 0.20833rem;
}
.OrderNoName[data-v-1e429d8b] {
  margin-left: 0.59896rem;
}
.ProvinceName[data-v-1e429d8b] {
  margin-left: 0.44271rem;
}
.StatesName[data-v-1e429d8b] {
  margin-left: 0.70313rem;
}
.AddressName[data-v-1e429d8b] {
  margin-left: 0.65104rem;
}
.ZIPName[data-v-1e429d8b] {
  margin-left: 0.59896rem;
}
.countrys[data-v-1e429d8b] {
  margin-left: 0.13021rem;
}
[data-v-1e429d8b] .inputbox .el-input__inner {
  border-radius: 25px;
  width: 1.40625rem;
}
[data-v-1e429d8b] .ZipCodeInput .el-input__inner {
  margin-left: 0.16667rem;
  width: 1.40625rem;
}
[data-v-1e429d8b] .PhoneInput .el-input__inner {
  margin-left: 0.18229rem;
  width: 1.40625rem;
}
[data-v-1e429d8b] .ProvinceInput .el-input__inner {
  margin-left: 0.18229rem;
  width: 1.40625rem;
}
[data-v-1e429d8b] .StatesInput .el-input__inner {
  margin-left: 0.18229rem;
  width: 1.40625rem;
}
[data-v-1e429d8b] .el-form-item {
  margin: 0;
}
.AddTableBox[data-v-1e429d8b] {
  width: 6.875rem;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
}
.AddTable[data-v-1e429d8b] {
  margin-top: 0.15625rem;
  padding-bottom: 0.05208rem;
}
[data-v-1e429d8b] .AddMessageBtn .el-button {
  margin-top: 0.07813rem;
  margin-left: 0.07813rem;
  background: #15104B;
  border: #15104B;
  font-size: 0.08333rem;
  border-radius: 50px;
}
[data-v-1e429d8b] .MessageContent .el-table th > .cell {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.RequiredOne[data-v-1e429d8b] {
  position: absolute;
  top: 0.78125rem;
  left: 0.6875rem;
  color: red;
  font-size: 0.09375rem;
  z-index: 9;
}
.RequiredTwo[data-v-1e429d8b] {
  position: absolute;
  top: 0.78125rem;
  left: 2.13542rem;
  color: red;
  font-size: 0.09375rem;
  z-index: 9;
}
.RequiredThree[data-v-1e429d8b] {
  position: absolute;
  top: 0.78125rem;
  left: 3.33333rem;
  color: red;
  font-size: 0.09375rem;
  z-index: 9;
}
.RequiredFour[data-v-1e429d8b] {
  position: absolute;
  top: 0.78125rem;
  right: 2.03125rem;
  color: red;
  font-size: 0.09375rem;
  z-index: 9;
}
[data-v-1e429d8b] .el-input__inner {
  border-radius: 25px !important;
}