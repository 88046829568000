.ad-login[data-v-0fb6c60f] {
  width: 100%;
  height: 100%;
  padding-top: 0.48958rem;
  background: url("http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580863839_loginBg.png") no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.ad-login .login-info[data-v-0fb6c60f] {
  margin-right: 0.41667rem;
  text-align: left;
  margin-top: -6%;
}
.ad-login .login-info .logo1[data-v-0fb6c60f] {
  width: 1.17188rem;
  height: 0.98438rem;
  margin-bottom: 0.20833rem;
}
.ad-login .login-info .logo1 img[data-v-0fb6c60f] {
  width: 100%;
}
.ad-login .login-info .btnContent[data-v-0fb6c60f] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 4.16667rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.ad-login .login-info .btnContent .btnInfo[data-v-0fb6c60f] {
  width: 2.08333rem;
  margin-bottom: 0.20833rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.ad-login .login-info .btnContent .btnInfo .content-icon[data-v-0fb6c60f] {
  width: 0.20833rem;
  height: 0.20833rem;
  background: #15104b;
}
.ad-login .login-info .btnContent .btnInfo .content-icon[data-v-0fb6c60f] .el-icon-check {
  font-size: 0.13542rem;
  color: #fff;
  line-height: 0.20833rem;
  margin-left: 0.03646rem;
  font-weight: 800;
}
.ad-login .login-info .btnContent .btnInfo .content-words[data-v-0fb6c60f] {
  font-size: 0.125rem;
  font-weight: 600;
  margin-left: 0.10417rem;
}
.ad-login .login-content[data-v-0fb6c60f] {
  width: 2.61979rem;
  height: 3.6875rem;
  margin-top: -6%;
  background: #fff;
  border-radius: 30px;
  padding: 0.10417rem 0.20833rem;
  position: relative;
}
.ad-login .login-content .logo2[data-v-0fb6c60f] {
  width: 45%;
  height: 0.52083rem;
  image-rendering: crisp-edges;
}
.ad-login .login-content .login-head[data-v-0fb6c60f] {
  font-size: 0.125rem;
  color: #000000;
  font-weight: 500;
  text-align: center;
}
.ad-login .login-content .option[data-v-0fb6c60f] {
  width: 2.1875rem;
  height: 0.52083rem;
  margin: 0 auto 0.05208rem;
  padding-left: 0.01563rem;
}
.ad-login .login-content .option[data-v-0fb6c60f] .el-input__inner {
  border-radius: 50px;
  width: 100%;
  height: 0.21875rem;
  border: 1px solid #cccccc;
}
.ad-login .login-content .forget[data-v-0fb6c60f] {
  width: 100%;
  text-align: right;
  color: #3f6aff !important;
  font-size: 0.07292rem;
  cursor: pointer;
}
.ad-login .login-content .submit[data-v-0fb6c60f] {
  width: 1.17188rem;
  height: 0.21875rem;
  line-height: 0.21875rem;
  font-size: 0.08333rem;
  color: #fff;
  background: #474882;
  text-align: center;
  border-radius: 50px;
  margin-top: 0.3125rem;
  margin-left: 0.52083rem;
}
.ad-login .login-content .submit[data-v-0fb6c60f]:hover {
  background: #3c3772 !important;
  color: #fff;
}
.ad-login .login-content .noaccount[data-v-0fb6c60f] {
  margin-top: 0.07813rem;
  margin-left: 0.41667rem;
}