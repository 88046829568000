.UploadOrderBox .UploadOrderContent.upgradeDialog[data-v-5dd92b76] {
  background: url("http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715593585615_pay_upgrade_img.svg") no-repeat;
  background-size: 2.58333rem 1.64583rem;
  width: 2.58333rem;
  height: 1.64583rem;
  z-index: 100;
  position: relative;
  padding: 0 0.39583rem;
}
.UploadOrderBox .UploadOrderContent.upgradeDialog .btns[data-v-5dd92b76] {
  position: absolute;
  bottom: 0.36458rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.UploadOrderBox .UploadOrderContent.upgradeDialog .btns .btn[data-v-5dd92b76] {
  width: 0.83333rem;
  height: 0.20833rem;
  border-radius: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 0.08333rem;
  font-weight: 600;
  cursor: pointer;
}
.UploadOrderBox .UploadOrderContent.upgradeDialog .btns .btn[data-v-5dd92b76]:first-child {
  background: #dcdfe6;
  color: #15104b;
  margin-right: 0.125rem;
}
.UploadOrderBox .UploadOrderContent.upgradeDialog .btns .btn[data-v-5dd92b76]:nth-child(2) {
  background: #15104b;
  color: #fff;
}
.min-wid100[data-v-5dd92b76] {
  min-width: 0.52083rem;
}
.min-wid200[data-v-5dd92b76] {
  min-width: 1.04167rem;
}
.plain[data-v-5dd92b76] {
  background: none;
  border: none;
}
.searchbox[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  height: 0.46875rem;
  background: #ffffff;
  box-shadow: 0 0 0.05208rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-left: 0.10417rem;
  margin-bottom: 0.10417rem;
}
.searchbox .search_input[data-v-5dd92b76] {
  display: block;
  width: 2.34375rem;
  height: 0.20833rem;
  background: #f1f2f4;
  border-radius: 30px;
  margin-right: 0.10417rem;
}
.searchbox .search_input .search_icon[data-v-5dd92b76] {
  display: inline-block;
  margin-top: 0.07813rem;
  margin-right: 0.125rem;
  margin-left: 0.125rem;
}
.searchbox .search_input input[data-v-5dd92b76] {
  background: transparent !important;
  width: calc(100% - 0.36458rem);
  font-size: 0.08333rem;
  color: #585858;
  padding-right: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.searchbox .topbox div[data-v-5dd92b76] {
  min-width: 0.52083rem;
  height: 0.20833rem;
  margin-right: 0.10417rem;
}
.searchbox .topbox .Synchronize a[data-v-5dd92b76] {
  color: #fff;
  font-size: 0.08333rem;
}
.searchbox .add_shopify a[data-v-5dd92b76] {
  color: #fff;
  font-size: 0.08333rem;
}
.typebox-btn-group .all[data-v-5dd92b76]:hover {
  background: #3f6aff;
  color: #fff !important;
}
.typebox-btn-group[data-v-5dd92b76] {
  text-align: right;
}
.typebox-btn-group .all[data-v-5dd92b76] {
  height: 0.20833rem;
  min-width: 0.78125rem;
  border-width: 2px;
  border-style: solid;
  margin-top: 0.10417rem;
  margin-right: 0.10417rem;
}
.typebox-btn-group .all[data-v-5dd92b76]:last-child {
  margin-right: 0;
}
.main-list[data-v-5dd92b76] {
  background-color: #fff;
}
.main-list li[data-v-5dd92b76] :hover {
  background-color: #eff1f3;
}
.main-list li .content[data-v-5dd92b76] {
  border-bottom: 2px solid #f2f2f2;
}
.main-list li .content .title_li[data-v-5dd92b76] {
  font-family: "Regular";
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-font-smoothing: antialiased;
}
.main-list li .content .title_li[data-v-5dd92b76]:nth-child(1) {
  width: 0.65104rem;
}
.main-list li .content .title_li[data-v-5dd92b76]:nth-child(2) {
  width: 0.88542rem;
}
.main-list li .content .title_li[data-v-5dd92b76]:nth-child(3) {
  width: 1.04167rem;
}
.main-list li .content .title_li[data-v-5dd92b76]:nth-child(4) {
  width: 0.9375rem;
}
.main-list li .content .title_li[data-v-5dd92b76]:nth-child(5) {
  width: 0.83333rem;
}
.main-list li .content .title_li[data-v-5dd92b76]:nth-child(6) {
  width: 0.52083rem;
}
.main-list li .content .title_li[data-v-5dd92b76]:nth-child(7) {
  width: 0.78125rem;
  padding-left: 0.07813rem;
}
.main-list li .content .title_li[data-v-5dd92b76]:nth-child(8) {
  width: 1.04167rem;
}
.main-list li .content .title_li[data-v-5dd92b76]:nth-child(9) {
  width: 0.52083rem;
}
.main-list li .contentMessage[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  cursor: pointer;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-size: 0.07292rem;
  color: #000000;
  height: auto;
  width: 100%;
  overflow-x: scroll;
}
.main-list li .contentMessage .content_li[data-v-5dd92b76] {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-bottom: 2px solid #f2f2f2;
  padding: 0 0.05208rem;
}
.main-list li .contentMessage .content_li[data-v-5dd92b76]:nth-child(1) {
  width: 0.83333rem;
}
.main-list li .contentMessage .content_li[data-v-5dd92b76]:nth-child(2) {
  width: 0.83333rem;
}
.main-list li .contentMessage .content_li[data-v-5dd92b76]:nth-child(3) {
  width: 1.25rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.main-list li .contentMessage .content_li[data-v-5dd92b76]:nth-child(4) {
  color: #3f6aff;
  width: 0.98958rem;
}
.main-list li .contentMessage .content_li:nth-child(4) span[data-v-5dd92b76] {
  margin-left: 0.02604rem;
  width: 0.07813rem;
  height: 0.07813rem;
  background: url("http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715593618161_blue.png") no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.main-list li .contentMessage .content_li[data-v-5dd92b76]:nth-child(5) {
  width: 0.83333rem;
}
.main-list li .contentMessage .content_li[data-v-5dd92b76]:nth-child(6) {
  width: 0.83333rem;
}
.main-list li .contentMessage .content_li[data-v-5dd92b76]:nth-child(7) {
  width: 0.83333rem;
}
.main-list li .contentMessage .content_li[data-v-5dd92b76]:nth-child(8) {
  width: 0.83333rem;
}
.main-list li .contentMessage .content_li[data-v-5dd92b76]:nth-child(9) {
  width: 0.83333rem;
}
.type[data-v-5dd92b76] {
  height: 0.41667rem;
  line-height: 0.375rem;
  padding: 0 0.04167rem;
  font-size: 0.08333rem;
  float: left;
  margin-right: 0.3125rem;
  position: relative;
  cursor: pointer;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.select[data-v-5dd92b76] {
  color: #3f6aff;
  font-size: 0.08333rem;
  line-height: 0.375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: relative;
}
.select[data-v-5dd92b76]::after {
  content: "";
  position: absolute;
  bottom: 0.08854rem;
  width: 60%;
  height: 0.01042rem;
  background: #3f6aff;
  left: 20%;
}
[data-v-5dd92b76] .mydialog {
  border-radius: 20px !important;
}
[data-v-5dd92b76] .el-message-box__message p {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.paybox[data-v-5dd92b76] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.paybox .pay_content[data-v-5dd92b76] {
  width: 3.48958rem;
  background: #fff;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  position: absolute;
  padding-bottom: 0.20833rem;
  left: calc(50% - 1.74479rem);
  top: calc(50% - 1.35417rem);
}
.paybox .pay_content .pay_title[data-v-5dd92b76] {
  width: 100%;
  height: 0.23438rem;
  line-height: 0.23438rem;
  font-size: 0.08333rem;
  color: #000;
  padding-left: 0.21875rem;
  padding-right: 0.16667rem;
}
.paybox .pay_content .pay_title span[data-v-5dd92b76] {
  width: 0.07813rem;
  height: 0.08333rem;
  display: block;
  background: url("http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715593643566_close.png") no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: right;
  margin-top: 0.10417rem;
  cursor: pointer;
}
.paybox .pay_content .pay_items[data-v-5dd92b76] {
  width: 100%;
  font-size: 0.08333rem;
  color: #000000;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.paybox .pay_content .pay_items .pay_item[data-v-5dd92b76] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 0.20833rem;
}
.paybox .pay_content .pay_items .pay_item[data-v-5dd92b76]:last-child {
  margin-bottom: 0.10417rem;
}
.paybox .pay_content .pay_items .pay_item .pay_left[data-v-5dd92b76] {
  width: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-right: 0.3125rem;
}
.paybox .pay_content .pay_items .pay_item .pay_right[data-v-5dd92b76] {
  padding-left: 0.3125rem;
  width: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.paybox .pay_content .pay_items .Member_exclusive[data-v-5dd92b76] {
  font-size: 0.07292rem;
  margin-bottom: 0.20833rem;
}
.paybox .pay_content .pay_items .pay_footer[data-v-5dd92b76] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.paybox .pay_content .pay_items .pay_footer .btn[data-v-5dd92b76] {
  width: 1.04167rem;
  height: 0.22917rem;
}
.paybox .pay_content .pay_items .pay_footer .btn[data-v-5dd92b76]:first-child {
  border-style: solid;
  border-width: 2px;
  margin-right: 0.52083rem;
}