@charset "UTF-8";
.pointer {
  cursor: pointer;
}

.text-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-word;
}

.text-ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-word;
}

.text-blue {
  color: #3f6aff;
}

html {
  font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei", "微软雅黑", "Segoe UI", Tahoma, "宋体b8b体", SimSun, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  /*background: #150f4b;*/
}

html,
body,
div,
object,
iframe,
applet,
object,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
address,
dl,
dt,
dd,
ol,
ul,
li,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
progress {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  /*caret-color: rgba(0, 0, 0, 0)
  caret-color: rgba(0, 0, 0, 0);*/
}

li {
  list-style: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
  text-decoration: none;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 0.20833rem;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  outline: none;
  line-height: normal;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
  border: none;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 0.01042rem;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

i,
em {
  font-style: normal;
}

i,
img {
  font-style: normal;
}

.pointer {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

input::-webkit-input-placeholder {
  color: #858585;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #858585;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #858585;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #858585;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 5.20833rem white inset;
  -webkit-text-fill-color: #858585;
}

.page {
  height: 100vh;
  min-height: 3.90625rem;
  min-width: 7.29167rem;
}

.page3 {
  height: 100vh;
  min-height: 3.90625rem;
  min-width: 7.29167rem;
  position: relative;
}

.select .el-input__inner {
  border: none;
  height: 0.22917rem;
}

.el-message-box__message p {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.el-button--mini,
.el-button--small {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/*提示气泡弹出框字体样式*/
.el-message--warning .el-message__content {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.el-message--error .el-message__content {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.el-message--success .el-message__content {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.el-message--info .el-message__content {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.el-select-dropdown {
  border-radius: 10px !important;
}

/*高级弹出框*/
.el-form-item__label {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.el-select-dropdown__item {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.el-date-table td div {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.el-date-range-picker__header div {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

.el-date-table th {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 公用样式 */
.c-pointer {
  cursor: pointer;
}

/* 分页器样式调整 */
.el-pager li.active {
  color: #5870cd;
  border: 1px solid #5870cd;
  border-radius: 6px;
}

.el-pager li,
.el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  color: #d2d2d2;
}

.el-pager li:hover {
  color: #5870cd;
}

.el-pagination .btn-next,
.el-pagination .btn-prev {
  color: #999;
}

.el-pagination .btn-next:hover,
.el-pagination .btn-prev:hover {
  color: #5870cd;
}

.dark .el-pagination .btn-next,
.dark .el-pagination .btn-prev,
.dark .el-pager li {
  background: #2a293e;
}

/* 分页器结束 */
.el-dropdown-menu .el-dropdown-menu__item:hover {
  color: #6788ff;
}

/* 标签栏 */
.el-tabs__item {
  height: 0.22917rem;
}

.el-tabs__item.is-active,
.el-tabs__item:hover,
.dark .el-tabs__item:hover {
  color: #6788ff;
}

.el-tabs__active-bar {
  background: #6788ff;
}

/* 下拉菜单 */
.el-cascader {
  position: absolute;
  opacity: 0;
  width: 0.08333rem;
  height: 0.07813rem;
}

.el-cascader-menu__wrap {
  height: 105%;
}

.el-table th > .cell {
  font-weight: normal;
  color: #000000;
  font-size: 0.07292rem;
}

.el-table .cell {
  font-size: 0.07292rem;
  color: #000;
}

.dark .el-table .cell {
  color: #fff;
}

/* 放大镜 */
.vue-photo-zoom-pro .selector {
  background: rgba(0, 0, 0, 0.6);
}

.vue-photo-zoom-pro .out-zoomer {
  z-index: 1;
}

/* 弹窗样式调整 */
.mydialog {
  border-radius: 10px;
}

.el-dialog__headerbtn .el-dialog__close:hover {
  color: #6788ff;
}

.usadialog {
  border-radius: 10px;
}
.usadialog .el-dialog__header {
  border-bottom: 1px solid #e5e5e5;
}

@font-face {
  font-family: "Black";
  src: url(../fonts/Poppins-Black.0573b923.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bold";
  src: url(../fonts/Poppins-Bold.a3e0b5f4.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ExtraBold";
  src: url(../fonts/Poppins-ExtraBold.544fa4f2.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Italic";
  src: url(../fonts/Poppins-Italic.5e956c44.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Light";
  src: url(../fonts/Poppins-Light.f6ea751e.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Medium";
  src: url(../fonts/Poppins-Medium.f61a4eb2.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Regular";
  src: url(../fonts/Poppins-Regular.8b6af8e5.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SemiBold";
  src: url(../fonts/Poppins-SemiBold.4cdacb8f.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Thin";
  src: url(../fonts/Poppins-Thin.25cd0f68.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PlayfairDisplay-Black";
  src: url(../fonts/PlayfairDisplay-Black.d51c5a75.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PlayfairDisplay-Bold";
  src: url(../fonts/PlayfairDisplay-Bold.fb9d6829.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PlayfairDisplay-ExtraBold";
  src: url(../fonts/PlayfairDisplay-ExtraBold.e4e3e62b.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PlayfairDisplay-Italic";
  src: url(../fonts/PlayfairDisplay-Italic.68221fae.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PlayfairDisplay-Medium";
  src: url(../fonts/PlayfairDisplay-Medium.0078b3be.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PlayfairDisplay-Regular";
  src: url(../fonts/PlayfairDisplay-Regular.544850af.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PlayfairDisplay-SemiBold";
  src: url(../fonts/PlayfairDisplay-SemiBold.e6456051.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova-Semibold";
  src: url(../fonts/ProximaNova-Semibold.woff.6fc5720b.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
.comBtn {
  border-radius: 25px;
  min-width: 0.36458rem;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: none;
  color: #ffffff;
  height: 0.20833rem;
  font-weight: 400;
  cursor: pointer;
  padding: 0.05208rem 0.09375rem;
  font-size: 0.08333rem;
  text-align: center;
  background-color: #ffffff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.comBtn:hover {
  opacity: 0.9;
}

.btn-bg0 {
  background-color: #15104b !important;
  color: #ffffff !important;
}

.btn-bg1 {
  background-color: #474882 !important;
  color: #ffffff !important;
}

.btn-bg2 {
  background-color: #93df89 !important;
  color: #ffffff !important;
}

.btn-bg3 {
  background-color: #ffda6b !important;
  color: #ffffff !important;
}

.btn-bg4 {
  background-color: #eaeffb !important;
}

.btn-bg5 {
  background-color: #3f6aff !important;
}

.btn-bg6 {
  background-color: #eff1f3 !important;
}

.btn-bg7 {
  background-color: #bc0c34 !important;
  color: #ffffff !important;
}

.btn-bg9 {
  background-color: #dbdbdb !important;
}

.col-col0 {
  color: #15104b !important;
}

.col-col1 {
  color: #474882 !important;
}

.col-col2 {
  color: #93df89 !important;
}

.col-col3 {
  color: #ffda6b !important;
}

.col-col4 {
  color: #eaeffb !important;
}

.col-col5 {
  color: #3f6aff !important;
}

.col-col6 {
  color: #eff1f3 !important;
}

.col-col7 {
  color: #bc0c34 !important;
}

.col-col8 {
  color: #ffffff !important;
}

.border-col0 {
  border-color: #15104b;
}

.border-col5 {
  border-color: #3f6aff;
}

.comSerInput .Filter .input-with-select {
  width: 5.20833rem;
}
.comSerInput .Filter .el-input {
  border-radius: 20px;
}
.comSerInput .Filter .el-input .el-input-group__prepend {
  border-radius: 20px 0 0 20px;
}
.comSerInput .Filter .el-input input {
  border-radius: 0 20px 20px 0;
}

.comSerInput ::v-deep .el-input__inner {
  border: 36px;
  border: 1px solid #e5e5e5;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #15104b !important;
  border-color: #15104b !important;
}

.raduisInput .el-input__inner {
  border-radius: 20px !important;
}

input.raduisInput {
  border-radius: 20px !important;
}

.mb-48 {
  margin-bottom: 0.25rem;
}

.mb-28 {
  margin-bottom: 0.14583rem;
}

.mt-28 {
  margin-top: 0.14583rem;
}

.mb-16 {
  margin-bottom: 0.08333rem;
}

.mb-24 {
  margin-bottom: 0.125rem;
}

.mb-20 {
  margin-bottom: 0.10417rem;
}

.mt-24 {
  margin-top: 0.125rem;
}

.mb-40 {
  margin-bottom: 0.20833rem;
}

.ft-56 {
  font-size: 0.29167rem;
}

.ft-24 {
  font-size: 0.125rem;
}

.ft-40 {
  font-size: 0.20833rem;
}

.ft-32 {
  font-size: 0.16667rem;
}

.ft-28 {
  font-size: 0.14583rem;
}

.ft-24 {
  font-size: 0.125rem;
}

.ft-20 {
  font-size: 0.10417rem;
}

.ft-16 {
  font-size: 0.08333rem;
}

.ft-12 {
  font-size: 0.0625rem;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.color1 {
  color: #bc0c34;
}

.color2 {
  color: #15104b;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  width: 0.03125rem;
  /* 水平滚动条的高度、垂直滚动条的宽度 */
  background-color: #f9f9f9;
  /* 滚动条的背景色 */
}

/* 滚动条内的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  /* 滑块的背景色 */
  border-radius: 6px;
  /* 滑块的圆角 */
}

/* 滑块hover效果 */
::-webkit-scrollbar-thumb:hover {
  background-color: #828282;
  /* 滑块hover时的背景色 */
}

.el-loading-spinner i {
  font-size: 0.15625rem;
  color: white;
}
.el-loading-spinner .el-loading-text {
  color: #ffffff;
  font-size: 0.10417rem;
}