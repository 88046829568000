
.particulars img[data-v-e6c7b19a] {
    display: block;
    width: 1.29167rem;
    height: 1.04167rem;
    margin: auto;
    margin-top: 1.5625rem;
}
.particulars .successful[data-v-e6c7b19a] {
    font-size: 0.15625rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #AAAAAA;
    text-align: center;
    margin-top: 0.29688rem;
}
