@charset "UTF-8";
.particulars[data-v-70d574a0] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.67708rem;
  font-family: "Regular";
}
.activeName[data-v-70d574a0] {
  width: 6.875rem;
  height: 3.82813rem;
  border-radius: 10px !important;
}
.CardFunctionBox[data-v-70d574a0] {
  margin-top: 0.20833rem;
  border-radius: 15px;
  margin-left: 0.07813rem;
  height: auto;
}
[data-v-70d574a0] .CardFunctionBox .el-tabs--border-card .el-tabs__content {
  border-radius: 15px;
  padding-bottom: 0.15625rem;
}
[data-v-70d574a0] .CardFunctionBox .el-tabs--border-card {
  border-radius: 10px !important;
}
[data-v-70d574a0] .CardFunctionBox .el-tabs__nav-scroll {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
[data-v-70d574a0] .CardFunctionBox .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
[data-v-70d574a0] .CardFunctionBox .el-tabs__nav-wrap {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
.RegistrationInfoImg img[data-v-70d574a0] {
  width: 0.08333rem;
  height: 0.08333rem;
}
.AccountSecurityImg img[data-v-70d574a0] {
  width: 0.08333rem;
  height: 0.08333rem;
}
.PublishSettingsImg img[data-v-70d574a0] {
  width: 0.08333rem;
  height: 0.08333rem;
}
.DisplayInfo[data-v-70d574a0] {
  width: auto;
  height: 0.29167rem;
  background: #93df89;
  border-radius: 10px;
  position: absolute;
  z-index: 999;
  left: 1.11979rem;
  top: 0.98958rem;
  padding-right: 0.04167rem;
}
.DisplayInfo p[data-v-70d574a0]:nth-child(1) {
  font-size: 0.07292rem;
  color: #fff;
  padding-top: 0.02604rem;
  padding-left: 0.05208rem;
}
.DisplayInfo p[data-v-70d574a0]:nth-child(2) {
  font-size: 0.07292rem;
  color: #fff;
  padding-left: 0.05208rem;
}

/*自动继承父元素的背景*/
.DisplayInfo[data-v-70d574a0]::before {
  content: "";
  position: absolute;
  bottom: 85%;
  left: 1.82292rem;
  width: 0.07813rem;
  height: 0.07813rem;
  margin-top: 0.07813rem;
  background: inherit;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.Important_Notice_main[data-v-70d574a0] {
  width: 6.25rem;
  height: 0.20833rem;
  border: 1px solid #ccc;
  border-radius: 50px;
  margin-left: 0.20833rem;
  margin-top: 0.10417rem;
  cursor: pointer;
  font-size: 0.07292rem;
}
.Important_Notice_main span[data-v-70d574a0]:nth-child(2) {
  line-height: 0.20833rem;
  margin-left: 0.05208rem;
  color: #4c8bf5;
}
.edit_password_main[data-v-70d574a0] {
  width: 4.16667rem;
  height: 1.5625rem;
  margin-top: 0.10417rem;
  margin-left: 0.78125rem;
  border-radius: 10px;
  position: relative;
}
.Reset_Password_content[data-v-70d574a0] {
  margin-left: 0.26042rem;
  margin-top: 0.15625rem;
  font-size: 0.07292rem;
}
.New_Password_btn[data-v-70d574a0] {
  margin-top: 0.18229rem;
  margin-left: 0.26042rem;
  font-size: 0.07292rem;
}
.New_Password_btn p[data-v-70d574a0]:nth-child(2) {
  width: 3.125rem;
}
.Repeat_New_Password[data-v-70d574a0] {
  margin-top: 0.18229rem;
  margin-left: 0.26042rem;
  font-size: 0.07292rem;
}
.Repeat_New_Password p[data-v-70d574a0]:nth-child(2) {
  width: 3.125rem;
}
.confirm_btn[data-v-70d574a0] {
  margin-top: 0.3125rem;
  margin-right: 0.67708rem;
  position: relative;
}
.confirm_btn button[data-v-70d574a0] {
  display: block;
  width: 1.04167rem;
  height: 0.21875rem;
  background: #474882;
  border-radius: 21px;
  border: 0;
  font-size: 0.08333rem;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  bottom: 0.15625rem;
  left: 2.08333rem;
}
.confirm_btn button[data-v-70d574a0]:hover {
  background: #3c3772 !important;
  color: #fff;
}
.header_Basic[data-v-70d574a0] {
  width: 0.13021rem;
  height: 0.13021rem;
}
.Welcome_name[data-v-70d574a0] {
  width: 100%;
  height: 0.26042rem;
  margin-top: 0.15625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  font-size: 0.09375rem;
  font-weight: 600;
  padding-left: 0.20313rem;
  margin-top: 0.09896rem;
  margin-bottom: 0.10417rem;
}
.Welcome_name .name[data-v-70d574a0] {
  margin-top: 0.03646rem;
  margin-left: 0.02604rem;
}
.Membership_Exhibition[data-v-70d574a0] {
  width: 100%;
  height: 0.67708rem;
  margin-top: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-left: 0.23438rem;
  margin-bottom: 0.05208rem;
}
.Membership_Exhibition .Membership_One[data-v-70d574a0] {
  width: 1.25rem;
  height: 0.57292rem;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.Membership_Exhibition .Membership_One .one_content p[data-v-70d574a0]:nth-child(1) {
  text-align: center;
  margin-top: 0.02604rem;
}
.Membership_Exhibition .Membership_One .one_content p[data-v-70d574a0]:nth-child(2) {
  text-align: center;
  font-size: 0.07292rem;
}
.Membership_Exhibition .Membership_One .one_content p[data-v-70d574a0]:nth-child(3) {
  text-align: center;
  font-size: 0.07292rem;
}
.Membership_Exhibition .Membership_Two[data-v-70d574a0] {
  width: 1.25rem;
  height: 0.57292rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-left: 0.38021rem;
}
.Membership_Exhibition .Membership_Two .two_content p[data-v-70d574a0]:nth-child(1) {
  text-align: center;
  margin-top: 0.05208rem;
}
.Membership_Exhibition .Membership_Two .two_content p:nth-child(1) img[data-v-70d574a0] {
  width: 0.18229rem;
  height: 0.16667rem;
  margin-top: 0.01563rem;
}
.Membership_Exhibition .Membership_Two .two_content p[data-v-70d574a0]:nth-child(2) {
  text-align: center;
  font-size: 0.07292rem;
}
.Membership_Exhibition .Membership_Two .two_content p[data-v-70d574a0]:nth-child(3) {
  text-align: center;
  font-size: 0.07292rem;
}
.Membership_Exhibition .Membership_Three[data-v-70d574a0] {
  width: 1.25rem;
  height: 0.57292rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-left: 0.38021rem;
}
.Membership_Exhibition .Membership_Three .three_content p:nth-child(1) img[data-v-70d574a0] {
  width: 0.13542rem;
  height: 0.13542rem;
  margin-top: 0.05208rem;
}
.Membership_Exhibition .Membership_Three .three_content p[data-v-70d574a0]:nth-child(1) {
  text-align: center;
  margin-top: 0.03646rem;
}
.Membership_Exhibition .Membership_Three .three_content p[data-v-70d574a0]:nth-child(2) {
  text-align: center;
  font-size: 0.07292rem;
}
.Membership_Exhibition .Membership_Three .three_content p[data-v-70d574a0]:nth-child(3) {
  text-align: center;
  font-size: 0.07292rem;
}
.Membership_Exhibition .Membership_Four[data-v-70d574a0] {
  width: 1.25rem;
  height: 0.57292rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-left: 0.38021rem;
}
.Membership_Exhibition .Membership_Four .four_content p[data-v-70d574a0]:nth-child(1) {
  text-align: center;
  margin-top: 0.10417rem;
  cursor: pointer;
}
.Membership_Exhibition .Membership_Four .four_content p:nth-child(1) img[data-v-70d574a0] {
  width: 0.20833rem;
  height: 0.20833rem;
}
.Membership_Exhibition .Membership_Four .four_content p[data-v-70d574a0]:nth-child(2) {
  text-align: center;
  cursor: pointer;
  font-size: 0.07292rem;
}
.personal_information_box[data-v-70d574a0] {
  width: 100%;
  height: 1.51042rem;
}
.personal_information_box .personal_msg[data-v-70d574a0] {
  width: 6.25rem;
  height: 1.45833rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: relative;
  margin-left: 0.20833rem;
}
.personal_information_box .personal_msg_box[data-v-70d574a0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.personal_information_box .edit_personal[data-v-70d574a0] {
  width: 100%;
  height: 0.20833rem;
}
.personal_information_box .edit_personal p[data-v-70d574a0] {
  position: absolute;
  top: 0.06771rem;
  right: 0.10417rem;
  color: #808080;
}
.personal_information_box .msg_left[data-v-70d574a0] {
  width: 47%;
  height: 1.66667rem;
}
.personal_information_box[data-v-70d574a0] .msg_left .el-input__inner {
  border-radius: 50px;
}
.personal_information_box .email_box[data-v-70d574a0] {
  margin-left: 0.10417rem;
}
.personal_information_box .email_box p[data-v-70d574a0]:nth-child(1) {
  font-size: 0.07292rem;
}
.personal_information_box .email_box p[data-v-70d574a0]:nth-child(2) {
  margin-top: 0.05208rem;
}
.telephone_box[data-v-70d574a0] {
  margin-left: 0.10417rem;
}
.telephone_box p[data-v-70d574a0]:nth-child(1) {
  font-size: 0.07292rem;
}
.telephone_box p[data-v-70d574a0]:nth-child(2) {
  margin-top: 0.05208rem;
}
.IconInfo[data-v-70d574a0] {
  position: absolute;
  top: 0.79167rem;
  left: 2.96875rem;
  cursor: pointer;
}
[data-v-70d574a0] .IconInfo .el-icon-info {
  font-size: 0.10417rem;
}
.facebook_box[data-v-70d574a0] {
  margin-left: 0.10417rem;
}
.facebook_box p[data-v-70d574a0]:nth-child(1) {
  font-size: 0.07292rem;
}
.facebook_box p[data-v-70d574a0]:nth-child(2) {
  margin-top: 0.05208rem;
}
.msg_right[data-v-70d574a0] {
  width: 47%;
  height: 1.66667rem;
  margin-left: 0.15625rem;
}
[data-v-70d574a0] .msg_right .el-input__inner {
  border-radius: 50px;
}
.skype_box[data-v-70d574a0] {
  margin-left: 0.10417rem;
}
.skype_box p[data-v-70d574a0]:nth-child(1) {
  font-size: 0.07292rem;
}
.skype_box p[data-v-70d574a0]:nth-child(2) {
  margin-top: 0.05208rem;
}
.whatsapp_box[data-v-70d574a0] {
  margin-left: 0.10417rem;
}
.whatsapp_box p[data-v-70d574a0]:nth-child(1) {
  font-size: 0.07292rem;
}
.whatsapp_box p[data-v-70d574a0]:nth-child(2) {
  margin-top: 0.05208rem;
}
.wechat_box[data-v-70d574a0] {
  margin-left: 0.10417rem;
}
.wechat_box p[data-v-70d574a0]:nth-child(1) {
  font-size: 0.07292rem;
}
.wechat_box p[data-v-70d574a0]:nth-child(2) {
  margin-top: 0.05208rem;
}
.publishSetting1[data-v-70d574a0] {
  width: 5.15625rem;
  height: 0.94271rem;
  padding-left: 0.26563rem;
}
.Price[data-v-70d574a0] {
  padding-top: 0.27083rem;
  font-size: 0.08333rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}
.cost[data-v-70d574a0] {
  margin-top: 0.10938rem;
  height: 0.25rem;
  line-height: 0.25rem;
  font-size: 0.09375rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.cost input[data-v-70d574a0] {
  padding-left: 0.10417rem;
  margin-left: 0.125rem;
  display: inline-block;
  width: 1.04167rem;
  height: 0.20833rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 50px;
}
.produce[data-v-70d574a0] {
  margin-top: 0.04167rem;
  font-size: 0.0625rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.payment[data-v-70d574a0] {
  text-align: center;
}
.payment button[data-v-70d574a0] {
  width: 1.04167rem;
  height: 0.21875rem;
  line-height: 0.21875rem;
  background: #474882;
  border-radius: 21px;
  border: 0;
  font-size: 0.08333rem;
  font-weight: 500;
  color: #ffffff;
  margin-top: 0.15625rem;
}
.payment button[data-v-70d574a0]:hover {
  background: #3c3772 !important;
  color: #fff;
}

/* 头像选择框 */
.avatar-uploader[data-v-70d574a0] {
  display: inline;
}
.avatar-uploader[data-v-70d574a0] .el-upload {
  border-radius: 50%;
  box-shadow: 0 0 0.00521rem 0 rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.avatar-uploader[data-v-70d574a0] .el-upload img {
  border-radius: 50%;
}
.avatar-uploader .el-upload[data-v-70d574a0] {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload[data-v-70d574a0]:hover {
  border-color: #3f6aff;
}
.avatar-uploader-icon[data-v-70d574a0] {
  font-size: 0.14583rem;
  color: #8c939d;
  width: 0.52083rem;
  height: 0.52083rem;
  line-height: 0.52083rem;
  text-align: center;
}
.avatar[data-v-70d574a0] {
  width: 0.22917rem;
  height: 0.22917rem;
  display: block;
}
.TabBox[data-v-70d574a0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-left: 0.05208rem;
}
.TabBox .contentBox1[data-v-70d574a0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 1.25rem;
  height: 0.625rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background: #ffffff;
  padding: 0.10417rem 0;
  font-size: 0.08333rem;
  font-weight: 400;
  border-radius: 14px;
  color: #15104b;
}
.TabBox .contentBox1 img[data-v-70d574a0] {
  width: 0.20833rem;
  height: 0.20833rem;
}
.TabBox .contentBox1[data-v-70d574a0]:hover {
  cursor: pointer;
}
.logoutbtn[data-v-70d574a0] {
  border-style: solid;
  border-width: 2px;
}
.btn[data-v-70d574a0] {
  margin-top: 0.15625rem;
  margin-right: 0.10417rem;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.btn button[data-v-70d574a0] {
  width: 1.04167rem;
  height: 0.21875rem;
}
.btn button[data-v-70d574a0]:first-child {
  margin-right: 0.20833rem;
}
.btn button[data-v-70d574a0]:nth-child(2) {
  margin-left: 0.04167rem;
}
.btn button[data-v-70d574a0]:nth-child(2):hover {
  background: #15104b;
  color: #ffffff !important;
}