.footer[data-v-27f46840] {
  width: 100%;
  height: 0.41667rem;
  background: #E0E8FF;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 0.52083rem;
  position: relative;
}
.footer[data-v-27f46840]::after {
  content: "";
  position: absolute;
  height: 0.00521rem;
  width: calc(100% - 0.3125rem);
  background: #E0E8FF;
  left: 0.3125rem;
  bottom: 0;
}
.footer .headerContent[data-v-27f46840] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.footer .headerContent .breadContent[data-v-27f46840] {
  width: calc(100% - 1.5625rem);
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  padding-left: 0.29167rem;
  height: 100%;
  padding-bottom: 0.07813rem;
}
.footer .content[data-v-27f46840] {
  width: 5.20833rem;
  font-size: 0.07292rem;
  color: #15104B;
  word-wrap: break-word;
  line-height: 0.15625rem;
}
.footer .content a[data-v-27f46840] {
  text-decoration: underline;
}
.footer .AcceptAllBtn[data-v-27f46840] {
  width: 0.78125rem;
  height: 0.20833rem;
  border-radius: 50px;
  border: 2px solid #3f6aff;
  color: #3F6AFF;
  font-size: 0.08333rem;
  background: #fff;
  margin-left: 0.15625rem;
}
.footer .AcceptAllBtn[data-v-27f46840]:hover {
  background: #3f6aff;
  color: #fff !important;
}
.footer .RejectAllBtn[data-v-27f46840] {
  width: 0.78125rem;
  height: 0.20833rem;
  border-radius: 50px;
  border: 2px solid #3f6aff;
  color: #3F6AFF;
  font-size: 0.08333rem;
  background: #fff;
  margin-left: 0.15625rem;
}
.footer .RejectAllBtn[data-v-27f46840]:hover {
  background: #3f6aff;
  color: #fff !important;
}