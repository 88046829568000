
/* 视频弹窗 */
.paybox[data-v-7d69b632] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.VideoSizeContentBox[data-v-7d69b632] {
  position: absolute;
  width: 2.60417rem;
  height: 1.82292rem;
  background: #fff;
  top: 0.78125rem;
  left: 1.5625rem;
  border-radius: 10px;
}
.VideoSizeContentBox img[data-v-7d69b632]{
  width: 3.125rem;
  height: 1.82292rem;
  border-radius: 8px;
}
.VideoSizeIconRemove[data-v-7d69b632] {
  position: absolute;
  top: 0.05208rem;
  right: -0.41667rem;
}
.VideoSizeIconRemove[data-v-7d69b632] .el-icon-remove {
  font-size: 0.10417rem;
}
.VideoSizeBtn[data-v-7d69b632] {
  position: absolute;
  bottom: 0.9375rem;
  right: 0.67708rem;
  width: 1.51042rem;
  height: 0.20833rem;
  background: #BC0C34;
  border-radius: 50px;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  color: #ffffff;
}
.VideoSizeBtn p[data-v-7d69b632]{
  text-align: center;
  line-height: 0.20833rem;
  font-size: 0.09375rem;
}
.VideoSizeBtn[data-v-7d69b632]:hover {
  background: #fc4343;
}
.VideoSizeBtn i[data-v-7d69b632] {
  font-size: 0.13542rem;
  color: #fff;
}
.VideoSizeBtn[data-v-7d69b632] .el-icon-caret-right {
  font-size: 0.15625rem;
  cursor: pointer;
  position: absolute;
  bottom: 0.02083rem;
  left: 0.67708rem;
  font-size: 0.13542rem;
}
.play_video[data-v-7d69b632] {
  width: 4.27083rem;
  height: 1.19792rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  overflow: hidden;
  position: relative;
}
.VideoBtn[data-v-7d69b632] {
  margin-left: 0.33854rem;
  width: 0.3125rem;
  height: 0.20833rem;
  border-radius: 10px;
  bottom: 0.625rem;
  position: absolute;
  background:#000;
  opacity: 0.8;
}
.VideoBtn[data-v-7d69b632]:hover{
  background:red;
}
.VideoBtn i[data-v-7d69b632] {
  font-size: 0.13542rem;
  color: #fff;
}
.VideoBtn .el-icon-caret-right[data-v-7d69b632]:before {
  font-size: 0.13542rem;
  cursor: pointer;
  position: absolute;
  bottom: 0.01563rem;
  left: 0.07813rem;
  font-size: 0.15625rem;
}
.Intro p[data-v-7d69b632]:nth-child(1){
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  margin-top: 0.05208rem;
  font-size: 0.09375rem;
  color: #999999;
  font-weight: 600;
}
.Intro p[data-v-7d69b632]:nth-child(2){
  width: 0.52083rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-size: 0.07292rem;
  margin-left: 3.90625rem;
  color: red;
}
.video_play[data-v-7d69b632] {
  width: 0.98958rem;
  height: 0.98958rem;
  margin-left: 0.07813rem;
}
.video_play img[data-v-7d69b632]{
  width: 0.9375rem;
  height: 0.9375rem;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.video_play img[data-v-7d69b632]:hover{
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.video_right[data-v-7d69b632] {
  background-color: #15104B;
  background-image: url(../img/Group2041_procVideo.52873953.jpg);
  background-repeat: no-repeat;
  height: 2.34375rem;
  width: 1.5625rem;
  position: absolute;
  top: 0;
  right: 0.67708rem;
  border-radius: 10px;
  background-size: 100% 100%;
}
.VideoPic[data-v-7d69b632] {
  position: absolute;
  top: 0.39063rem;
  left: -0.10417rem;
}
.synopsis[data-v-7d69b632] {
  width:  0.65104rem;
  height: 0.10417rem;
  margin-top: 0.04167rem;
  margin-left: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.07292rem;
}
.VideoPic img[data-v-7d69b632] {
  width: 1.51042rem;
  height: 1.25rem;
}
.video_left[data-v-7d69b632] {
  width: 4.32292rem;
  height: 2.70833rem;
  float: left;
}
.particulars[data-v-7d69b632] {
  width: 100%;
  min-height: 100vh;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
  position: relative;
  background: #eff1f3;
}
.activeName[data-v-7d69b632] {
  width: 6.875rem;
  border-radius: 10px;
  line-height: 0.10417rem;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
}
[data-v-7d69b632] .el-tabs__nav-scroll {
  width: 6.875rem;
  height: 0.41667rem;
  padding: 0.07813rem 0;
  background: -webkit-linear-gradient(bottom, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}
[data-v-7d69b632] .el-tabs__nav {
  margin-left: 0.16667rem;
}
.VideoYoutuBe[data-v-7d69b632] .el-empty {
  padding-top: 0.52083rem !important;
}
[data-v-7d69b632] .el-tabs__item.is-active {
  font-size: 0.08333rem;
  color: #6788ff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
}
/* 标签页下划线 */
[data-v-7d69b632] .el-tabs__active-bar {
  height: 0.01563rem !important;
  background: #6788ff;
}
[data-v-7d69b632] .el-tabs__header {
  margin: 0;
}
[data-v-7d69b632] .el-tabs__nav-wrap::after {
  background-color: rgb(255 255 255 / 50%);
}
/* 按钮 */
.activeName .orders[data-v-7d69b632] {
  position: absolute;
  bottom: 1.25rem;
  right: 0.66667rem;
  width: 0.67708rem;
  height: 0.20833rem;
   background: #474882;
  border-radius: 50px;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #ffffff;
  border: 0;
}
.activeName .orders2[data-v-7d69b632] {
  position: absolute;
  bottom: 1.25rem;
  right: 1.53646rem;
  width: 0.67708rem;
  height: 0.20833rem;
  background: #474882;
  border-radius: 50px;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #ffffff;
  border: 0;
}
.activeName .orders2[data-v-7d69b632]:hover{
  background: #3C3772 !important;
  color: #fff;
}
.activeName .orders[data-v-7d69b632]:hover{
  background: #3C3772 !important;
  color: #fff;
}
.VideoSizeBox[data-v-7d69b632] {
  position: absolute;
  bottom: 0.9375rem;
  right: 0.67708rem;
  width: 1.51042rem;
  height: 0.20833rem;
  background: #474882;
  border-radius: 10px;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #ffffff;
  border: 0;
  cursor: pointer;
}
.VideoSizeBox p[data-v-7d69b632] {
  text-align: center;
  line-height: 0.20833rem;
  font-size: 0.09375rem;
}
.VideoMemberBtn p[data-v-7d69b632]{
  padding-right: 0.46875rem;
}
.box[data-v-7d69b632] {
  width: 4.32292rem;
  height: 2.34375rem;
  background: #ccc;
  box-shadow: 0 0 0.09375rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.VideoYoutuBe[data-v-7d69b632] {
  width: 4.32292rem;
  height: 2.34375rem;
}
