
.particulars[data-v-833a0f72] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
}
.activeName[data-v-833a0f72] {
  width: 6.875rem;
  position: relative;
  margin-top: 0.15625rem;
}
.HelpCenterTitleBig[data-v-833a0f72] {
  width: 80%;
  color: #15104B;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-size: 0.15625rem;
  font-weight: 600;
}
.HelpCenterBigBox[data-v-833a0f72] {
  width: 6.875rem;
  background: #fff;
  border-radius: 10px;
  position: relative;
}
.HelpCenterBigBox[data-v-833a0f72] .el-collapse {
  width: 6.66667rem;
  height: auto;
  margin-left: 0.10417rem;
}
.HelpCenterBigBox[data-v-833a0f72] .el-collapse-item__header {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
}
