.particulars[data-v-04f8d190] {
  width: 6.875rem;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  padding-bottom: 0.16667rem;
  box-sizing: border-box;
  font-family: "Regular";
}

/* orders */
.main[data-v-04f8d190] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.main .orders[data-v-04f8d190] {
  position: absolute;
  top: 0.10417rem;
  right: 0.15625rem;
  font-weight: 500;
  color: #fff;
  border-radius: 50px;
  height: 0.20833rem;
}
.main .speed-progress[data-v-04f8d190] {
  position: absolute;
  top: 0.13542rem;
  left: 0.28646rem;
}
.main .progress-fulfilled span[data-v-04f8d190]:nth-child(1) {
  font-size: 0.07292rem;
}
.main .progress-fulfilled span[data-v-04f8d190]:nth-child(2) {
  font-size: 0.07292rem;
  margin-left: 0.15625rem;
}
.main .progress-fulfilled span[data-v-04f8d190]:nth-child(3) {
  font-size: 0.07292rem;
  margin-left: 0.02604rem;
}
.main .progress-fulfilled span[data-v-04f8d190]:nth-child(4) {
  font-size: 0.07292rem;
  margin-left: 0.03125rem;
}
.main .monthly-red[data-v-04f8d190] {
  position: absolute;
  top: -0.02604rem;
  left: 1.19792rem;
  cursor: pointer;
}
.main .monthly-red span img[data-v-04f8d190] {
  width: 0.13542rem;
  height: 0.14063rem;
}
.main .monthly-red span img[data-v-04f8d190]:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.main .icon-question[data-v-04f8d190] {
  position: absolute;
  top: 0.01042rem;
  left: 1.35417rem;
}
.main .icon-question .el-icon-question[data-v-04f8d190] {
  font-size: 0.10417rem;
  color: #3f6aff;
}
.source-tab[data-v-04f8d190] {
  width: 100%;
}
.source-tab[data-v-04f8d190] .el-tabs__item {
  font-size: 0.08333rem;
}
.source-tab[data-v-04f8d190] .el-tabs__active-bar {
  height: 0.01563rem;
  background-color: #6788ff;
  border-radius: 10px;
}
.source-tab[data-v-04f8d190] .el-tabs__nav-scroll {
  height: 0.41667rem;
  padding: 0.07813rem 0;
  background: -webkit-linear-gradient(bottom, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}
.source-tab[data-v-04f8d190] .el-tabs__header {
  margin: 0;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
.source-tab[data-v-04f8d190] .el-tabs__nav {
  margin-left: 0.16667rem;
}
.source-tab[data-v-04f8d190] .el-table th,
.source-tab[data-v-04f8d190] .el-table tr {
  height: 0.36458rem;
  font-weight: 500;
}
.source-tab .product_link_box[data-v-04f8d190] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  font-size: 0.0625rem;
  color: #555555;
}
.source-tab .product_link_box .product_link[data-v-04f8d190] {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.source-tab .product_link_box .copy_btn[data-v-04f8d190] {
  cursor: pointer;
}
.source-tab .product_link_box .copy_btn[data-v-04f8d190]:hover {
  color: #3f6aff;
}
.source-tab .product_describe[data-v-04f8d190] {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.source-tab .title_name[data-v-04f8d190] {
  padding: 0 0.03125rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.source-tab .content[data-v-04f8d190] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  box-sizing: border-box;
  padding-left: 0.125rem;
}
.source-tab .content img[data-v-04f8d190] {
  width: 0.52083rem;
  height: 0.52083rem;
}
.source-tab .content .description[data-v-04f8d190] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  min-width: 0;
  padding-left: 0.04167rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  font-size: 0.07292rem;
}
.source-tab .content .description .title[data-v-04f8d190] {
  line-height: 0.09375rem;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  word-break: keep-all;
}
.source-tab .content .description .info[data-v-04f8d190] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  line-height: 0.09375rem;
  color: #999999;
  margin-top: 0.03125rem;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  padding-right: 0.10417rem;
  position: relative;
}
.source-tab .content .description .info .copy_btn[data-v-04f8d190] {
  position: absolute;
  top: 0.01563rem;
  right: 0;
  width: 0.07292rem;
  height: 0.07292rem;
  cursor: pointer;
}
.source-tab .content .description .info .copy_btn[data-v-04f8d190]:hover {
  color: #3f6aff;
}
.source-tab .content .description .remark[data-v-04f8d190] {
  margin-top: 0.08333rem;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.source-tab .content .description .remark .remark-inner[data-v-04f8d190] {
  height: 0.125rem;
  padding: 0 0.05208rem;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #d2d2d2;
}
.source-tab .content .result[data-v-04f8d190] {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.sign-box-btn[data-v-04f8d190] {
  border: 2px solid #3f6aff;
}
.sign-box-btn[data-v-04f8d190]:hover {
  opacity: 0.7;
}
.pagebox[data-v-04f8d190] {
  width: 100%;
  height: 0.36458rem;
  padding-bottom: 0.02083rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.pagebox .el-pagination[data-v-04f8d190] {
  float: left;
  margin-top: 0.13542rem;
}
.no-data[data-v-04f8d190] {
  background-color: #fff;
}
.success-text[data-v-04f8d190] {
  color: #2ad131;
}
.warning-text[data-v-04f8d190] {
  color: #ff6b00;
}
.error-text[data-v-04f8d190] {
  color: #ff0000;
}