.active[data-v-7ee4f90d] {
  color: #3f6aff;
}
.shopifyUpgrade[data-v-7ee4f90d] {
  width: 7.29167rem;
  height: 3.90625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.shopifyUpgrade .leftContent[data-v-7ee4f90d] {
  width: calc(100% - 1.82292rem);
  height: 100%;
  background: #ffffff;
  margin-right: 0.20833rem;
  border-radius: 10px;
  padding: 0.20833rem;
}
.shopifyUpgrade .leftContent .searchItem[data-v-7ee4f90d] {
  width: 80%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  color: #333333;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0.13021rem;
}
.shopifyUpgrade .leftContent .searchItem .label[data-v-7ee4f90d] {
  color: #333333;
  font-size: 0.07292rem;
  font-weight: 500;
  margin-right: 0.04167rem;
  width: 0.46875rem;
}
.shopifyUpgrade .leftContent .searchItem[data-v-7ee4f90d] .el-input__inner {
  width: 2.91667rem;
  border-radius: 30px;
}
.shopifyUpgrade .leftContent .searchItem[data-v-7ee4f90d] .el-range-separator {
  width: 10%;
}
.shopifyUpgrade .leftContent .option[data-v-7ee4f90d] {
  font-size: 0.07292rem;
  color: #000000;
  font-weight: 500;
  margin: 0.20833rem 0 0.10417rem 0;
}
.shopifyUpgrade .leftContent .optionsContent[data-v-7ee4f90d] {
  border: 1px solid #eff1f3;
  overflow: auto;
  margin-bottom: 0.36458rem;
  border: 1px solid #eff1f3;
  border-radius: 6px;
  border-top: none;
}
.shopifyUpgrade .leftContent .optionsContent .optionItem[data-v-7ee4f90d] {
  cursor: pointer;
  border-top: 1px solid #eff1f3;
  height: 0.38542rem;
  font-size: 0.07292rem;
  font-weight: 500;
  color: #666666;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.shopifyUpgrade .leftContent .optionsContent .optionItem .optionEach[data-v-7ee4f90d] {
  width: 25%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.shopifyUpgrade .leftContent .optionsContent .optionItem .optionEach .text_item[data-v-7ee4f90d] {
  margin-left: 0.08333rem;
}
.shopifyUpgrade .leftContent .optionsContent .optionItem .optionEach .radio[data-v-7ee4f90d] {
  width: 0.41667rem;
  text-align: left;
}
.shopifyUpgrade .leftContent .optionsContent .optionItem .optionEach[data-v-7ee4f90d]:nth-child(2) {
  width: 75%;
  text-align: left;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.shopifyUpgrade .leftContent .optionsContent .optionItem .optionTip[data-v-7ee4f90d] {
  color: #32cd32;
  position: absolute;
  left: 0.41667rem;
  bottom: 0.02604rem;
  font-size: 0.07292rem;
}
.shopifyUpgrade .leftContent .optionsContent .optionItem .optionsTip1[data-v-7ee4f90d] {
  margin-left: 0.15625rem;
  position: absolute;
  right: 0.15625rem;
  color: #32cd32;
}
.shopifyUpgrade .leftContent .optionsContent .activeLabel[data-v-7ee4f90d] {
  color: #3f6aff;
}
.shopifyUpgrade .leftContent .optionsContent .activeLabel[data-v-7ee4f90d] .el-radio__input.is-checked .el-radio__inner {
  border-color: #3f6aff;
  background: #3f6aff;
}
.shopifyUpgrade .leftContent .optionsContent .activeLabel[data-v-7ee4f90d] .el-radio__label {
  color: #3f6aff;
}
.shopifyUpgrade .leftContent .payWay[data-v-7ee4f90d] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0.125rem;
}
.shopifyUpgrade .leftContent .payWay img[data-v-7ee4f90d] {
  width: 1.19792rem;
  height: 0.26042rem;
  cursor: pointer;
}
.shopifyUpgrade .leftContent .agree[data-v-7ee4f90d] {
  text-align: center;
  font-weight: 0.0625rem;
  font-weight: 500;
}
.shopifyUpgrade .leftContent .agree a[data-v-7ee4f90d] {
  color: #3f6aff;
}
.shopifyUpgrade .rightContent[data-v-7ee4f90d] {
  width: 1.82292rem;
  height: 100%;
}