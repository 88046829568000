
.particulars[data-v-da616a96] {
  position: relative;
  /*background: #eff1f3;
  background-image: url('../../assets/imgs/Christmas/XueHua.png');*/
  min-width: 6.875rem;
}
.particulars.dark[data-v-da616a96] {
  background: #1f1e2e !important;
}
