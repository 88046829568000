
.editor {
  width: 100%;
  margin: 0 auto;
  position: relative;
  /* z-index: 0; */
}
/* .toolbar {
  border: 1px solid #ccc;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.text {
  border: 1px solid #ccc;
  min-height: 500px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.w-e-toolbar {
  z-index: 10 !important;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.w-e-text-container {
  z-index: 9 !important;
  height: 500px !important;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.dark .editor {
  width: 100%;
  position: relative;
  z-index: 0;
  margin: 0;
  padding: 0;
}
.dark .w-e-toolbar {
  z-index: 10 !important;
  background: #2a293e !important;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.dark .w-e-text {
  padding-left: 5px;
  padding-bottom: 25px !important;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  background: #2a293e !important;
}
.dark .w-e-text {
  padding-left: 5px;
  background: #2a293e !important;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.dark .w-e-text-container {
  z-index: 9 !important;
  height: 500px !important;
  padding-bottom: 25px !important;
  background: #2a293e !important;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
} */
