@charset "UTF-8";
.particulars[data-v-3b21a0b4] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
  /*overflow-x: scroll;*/
  font-family: "Regular";
}
.activeName[data-v-3b21a0b4] {
  width: 6.875rem;
  border-radius: 10px;
  line-height: 0.10417rem;
  position: relative;
}
.CheckAgreement[data-v-3b21a0b4] {
  /* 条款协议 */
  text-align: center;
  margin-top: 0.10417rem;
}
.Account[data-v-3b21a0b4] {
  margin-left: 0.05729rem;
  font-size: 0.09375rem;
  font-weight: 400;
  color: #ffffff;
  margin-right: 0.3125rem;
}
.GreenPCB[data-v-3b21a0b4] {
  /* tab栏切换 */
  width: 6.875rem;
  margin-left: 0.10417rem;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
}
[data-v-3b21a0b4] .el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 0.76042rem;
  height: 0.76042rem;
  line-height: 0.76042rem;
  vertical-align: top;
}
.btn1[data-v-3b21a0b4] {
  display: inline-block;
  height: 0.77604rem;
  line-height: 0.77604rem;
  border-bottom-color: #fff !important;
  background-color: #fff !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 0.52083rem;
  /*测试库样式*/
}
.btn1 button[data-v-3b21a0b4] {
  width: 1.19792rem;
  height: 0.72917rem;
  border-radius: 10px;
  padding: 0;
  margin-left: 0.26563rem !important;
  border: none;
  background: #fff;
  font-size: 0.09375rem;
}
.btn1 img[data-v-3b21a0b4] {
  width: 1.04167rem;
  height: 0.67708rem;
}
[data-v-3b21a0b4] .el-button.Transfer:focus,
.el-button.Transfer[data-v-3b21a0b4]:hover,
.selectedType.Transfer[data-v-3b21a0b4] {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.btn1 button[data-v-3b21a0b4]:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.btn1[data-v-3b21a0b4]:nth-child(4) {
  width: 1.97917rem;
}
.btn1 button img[data-v-3b21a0b4] {
  vertical-align: middle;
  margin-right: -0.02604rem;
}
.btn_payList[data-v-3b21a0b4] {
  display: inline-block;
  height: 0.77604rem;
  line-height: 0.77604rem;
  border-bottom-color: #fff !important;
  background-color: #fff !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 1.19792rem;
  /*正式库样式*/
}
.btn_payList button[data-v-3b21a0b4] {
  width: 1.19792rem;
  height: 0.72917rem;
  border-radius: 10px;
  padding: 0;
  margin-left: 0.26563rem !important;
  border: none;
  background: #fff;
}
.btn_payList img[data-v-3b21a0b4] {
  width: 1.04167rem;
  height: 0.67708rem;
}
[data-v-3b21a0b4] .el-button.Transfer:focus,
.el-button.Transfer[data-v-3b21a0b4]:hover,
.selectedType.Transfer[data-v-3b21a0b4] {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.btn_payList button[data-v-3b21a0b4] {
  font-size: 0.09375rem;
}
.btn_payList button[data-v-3b21a0b4]:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.btn_payList[data-v-3b21a0b4]:nth-child(4) {
  width: 1.97917rem;
}
.btn_payList button img[data-v-3b21a0b4] {
  vertical-align: middle;
  margin-right: -0.02604rem;
}
.money[data-v-3b21a0b4] {
  background: none;
  width: 80%;
  color: #ff7519;
}
.TotalAmount[data-v-3b21a0b4] {
  display: inline-block;
  margin-top: 0.16667rem;
  margin-left: 0.70313rem;
  height: 0.23958rem;
  line-height: 0.23958rem;
}
.TotalAmount span[data-v-3b21a0b4] {
  font-size: 0.09375rem;
  font-weight: 400;
  color: #585858;
}
.TotalAmount .price-list[data-v-3b21a0b4] {
  display: inline-block;
  width: 0.55729rem;
  height: 0.23958rem;
  border-radius: 5px;
  text-align: center;
  margin-left: 0.22396rem;
}
.TotalAmount .price-list span[data-v-3b21a0b4] {
  font-size: 0.09375rem;
  font-weight: 400;
  color: #ff7519;
}
.voucher-center p[data-v-3b21a0b4] {
  font-size: 0.07292rem;
  font-weight: 400;
  color: #c2c2c2;
}
.voucher-center p[data-v-3b21a0b4]:nth-child(2) {
  font-size: 0.08333rem;
  font-weight: 600;
  color: red;
  margin-top: 0.02604rem;
  margin-left: 0.31771rem;
}
.checked[data-v-3b21a0b4] {
  font-size: 0.07292rem;
  font-weight: 400;
  color: #585858;
  margin-left: 0.33333rem;
  margin-top: 0.125rem;
}
.Confirm button[data-v-3b21a0b4] {
  width: 1.04167rem;
  height: 0.23958rem;
  background: #93df89;
  border-radius: 50px;
  border: 0;
  margin-top: 0.15625rem;
  margin-left: 2.86458rem;
  margin-bottom: 0.10417rem;
  color: #ffffff;
  font-size: 0.09375rem;
}
.Confirm button[data-v-3b21a0b4]:hover {
  background: #bff4b8;
  color: #fff;
}
.recharge[data-v-3b21a0b4] {
  height: 3.95833rem !important;
  background-color: #ffffff !important;
  border-radius: 10px !important;
}

/* 填写信息 */
.paybox[data-v-3b21a0b4] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.paybox .pay_content[data-v-3b21a0b4] {
  width: 6.25rem;
  background: #fff;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  overflow: hidden;
  position: absolute;
  left: calc(50% - 2.86458rem);
  top: calc(50% - 1.66667rem);
  padding-bottom: 0.07813rem;
}
.paybox .pay_header_title[data-v-3b21a0b4] {
  width: 100%;
  height: 0.18229rem;
  line-height: 0.18229rem;
  font-size: 0.07292rem;
  font-weight: 500;
  color: #000000;
  padding-left: 0.22396rem;
  background: #ffffff;
}
.paybox .pay_header_title span[data-v-3b21a0b4] {
  width: 0.0625rem;
  height: 0.0625rem;
  display: block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOBAMAAADtZjDiAAAALVBMVEUAAACfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoapgOQNlAAAADnRSTlMAmIGmkqvxuolo93l4dQWiX7wAAABSSURBVAjXY0hlAAExhjgBIMX0nIHlIZDWM2Bg8BNgYHoMZAMFQFygwMnHYLW87xrANOc7BTCt1/0QRDE9BWoB6wVpAesFCcgFAGnGpwzpYLVWANBXEYKHOC/hAAAAAElFTkSuQmCC) no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: right;
  margin-top: 0.07813rem;
  cursor: pointer;
  margin-right: 0.15625rem;
}
.paybox .pay_items[data-v-3b21a0b4] {
  width: 100%;
  height: 1.25rem;
  background: #fff;
  border-bottom: 2px solid #f2f2f2;
  padding-top: 0.02604rem;
}
.paybox .account-information[data-v-3b21a0b4] {
  font-size: 0.08333rem;
  font-weight: 500;
  color: #000000;
  padding-left: 0.22396rem;
  margin-top: -0.03125rem;
}
.paybox .pay_item_word[data-v-3b21a0b4] {
  width: 100%;
  height: 0.18229rem;
  line-height: 0.18229rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 0.09375rem;
  color: #585858;
}
.paybox .pay_left[data-v-3b21a0b4] {
  font-size: 0.08333rem;
  font-weight: 500;
  color: #000000;
  margin-left: 1.15104rem;
  width: 2.60417rem;
  padding: 0;
  margin-top: 0.05208rem;
}
.paybox .pay_right[data-v-3b21a0b4] {
  font-size: 0.07292rem;
  font-weight: 400;
  color: #000000;
  margin-top: 0.05208rem;
}
.paybox .transfer[data-v-3b21a0b4] {
  /* transfer转账记录 */
  width: 100%;
  background: #fff;
  padding-top: 0.02604rem;
}
.paybox .transfer .slip[data-v-3b21a0b4] {
  font-size: 0.08333rem;
  font-weight: 500;
  color: #000000;
  margin-left: 0.22917rem;
}
.paybox .price[data-v-3b21a0b4] {
  margin-top: 0.02604rem;
  margin-left: 1.15625rem;
}
.paybox .price span[data-v-3b21a0b4]:nth-child(1) {
  font-size: 0.08333rem;
  font-weight: 500;
  color: #000000;
}
.paybox .price span[data-v-3b21a0b4]:nth-child(2) {
  font-size: 0.11458rem;
  font-weight: 500;
  color: #ac1010;
  margin-left: 0.24479rem;
}
.paybox .ImgBigBox[data-v-3b21a0b4] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.paybox .upload[data-v-3b21a0b4] {
  margin-left: 1.15625rem;
  margin-top: 0.05208rem;
  width: 2.60417rem;
}
.paybox .ScreenshotTitle[data-v-3b21a0b4] {
  margin-top: -0.14583rem;
  font-size: 0.08333rem;
}
.paybox .account[data-v-3b21a0b4] {
  margin-left: 1.15625rem;
  margin-top: 0.05208rem;
}
.paybox .account span[data-v-3b21a0b4] {
  display: inline-block;
  height: 0.18229rem;
  line-height: 0.18229rem;
  font-size: 0.08333rem;
  font-weight: 500;
  color: #000000;
}
.paybox .account input[data-v-3b21a0b4] {
  display: inline-block;
  width: 2.70833rem;
  height: 0.18229rem;
  background: #ffffff;
  border: 1px solid #878787;
  border-radius: 24px;
  font-size: 0.07292rem;
  margin-left: 0.21354rem;
  padding-left: 0.27083rem;
}
.paybox .message[data-v-3b21a0b4] {
  margin-left: 1.15104rem;
  margin-top: 0.05208rem;
}
.paybox .message span[data-v-3b21a0b4] {
  font-size: 0.0625rem;
  font-weight: 500;
  color: #000000;
  opacity: 0.6;
}
.paybox .btn2[data-v-3b21a0b4] {
  margin-left: 1.92708rem;
  margin-top: 0.10417rem;
}
.paybox .btn2 .cancel[data-v-3b21a0b4] {
  width: 0.78125rem;
  height: 0.20833rem;
  border-style: solid;
  border-width: 2px;
}
.paybox .btn2 .ascertain[data-v-3b21a0b4] {
  width: 0.78125rem;
  height: 0.20833rem;
  margin-left: 0.67708rem;
}
.pleaseagent_word[data-v-3b21a0b4] {
  text-align: center;
  margin-top: -0.3125rem;
}
.pleaseagent_word p[data-v-3b21a0b4] {
  font-family: "Regular";
  font-size: 0.09375rem;
  font-weight: 400;
}
.pleaseagent_img[data-v-3b21a0b4] {
  text-align: center;
  margin-top: 0.26042rem;
  cursor: pointer;
}
.pleaseagent_img img[data-v-3b21a0b4] {
  width: 0.41667rem;
  height: 0.41667rem;
}
.pay_pal[data-v-3b21a0b4] {
  /* paypal支付弹出框 */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.pay_pal .pay_content[data-v-3b21a0b4] {
  width: 1.82292rem;
  height: 0.78125rem;
  background: #fff;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  position: absolute;
  left: calc(50% - 1.04167rem);
  top: calc(50% - 0.78125rem);
  padding-bottom: 0.16667rem;
}
.pay_pal .pay_title[data-v-3b21a0b4] {
  width: 100%;
  height: 0.20833rem;
  line-height: 0.23438rem;
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #000000;
  padding-right: 0.10417rem;
  background: #ffffff;
  border-bottom: 1px solid #cccccc;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: right;
}
.flex[data-v-3b21a0b4], .activeName_big_box .part3 .item .right .price-list[data-v-3b21a0b4], .activeName_big_box .part3 .item .right[data-v-3b21a0b4], .activeName_big_box .part3 .item .left[data-v-3b21a0b4], .activeName_big_box .part3 .item[data-v-3b21a0b4], .activeName_big_box .part2 .card[data-v-3b21a0b4], .activeName_big_box .part1 .btn[data-v-3b21a0b4], .activeName_big_box .part[data-v-3b21a0b4], .activeName_big_box[data-v-3b21a0b4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.flex_start[data-v-3b21a0b4] {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.activeName_big_box[data-v-3b21a0b4] {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.activeName_big_box .part[data-v-3b21a0b4] {
  width: 2.29167rem;
  height: 1.97917rem;
  background: #ffffff;
  border-radius: 10px;
  margin-right: 0.20833rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.activeName_big_box .part[data-v-3b21a0b4]:first-child {
  background: #15104b;
  background-image: url("http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715934649028_part2.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.activeName_big_box .part1[data-v-3b21a0b4] {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-left: 0.20833rem;
  padding: 0.15625rem 0.20833rem;
  color: #ffffff;
  position: relative;
}
.activeName_big_box .part1 .top_account[data-v-3b21a0b4] {
  margin-left: -0.57292rem;
}
.activeName_big_box .part1 .wallet_box_money[data-v-3b21a0b4] {
  margin-top: -0.26042rem;
}
.activeName_big_box .part1 .sum[data-v-3b21a0b4] {
  text-align: center;
  font-size: 0.11458rem;
  font-weight: bold;
  margin-bottom: 0.07813rem;
}
.activeName_big_box .part1 .sum span[data-v-3b21a0b4]:first-child {
  font-size: 0.07292rem;
  margin-right: 0.02604rem;
}
.activeName_big_box .part1 .Ucredits[data-v-3b21a0b4] {
  text-align: center;
  font-size: 0.08333rem;
  font-weight: 500;
}
.activeName_big_box .part1 .Ucredits span[data-v-3b21a0b4] {
  margin-right: 0.02604rem;
}
.activeName_big_box .part1 .credit_limit[data-v-3b21a0b4] {
  text-align: center;
  font-size: 0.07292rem;
  font-weight: 500;
}
.activeName_big_box .part1 .credit_limit span[data-v-3b21a0b4] {
  margin-right: 0.02604rem;
}
.activeName_big_box .part1 .btn[data-v-3b21a0b4] {
  width: 100%;
  position: relative;
}
.activeName_big_box .part1 .btn button[data-v-3b21a0b4] {
  width: 0.83333rem;
  border: none;
}
.activeName_big_box .part1 .btn button[data-v-3b21a0b4]:first-child {
  margin-right: 0.15625rem;
}
.activeName_big_box .part1 .btn .OpenWithdrawal[data-v-3b21a0b4] {
  position: absolute;
  top: -0.52083rem;
  left: 0.52083rem;
}
.activeName_big_box .part1 .btn .rechargeBtn[data-v-3b21a0b4] {
  position: absolute;
  bottom: 0;
  left: 0;
}
.activeName_big_box .part1 .btn .recordsBtn[data-v-3b21a0b4] {
  position: absolute;
  bottom: 0;
  right: 0;
}
.activeName_big_box .part1 .creditsBox[data-v-3b21a0b4] {
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  width: 2.86458rem;
  line-height: 0.09375rem;
  padding: 0.07813rem;
  left: 60%;
  top: 15%;
  font-size: 0.07292rem;
  color: #000000;
  z-index: 9;
}
.activeName_big_box .part1 .creditsBox p[data-v-3b21a0b4] {
  margin-bottom: 0.02604rem;
  word-break: normal;
}
.activeName_big_box .part1 .creditsBox p[data-v-3b21a0b4]:last-child {
  margin-bottom: 0;
}
.activeName_big_box .part2[data-v-3b21a0b4] {
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.activeName_big_box .part2[data-v-3b21a0b4] .el-checkbox__input {
  margin-top: -0.13021rem;
  margin-right: 0.05208rem;
}
.activeName_big_box .part2[data-v-3b21a0b4] .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #3f6aff;
  border-color: #3f6aff;
}
.activeName_big_box .part2 .card[data-v-3b21a0b4] {
  width: 1.35417rem;
  height: 0.26042rem;
  background: #d3dfff;
  border-radius: 25px 25px 25px 25px;
  background-repeat: no-repeat;
  background-position: center;
}
.activeName_big_box .part2 .card img[data-v-3b21a0b4] {
  height: 80%;
}
.activeName_big_box .part2 .choosed[data-v-3b21a0b4] {
  background: #3f6aff;
}
.activeName_big_box .part3[data-v-3b21a0b4] {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.activeName_big_box .part3 .item[data-v-3b21a0b4] {
  width: 100%;
  margin-bottom: 0.15625rem;
}
.activeName_big_box .part3 .item .left[data-v-3b21a0b4] {
  width: 50%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  font-size: 0.08333rem;
  font-weight: 400;
  color: #333333;
  margin-right: 0.10417rem;
}
.activeName_big_box .part3 .item .left.bold[data-v-3b21a0b4] {
  font-size: 0.10417rem;
  font-weight: 500;
}
.activeName_big_box .part3 .item .right[data-v-3b21a0b4] {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 50%;
  font-size: 0.07292rem;
  font-weight: 400;
  color: #ff7519;
}
.activeName_big_box .part3 .item .right .selectedDatr[data-v-3b21a0b4] {
  color: red;
  font-weight: 900;
  font-size: 0.08333rem;
}
.activeName_big_box .part3 .item .right .price-list[data-v-3b21a0b4] {
  width: 0.46875rem;
  height: 0.14583rem;
  border-radius: 20px;
  border: 1px solid #e5e5e5;
}
.activeName_big_box .part3 .item .right .price-list input[data-v-3b21a0b4] {
  width: calc(100% - 0.10417rem);
}
.activeName_big_box .part3 .payBtn button[data-v-3b21a0b4] {
  width: 1.125rem;
  margin-top: 0.07813rem;
}
[data-v-3b21a0b4] .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #3f6aff;
  border-color: #3f6aff;
}