.particulars[data-v-9d1b47be] {
  width: 100%;
  height: auto;
  background: #eff1f3;
  padding-bottom: 0.16667rem;
}
.activeName[data-v-9d1b47be] {
  width: 6.875rem;
  border-radius: 10px;
  line-height: 0.10417rem;
  background: white;
}
.activeName .header[data-v-9d1b47be] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  min-height: 0.29167rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 0.20833rem;
  border-bottom: 1px solid #d9d9d9;
}
.activeName .header .title[data-v-9d1b47be] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  font-size: 0.09375rem;
  font-weight: 500;
  text-align: left;
  color: #15104b;
}
.activeName .header .icon-color[data-v-9d1b47be] {
  color: #15104b;
  font-weight: bold;
  font-size: 0.07292rem;
}
.activeName .container-layout[data-v-9d1b47be] {
  padding: 0.08333rem 0.20833rem;
}
.activeName .container-layout .tracking-number[data-v-9d1b47be] {
  color: #666;
  font-size: 0.06771rem;
}
.activeName .container-layout[data-v-9d1b47be] .el-form-item {
  margin-bottom: 0.05208rem;
}
.activeName .container-layout[data-v-9d1b47be] .el-input__inner {
  border-radius: 37px;
}
.activeName .container-layout[data-v-9d1b47be] .el-textarea__inner {
  border-radius: 10px;
}
.activeName .container-layout[data-v-9d1b47be] .el-input--suffix {
  border-radius: 37px;
}
.activeName .image-upload[data-v-9d1b47be] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: -0.125rem;
}
.activeName .image-upload .image-upload-tips[data-v-9d1b47be] {
  font-size: 0.0625rem;
  color: red;
  padding-right: 0.05208rem;
}
.activeName .image-upload .button[data-v-9d1b47be] {
  color: red;
  font-size: 0.0625rem;
}
[data-v-9d1b47be] .el-input.is-disabled .el-input__inner {
  color: #606266 !important;
  border: none !important;
  background: #f1f2f4 !important;
}
[data-v-9d1b47be] .el-textarea.is-disabled .el-textarea__inner {
  color: #606266 !important;
  border: none !important;
  background: #f1f2f4 !important;
}