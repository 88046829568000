.feed-process-result[data-v-f30db960] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.feed-process-result .header[data-v-f30db960] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  min-height: 0.29167rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 0.08333rem;
}
.feed-process-result .header .title[data-v-f30db960] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  font-size: 0.09375rem;
  font-weight: 500;
  text-align: left;
  color: #15104b;
}
.feed-process-result .reply-title[data-v-f30db960] {
  font-size: 0.07292rem;
  font-weight: 500;
}
.feed-process-result .reply-content-title[data-v-f30db960] {
  font-size: 0.07292rem;
  font-weight: 500;
  margin-top: 0.08333rem;
  margin-bottom: 0.04167rem;
}
.feed-process-result .reply-content-footer[data-v-f30db960] {
  font-size: 0.0625rem;
  font-weight: 500;
  margin-top: 0.04167rem;
}
.feed-process-result .reply-image-wrapper[data-v-f30db960] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  gap: 0.03125rem;
}
.feed-process-result .reply-content-platform[data-v-f30db960] {
  font-size: 0.07292rem;
  font-weight: 500;
  margin-top: 0.08333rem;
  margin-bottom: 0.04167rem;
}
.feed-process-result .reply-content-img[data-v-f30db960] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  gap: 0.10417rem;
}
.feed-process-result .reply-content-img img[data-v-f30db960] {
  width: 0.52083rem;
  height: 0.52083rem;
  border-radius: 15px;
  cursor: pointer;
}
.dialog-footer[data-v-f30db960] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  gap: 0.20833rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.dialog-footer .button-close[data-v-f30db960] {
  width: 1.04167rem;
  height: 0.22917rem;
  border: 1px solid #15104b;
  color: #15104b;
}
.dialog-footer .button-confirm[data-v-f30db960]:hover {
  background: #110570;
}
.dialog-footer .button-confirm[data-v-f30db960] {
  width: 1.04167rem;
  height: 0.22917rem;
  background: #15104b;
  border: none;
}
[data-v-f30db960] .el-dialog {
  border-radius: 20px;
}
[data-v-f30db960] .el-dialog__body {
  padding: 0.05208rem 0.08333rem;
}
[data-v-f30db960] .el-dialog__header {
  padding: 0;
}