.header[data-v-e244ad2e] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  min-height: 0.29167rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 0.125rem;
}
.header .title[data-v-e244ad2e] {
  font-size: 0.09375rem;
  font-weight: 500;
  text-align: left;
  color: #15104b;
}
.table-class[data-v-e244ad2e] {
  margin-top: 0.08333rem;
}
.table-class[data-v-e244ad2e] .el-table__header th {
  color: #43445c;
  background-color: #f9f9f9 !important;
}
[data-v-e244ad2e] .el-table {
  border-radius: 4px !important;
}
.line[data-v-e244ad2e] {
  width: 100%;
  height: 0.00521rem;
  background: #ccc;
  margin-top: 0.04167rem;
}