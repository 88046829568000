.required[data-v-48f1a5b0] {
  color: #ff0000;
}
.souringUrl[data-v-48f1a5b0] {
  width: 100%;
  min-height: calc(100vh - 0.41667rem);
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
  font-family: PingFang SC-Regular, PingFang SC;
}
.souringUrl .activeName[data-v-48f1a5b0] {
  width: 6.875rem;
  padding-bottom: 0.3125rem;
  box-shadow: 0 0 0.10417rem 0 rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  position: relative;
  font-family: Poppins-Regular, Poppins;
  color: #333333;
  background: #ffffff;
}
.souringUrl .activeName .speed-progress[data-v-48f1a5b0] {
  width: 1.25rem;
  height: 0.3125rem;
  position: absolute;
  top: 0.14063rem;
  left: 1.04167rem;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.souringUrl .activeName .speed-progress .progress-fulfilled span[data-v-48f1a5b0]:nth-child(2) {
  margin-left: 0.10417rem;
}
.souringUrl .activeName .speed-progress .progress-fulfilled span[data-v-48f1a5b0]:nth-child(3) {
  margin-left: 0.02604rem;
}
.souringUrl .activeName .speed-progress .progress-fulfilled span[data-v-48f1a5b0]:nth-child(4) {
  margin-left: 0.02604rem;
}
.souringUrl .activeName .Sourcing[data-v-48f1a5b0] {
  width: 100%;
  height: 0.41667rem;
  line-height: 0.41667rem;
  background: #f9f9f9;
  font-size: 0.08333rem;
  padding-left: 0.26042rem;
  font-family: "Regular";
}
.souringUrl .activeName .ProductLink input[data-v-48f1a5b0] {
  height: 0.26042rem;
  width: calc(100% - 0.41667rem);
  margin: 0.20833rem;
  border: 1px solid #878787;
  border-radius: 24px;
  padding-left: 0.17708rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.souringUrl .activeName .Destination[data-v-48f1a5b0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding-left: 0.20833rem;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.souringUrl .activeName .Country .Country1[data-v-48f1a5b0] {
  width: 2.66146rem;
  margin-left: 0.20833rem;
  margin-top: 0.10417rem;
  background: #f9fafb;
  border: 1px solid #d7d7d8;
  border-radius: 50px;
  overflow: hidden;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.souringUrl .activeName .CheckAttribute .Country1[data-v-48f1a5b0] {
  width: 2.66146rem;
  margin-left: 0.20833rem;
  margin-top: 0.10417rem;
  background: #f9fafb;
  border: 1px solid #d7d7d8;
  border-radius: 50px;
  overflow: hidden;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.souringUrl .activeName .CheckAttribute .Country1[data-v-48f1a5b0] .el-input__inner {
  border-radius: 25px;
  border: none;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}