@charset "UTF-8";
.icon-question[data-v-f8b3d872] {
  position: absolute;
  left: 1.26042rem;
  top: 0;
}
[data-v-f8b3d872] .icon-question .el-icon-question {
  font-size: 0.10417rem;
  color: #3f6aff;
}
.display-info[data-v-f8b3d872] {
  width: 1.875rem;
  height: 0.28646rem;
  background: #93df89;
  border-radius: 10px;
  position: absolute;
  left: 1.38021rem;
  top: -0.05208rem;
}
.display-info p[data-v-f8b3d872]:nth-child(1) {
  font-size: 0.07292rem;
  color: #fff;
  padding-top: 0.02604rem;
  padding-left: 0.05208rem;
}
.display-info p[data-v-f8b3d872]:nth-child(2) {
  font-size: 0.07292rem;
  color: #fff;
  padding-left: 0.05208rem;
}
.monthly-red[data-v-f8b3d872] {
  position: absolute;
  top: -0.02083rem;
  left: 1.13542rem;
  cursor: pointer;
}
.monthly-red span img[data-v-f8b3d872] {
  width: 0.11458rem;
  height: 0.11458rem;
}
.monthly-red span img[data-v-f8b3d872]:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.progress[data-v-f8b3d872] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.20833rem 0.52083rem;
}
.progress img[data-v-f8b3d872] {
  width: 0.29167rem;
}
.progress .progress-item[data-v-f8b3d872] {
  min-width: 1.04167rem;
  text-align: center;
}
.progress .progress-item p[data-v-f8b3d872] {
  margin-top: 0.05208rem;
  font-size: 0.07292rem;
}
.progress .progress-line[data-v-f8b3d872] {
  margin: 0 -0.10417rem;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
}
.progress .progress-line img[data-v-f8b3d872] {
  width: 1.5625rem;
  position: relative;
  top: -0.09375rem;
}
.form-item[data-v-f8b3d872] {
  padding: 0 0.20833rem;
  margin: 0.0625rem 0;
}
.form-item .form-item-title[data-v-f8b3d872] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  font-size: 0.07292rem;
  margin-bottom: 0.04167rem;
}
.form-item .form-item-title .subtitle[data-v-f8b3d872] {
  color: #999999;
  padding-left: 0.08333rem;
}
.form-item .form-item-content[data-v-f8b3d872] input {
  height: 0.20833rem;
  width: 4.16667rem;
  border: 1px solid #d9d9d9;
  border-radius: 24px;
  padding: 0 0.10417rem;
}
.form-item .form-item-content[data-v-f8b3d872] .el-textarea__inner {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}
[data-v-f8b3d872] .el-input--suffix input {
  border: none;
  background: none;
  display: block;
}
.dialog_footer[data-v-f8b3d872] {
  margin-top: 0.33333rem;
  text-align: center;
}
.save[data-v-f8b3d872] {
  width: 1.04167rem;
  height: 0.22917rem;
  border: none;
  border-radius: 50px !important;
  font-size: 0.08333rem !important;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #474882;
}
.save[data-v-f8b3d872]:hover {
  background: #3c3772 !important;
  color: #fff;
}
.required[data-v-f8b3d872] {
  color: #ff0000;
  margin-right: 0.01042rem;
}
.souring-url[data-v-f8b3d872] {
  width: 100%;
  min-height: calc(100vh - 0.41667rem);
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
  font-family: "Regular";
  /* 定义fade动画 */
}
.souring-url .active-name[data-v-f8b3d872] {
  width: 6.875rem;
  padding-bottom: 0.3125rem;
  box-shadow: 0 0 0.10417rem 0 rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  position: relative;
  color: #333333;
  background: #ffffff;
}
.souring-url .active-name .speed-progress[data-v-f8b3d872] {
  width: 1.25rem;
  height: 0.3125rem;
  position: absolute;
  top: 0.14063rem;
  left: 1.04167rem;
  font-size: 0.07292rem;
}
.souring-url .active-name .speed-progress .progress-fulfilled span[data-v-f8b3d872]:nth-child(2) {
  margin-left: 0.10417rem;
}
.souring-url .active-name .speed-progress .progress-fulfilled span[data-v-f8b3d872]:nth-child(3) {
  margin-left: 0.02604rem;
}
.souring-url .active-name .speed-progress .progress-fulfilled span[data-v-f8b3d872]:nth-child(4) {
  margin-left: 0.02604rem;
}
.souring-url .active-name .sourcing[data-v-f8b3d872] {
  width: 100%;
  height: 0.41667rem;
  line-height: 0.41667rem;
  background: #f9f9f9;
  font-size: 0.08333rem;
  padding-left: 0.26042rem;
  font-family: "Regular";
}
.souring-url .fade-enter-active[data-v-f8b3d872],
.souring-url .fade-leave-active[data-v-f8b3d872] {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.souring-url .fade-enter[data-v-f8b3d872], .souring-url .fade-leave-to[data-v-f8b3d872] {
  opacity: 0;
}
.souring-url .fade-enter-to[data-v-f8b3d872], .souring-url .fade-leave[data-v-f8b3d872] {
  opacity: 1;
}