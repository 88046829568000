@charset "UTF-8";
.store[data-v-da6010d0] {
  width: 7.29167rem;
  font-family: "Regular";
}
.particulars[data-v-da6010d0] {
  width: 100%;
  background: #eff1f3;
  min-width: 6.875rem;
  border-radius: 10px;
  font-family: "Regular";
  position: relative;
}
.paybox[data-v-da6010d0] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.paybox .storeDialog[data-v-da6010d0] {
  background: url(../img/UD-SOTRE.b8cac652.svg) no-repeat;
  background-size: 3.125rem 2.08333rem;
  width: 3.125rem;
  /* 与图片宽度匹配 */
  height: 2.08333rem;
  /* 与图片高度匹配 */
  z-index: 100;
  position: absolute;
  top: 1.04167rem;
  left: 3.64583rem;
}
.paybox .word[data-v-da6010d0] {
  font-size: 0.11458rem;
  margin-top: 0.52083rem;
  margin-left: 0.78125rem;
  line-height: 0.3125rem;
}
.paybox .close_btn[data-v-da6010d0] {
  width: 0.78125rem;
  height: 0.20833rem;
  border-radius: 50px;
  text-align: center;
  color: #fff;
  background: #15104b;
  margin-left: 1.19792rem;
  font-size: 0.09375rem;
  line-height: 0.20833rem;
  margin-top: 0.05208rem;
}
.btn_box[data-v-da6010d0] {
  width: 100%;
  background: #fff;
  min-width: 6.875rem;
  border-radius: 10px;
  line-height: 0.36458rem;
}
.btn_box .add-tt-btn[data-v-da6010d0] {
  display: inline-block;
  width: 0.9375rem;
  height: 0.20833rem;
  background-color: #000000;
  border-radius: 50px;
  color: #fff;
  font-size: 0.08333rem;
  border: 1px solid #000000;
  margin-left: 0.07813rem;
}
.btn_box .add-tt-btn[data-v-da6010d0]:hover {
  background: #4d4d4d;
  color: #fff;
}
.btn_box .orders[data-v-da6010d0] {
  display: inline-block;
  width: 0.9375rem;
  height: 0.20833rem;
  background-color: #93df89;
  border-radius: 50px;
  color: #fff;
  font-size: 0.08333rem;
  margin-left: 0.26042rem;
  border: 1px solid #93df89;
}
.btn_box .orders[data-v-da6010d0]:hover {
  background: #bff4b8;
  color: #fff;
}
.btn_box .Inventorylist[data-v-da6010d0] {
  width: 0.9375rem;
  height: 0.20833rem;
  background: #3f6aff;
  border-radius: 50px;
  color: #fff;
  font-size: 0.08333rem;
  border: 1px solid #3f6aff;
  margin-left: 0.07813rem;
}
.btn_box .Inventorylist[data-v-da6010d0]:hover {
  background: #6c8dff;
  color: #fff;
}
.btn_box .AddOfflineStore[data-v-da6010d0] {
  width: 0.9375rem;
  margin-left: 0.07813rem;
}
.btn_box .AddOfflineStore[data-v-da6010d0]:hover {
  background: #6c8dff !important;
  color: #fff;
}
.btn_box .linkStore[data-v-da6010d0] {
  width: 1.19792rem;
  margin-left: 0.07813rem;
}
.btn_box .linkStore[data-v-da6010d0]:hover {
  background: #bff4b8;
  color: #fff;
}
.activeName[data-v-da6010d0] {
  border-radius: 10px;
  line-height: 0.10417rem;
  position: relative;
  padding-bottom: 0.16667rem;
  margin-top: 0.10417rem;
}

/* 标签页内容区的总长度 */
[data-v-da6010d0] .activeName .el-tabs__content {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.NoShopifyStoreTitle[data-v-da6010d0] {
  text-align: center;
}
.NoShopifyStoreTitle p[data-v-da6010d0] {
  font-style: italic;
  color: #4c8bf5;
}
[data-v-da6010d0] .el-tabs__nav-scroll {
  height: 0.41667rem;
  padding: 0.10417rem 0;
}
[data-v-da6010d0] .el-tabs__nav {
  margin-left: 0.16667rem;
}
[data-v-da6010d0] .el-tabs__item.is-active {
  font-size: 0.08333rem;
  color: #3f6aff;
}
[data-v-da6010d0] .el-table th,
.el-table tr[data-v-da6010d0] {
  height: 0.36979rem;
}
[data-v-da6010d0] .el-table__row {
  height: 0.44792rem !important;
}
[data-v-da6010d0] .el-table__expanded-cell[class*=cell] {
  width: 6.875rem !important;
  padding-left: 1.53125rem !important;
  padding-top: 0.125rem !important;
  padding-bottom: 0.0625rem !important;
}
[data-v-da6010d0] .el-tabs__header {
  margin: 0 !important;
  background-color: #fff;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
.status[data-v-da6010d0] {
  font-size: 0.07292rem;
  height: 0.20833rem;
}
[data-v-da6010d0] .status.el-button--primary.el-button:hover {
  background: #e9efff;
  color: #6788ff;
}

/* 标签页下划线 */
[data-v-da6010d0] .el-tabs__active-bar {
  height: 0.01563rem !important;
  background: #6788ff;
}

/* 新增弹框 */
[data-v-da6010d0] .el-dialog__wrapper {
  height: 5.03125rem;
}
[data-v-da6010d0] .el-dialog {
  margin-top: 25vh !important;
  width: 4.21875rem !important;
  min-height: 1.46875rem !important;
  background: #ffffff !important;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14) !important;
  border-radius: 21px !important;
  overflow: hidden;
}
.Add-Store[data-v-da6010d0] {
  background-color: #ffffff;
  width: 4.21875rem;
  height: 0.28646rem;
  line-height: 0.28646rem;
  color: #333333;
  padding-left: 0.21875rem;
  font-size: 0.08333rem;
  border-bottom: 1px solid #e4e6ef;
}
[data-v-da6010d0] .el-dialog__header {
  padding: 0 !important;
}
[data-v-da6010d0] .el-dialog__body {
  padding: 0 !important;
}
[data-v-da6010d0] .el-dialog__headerbtn {
  position: absolute !important;
  top: 0.10417rem !important;
  right: 0.16667rem !important;
  padding: 0 !important;
  background: 0 0 !important;
  border: none !important;
  outline: 0 !important;
  cursor: pointer !important;
  font-size: 0.08333rem !important;
}
[data-v-da6010d0] .el-dialog__headerbtn .el-dialog__close {
  color: #fff !important;
}
.Store[data-v-da6010d0] {
  margin-top: 0.16667rem;
}
.Store .Name[data-v-da6010d0] {
  display: inline-block;
  font-size: 0.08333rem;
  font-weight: 500;
  color: #000000;
  line-height: 0.09896rem;
  opacity: 0.8;
}
[data-v-da6010d0] .Store .el-form-item__content {
  margin-left: 0.61458rem !important;
  height: 0.25rem;
}
[data-v-da6010d0] .Store .el-input {
  display: inline-block;
  font-size: 0.07292rem;
  width: 1.66667rem;
  height: 0.25rem;
  background: #ffffff;
  margin-left: 0.16667rem;
}
[data-v-da6010d0] .Store .el-input__inner {
  width: 1.66667rem;
  height: 0.20833rem;
  border-radius: 24px;
  padding-left: 0.27083rem;
  font-size: 0.07292rem;
  overflow: hidden;
}
[data-v-da6010d0] .el-input__inner:focus {
  border-color: #c0c4cc;
}
.btn_com[data-v-da6010d0] {
  width: 1.04167rem;
  height: 0.23438rem;
  border-width: 2px;
  border-style: solid;
  font-weight: 600;
}
.Cancel[data-v-da6010d0] {
  position: absolute;
  top: 1.05208rem;
  left: 0.91667rem;
}
.Save[data-v-da6010d0] {
  position: absolute;
  top: 1.05208rem;
  left: 2.46875rem;
  border-width: 2px;
  border-style: solid;
}
[data-v-da6010d0] .el-tabs__nav-wrap::after {
  background: #eff1f3 !important;
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}