.menu-item-warp .el-menu-item[data-v-6062f0a0] {
  font-size: 0.08333rem !important;
  color: #abacc3;
}
.menu-item-warp .menuItems .el-menu-item[data-v-6062f0a0] {
  font-size: 0.07292rem !important;
  margin-left: 0.15625rem;
}
.menu-item-warp .is-active[data-v-6062f0a0] {
  color: #ffffff !important;
}
.menu-item-warp .silder-menu-item[data-v-6062f0a0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.menu-item-warp .silder-menu-item .silder-menu-item-title[data-v-6062f0a0] {
  font-size: 0.08333rem;
}
.menu-item-warp .silder-menu-item img[data-v-6062f0a0] {
  width: 0.16667rem;
  height: 0.16667rem;
  margin-left: -0.10417rem;
}
.menu-item-warp .silder-menu-item span[data-v-6062f0a0] {
  margin-left: 0.10417rem;
}
[data-v-6062f0a0] .el-submenu__title .el-submenu__icon-arrow {
  right: 0;
}