@charset "UTF-8";
.particulars[data-v-3fccab96] {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
  font-family: "Regular";
}
.particulars .activeName[data-v-3fccab96] {
  width: 6.875rem;
  overflow: hidden;
  padding-bottom: 0.15625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.left[data-v-3fccab96] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  min-width: 0;
  min-height: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.left .big_video[data-v-3fccab96] {
  background: rgba(0, 0, 0, 0.74);
  -webkit-backdrop-filter: blur(0.01563rem);
          backdrop-filter: blur(0.01563rem);
  width: 4.73958rem;
  height: 2.34375rem;
  position: absolute;
  top: 0.15625rem;
  left: 0.10417rem;
  border-radius: 21px;
}
.left .big_video .members_video[data-v-3fccab96] {
  font-size: 0.1875rem;
  color: #fff;
  text-align: center;
  margin-top: 0.46875rem;
}
.left .big_video .become_video[data-v-3fccab96] {
  font-size: 0.125rem;
  color: #fff;
  text-align: center;
}
.left .big_video .subscribe_now_btn[data-v-3fccab96] {
  width: 1.43229rem;
  height: 0.29167rem;
  background: #fff;
  text-align: center;
  border-radius: 50px;
  margin-left: 1.5625rem;
  margin-top: 0.20833rem;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.left .big_video .subscribe_now_btn p[data-v-3fccab96] {
  color: #15104b;
  font-size: 0.13542rem;
  font-weight: 700;
  text-align: center;
  line-height: 0.29167rem;
}
.left .big_video .subscribe_now_btn[data-v-3fccab96]:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.left .big_video .subscribe_price_box[data-v-3fccab96] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 1.11979rem;
  height: 0.20833rem;
  border: 1px solid #fff;
  margin-left: 1.71875rem;
  margin-top: 0.15625rem;
  border-radius: 50px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.left .big_video .subscribe_price_box .lock_img img[data-v-3fccab96] {
  width: 0.14583rem;
  height: 0.14583rem;
  margin-top: 0.02604rem;
  margin-left: 0.15625rem;
}
.left .big_video .subscribe_price_box .videp_price[data-v-3fccab96] {
  font-size: 0.08333rem;
  font-weight: 400;
  color: #fff;
  margin-top: 0.04167rem;
  margin-left: 0.05208rem;
}
.left .big_video .subscribe_price_box[data-v-3fccab96]:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.left .big_video .per_next_toplay_box[data-v-3fccab96] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  /* Center the content horizontally */
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  /* Center the content vertically */
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  /* Stack the elements vertically */
}
.left .big_video .per_next_toplay_box .previous_lesson[data-v-3fccab96],
.left .big_video .per_next_toplay_box .next_lesson[data-v-3fccab96] {
  font-size: 0.125rem;
  font-weight: 400;
  color: #fff;
  margin-top: 0.41667rem;
  margin-left: 0.10417rem;
}
.left .big_video .per_next_toplay_box .previous_lesson p[data-v-3fccab96],
.left .big_video .per_next_toplay_box .next_lesson p[data-v-3fccab96] {
  margin-left: 0.36458rem;
}
.left .big_video .per_next_toplay_box .previous_lesson .previous_lesson_video[data-v-3fccab96],
.left .big_video .per_next_toplay_box .previous_lesson .next_lesson_video[data-v-3fccab96],
.left .big_video .per_next_toplay_box .next_lesson .previous_lesson_video[data-v-3fccab96],
.left .big_video .per_next_toplay_box .next_lesson .next_lesson_video[data-v-3fccab96] {
  width: 1.5625rem;
  height: 0.93229rem;
  border-radius: 20px;
  margin-top: 0.15625rem;
  cursor: pointer;
}
.left .big_video .per_next_toplay_box .previous_lesson .previous_lesson_video img[data-v-3fccab96],
.left .big_video .per_next_toplay_box .previous_lesson .next_lesson_video img[data-v-3fccab96],
.left .big_video .per_next_toplay_box .next_lesson .previous_lesson_video img[data-v-3fccab96],
.left .big_video .per_next_toplay_box .next_lesson .next_lesson_video img[data-v-3fccab96] {
  width: 1.5625rem;
  height: 0.93229rem;
  border-radius: 20px;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.left .big_video .per_next_toplay_box .previous_lesson .previous_lesson_video:hover img[data-v-3fccab96],
.left .big_video .per_next_toplay_box .previous_lesson .next_lesson_video:hover img[data-v-3fccab96],
.left .big_video .per_next_toplay_box .next_lesson .previous_lesson_video:hover img[data-v-3fccab96],
.left .big_video .per_next_toplay_box .next_lesson .next_lesson_video:hover img[data-v-3fccab96] {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
  /* 悬停时放大 */
}
.left .big_video .per_next_toplay_box .function_buttons[data-v-3fccab96] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.78125rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  /* Center the buttons horizontally */
}
.left .big_video .per_next_toplay_box .function_buttons .pre_video_img[data-v-3fccab96],
.left .big_video .per_next_toplay_box .function_buttons .to_replay_img[data-v-3fccab96],
.left .big_video .per_next_toplay_box .function_buttons .next_video_img[data-v-3fccab96] {
  width: 0.20833rem;
  height: 0.20833rem;
  margin-left: 0.10417rem;
  cursor: pointer;
}
.left .big_video .per_next_toplay_box .function_buttons .pre_video_img img[data-v-3fccab96],
.left .big_video .per_next_toplay_box .function_buttons .to_replay_img img[data-v-3fccab96],
.left .big_video .per_next_toplay_box .function_buttons .next_video_img img[data-v-3fccab96] {
  width: 0.20833rem;
  height: 0.20833rem;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.left .big_video .per_next_toplay_box .function_buttons .pre_video_img:hover img[data-v-3fccab96],
.left .big_video .per_next_toplay_box .function_buttons .to_replay_img:hover img[data-v-3fccab96],
.left .big_video .per_next_toplay_box .function_buttons .next_video_img:hover img[data-v-3fccab96] {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  /* 悬停时放大 */
}
.left .wrap[data-v-3fccab96] {
  height: auto;
  background-color: #fff;
  border-radius: 21px;
  box-sizing: border-box;
  padding: 0.15625rem 0.10417rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  /*.info {
    //@debugheight: 90px;
    margin-top: 10px;
    .title {
      font-size: 24px;
      font-weight: 600;
    }
    .show_icon_content {
      display: flex;
    }
    .content {
      width: 880px;
      font-weight: 500;
      font-size: 16px;
      -webkit-line-clamp: 2;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      transition: all 0.3s ease;
    }
    .show_icon {
      margin-top: 30px;
      ::v-deep .el-icon-arrow-down {
        font-size: 20px;
      }
      ::v-deep .el-icon-arrow-up {
        font-size: 20px;
      }
    }
  }*/
}
.left .wrap .video[data-v-3fccab96] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  max-height: 2.34375rem;
  border-radius: 21px;
  height: 2.34375rem;
}
.left .wrap .info .title[data-v-3fccab96] {
  font-size: 0.125rem;
  font-weight: 600;
  margin-top: 0.10417rem;
}
.left .wrap .info .show_icon_content[data-v-3fccab96] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.left .wrap .info .content[data-v-3fccab96] {
  width: 4.58333rem;
  font-weight: 500;
  font-size: 0.08333rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 0.26042rem;
  -webkit-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
}
.left .wrap .info .content.expanded[data-v-3fccab96] {
  max-height: 1.5625rem;
  -webkit-line-clamp: unset;
}
.left .wrap .info .show_icon[data-v-3fccab96] .el-icon-arrow-down {
  font-size: 0.10417rem;
}
.left .wrap .info .show_icon[data-v-3fccab96] .el-icon-arrow-up {
  font-size: 0.10417rem;
}
.left .more[data-v-3fccab96] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  margin-top: 0.10417rem;
  background-color: #fff;
  border-radius: 21px;
  box-sizing: border-box;
  padding: 0.05208rem 0.10417rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.left .more .title[data-v-3fccab96] {
  font-size: 0.125rem;
  font-weight: 600;
  -webkit-align-content: center;
          align-content: center;
  height: 0.20833rem;
  margin-left: 0.10417rem;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.left .more:hover .title[data-v-3fccab96] {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  /* 悬停时放大 */
}
.left .more .list[data-v-3fccab96] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.15625rem;
  margin-top: 0.0625rem;
}
.left .more .list .list-item .item-video[data-v-3fccab96] {
  height: 0.9375rem;
  border-radius: 21px;
}
.left .more .list .list-item .coverurl_video[data-v-3fccab96] {
  width: 1.45833rem;
  height: 0.9375rem;
  cursor: pointer;
}
.left .more .list .list-item .coverurl_video img[data-v-3fccab96] {
  width: 1.45833rem;
  height: 0.9375rem;
  border-radius: 20px;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.left .more .list .list-item .coverurl_video:hover img[data-v-3fccab96] {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
  /* 悬停时放大 */
}
.left .more .list .list-item .item-describe[data-v-3fccab96] {
  -webkit-align-content: center;
          align-content: center;
  font-weight: 600;
  font-size: 0.06771rem;
  text-overflow: ellipsis;
  margin-top: 0.07813rem;
  margin-left: 0.04167rem;
  cursor: pointer;
}
.right[data-v-3fccab96] {
  width: 1.82292rem;
  height: 100vh;
  min-width: 0;
  margin-left: 0.10417rem;
  background-color: #fff;
  border-radius: 21px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-sizing: border-box;
  padding: 0.05208rem 0.10417rem 0.15625rem 0.10417rem;
}
.right > .comments[data-v-3fccab96] {
  font-weight: 600;
  font-size: 0.125rem;
  box-sizing: border-box;
}
.right > .block[data-v-3fccab96] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  min-height: 0;
  overflow: auto;
  padding: 0.05208rem 0;
}
.right > .block .block-item[data-v-3fccab96] {
  min-height: 0.57292rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.right > .block .block-item .lt[data-v-3fccab96] {
  width: 0.21875rem;
  margin-right: 0.05208rem;
}
.right > .block .block-item .lt .avatar[data-v-3fccab96] {
  width: 0.20833rem;
  height: 0.20833rem;
  border-radius: 50px;
  -webkit-align-content: center;
          align-content: center;
  text-align: center;
  background-color: #f5f7fa;
}
.right > .block .block-item .rt[data-v-3fccab96] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.right > .block .block-item .rt .header[data-v-3fccab96] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  background-color: #e6e6e6;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0.03125rem 0.0625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.right > .block .block-item .rt .header .user[data-v-3fccab96] {
  height: 0.125rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.right > .block .block-item .rt .header .user .name[data-v-3fccab96] {
  font-size: 0.08333rem;
  font-weight: 600;
  letter-spacing: 0.00521rem;
  width: 0.78125rem;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.right > .block .block-item .rt .header .user .time[data-v-3fccab96] {
  font-size: 0.0625rem;
  color: #999;
  margin-left: 0.05208rem;
}
.right > .block .block-item .rt .header .replay[data-v-3fccab96] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  width: 1.19792rem;
  margin-top: 0.05208rem;
  line-height: 0.10417rem;
  font-weight: 400;
  font-size: 0.08333rem;
  letter-spacing: 0.00156rem;
}
.right > .block .block-item .rt .footer[data-v-3fccab96] {
  width: 1.35417rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  min-height: 0.13542rem;
  cursor: pointer;
  padding: 0 0.03125rem;
}
.right > .add[data-v-3fccab96] {
  height: 0.23958rem;
}
.right > .add .add-input[data-v-3fccab96] .el-input__inner {
  background-color: #e6e6e6;
}