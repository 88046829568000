.page[data-v-8361879a] {
  width: 100%;
  height: 100%;
  background: url("http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580863839_loginBg.png") no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.page .content .main[data-v-8361879a] {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.page .content .main .RegisterRight[data-v-8361879a] {
  width: 2.63021rem;
  height: 3.89583rem;
  background: #ffffff;
  border-radius: 30px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.52083rem;
}
.page .content .main .RegisterRight .register[data-v-8361879a] {
  border-bottom: 1px solid #e4e6ef;
  padding-bottom: 0.05208rem;
}
.page .content .main .RegisterRight .register .LogoBig[data-v-8361879a] {
  margin-left: 0.15625rem;
  margin-top: 0.10417rem;
}
.page .content .main .RegisterRight .register .LogoBig img[data-v-8361879a] {
  width: 45%;
  height: 0.52083rem;
  image-rendering: crisp-edges;
}
.page .content .main .RegisterRight .register .account[data-v-8361879a] {
  font-size: 0.13021rem;
  color: #433759;
  font-weight: 600;
  line-height: 0.21875rem;
  margin-bottom: 0.05208rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  text-align: center;
  margin-top: 0.15625rem;
}
.page .content .main .RegisterRight .register .account1[data-v-8361879a] {
  font-size: 0.09375rem;
  color: #433759;
  text-align: center;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.page .content .main .RegisterRight .register .register-message[data-v-8361879a] {
  text-align: center;
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #333333;
}
.page .content .main .RegisterRight .register .register-message span[data-v-8361879a] {
  color: #93df89;
  font-size: 0.10417rem;
  font-weight: 600;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}