.sourcing-upload[data-v-3fa7a5ed] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.sourcing-upload[data-v-3fa7a5ed] .el-upload {
  width: 0.33333rem;
  height: 0.33333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.sourcing-upload[data-v-3fa7a5ed] .el-upload-list--picture-card .el-upload-list__item {
  width: 0.33333rem;
  height: 0.33333rem;
}
.matched-pruducts[data-v-3fa7a5ed] {
  height: 2.47917rem;
  background-color: #eff1f3;
  padding: 0.125rem 0.20833rem;
  margin-top: 0.125rem;
}
.matched-pruducts .title[data-v-3fa7a5ed] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0.125rem;
}
.matched-pruducts .title p[data-v-3fa7a5ed]:nth-of-type(1) {
  font-size: 0.11458rem;
}
.matched-pruducts .title p[data-v-3fa7a5ed]:nth-of-type(2) {
  font-size: 0.09375rem;
}
.matched-pruducts .img-list[data-v-3fa7a5ed] {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  -webkit-column-gap: 0.10417rem;
          column-gap: 0.10417rem;
}
.matched-pruducts .img-list .img-item[data-v-3fa7a5ed] {
  width: 1.09375rem;
}
.matched-pruducts .img-list .img-item img[data-v-3fa7a5ed] {
  width: 1.09375rem;
  height: 1.09375rem;
  cursor: pointer;
  object-fit: contain;
}
.matched-pruducts .img-list .img-item p[data-v-3fa7a5ed] {
  margin-top: 0.07292rem;
  width: 1.14583rem;
  color: #333;
}
.matched-pruducts .img-list .img-item .product-price[data-v-3fa7a5ed] {
  font-size: 0.11458rem;
}
.matched-pruducts .tips[data-v-3fa7a5ed] {
  color: #999;
  font-size: 0.125rem;
  margin-top: 0.16667rem;
  font-weight: 500;
  text-align: center;
}