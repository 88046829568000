
.offcanvas[data-v-2c0d290a] {
  width: 2.04167rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  z-index: 1001;
  background: #fafafb;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.24);
  -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
}
.offcanvas-right[data-v-2c0d290a] {
  right: -2.03125rem;
}
[data-v-2c0d290a] .el-tabs,[data-v-2c0d290a] .el-tab-pane {
  height: 100%;
}
[data-v-2c0d290a] .el-tabs__content {
  height: calc(100% - 0.33333rem);
}
[data-v-2c0d290a] .el-tabs__nav {
  margin-left: 0.29167rem;
}
[data-v-2c0d290a] .el-tabs__nav-scroll {
  text-align: center;
  line-height: 0.25521rem;
}
[data-v-2c0d290a] .el-tabs__nav-wrap::after {
  background-color: #d2d9f2;
}
[data-v-2c0d290a] .el-tabs__item {
  padding: 0 0.20833rem;
  font-size: 0.10417rem;
}
/* ::v-deep .el-tabs__item.is-active,
::v-deep .el-tabs__item:hover {
  color: #6788ff;
}
::v-deep .el-tabs__active-bar{
  background: #6788ff;
} */
[data-v-2c0d290a] .el-tabs__header {
  margin: 0;
  padding-top: 0.07292rem;
}
/* first */
.all[data-v-2c0d290a] {
  width: 100%;
  height: 100%;
  padding-top: 0.20833rem;
  padding-left: 0.21875rem;
  padding-bottom: 0.52083rem;
  overflow: auto;
}
.box[data-v-2c0d290a] {
  line-height: 0.10417rem;
  margin-bottom: 0.20833rem;
  position: relative;
  cursor: pointer;
}
.dot[data-v-2c0d290a] {
  width: 0.0625rem;
  height: 0.0625rem;
  background: #ffa800;
  border-radius: 50%;
  position: absolute;
  left: -0.07813rem;
  top: -0.04167rem;
}
.updated[data-v-2c0d290a] {
  font-size: 0.07292rem;
  color: #000000;
  margin-bottom: 0.07813rem;
  margin-left: 0.52083rem;
}
.time[data-v-2c0d290a] {
  font-size: 0.0625rem;
  font-weight: 500;
  color: #000000;
}
.notifications[data-v-2c0d290a] {
  width: 100%;
  line-height: 0.15625rem;
  font-size: 0.09375rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  left: 0;
  bottom: 0.18229rem;
}
.notifications a[data-v-2c0d290a] {
  color: #6984e8;
}
