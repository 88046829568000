@charset "UTF-8";
.particulars[data-v-51ddf2f8] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
  font-family: "Regular";
}
.activeName[data-v-51ddf2f8] {
  width: 6.875rem;
  border-radius: 10px;
  padding-bottom: 0.16667rem;
  position: relative;
  font-family: "Regular";
}
.minimum_withdrawal[data-v-51ddf2f8] {
  text-align: center;
  margin-top: 0.26042rem;
}
.minimum_withdrawal span[data-v-51ddf2f8] {
  font-size: 0.07292rem;
}
.minimum_withdrawal .el-icon-question[data-v-51ddf2f8] {
  font-size: 0.09375rem;
}
.flex[data-v-51ddf2f8], .photograph .Total_Box .memberRate[data-v-51ddf2f8], .photograph .Total_Box .fullRate[data-v-51ddf2f8] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.photograph[data-v-51ddf2f8] {
  width: 100%;
  height: 1.13542rem;
  border-radius: 10px;
  font-size: 0.08333rem;
  margin: 0 0.10417rem 0.10417rem;
  position: relative;
  color: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.photograph .Balance_Box[data-v-51ddf2f8] {
  width: 3.35417rem;
  height: 1.11979rem;
  background: #15104B;
  opacity: 1;
  border-radius: 10px;
}
.photograph .Balance_Box .Balance_upgrade_box[data-v-51ddf2f8] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.photograph .Balance_Box .Balance_upgrade_box .upgrade[data-v-51ddf2f8] {
  color: #3f6aff;
  cursor: pointer;
  margin-top: 0.11458rem;
  margin-left: 0.05208rem;
}
.photograph .Balance_Box .Balance_upgrade_box .upgrade img[data-v-51ddf2f8] {
  width: 0.10417rem;
  height: 0.10417rem;
}
.photograph .Balance_Box .Balance_upgrade_box .upgrade[data-v-51ddf2f8]:hover {
  font-size: 0.09375rem;
}
.photograph .Balance_Box .Balance_word[data-v-51ddf2f8] {
  margin-left: 0.26042rem;
  margin-top: 0.10417rem;
}
.photograph .Balance_Box .Balance_word p[data-v-51ddf2f8] {
  font-size: 0.11458rem;
}
.photograph .Balance_Box .AllianceMessage_CommissionEarned[data-v-51ddf2f8] {
  text-align: center;
  margin-top: 0.05208rem;
}
.photograph .Balance_Box .AllianceMessage_CommissionEarned p[data-v-51ddf2f8] {
  font-size: 0.125rem;
}
.photograph .Balance_Box .AllianceMessage_CommissionEarned_affiliate[data-v-51ddf2f8] {
  text-align: center;
  margin-top: 0.05208rem;
}
.photograph .Balance_Box .AllianceMessage_CommissionEarned_affiliate p[data-v-51ddf2f8] {
  font-size: 0.08333rem;
  color: #BABABA;
}
.photograph .Balance_Box .Alliance_withdraw[data-v-51ddf2f8] {
  margin-left: 2.23958rem;
  margin-top: 0.13021rem;
  width: 0.9375rem;
  height: 0.20833rem;
}
.photograph .Total_Box[data-v-51ddf2f8] {
  width: 3.35417rem;
  height: 1.11979rem;
  background: #15104B;
  opacity: 1;
  border-radius: 10px;
}
.photograph .Total_Box .Total_word[data-v-51ddf2f8] {
  margin-left: 0.26042rem;
  margin-top: 0.10417rem;
}
.photograph .Total_Box .Total_word p[data-v-51ddf2f8] {
  font-size: 0.11458rem;
}
.photograph .Total_Box .AllianceMessage_CommissionEarned_Total[data-v-51ddf2f8] {
  margin-left: 0.52083rem;
  margin-top: 0.10417rem;
}
.photograph .Total_Box .AllianceMessage_CommissionEarned_Total p[data-v-51ddf2f8] {
  font-size: 0.10417rem;
}
.photograph .Total_Box .Total_box_AffiliateBalance[data-v-51ddf2f8] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.07813rem;
  position: relative;
}
.photograph .Total_Box .Affiliate_TopLine[data-v-51ddf2f8] {
  position: relative;
}
.photograph .Total_Box .Affiliate_TopLine img[data-v-51ddf2f8]:nth-child(1) {
  width: 0.78125rem;
  height: 0.15625rem;
  position: absolute;
  top: -0.05208rem;
  left: -0.05208rem;
}
.photograph .Total_Box .Affiliate_TopLine img[data-v-51ddf2f8]:nth-child(2) {
  width: 0.78125rem;
  height: 0.15625rem;
  position: absolute;
  top: 0.26042rem;
  left: -0.05208rem;
}
.photograph .Total_Box .Alliance_FulfillmentEarned[data-v-51ddf2f8] {
  margin-left: 0.83333rem;
  margin-top: -0.10417rem;
  cursor: pointer;
}
.photograph .Total_Box .Alliance_MembershipEarned[data-v-51ddf2f8] {
  margin-left: 0.83333rem;
  margin-top: 0.33854rem;
  cursor: pointer;
}
.photograph .Total_Box .fullRate[data-v-51ddf2f8] {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAABoCAMAAAAw2/I0AAAAOVBMVEUAAAD///////////////////////////////////////////////////////////////////////8KOjVvAAAAEnRSTlMAQCDf7xCPv4CfX6/PMG8/cE9y/E56AAAB7UlEQVR42u3dW5KbMBBG4bYuCMTFM73/xWaSSoGHYHvy2v/5tnAKgaQq2gAgqLWVMiWDkLX4l0x1Ian4H7kaRKTsfzWDhpR9NxgU1OwPRoOAxb/phvAmP1kNwY1+Vti4BXduznY9vtGvLGzXA+t+bTJE9emP2Lgp6MWpLubDX5n5mosnNX8tfxpCqWPxt7hfj+Q2FP+R1qU3bzWttxD6Nhf/D23ot0BS/Wnwbc6OIMrck71za45gWie5oLzaM3VwBDXYtbQ4wsrpsjmfb6HlRHM9F9VZ28NrdjI6whvtm+QQsNqjySGg8aALuvGg6xnsUBwSSj2O3B0ijvWdM3cZx/rO3ZqMeY/OCayMvEd3qChEF0R0QUQXRHRBRBdEdEFEF0R0QUQXRHRBRBdEdEFEF0R0QUQXRHRBRBdEdEFEF0R0QUQXRHRBRBdEdEFEF0R0QUQXRHRBRBdEdEFEF0R0QUQXRHRBRBdEdEFEF0R0QUQXRHRBRBfEn7/1FP4Nq2fZo88OEfMefXOIGJnsoOfODBc5mfmLeiY7VIeEZGaM7tEy2aPKW13AeZj2hyO8bl9Y4KUMe2yGs6lY7F91cQTWqv3GCi9ksCc6121Blc2eSgxSD2mo9kqaeNqDKWO1t+7jvHBWE0LJ83Y3AAAQ1C/b4rJjJlP7YgAAAABJRU5ErkJggg==);
  background-size: contain;
  width: 1.30208rem;
  height: 0.27083rem;
  position: absolute;
  left: 1.77083rem;
  top: 0.49479rem;
  color: #15104b;
  padding-top: 0.05208rem;
}
.photograph .Total_Box .memberRate[data-v-51ddf2f8] {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAABoCAMAAAAw2/I0AAAAOVBMVEUAAAD///////////////////////////////////////////////////////////////////////8KOjVvAAAAEnRSTlMAQCDf7xCPv4CfX6/PMG8/cE9y/E56AAAB7UlEQVR42u3dW5KbMBBG4bYuCMTFM73/xWaSSoGHYHvy2v/5tnAKgaQq2gAgqLWVMiWDkLX4l0x1Ian4H7kaRKTsfzWDhpR9NxgU1OwPRoOAxb/phvAmP1kNwY1+Vti4BXduznY9vtGvLGzXA+t+bTJE9emP2Lgp6MWpLubDX5n5mosnNX8tfxpCqWPxt7hfj+Q2FP+R1qU3bzWttxD6Nhf/D23ot0BS/Wnwbc6OIMrck71za45gWie5oLzaM3VwBDXYtbQ4wsrpsjmfb6HlRHM9F9VZ28NrdjI6whvtm+QQsNqjySGg8aALuvGg6xnsUBwSSj2O3B0ijvWdM3cZx/rO3ZqMeY/OCayMvEd3qChEF0R0QUQXRHRBRBdEdEFEF0R0QUQXRHRBRBdEdEFEF0R0QUQXRHRBRBdEdEFEF0R0QUQXRHRBRBdEdEFEF0R0QUQXRHRBRBdEdEFEF0R0QUQXRHRBRBdEdEFEF0R0QUQXRHRBRBfEn7/1FP4Nq2fZo88OEfMefXOIGJnsoOfODBc5mfmLeiY7VIeEZGaM7tEy2aPKW13AeZj2hyO8bl9Y4KUMe2yGs6lY7F91cQTWqv3GCi9ksCc6121Blc2eSgxSD2mo9kqaeNqDKWO1t+7jvHBWE0LJ83Y3AAAQ1C/b4rJjJlP7YgAAAABJRU5ErkJggg==);
  background-size: contain;
  width: 1.30208rem;
  height: 0.27083rem;
  position: absolute;
  left: 1.77083rem;
  top: 0.05208rem;
  color: #15104b;
  padding-top: 0.05208rem;
}
.time_slot[data-v-51ddf2f8] {
  width: 1.82292rem;
  height: 0.20833rem;
  position: absolute;
  border: none;
  outline: none;
  margin-top: -0.02604rem;
}
[data-v-51ddf2f8] .time_slot .el-input--suffix .el-input__inner {
  width: 1.82292rem !important;
  height: 0.20833rem !important;
  border: 1px solid #878787;
  border-radius: 50px;
}
[data-v-51ddf2f8] .time_slot .el-select .el-input--suffix .el-input__suffix {
  display: none !important;
}
.commission[data-v-51ddf2f8] {
  width: 2.29167rem;
  height: 1.04167rem;
  margin-left: 2.60417rem;
  margin-top: 0.15625rem;
}
.searchbox[data-v-51ddf2f8] {
  width: 100%;
  height: auto;
  line-height: 0.5rem;
  background: #ffffff;
  box-shadow: 0 0 0.10417rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-left: 0.10417rem;
}
.searchbox .dp_box[data-v-51ddf2f8] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.searchbox .Link[data-v-51ddf2f8] {
  font-size: 0.09375rem;
  font-weight: 400;
  color: #999999;
  margin-left: 0.28646rem;
  margin-top: 0.05208rem;
}
.searchbox .search_input[data-v-51ddf2f8] {
  width: 1.82292rem;
  height: 0.25rem;
  line-height: 0.25rem;
  background: #ffffff;
  margin-left: 0.15625rem;
  margin-top: 0.15625rem;
}
.searchbox .search_input[data-v-51ddf2f8] .el-input__inner {
  width: 1.82292rem;
  height: 0.25rem;
  line-height: 0.25rem;
  font-size: 0.07292rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 0.16667rem;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 50px;
}
.searchbox .search_btn[data-v-51ddf2f8] {
  width: 0.52083rem;
  height: 0.25rem;
  margin-left: 0.15625rem;
  font-weight: 500;
  color: #ffffff;
  margin-top: 0.14583rem;
}
.searchbox .Link_Affiliate_Code[data-v-51ddf2f8] {
  font-size: 0.09375rem;
  font-weight: 400;
  color: #999999;
  margin-left: 0.20833rem;
  margin-top: 0.04167rem;
}
.searchbox .Link_Affiliate_Code_input[data-v-51ddf2f8] {
  display: inline-block;
  width: 0.67708rem;
  height: 0.25rem;
  line-height: 0.25rem;
  background: #ffffff;
  margin-left: 0.15625rem;
  margin-top: 0.15625rem;
}
.searchbox .Link_Affiliate_Code_input[data-v-51ddf2f8] .el-input__inner {
  width: 0.67708rem;
  height: 0.25rem;
  line-height: 0.25rem;
  font-size: 0.07292rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 0.0625rem;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 50px;
}
.searchbox .Affiliate_Code_btn[data-v-51ddf2f8] {
  width: 0.52083rem;
  height: 0.25rem;
  margin-left: 0.15625rem;
  font-weight: 500;
  color: #ffffff;
  padding-left: 0.10417rem;
  padding-right: 0.10417rem;
  margin-top: 0.15625rem;
}
.activeName1[data-v-51ddf2f8] {
  width: 100%;
  margin-left: 0.10417rem;
  margin-top: 0.16667rem;
  background-color: #fff;
  border-radius: 10px !important;
  overflow: hidden;
  box-shadow: 0 0 0.10417rem 0 rgba(0, 0, 0, 0.05);
}
[data-v-51ddf2f8] .el-tabs__nav-scroll {
  height: 0.41667rem;
  padding: 0.07813rem 0;
  background: -webkit-linear-gradient(bottom, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}
[data-v-51ddf2f8] .el-tabs__nav {
  margin-left: 0.16667rem;
}
[data-v-51ddf2f8] .el-table th,
.el-table tr[data-v-51ddf2f8] {
  /* 标签页头部高度 */
  height: 0.33333rem;
}
[data-v-51ddf2f8] .el-table__row {
  /* 内容区的高度 */
  height: 0.42188rem !important;
}
[data-v-51ddf2f8] .el-tabs__active-bar {
  /* 标签页下划线 */
  height: 0.01563rem !important;
  background-color: #6788ff;
  border-radius: 10px;
}
[data-v-51ddf2f8] .el-tabs__nav-wrap::after {
  background-color: #f9f9f9;
}
[data-v-51ddf2f8] .el-tabs__item {
  font-size: 0.08333rem !important;
}
.el-table[data-v-51ddf2f8]::before {
  background: #dbdbe4;
}
.success[data-v-51ddf2f8] {
  display: inline-block;
  width: 0.96354rem;
  height: 0.24479rem;
  line-height: 0.24479rem;
  border-radius: 10px;
  font-size: 0.08333rem;
  font-weight: 400;
  color: #6788ff;
  border: 0;
}
.paybox[data-v-51ddf2f8] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.pay_content[data-v-51ddf2f8] {
  width: 3.64583rem;
  height: 1.97917rem;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  overflow: hidden;
  position: absolute;
  left: calc(50% - 1.73438rem);
  top: calc(50% - 0.91146rem);
}
.pay_title[data-v-51ddf2f8] {
  width: 100%;
  height: 0.20833rem;
  line-height: 0.20833rem;
  font-size: 0.09375rem;
  color: #000;
  padding-left: 0.20833rem;
  padding-right: 0.16667rem;
  background: #ffffffff;
  border-bottom: 1px solid #cccccc;
}
.pay_title span[data-v-51ddf2f8] {
  width: 0.07813rem;
  height: 0.07813rem;
  display: block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOBAMAAADtZjDiAAAALVBMVEUAAACfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoapgOQNlAAAADnRSTlMAmIGmkqvxuolo93l4dQWiX7wAAABSSURBVAjXY0hlAAExhjgBIMX0nIHlIZDWM2Bg8BNgYHoMZAMFQFygwMnHYLW87xrANOc7BTCt1/0QRDE9BWoB6wVpAesFCcgFAGnGpwzpYLVWANBXEYKHOC/hAAAAAElFTkSuQmCC) no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: right;
  margin-top: 0.07813rem;
  cursor: pointer;
}
[data-v-51ddf2f8] .el-input__inner {
  width: 1.82292rem;
  height: 0.20833rem;
  background: #ffffff;
  border: 1px solid #878787;
  border-radius: 50px;
  padding-left: 0.16667rem;
}
.pay_items[data-v-51ddf2f8] {
  width: 100%;
  height: 1.97917rem;
  background: #fff;
  padding-top: 0.05208rem;
}
.pay_item[data-v-51ddf2f8] {
  width: 100%;
  height: 0.23438rem;
  line-height: 0.39063rem;
  margin-bottom: 0.07813rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.09375rem;
  color: #585858;
}
.pay_left_account[data-v-51ddf2f8] {
  width: 1.11458rem;
  height: 0.20833rem;
  margin-left: 0.54688rem;
}
.pay_right_account[data-v-51ddf2f8] {
  width: 1.11458rem;
  height: 0.20833rem;
  margin-left: -0.26042rem;
}
.pay_left[data-v-51ddf2f8],
.pay_right[data-v-51ddf2f8] {
  width: 1.11458rem;
  height: 0.20833rem;
  margin: 0 0.09375rem;
}
.pay_left[data-v-51ddf2f8] {
  text-align: right;
}
.paybtn[data-v-51ddf2f8] {
  border-radius: 25px;
  width: 1.04167rem;
  height: 0.22917rem;
  margin: 0.20833rem auto 0;
  color: #fff;
}
.pagebox[data-v-51ddf2f8] {
  /* 分页切换 */
  width: 100%;
  height: 0.36458rem;
  padding-bottom: 0.02083rem;
  background-color: #fff;
}