.slider[data-v-64355c54] {
  width: 1.35417rem;
  height: 100vh;
  background: #15104b;
  padding: 0 0.15625rem;
  overflow-y: scroll;
}
.slider[data-v-64355c54]::-webkit-scrollbar {
  display: none;
}
.slider .slider-header[data-v-64355c54] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  height: 0.36458rem;
  padding: 0.10417rem 0;
}
.slider .slider-header img[data-v-64355c54] {
  width: 0.82813rem;
  height: 0.26042rem;
  margin-left: 0.04688rem;
}
.slider .silder-menu .menu-item[data-v-64355c54] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.09375rem;
  padding-left: 0.05208rem;
}
.slider .silder-menu .menu-item .menu-item-image[data-v-64355c54] {
  margin-right: 0.10417rem;
}
.slider .silder-menu .menu-item .menu-item-image img[data-v-64355c54] {
  height: 0.16667rem;
  width: 0.16667rem;
}
.slider .silder-menu[data-v-64355c54] .el-menu {
  border: none;
  margin-left: -0.08333rem;
}
.slider .silder-menu[data-v-64355c54] .el-menu .el-menu-item.is-active .menu-item {
  background-color: #4d4e71 !important;
  border-radius: 4px 4px 4px 4px;
}