.sense-name-dialog[data-v-2815a79d] {
  width: 100%;
  font-family: Open Sans;
}
.sense-name-dialog[data-v-2815a79d] .el-dialog {
  overflow: auto;
  border-radius: 26px;
}
.sense-name-dialog[data-v-2815a79d] .el-dialog .el-dialog__body {
  padding: 0;
}
.sense-name-dialog[data-v-2815a79d] .el-collapse-item__header {
  background: #f4f7ff;
  padding: 0 0.0625rem 0 0.16667rem;
  font-weight: 600;
  font-size: 0.08333rem;
}
.sense-name-dialog[data-v-2815a79d] .el-collapse-item__content {
  padding: 0.10417rem 0.20833rem 0.10417rem;
}
.sense-name-dialog .w-e-text-container[data-v-2815a79d] {
  min-height: 0.67708rem !important;
}
.sense-name-dialog .formInput[data-v-2815a79d] .el-input__inner {
  border-radius: 36px;
  border: 1px solid #e5e5e5;
}