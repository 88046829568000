.bigbox[data-v-60b80c0f] {
  display: grid;
  grid-template-columns: repeat(4, 1.5625rem);
  grid-gap: 0.15625rem 0.19271rem;
}
.bigbox .box[data-v-60b80c0f] {
  padding: 0.15625rem;
  width: 1.5625rem;
  background: #f6f6fa;
  box-shadow: 0.01042rem 0.02083rem 0.03125rem 0 #0000001a;
  border-radius: 10px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.bigbox .box[data-v-60b80c0f]:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.bigbox .box .images[data-v-60b80c0f] {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 auto;
  cursor: pointer;
}
.bigbox .box .images.blur[data-v-60b80c0f] {
  -webkit-filter: blur(0.07813rem);
          filter: blur(0.07813rem);
}
.bigbox .box .images img[data-v-60b80c0f] {
  width: 100%;
  height: 100%;
}
.bigbox .box .simple[data-v-60b80c0f] {
  font-size: 0.07292rem;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 0.28646rem;
  margin: 0.07813rem 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 0.13021rem;
  word-break: normal;
}
.bigbox .box .simple.blur[data-v-60b80c0f] {
  -webkit-filter: blur(0.04167rem);
          filter: blur(0.04167rem);
}
.bigbox .box .lists_num[data-v-60b80c0f] {
  color: #999999;
  font-size: 0.07292rem;
}
.bigbox .box .btn[data-v-60b80c0f] {
  margin-top: 0.10417rem;
}
.bigbox .box .btn .connect_btn[data-v-60b80c0f] {
  color: #15104b;
  background: #fff;
  border-radius: 50px;
  font-size: 0.07292rem;
  width: 0.53125rem;
  height: 0.13021rem;
  border: 1px solid #15104b;
}
.bigbox .box .btn .connect_btn[data-v-60b80c0f]:hover {
  background: #15104b;
  color: #fff;
}
.bigbox .box .btn .list_btn[data-v-60b80c0f] {
  color: #fff;
  background: #15104b;
  border-radius: 50px;
  font-size: 0.07292rem;
  width: 0.53125rem;
  height: 0.13021rem;
  border: 1px solid #15104b;
  margin-left: 0.07813rem;
}
.bigbox .box .btn .list_btn[data-v-60b80c0f]:hover {
  background: #15104b;
  color: #fff;
  opacity: 0.8;
}