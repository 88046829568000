.main[data-v-1f1a301c] {
  padding-bottom: 0.20833rem;
  position: relative;
}
.main .main-member[data-v-1f1a301c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 6.82292rem;
}
.main .main-member .main-member-item[data-v-1f1a301c] {
  width: 1.77083rem;
  height: 0.61458rem;
  border-radius: 16px;
  background: #d9d9d9;
  cursor: pointer;
  background-size: 100% 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 0.67708rem;
  position: relative;
}
.main .main-member .main-member-item.active[data-v-1f1a301c] {
  box-shadow: 0.02083rem 0.02083rem 0.05208rem 0.01042rem rgba(73, 73, 73, 0.521);
  position: relative;
  top: -0.0625rem;
}
.main .main-member .main-member-item[data-v-1f1a301c]:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.main .main-member .main-member-item .main-member-item-inner[data-v-1f1a301c] {
  color: #fff;
  font-family: "Regular";
}
.main .main-member .main-member-item .main-member-item-inner .main-member-item-title[data-v-1f1a301c] {
  font-size: 0.1875rem;
}
.main .main-member .main-member-item .main-member-item-inner .main-member-item-sub-title[data-v-1f1a301c] {
  font-size: 0.10417rem;
}
.main .main-search[data-v-1f1a301c] {
  background-color: #fff;
  width: 6.82292rem;
  height: 0.36458rem;
  box-shadow: 0.01042rem 0.02083rem 0.03125rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 0.20833rem;
  padding: 0 0.15625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.main .main-search .main-search-content[data-v-1f1a301c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 100%;
}
.main .main-search .main-search-input[data-v-1f1a301c] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
}
.main .main-search[data-v-1f1a301c] .el-input {
  height: 0.20833rem;
}
.main .main-search[data-v-1f1a301c] .el-input .el-input__inner {
  height: 0.20833rem;
  text-align: center;
  background: #f1f1f2;
  border: none;
  border-radius: 10px;
}
.main .main-search[data-v-1f1a301c] .el-input .el-input__icon.el-icon-circle-close.el-input__clear {
  position: absolute;
  right: 0.1875rem;
  top: 0.01042rem;
}
.main .main-search[data-v-1f1a301c] .el-input .el-input__suffix-inner {
  margin: auto 0;
}
.main .main-search .main-search-btn[data-v-1f1a301c] {
  width: 0.75521rem;
  height: 0.20833rem;
  line-height: 0.20833rem;
  margin-left: 0.125rem;
  background: #eaeffb;
  color: #6e8fff;
  border-radius: 10px;
}
[data-v-1f1a301c] .search-input {
  width: 100%;
}
[data-v-1f1a301c] .search-input .el-input__inner {
  border-radius: 32px;
  background-color: #eff1f3 !important;
  border-color: #eff1f3;
}
[data-v-1f1a301c] .search-input2 {
  width: 100%;
}
[data-v-1f1a301c] .search-input2 .el-input__inner {
  border-radius: 32px;
  background-color: #eff1f3 !important;
  border-color: #eff1f3;
  padding-left: 0.22917rem !important;
}
[data-v-1f1a301c] .search-input2 .search-img {
  width: 0.125rem;
  height: 0.125rem;
}