[data-v-688ef2a3] .mydialog {
  border-radius: 10px !important;
  width: 3.02083rem;
}
.particulars[data-v-688ef2a3] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
}
.particulars .main[data-v-688ef2a3] {
  width: 7.29167rem;
  padding: 0 0 0.16667rem;
}
.particulars .searchbox[data-v-688ef2a3] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  height: 0.39063rem;
  background: #ffffff;
  box-shadow: 0 0 0.05208rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-left: 0.10417rem;
  margin-bottom: 0.10417rem;
}
.particulars .searchbox .search_input[data-v-688ef2a3] {
  display: block;
  width: 2.34375rem;
  height: 0.20833rem;
  background: #f1f2f4;
  border-radius: 30px;
  margin-right: 0.10417rem;
}
.particulars .searchbox .search_input .search_icon[data-v-688ef2a3] {
  display: inline-block;
  margin-top: 0.07813rem;
  margin-right: 0.125rem;
  margin-left: 0.125rem;
}
.particulars .searchbox .search_input input[data-v-688ef2a3] {
  background: transparent !important;
  width: calc(100% - 0.36458rem);
  font-size: 0.08333rem;
  color: #585858;
  padding-right: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.particulars .searchbox .topbox div[data-v-688ef2a3] {
  min-width: 0.52083rem;
  height: 0.20833rem;
  margin-right: 0.10417rem;
}
.particulars .searchbox .topbox .Synchronize a[data-v-688ef2a3] {
  color: #fff;
  font-size: 0.08333rem;
}
.main-list[data-v-688ef2a3] {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.main-list li .content[data-v-688ef2a3] {
  border-bottom: 2px solid #f2f2f2;
  width: 100%;
  height: 0.42708rem;
  line-height: 0.42708rem;
  font-size: 0.07292rem;
  color: #000000;
  text-align: center;
  cursor: pointer;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.main-list li .content .title_li[data-v-688ef2a3] {
  font-family: "Regular";
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-font-smoothing: antialiased;
}
.main-list li .content .title_li[data-v-688ef2a3]:nth-child(1) {
  width: 0.83333rem;
}
.main-list li .content .title_li[data-v-688ef2a3]:nth-child(2) {
  width: 0.83333rem;
}
.main-list li .content .title_li[data-v-688ef2a3]:nth-child(3) {
  width: 1.25rem;
}
.main-list li .content .title_li[data-v-688ef2a3]:nth-child(4) {
  width: 1.04167rem;
}
.main-list li .content .title_li[data-v-688ef2a3]:nth-child(5) {
  width: 1.04167rem;
}
.main-list li .content .title_li[data-v-688ef2a3]:nth-child(6) {
  width: 1.14583rem;
}
.main-list li .content .title_li[data-v-688ef2a3]:nth-child(7) {
  width: 1.04167rem;
}
.main-list li .contentMessage[data-v-688ef2a3] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  cursor: pointer;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-size: 0.07292rem;
  color: #000000;
  height: auto;
  width: 100%;
  /*overflow-x: scroll;*/
  overflow: hidden;
}
.main-list li .contentMessage .content_li[data-v-688ef2a3] {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-bottom: 2px solid #f2f2f2;
  padding: 0 0.05208rem;
}
.main-list li .contentMessage .content_li[data-v-688ef2a3]:nth-child(1) {
  width: 0.83333rem;
}
.main-list li .contentMessage .content_li[data-v-688ef2a3]:nth-child(2) {
  width: 0.83333rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.main-list li .contentMessage .content_li[data-v-688ef2a3]:nth-child(3) {
  width: 1.25rem;
}
.main-list li .contentMessage .content_li[data-v-688ef2a3]:nth-child(4) {
  width: 1.04167rem;
}
.main-list li .contentMessage .content_li[data-v-688ef2a3]:nth-child(5) {
  width: 1.04167rem;
}
.main-list li .contentMessage .content_li[data-v-688ef2a3]:nth-child(6) {
  width: 1.14583rem;
}
.main-list li .contentMessage .content_li[data-v-688ef2a3]:nth-child(7) {
  width: 1.14583rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.main-list li .contentMessage .content_li:nth-child(7) .ViewdetailsContent[data-v-688ef2a3] {
  margin-top: 0.07813rem;
}
.main-list li .contentMessage .content_li:nth-child(7) .ViewdetailsContent p[data-v-688ef2a3] {
  color: #BC0C34;
}
.main-list li .contentMessage .content_li:nth-child(7) .ViewOrderDetails[data-v-688ef2a3] {
  margin-top: 0.07813rem;
}
.main-list li .contentMessage .content_li:nth-child(7) .ViewOrderDetails p[data-v-688ef2a3] {
  color: #BC0C34;
}
.main-list li .contentMessage .content_li:nth-child(7) .ModifyBtn[data-v-688ef2a3] {
  margin-top: 0.07813rem;
  margin-bottom: 0.05208rem;
}
.main-list li .contentMessage .content_li:nth-child(7) .ModifyBtn button[data-v-688ef2a3] {
  width: 0.52083rem;
  height: 0.18229rem;
  border-radius: 50px;
  background-color: #FFB22B !important;
  color: #ffffff !important;
  border: #FFB22B;
}
[data-v-688ef2a3] .pagebox .el-pagination .el-select .el-input .el-input__inner {
  border: 1px solid #ccc;
  height: 0.18229rem;
  border-radius: 10px;
  margin-left: 0.05208rem;
}
.pagebox[data-v-688ef2a3] {
  width: 100%;
  height: 0.3125rem;
  padding-bottom: 0.02083rem;
  background-color: #fff;
  margin-top: 0.04167rem;
  border-radius: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  justify-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
[data-v-688ef2a3] .el-pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.page_sign[data-v-688ef2a3] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 0.07813rem;
}
.page_sign input[data-v-688ef2a3] {
  width: 0.23438rem;
  height: 0.14583rem;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.page_sign span[data-v-688ef2a3] {
  display: inline-block;
  margin-right: 0.05208rem;
  cursor: pointer;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Contact_input[data-v-688ef2a3] {
  width: 2.08333rem;
  height: 0.20833rem;
  border: 1px solid #ccc;
  float: left;
  color: #585858;
  text-align: center;
  border-radius: 50px;
  padding: 0.02604rem 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.26042rem;
}
.Contact_input input[data-v-688ef2a3],[data-v-688ef2a3] .el-input__inner,[data-v-688ef2a3] .el-input {
  width: 100%;
  height: 100%;
  display: block;
  line-height: 0.15625rem;
  background: none;
  color: #585858;
  padding: 0;
  border: none;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.myselect[data-v-688ef2a3],[data-v-688ef2a3] .myselect.el-date-editor {
  width: 2.08333rem;
  height: 0.20833rem;
  padding: 0 0.07813rem;
  border-radius: 50px;
  color: #585858;
  display: inline-block;
  margin-right: 0.13021rem;
  border: 1px solid #ccc;
  margin-left: 0.05208rem;
}
[data-v-688ef2a3] .myselect.el-date-editor {
  width: 2.08333rem;
}
.myselect1[data-v-688ef2a3],[data-v-688ef2a3] .myselect1.el-date-editor {
  width: 2.08333rem;
  height: 0.20833rem;
  padding: 0 0.07813rem;
  border-radius: 50px;
  color: #585858;
  display: inline-block;
  margin-right: 0.13021rem;
  border: 1px solid #ccc;
  margin-left: 0.07813rem;
}
[data-v-688ef2a3] .myselect1.el-date-editor {
  width: 2.08333rem;
}
[data-v-688ef2a3] .el-date-editor .el-range-separator {
  width: 10%;
}
.BackTop[data-v-688ef2a3] {
  position: fixed;
  bottom: 0;
  right: 0.10417rem;
  cursor: pointer;
}
.BackTop img[data-v-688ef2a3] {
  width: 0.26042rem;
  height: 0.26042rem;
}
[data-v-688ef2a3] .list .el-empty__description p {
  display: none;
}
.list[data-v-688ef2a3] {
  background: #ffffff;
  box-shadow: 0 0 0.10417rem 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.list li[data-v-688ef2a3] {
  background: #fff;
  border-bottom: 2px solid #f2f2f2;
}
.typebox[data-v-688ef2a3] {
  width: 100%;
  padding: 0 0.16667rem;
  background: -webkit-linear-gradient(bottom, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  overflow: hidden;
  position: relative;
}
.type[data-v-688ef2a3] {
  height: 0.41667rem;
  line-height: 0.375rem;
  padding: 0 0.04167rem;
  font-size: 0.07292rem;
  float: left;
  margin-right: 0.02604rem;
  position: relative;
  cursor: pointer;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.select[data-v-688ef2a3] {
  color: #3f6aff;
  font-size: 0.07292rem;
  line-height: 0.375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: relative;
}
.select[data-v-688ef2a3]::after {
  content: "";
  position: absolute;
  bottom: 0.08854rem;
  width: 60%;
  height: 0.01042rem;
  background: #3f6aff;
  left: 20%;
}
.NoDataImg[data-v-688ef2a3] {
  text-align: center;
  padding-top: 0.26042rem;
}
.NoDataImg p[data-v-688ef2a3]:nth-child(2) {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-size: 0.08333rem;
  margin-top: 0.05208rem;
  color: #ccc;
}