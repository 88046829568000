@charset "UTF-8";
.container[data-v-4dc79c62] {
  width: 2.8125rem;
  height: 2.73958rem;
  font-size: 0.08333rem;
  font-weight: 400;
}
.container[data-v-4dc79c62] .el-dialog {
  border-radius: 10px;
  /* 添加圆角 */
}
.container[data-v-4dc79c62] .el-dialog__header {
  height: 0.28125rem;
  /* 设置为你想要的高度 */
}
.container[data-v-4dc79c62] .el-dialog__body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.content[data-v-4dc79c62] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.line[data-v-4dc79c62] {
  width: 100%;
  height: 0.00521rem;
  background-color: #e5e5e5;
}
.scrollbar[data-v-4dc79c62] {
  padding-top: 0.14583rem;
  padding-left: 0.20833rem;
  padding-right: 0.20833rem;
}
.scrollbar[data-v-4dc79c62]::-webkit-scrollbar {
  width: 0.04167rem;
}
.scrollbar[data-v-4dc79c62]::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 4px;
}
.shop-list[data-v-4dc79c62] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.shop-item[data-v-4dc79c62] {
  width: 100%;
  margin-bottom: 0.03125rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}
.shop-content[data-v-4dc79c62] {
  padding: 0.02083rem;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  /* 垂直居中对齐 */
  border: 1px solid transparent;
  /* 添加默认边框样式 */
}
.selected[data-v-4dc79c62] {
  border-color: #3f6aff;
  /* 设置选中时的边框颜色 */
  border-radius: 14px;
}
.shop-image[data-v-4dc79c62] {
  width: 0.3125rem;
  height: 0.3125rem;
  border: none;
  border-radius: 14px;
  background-size: cover;
  /* 确保图片完整覆盖容器 */
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  /* 文字水平居中 */
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  /* 文字垂直居中 */
  background-color: #d9e1ff;
  /* 图片加载前的背景色，可以根据需要调整 */
  color: #000;
  /* 字体颜色，根据需要调整 */
  font-weight: bold;
  /* 加粗显示店铺名的前两个字母 */
  margin-right: 0.05208rem;
  /* 与右侧信息的间距 */
}
.shop-info[data-v-4dc79c62] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* 使shop-title和shop-address垂直排列 */
}
.shop-title[data-v-4dc79c62] {
  width: 1.875rem;
  margin: 0.01042rem 0;
  /* 调整间距 */
  white-space: nowrap;
  /* 确保文本在同一行显示 */
  overflow: hidden;
  /* 隐藏超出容器部分的文本 */
  text-overflow: ellipsis;
  /* 使用省略号表示文本被截断 */
  color: #000;
  font-size: 0.08333rem;
}
.shop-address[data-v-4dc79c62] {
  width: 1.875rem;
  margin: 0.01042rem 0;
  /* 调整间距 */
  white-space: nowrap;
  /* 确保文本在同一行显示 */
  overflow: hidden;
  /* 隐藏超出容器部分的文本 */
  text-overflow: ellipsis;
  /* 使用省略号表示文本被截断 */
  font-size: 0.07292rem;
  color: #666666;
}
.confirm-button[data-v-4dc79c62] {
  width: 1.04167rem;
  height: 0.22917rem;
  color: white;
  background-color: #15104b;
  border: none;
  border-radius: 22px;
  cursor: pointer;
}