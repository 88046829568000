.feed-missing-item .header[data-v-b9e01c06] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0.08333rem;
}
.feed-missing-item .header .title-container[data-v-b9e01c06] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
}
.feed-missing-item .header .title-container .tips[data-v-b9e01c06] {
  color: #999999;
  line-height: 0.07292rem;
}
[data-v-b9e01c06] .el-form-item__label {
  padding-right: 0.05208rem !important;
}