.feed-return-info .header[data-v-51a921ca] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0.08333rem;
  margin-top: 0.08333rem;
}
.feed-return-info .header .title-container[data-v-51a921ca] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
}
.feed-return-info .header .title-container .title[data-v-51a921ca] {
  font-size: 0.09375rem;
  font-weight: 500;
  text-align: left;
  color: #15104b;
}
.feed-return-info .header .title-container .tips[data-v-51a921ca] {
  color: #999999;
  line-height: 0.07292rem;
}
[data-v-51a921ca] .el-form-item__label {
  padding-right: 0.05208rem !important;
}