
.successful[data-v-02e650d9] {
  font-size: 0.15625rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #AAAAAA;
  text-align: center;
  margin-top: 0.29688rem;
}
.headerBg_Img[data-v-02e650d9] {
  margin-top: 0.26042rem;
  margin-left: 0.41667rem;
}
.headerBg_Img img[data-v-02e650d9]{
  width: 0.67708rem;
  height: 0.67708rem;
}
.loading_text[data-v-02e650d9] {
  text-align: center;
  margin-top: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.loading_text img[data-v-02e650d9] {
  width: 1.82292rem;
  height: 1.30208rem;
}
.Confirm_btn[data-v-02e650d9] {
  text-align: center;
}
.Confirm_btn button[data-v-02e650d9] {
  border: 0;
  margin-top: 0.26042rem;
  width: 0.9375rem;
  height: 0.23438rem;
  line-height: 0.20833rem;
  background: #15104B;
  color: #fff;
  border-radius: 50px;
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Confirm_btn button[data-v-02e650d9]:hover {
  background: #3C3772;
  color: #fff;
}
