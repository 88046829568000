
.particulars[data-v-13217398] {
  width: 100%;
  position: relative;
  background: #FFFFFF;
  min-height: 100vh;
}
.Bgrd[data-v-13217398] {
  width: 100%;
  height: 0.67708rem;
  position: relative;
  background-image: url(../img/firstStep.855a9332.png);
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
}
.Bgrd img[data-v-13217398]{
position: absolute;
top: 0.1875rem;
left: 3.90625rem;
}
.activeName[data-v-13217398] {
  margin: auto;
  padding-bottom: 0.16667rem;
  width: 6.875rem;
  border-radius: 10px !important;
  position: relative;
  background-color: #fff;
}
.ShopyLogo[data-v-13217398] {
  width: 1.82292rem;
  height: 1.82292rem;
  margin: 0 auto;
  margin-top: 0.57292rem;
  line-height: 1.82292rem;
}
.content[data-v-13217398] {
  width: 3.38542rem;
  height: 1.30208rem;
  margin: 0 auto;
  margin-top: 0.02604rem;
}
.Confirm button[data-v-13217398] {
  width: 1.375rem;
  height: 0.23958rem;
  background: -webkit-linear-gradient(318deg, #5e5ee0, #6a8aff);
  background: linear-gradient(132deg, #5e5ee0, #6a8aff);
  border-radius: 10px;
  border: 0;
  margin-top: 0.15625rem;
  margin-left: 0.78125rem;
  margin-bottom: 0.10417rem;
  color: #ffffff;
}
.content p[data-v-13217398] {
  margin-top: 0.18229rem;
  font-size: 0.11458rem;
  font-weight: bold;
}
