@charset "UTF-8";
.IconInfo[data-v-aaf166c6] {
  position: absolute;
  top: 1.90104rem;
  right: 0.02604rem;
  cursor: pointer;
}
.DisplayInfo[data-v-aaf166c6] {
  position: absolute;
  bottom: 1.66146rem;
  right: 0.15104rem;
  color: #fff;
  z-index: 9999;
  background: #93df89;
  border-radius: 10px;
  font-size: 0.0625rem;
  padding: 0.03125rem 0.05208rem;
}
.StepBigBox[data-v-aaf166c6] {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 3.90625rem;
}
.StepOneRight[data-v-aaf166c6] {
  width: 4.16667rem;
  height: 3.89583rem;
  margin-right: -0.20833rem;
}
.StepMessageBox[data-v-aaf166c6] {
  width: 2.65625rem;
  height: 3.89583rem;
  position: absolute;
  right: 1.30208rem;
  background: #fff;
  padding-top: 0.39063rem;
}
.register[data-v-aaf166c6] {
  width: 2.65625rem;
  height: 3.92708rem;
  position: absolute;
  right: 0;
  top: -0.02604rem;
  background: #fff;
  padding-top: 0.39063rem;
}
.LogoBig[data-v-aaf166c6] {
  width: 0.625rem;
  height: 0.625rem;
  position: absolute;
  right: 1.875rem;
  top: 0;
  z-index: 99999;
}
.LogoBig .LogoBig img[data-v-aaf166c6] {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.guide_FillInYou[data-v-aaf166c6] {
  margin-top: 0.13021rem;
  font-size: 0.13542rem;
  font-weight: 600;
  color: #433759;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.44271rem;
}
.StartStepMessage[data-v-aaf166c6] {
  margin-top: 0.05208rem;
}
.StartStepMessage .StartStepMessage p[data-v-aaf166c6]:nth-child(1) {
  font-size: 0.09375rem;
  color: #4e415f;
  margin-left: 0.59896rem;
}
.StartStepMessage .StartStepMessage p[data-v-aaf166c6]:nth-child(2) {
  font-size: 0.09375rem;
  color: #4e415f;
  margin-left: 0.75521rem;
}
.UnableVerification[data-v-aaf166c6] {
  position: absolute;
  top: 0.07813rem;
  right: 0.41667rem;
  color: #4c8bf5;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.UnableVerification .UnableVerification p[data-v-aaf166c6] {
  font-size: 0.07292rem;
  color: #4c8bf5;
  cursor: pointer;
  font-style: italic;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.UnableVerification .UnableVerification p[data-v-aaf166c6]:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.UnableReceive[data-v-aaf166c6] {
  width: 1.5625rem;
  height: 0.15625rem;
  position: absolute;
  bottom: -0.18229rem;
  left: 0.18229rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  font-family: "Regular";
}
.UnableReceive .UnableReceive p[data-v-aaf166c6] {
  font-size: 0.07292rem;
  color: #858585;
  line-height: 0.15625rem;
}
.UnableReceive .UnableReceive p[data-v-aaf166c6]:nth-child(1) {
  font-size: 0.07292rem;
  color: #858585;
  line-height: 0.15625rem;
}
.UnableReceive .UnableReceive p[data-v-aaf166c6]:nth-child(2) {
  font-size: 0.07292rem;
  color: #858585;
  line-height: 0.15625rem;
  font-weight: 600;
}
.nnnn[data-v-aaf166c6] {
  width: 0.67708rem;
  height: 0.23958rem;
  background: #efeff7;
  border-radius: 50px;
  border: 0;
  color: #5e5ee0;
  font-size: 0.07813rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: -0.02604rem;
}
.nnnn[data-v-aaf166c6]:hover {
  background: #f9f9fa;
  color: #5e5ee0;
}
.codebox[data-v-aaf166c6] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
}
.codebox input[data-v-aaf166c6] {
  width: 0.88542rem;
  height: 0.23438rem;
  border: 1px solid #b2b2b2;
  margin-right: 0.10417rem;
  padding-left: 0.05208rem;
  border-radius: 50px;
  margin-left: 0.15625rem;
}
.activeName[data-v-aaf166c6] {
  width: 100%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background: #150f4b;
  overflow: hidden;
}
.detail[data-v-aaf166c6] {
  margin-top: 0.26042rem;
  margin-left: 1.30208rem;
  position: relative;
}
.detail .content[data-v-aaf166c6] {
  height: 0.36458rem;
  line-height: 0.26042rem;
  position: relative;
}
.detail .content span[data-v-aaf166c6]:nth-child(1) {
  width: 0.05208rem;
  height: 0.05208rem;
  line-height: 0.05208rem;
  color: red;
  display: block;
  position: absolute;
  top: 0.07813rem;
  left: 0.91146rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.detail .content span[data-v-aaf166c6]:nth-child(2) {
  display: inline-block;
  width: 0.875rem;
  text-align: right;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #000000;
}
.detail .content span[data-v-aaf166c6]:nth-child(3) {
  display: inline-block;
  margin-left: 0.27604rem;
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #666666;
  width: 2.60417rem;
}
[data-v-aaf166c6] .detail .content .el-input__inner {
  height: 0.20833rem;
  width: 100%;
  padding: 0 !important;
  border-radius: 50px;
  text-indent: 0.10417rem;
  line-height: 0.18229rem;
  outline: none;
  background: none;
  border: 1px solid #ccc;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.detail .conTent p[data-v-aaf166c6]:nth-child(1) {
  display: inline-block;
  width: 0.875rem;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #000;
  margin-left: 0.18229rem;
}
.detail .conTent p[data-v-aaf166c6]:nth-child(2) {
  display: inline-block;
  margin-left: 0.18229rem;
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #000;
  width: 2.34375rem;
}
[data-v-aaf166c6] .detail .conTent .el-input__inner {
  height: 0.20833rem;
  width: 100%;
  padding: 0 !important;
  border-radius: 50px;
  text-indent: 0.10417rem;
  line-height: 0.18229rem;
  outline: none;
  background: none;
  border: 1px solid #ccc;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Confirm button[data-v-aaf166c6] {
  width: 1.30208rem;
  height: 0.23958rem;
  border-radius: 50px;
  border: 0;
  margin-top: 0.20833rem;
  margin-bottom: 0.10417rem;
  color: #ffffff;
  margin-left: 0.78125rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-size: 0.09375rem;
}
.Confirm button[data-v-aaf166c6]:hover {
  background: #67689a;
  color: #fff;
}
[data-v-aaf166c6] .el-step__icon {
  position: relative;
  z-index: 1;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  width: 0.15625rem;
  height: 0.15625rem;
  font-size: 0.07292rem;
  box-sizing: border-box;
  background: #fff;
  -webkit-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
}
[data-v-aaf166c6] .el-step__head {
  margin-left: 0.26042rem !important;
}
.selectbox[data-v-aaf166c6] {
  width: 2.29167rem;
  height: 0.23438rem;
  border-radius: 50px;
  border: 1px solid #b2b2b2;
  padding: 0.04688rem 0.15625rem 0.04688rem 0.59896rem;
  position: relative;
  margin-left: 0.15625rem;
  margin-top: 0.26042rem;
}
.selectbox input[data-v-aaf166c6] {
  width: 100%;
  height: 0.15625rem;
  line-height: 0.15625rem;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.GuideTelephone p[data-v-aaf166c6] {
  position: absolute;
  top: 1.66667rem;
  left: 0.15625rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.GuideTelephone span[data-v-aaf166c6] {
  display: block;
  width: 0.05208rem;
  height: 0.05208rem;
  line-height: 0.05208rem;
  position: absolute;
  color: red;
  top: 1.66667rem;
  left: 0.65104rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.select[data-v-aaf166c6] {
  width: 0.52083rem;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 0.07813rem;
  border-radius: 50px 0 0 50px;
  overflow: hidden;
  background: none !important;
  border-right: 1px solid #eee;
}
[data-v-aaf166c6] .select .el-select {
  height: 0.25rem;
  line-height: 0.25rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-aaf166c6] .select .el-input--suffix {
  height: 0.25rem;
}
[data-v-aaf166c6] .select .el-input__inner {
  background: none !important;
  height: 0.25rem;
  line-height: 0.25rem;
  width: 0.75521rem;
  padding-left: 0.15625rem;
  background: none;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  border-radius: 50px;
}
.time_orders[data-v-aaf166c6] {
  /* 订单量 */
  width: 100%;
  height: 0.52083rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.Order_quantity p[data-v-aaf166c6] {
  color: #858585;
  font-size: 0.06771rem;
  font-family: Regular;
  -webkit-font-smoothing: antialiased;
  margin-top: 0.09375rem;
  margin-left: 0.10417rem;
  font-style: italic;
}
.how_many[data-v-aaf166c6] {
  margin-left: 0;
}
.how_many[data-v-aaf166c6] .el-input__inner {
  border-radius: 50px;
}
.Order_quantity p[data-v-aaf166c6] {
  color: #858585;
  font-size: 0.06771rem;
  font-family: Regular;
  -webkit-font-smoothing: antialiased;
  margin-top: 0.15625rem;
  margin-left: 0.18229rem;
}
.how_many[data-v-aaf166c6] {
  margin-left: 1.14583rem;
}
.paybox[data-v-aaf166c6] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.ValidationBox[data-v-aaf166c6] {
  vertical-align: middle;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 1.04167rem;
}