.content[data-v-74e37c48] {
  width: 100%;
  height: 100%;
  background: #15104B;
  padding-top: 1.45833rem;
}
.content .reset[data-v-74e37c48] {
  position: relative;
  margin: 0 auto;
  width: 4.375rem;
  height: 1.94792rem;
  background: #FFFFFF;
  border-radius: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.20833rem 0;
  font-size: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.content .reset .registration img[data-v-74e37c48] {
  width: 0.39583rem;
  height: 0.39583rem;
  margin-bottom: 0.23438rem;
}
.content .reset button[data-v-74e37c48] {
  width: 1.04167rem;
  height: 0.22917rem;
  margin-top: 0.26042rem;
  font-size: 0.08333rem;
}