.content-left[data-v-3fab0f37] {
  height: 3.89583rem;
  width: 4.72917rem;
  background-image: url("http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580086548_Group94.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #231d64;
  color: #ffffff;
  border-bottom-left-radius: 26px;
  border-top-left-radius: 26px;
}
.content-left .titleTopImg[data-v-3fab0f37] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 0.28646rem;
  padding: 0 0.23958rem 0 0.26042rem;
}
.content-left .titleTopImg .LoginImgTitle[data-v-3fab0f37] {
  width: 1.11979rem;
  height: 0.98438rem;
}
.content-left .titleTopImg .LoginImgTitle img[data-v-3fab0f37] {
  width: 100%;
}
.content-left .titleTopImg .GroupAppraise[data-v-3fab0f37] {
  width: 0.82292rem;
  height: 0.15104rem;
}
.content-left .titleTopImg .GroupAppraise img[data-v-3fab0f37] {
  width: 100%;
  margin-top: 0.13542rem;
}
.content-left .ContentWordBox[data-v-3fab0f37] {
  font-size: 0.17708rem;
  font-family: "Bold";
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 0.28646rem;
  margin-top: 0.10417rem;
  padding: 0 0.11979rem 0 0.26042rem;
}
.content-left .ContentWordBox span[data-v-3fab0f37] {
  font-size: 0.26042rem;
  font-weight: 900;
}
.content-left .DropshippingPlatform[data-v-3fab0f37] {
  padding: 0 0.11979rem 0 0.26042rem;
  font-size: 0.17708rem;
  font-weight: 600;
  margin-top: 0.52083rem;
  line-height: 2;
  font-family: "Bold";
}
.content-left .DropshippingWorldwide[data-v-3fab0f37] {
  padding: 0 0.11979rem 0 0.26042rem;
  margin-top: 0.13021rem;
  font-size: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}