.member-tips[data-v-2d51a084] .tips-dialog.el-dialog__wrapper {
  border: 1px solid red;
  z-index: 100010 !important;
}
.member-tips[data-v-2d51a084] .tips-dialog .el-dialog {
  width: 2.58333rem;
  height: 1.64583rem;
  border-radius: 38px;
  background: url("https://udshop-test.hzpdex.com/static/bg_upgrade_dialog.svg") no-repeat;
  background-size: 2.58333rem 1.64583rem;
}
.member-tips[data-v-2d51a084] .tips-dialog .el-dialog .el-dialog__header {
  display: none;
}
.member-tips[data-v-2d51a084] .tips-dialog .el-dialog .tips-wrap {
  padding: 0.25rem 0.20833rem;
  font-size: 0.10417rem;
  text-align: center;
}
.member-tips[data-v-2d51a084] .tips-dialog .el-dialog .dialog-footer {
  position: absolute;
  bottom: 0.36458rem;
  left: 0.39583rem;
  right: 0.39583rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.member-tips[data-v-2d51a084] .tips-dialog .el-dialog .footer-btn {
  width: 1.04167rem;
  height: 0.21875rem;
  border-radius: 30px;
  font-size: 0.08333rem;
}