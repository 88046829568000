
.particulars[data-v-ade4764c] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  /*background-image: linear-gradient(to bottom right, #373296, #7665d2);
  background-image: url('../../assets/imgs/Christmas/XueHua.png');*/
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
}
.activeName[data-v-ade4764c] {
  margin: auto;
  width: 6.875rem;
  position: relative;
}
.help_title[data-v-ade4764c] {
  text-align: center;
  margin-top: 0.15625rem;
  font-size: 0.20833rem;
  font-weight: 600;
  color: #474882;
  /*color: #fff;*/
}
.content_box[data-v-ade4764c] {
  width: 100%;
  height: 3.02083rem;
  background: #fff;
  margin-top: 0.18229rem;
  border-radius: 15px;
}
.Help_something p[data-v-ade4764c]{
  margin-top: 0.05208rem;
  margin-left: 1.04167rem;
  padding-top: 0.15625rem;
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Help_ForGeneral p[data-v-ade4764c]{
  font-size: 0.09375rem;
  margin-top: 0.07813rem;
  margin-left: 1.04167rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Help_WithinHours p[data-v-ade4764c]{
  font-size: 0.09375rem;
  margin-top: 0.07813rem;
  margin-left: 1.04167rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.message_box[data-v-ade4764c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-left: 1.82292rem;
  margin-top: 0.10417rem;
}
.message_Name p[data-v-ade4764c]:nth-child(1) {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-ade4764c] .message_Name .el-input__inner {
  width: 1.5625rem;
  margin-top: 0.07813rem;
}
.message_Email[data-v-ade4764c] {
  margin-left: 0.28646rem;
}
.message_Email p[data-v-ade4764c]:nth-child(1) {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-ade4764c] .message_Email .el-input__inner {
  width: 1.5625rem;
  margin-top: 0.07813rem;
}
.InputContent[data-v-ade4764c] {
  margin-left: 1.82292rem;
  margin-top: 0.07813rem;
  width: 3.4375rem;
  height: 0.78125rem;
  position: relative;
}
.InputContent p[data-v-ade4764c]:nth-child(1) {
  font-size: 0.08333rem;
  color: red;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: -0.01563rem;
  left: -0.0625rem;
}
.InputContent p[data-v-ade4764c]:nth-child(2) {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-ade4764c] .InputContent .el-textarea__inner {
  width: 3.4375rem;
  height: 0.78125rem;
  margin-top: 0.07813rem;
}
.btn[data-v-ade4764c] {
  margin-top: 0.26042rem;
  margin-right: 0.67708rem;
  position: relative;
}
.btn button[data-v-ade4764c] {
  display: block;
  width: 1.04167rem;
  height: 0.18229rem;
  background: #474882;
  border-radius: 21px;
  border: 0;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  top: 0.26042rem;
  left: 3.125rem;
}
/* 暗夜 */
body .dark[data-v-ade4764c] {
  background: #1f1e2e;
}
