[data-v-932ca890] .el-form-item--small.el-form-item {
  margin-bottom: 0.02083rem;
}
.usadrop-design-form[data-v-932ca890] {
  padding: 0.0625rem;
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
}
.usadrop-design-form .step-title[data-v-932ca890] {
  font-size: 0.09375rem;
  color: #333;
  font-weight: bold;
  margin: 0.04167rem 0;
}
.usadrop-design-form .preview-btn[data-v-932ca890] {
  width: 100%;
  background: #bc0c34;
  border: none;
  height: 0.20833rem;
  font-size: 0.08333rem;
}
.usadrop-design-form .pod-color-config[data-v-932ca890] {
  border-radius: 6px;
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
}
.usadrop-design-form .pod-color-config .color-image-wrapper .color-image-item[data-v-932ca890] {
  margin-bottom: 0.08333rem;
}
.usadrop-design-form .pod-color-config .color-image-wrapper .color-image-item .color-title[data-v-932ca890] {
  font-size: 0.07292rem;
  color: #333;
  font-weight: 500;
  margin: 0 0.04167rem 0.04167rem 0;
}
.usadrop-design-form .pod-color-config .color-image-wrapper .color-image-item .color-rect[data-v-932ca890] {
  width: 0.10417rem;
  height: 0.10417rem;
  margin-right: 0.04167rem;
  display: inline-block;
  border: 1px solid #ccc;
  cursor: pointer;
}
.usadrop-design-form .pod-color-config .color-image-wrapper .color-image-item .color-rect.active[data-v-932ca890] {
  border: 2px solid #3f6aff;
}
.usadrop-design-form .pod-color-config .color-image-wrapper .color-image-item .color-select[data-v-932ca890] {
  width: 0.625rem;
}