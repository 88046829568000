[data-v-3cef040c] .search-input {
  width: 100%;
}
[data-v-3cef040c] .search-input .el-input__inner {
  border-radius: 32px;
  background-color: #eff1f3 !important;
  border-color: #eff1f3;
}
[data-v-3cef040c] .search-input2 {
  width: 100%;
}
[data-v-3cef040c] .search-input2 .el-input__inner {
  border-radius: 32px;
  background-color: #eff1f3 !important;
  border-color: #eff1f3;
  padding-left: 0.22917rem !important;
}
[data-v-3cef040c] .search-input2 .search-img {
  width: 0.125rem;
  height: 0.125rem;
}
[data-v-3cef040c] .el-input__prefix {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.particulars[data-v-3cef040c] {
  width: 6.875rem;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  /*background-image: linear-gradient(to bottom right, #373296, #7665d2);
  background-image: url('../../assets/imgs/Christmas/XueHua.png');*/
  padding-bottom: 0.16667rem;
  box-sizing: border-box;
  font-family: "Regular";
}
.particulars .main[data-v-3cef040c] {
  width: 100%;
  height: 100%;
  padding-right: 0.15625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.search[data-v-3cef040c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.search .s-wrap[data-v-3cef040c] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
}
.search .s-btns[data-v-3cef040c] {
  width: 0.72917rem;
  padding-left: 0.15625rem;
  box-sizing: border-box;
}
.search .s-item + .s-item[data-v-3cef040c] {
  margin-top: 0.02604rem;
}
.search .btns-item[data-v-3cef040c] {
  width: 0.52083rem;
  border-radius: 32px;
  font-size: 0.08333rem;
  font-weight: bold;
  color: #15104b;
  background-color: #eff1f3;
  box-sizing: border-box;
  border: none;
  height: 0.16667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.search .btns-item + .btns-item[data-v-3cef040c] {
  margin-left: 0;
}
.left[data-v-3cef040c],
.right[data-v-3cef040c] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  background-color: #fff;
  padding: 0.13021rem 0.10417rem;
  box-sizing: border-box;
  border-radius: 10px;
}
.left .title[data-v-3cef040c],
.right .title[data-v-3cef040c] {
  font-size: 0.10938rem;
}
.left > div + div[data-v-3cef040c],
.right > div + div[data-v-3cef040c] {
  margin-top: 0.02604rem;
}
.left[data-v-3cef040c] {
  margin-right: 0.15625rem;
}
.lt-box .tab-wrap[data-v-3cef040c] {
  max-height: 3.22917rem;
  overflow: auto;
}
.rt-box[data-v-3cef040c] {
  padding-top: 0.10417rem;
  box-sizing: border-box;
  max-height: calc(100% - 0.625rem);
  overflow: auto;
}
.rt-box .content[data-v-3cef040c] {
  padding-top: 0.15625rem;
  font-size: 0.07292rem;
}
.rt-box .content .title[data-v-3cef040c] {
  color: #666666;
  font-size: 0.07292rem;
  margin-bottom: 0.04167rem;
}
.rt-box .content .section[data-v-3cef040c] {
  color: #999999;
  line-height: 0.11458rem;
}
.box .card[data-v-3cef040c] {
  width: 100%;
  height: 0.55208rem;
  padding: 0.04167rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  border: 1px solid #fff;
  border-bottom: 1px solid #eff1f3;
}
.box .card[data-v-3cef040c]:hover {
  border: 1px solid #3f6aff;
}
.box .card + .card[data-v-3cef040c] {
  margin-top: 0.04167rem;
}
.box .card .card-img[data-v-3cef040c] {
  width: 0.46875rem;
  height: 0.46875rem;
}
.box .card .card-detail[data-v-3cef040c] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  box-sizing: border-box;
  padding: 0 0.08333rem;
}
.box .card .card-detail .detail-text[data-v-3cef040c] {
  font-size: 0.07292rem;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
}
.box .card .card-detail .detail-list[data-v-3cef040c] {
  margin-top: 0.04167rem;
  color: #999999;
  font-size: 0.07292rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.box .card .card-btns[data-v-3cef040c] {
  width: 0.52083rem;
}
.box .card .card-btns .card-btnItem[data-v-3cef040c] {
  width: 100%;
  border-radius: 32px;
  background-color: #e0e7ff;
  color: #15104b;
  font-size: 0.07292rem;
  border-color: transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.box .card .card-btns .card-btnItem[data-v-3cef040c]:hover {
  opacity: 0.7;
}
.box .card .card-btns .card-btnItem + .card-btnItem[data-v-3cef040c] {
  margin-left: 0;
  margin-top: 0.04167rem;
}
.box .card .card-btns .view-btn[data-v-3cef040c] {
  background-color: #3f6aff;
  color: #fff;
}
.box .is-active[data-v-3cef040c] {
  border: 1px solid #3f6aff;
}
.pagination[data-v-3cef040c] {
  height: 0.20833rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}