.bread[data-v-7b87e473] {
  line-height: 0.3125rem;
  font-size: 0.0625rem;
  color: #858585;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.bread .el-breadcrumb[data-v-7b87e473] {
  font-size: 0.08333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  margin-right: 0.15625rem;
  height: 100%;
  font-family: "Regular";
  font-size: 0.11458rem;
}
.bread .el-breadcrumb .last-bread[data-v-7b87e473] {
  font-size: 0.09375rem;
}
.bread .el-breadcrumb .last-bread[data-v-7b87e473] .el-breadcrumb__inner {
  font-weight: 500 !important;
}
.bread .el-breadcrumb[data-v-7b87e473] .el-breadcrumb__inner {
  color: #15104b;
  font-weight: 600 !important;
}