.ad-login[data-v-50c42418] {
  width: 100%;
  height: 100%;
  padding-top: 0.48958rem;
  background: url("https://file.securepago.com/ud/1737362788181_ny_login_bg.png") no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.ad-login .login-info[data-v-50c42418] {
  margin-right: 0.41667rem;
  text-align: left;
  margin-top: -6%;
}
.ad-login .login-info .logo1[data-v-50c42418] {
  width: 1.17188rem;
  height: 0.98438rem;
  margin-bottom: 0.20833rem;
}
.ad-login .login-info .logo2[data-v-50c42418] {
  width: 2.22917rem;
  height: 0.21875rem;
  margin: 0.16667rem 0 0.16667rem 0;
}
.ad-login .login-info .bottomContent[data-v-50c42418] {
  color: #15104b;
  width: 4.5625rem;
  border-radius: 16px;
  text-align: center;
}
.ad-login .login-info .bottomContent .items1[data-v-50c42418] {
  width: 3.59375rem;
  height: 0.28646rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  background: #E0E3F2;
  border-radius: 16px;
}
.ad-login .login-info .bottomContent .items2[data-v-50c42418] {
  width: 3.59375rem;
  height: 0.28646rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  background: #E0E3F2;
  border-radius: 16px;
  margin-top: 0.15625rem;
}
.ad-login .login-info .bottomContent .payment_icon[data-v-50c42418] {
  margin-left: 0.15625rem;
  margin-top: 0.05208rem;
}
.ad-login .login-info .bottomContent .tip-text[data-v-50c42418] {
  width: 0.01042rem;
  height: 0.15625rem;
  background: #231D65;
  margin-left: 0.05208rem;
  margin-top: 0.05729rem;
}
.ad-login .login-info .bottomContent .support_methods[data-v-50c42418] {
  font-size: 0.125rem;
  font-weight: 800;
  color: #15104B;
  margin-top: 0.05729rem;
  margin-left: 0.10417rem;
}
.ad-login .login-info .bottomContent .support_methods span[data-v-50c42418] {
  color: #C00A31;
}
.ad-login .login-info .opportunity[data-v-50c42418] {
  font-size: 0.10417rem;
  font-weight: 800;
  color: #15104B;
  margin-top: 0.18229rem;
}
.ad-login .login-info .worldwide[data-v-50c42418] {
  font-size: 0.10417rem;
  font-weight: 800;
  color: #15104B;
  margin-top: 0.07813rem;
}
.ad-login .login-content[data-v-50c42418] {
  width: 2.61979rem;
  height: 3.6875rem;
  margin-top: -6%;
  background: #fff;
  border-radius: 30px;
  padding: 0.10417rem 0.20833rem;
  position: relative;
}
.ad-login .login-content .ny_logo[data-v-50c42418] {
  text-align: center;
  margin-top: 0.07813rem;
}
.ad-login .login-content .ny_logo img[data-v-50c42418] {
  width: 1.04167rem;
  height: 0.36979rem;
}
.ad-login .login-content .login-head[data-v-50c42418] {
  font-size: 0.125rem;
  color: #433759;
  font-weight: 400;
  margin-top: 0.15625rem;
  text-align: center;
}
.ad-login .login-content .payment_solution[data-v-50c42418] {
  font-size: 0.125rem;
  color: #433759;
  font-weight: 400;
  text-align: center;
  margin-top: 0.07813rem;
  margin-bottom: 0.20833rem;
  font-family: "Regular";
}
.ad-login .login-content .forget[data-v-50c42418] {
  width: 100%;
  text-align: right;
  position: absolute;
  top: 0.22917rem;
}
.ad-login .login-content .option[data-v-50c42418] {
  width: 2.1875rem;
  height: 0.52083rem;
  margin: 0 auto 0.05208rem;
  padding-left: 0.01563rem;
}
.ad-login .login-content .option[data-v-50c42418] .el-input__inner {
  border-radius: 50px;
  font-family: "Regular";
  width: 100%;
  height: 0.21875rem;
  border: 1px solid #cccccc;
}
.ad-login .login-content .login-bottom[data-v-50c42418] {
  margin-top: 0.41667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.ad-login .login-content .login-bottom .el-button[data-v-50c42418] {
  width: 1.17708rem;
  height: 0.22917rem;
  background: #15104b;
  font-size: 0.08333rem;
  margin-bottom: 0.04167rem;
  color: #ffffff;
  border-radius: 30px;
  margin-left: 0.05208rem;
}
.ad-login .login-content .login-bottom .small[data-v-50c42418] {
  font-size: 0.07292rem;
  color: #999999;
}
.ad-login .login-content .demo-ruleForm[data-v-50c42418] .el-form-item {
  margin-bottom: 0 !important;
}
.ad-login .login-content .demo-ruleForm[data-v-50c42418] .el-form-item .el-form-item__content {
  margin-left: 0 !important;
}