.searchbox_span[data-v-c89a1480] {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-size: 0.07292rem;
}
.searchbox[data-v-c89a1480] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 0.5rem;
  padding: 0 0.20833rem;
  margin-bottom: 0.10417rem;
  box-shadow: 0 0 0.10417rem 0 rgba(55, 55, 89, 0.1);
  background: #ffffff;
}
.searchbox .myselect[data-v-c89a1480] {
  margin-left: 0.10417rem;
  font-family: Regular;
}
.searchbox .myselect[data-v-c89a1480] .el-input input {
  border-radius: 25px !important;
}
.searchbox .Button[data-v-c89a1480] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.searchbox .Button .btn1[data-v-c89a1480] {
  margin-right: 0.10417rem;
}