
.particulars[data-v-6089afa8] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
}
.activeName[data-v-6089afa8] {
  width: 6.875rem;
}
.Winners_box[data-v-6089afa8] {
  position: relative;
}
.bigbox[data-v-6089afa8] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.box[data-v-6089afa8] {
  margin-top: 0.11458rem;
  margin-left: 0.16667rem;
 /* width: 290px;
  height: 380px;
  background: #f6f6fa;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);*/
  border-radius: 10px;
  position: relative;
}
.box[data-v-6089afa8]:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}
.box .images[data-v-6089afa8] {
  width: 1.51042rem;
  height: 1.51042rem;
  text-align: center;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.images img[data-v-6089afa8] {
  width: 1.51042rem;
  height: 1.51042rem;
  margin-top: 0.05208rem;
  border-radius: 8px;
  object-fit: cover;
	object-position: left top;
	-webkit-transition: 1s;
	transition: 1s;
}
.images[data-v-6089afa8] .el-image {
  width: 100%;
  height: 100%;
  margin-top: 0.05208rem;
  border-radius: 8px;
}
.box .simple[data-v-6089afa8] {
  margin-top: 0.05208rem;
  width: 1.02083rem;
  height: 0.23958rem;
  text-align: left;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  color: #000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.23438rem;
  word-break: normal;
}
/* 分页 */
.pagebox[data-v-6089afa8] {
  width: 6.875rem;
  margin-top: 0.15625rem;
}
.Contents_Exhibition[data-v-6089afa8] {
  position: absolute;
  width: 1.51042rem;
  height: 1.51042rem;
  background: #000;
  opacity: 0.6;
  top: 0.05208rem;
  left: 0;
  border-radius: 8px;
}
.ProductExhibition_Description[data-v-6089afa8] {
  z-index: 999;
  position: absolute;
  bottom: 0.05208rem;
  left: 0.02604rem;
}
.ProductExhibition_Description p[data-v-6089afa8] {
  color: #fff;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.LargeImagePreview[data-v-6089afa8] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background:rgba(0,0,0,0.5);
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  z-index: 999;
}
.LargeImagePreviewImgBox[data-v-6089afa8] {
  width: 6.875rem;
  height: 3.90625rem;
  position: absolute;
  top: 0.26042rem;
  left: 2.60417rem;
}
/*.LargePreviewImg {
  width: 1000px;
  height: 800px;
}*/
.LargePreviewImg img[data-v-6089afa8] {
  width: 5.20833rem;
  height: 4.16667rem;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.CloseImg[data-v-6089afa8] {
  position: absolute;
  top: 0;
  left: 0;
}
.CloseImgBtn[data-v-6089afa8] {
  position: absolute;
  top: 0;
  left: 0.05208rem;
}
.ExhibitionTitle[data-v-6089afa8] {
  text-align: center;
}
.ExhibitionTitle p[data-v-6089afa8] {
  font-size: 0.15625rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.UniqueMoments[data-v-6089afa8] {
  text-align: center;
}
.UniqueMoments p[data-v-6089afa8] {
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.elIconCircleClose[data-v-6089afa8] {
  position: absolute;
  top: 0.15625rem;
  right: 1.97917rem;
}
.elIconCircleClose[data-v-6089afa8] .el-icon-close {
  font-size: 0.15625rem;
  color: #fff !important;
  cursor: pointer;
}
