.img-upload-container[data-v-659fe3c0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 0.05208rem;
}
.img-upload-container .upload-container[data-v-659fe3c0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.img-upload-container .upload-container .title-view[data-v-659fe3c0] {
  padding-right: 0.10417rem;
}
.img-upload-container .upload-container .title-view .title[data-v-659fe3c0] {
  padding-right: 0.02604rem;
  font-size: 0.08333rem;
  color: #606266;
}
.img-upload-container .upload-container .title-view .info-icon[data-v-659fe3c0] {
  color: red;
  font-size: 0.08333rem;
}
.img-upload-container .upload-container .upload-button[data-v-659fe3c0] {
  width: 0.66667rem;
  height: 0.17708rem;
  background: #3f6aff;
  border: none;
}
.img-upload-container .image-upload[data-v-659fe3c0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.img-upload-container .image-upload .image-upload-tips[data-v-659fe3c0] {
  font-size: 0.06771rem;
  color: red;
  padding-right: 0.05208rem;
}
.img-upload-container .image-upload .button[data-v-659fe3c0] {
  color: red;
  font-size: 0.0625rem;
}
.img-upload-container .image-card[data-v-659fe3c0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  gap: 0.05208rem;
  padding-top: 0.05208rem;
}
.img-upload-container .image-card .image-container[data-v-659fe3c0] {
  position: relative;
}
.img-upload-container .image-card .img[data-v-659fe3c0] {
  width: 0.41667rem;
  height: 0.41667rem;
  border-radius: 6px;
  border: 1px solid #ececec;
  cursor: pointer;
}
.img-upload-container .image-card .actions[data-v-659fe3c0] {
  width: 0.41667rem;
  height: 0.41667rem;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 6px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.img-upload-container .image-card .icon-space[data-v-659fe3c0] {
  margin-left: 0.04167rem;
}
.img-upload-container .image-card .icon-class[data-v-659fe3c0] {
  font-size: 0.10417rem;
  color: white;
  cursor: pointer;
}
.img-upload-container .image-card .image-container:hover .actions[data-v-659fe3c0] {
  opacity: 1;
}
.example-image[data-v-659fe3c0] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  gap: 0.08333rem;
}
.example-image .img[data-v-659fe3c0] {
  width: 0.625rem;
  height: 0.625rem;
}