.particulars[data-v-3d56bb21] {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
  font-family: "Regular";
}
.activeName[data-v-3d56bb21] {
  width: 6.875rem;
}
.winners_box[data-v-3d56bb21] {
  position: relative;
}
.bigbox[data-v-3d56bb21] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.box[data-v-3d56bb21] {
  margin: 0.11458rem 0 0 0.16667rem;
  border-radius: 10px;
  position: relative;
  width: 1.45833rem;
  height: 1.82292rem;
  background: #f6f6fa;
}
.images[data-v-3d56bb21] {
  width: 1.14583rem;
  height: 1.14583rem;
  margin: 0.10417rem 0 0 0.15625rem;
}
.contents_exhibition[data-v-3d56bb21] {
  position: absolute;
  width: 1.14583rem;
  height: 1.14583rem;
  background: rgba(0, 0, 0, 0.6);
  top: 0.10417rem;
  left: 0.15625rem;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 0.05208rem;
}
.contents_exhibition .custom_controls .video_player_icon[data-v-3d56bb21] {
  position: absolute;
  top: 0.41667rem;
  left: 0.46875rem;
  border: none;
}
.contents_exhibition .custom_controls .video_player_icon[data-v-3d56bb21] .el-icon-video-play {
  font-size: 0.20833rem;
  color: #fff;
}
.contents_exhibition .custom_controls .video_player_icon[data-v-3d56bb21] .el-icon-video-pause {
  font-size: 0.20833rem;
  color: #fff;
}
.contents_exhibition .custom_controls[data-v-3d56bb21] .el-icon-full-screen {
  font-size: 0.10417rem;
  color: #fff;
  position: absolute;
  right: 0.05208rem;
  top: 0.05208rem;
}
.productExhibition_description p[data-v-3d56bb21] {
  color: #fff;
  font-size: 0.07292rem;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.video_title p[data-v-3d56bb21] {
  font-size: 0.07292rem;
  margin: 0.05208rem 0 0 0.15625rem;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  width: 1.14583rem;
}
.video_time[data-v-3d56bb21] {
  text-align: right;
  font-size: 0.07292rem;
  color: #999999;
  margin-top: 0.07813rem;
  margin-right: 0.15625rem;
}
.Filter[data-v-3d56bb21] {
  width: 6.35417rem;
  height: 0.36458rem;
  background: #f9fafb;
  box-shadow: 0 0 0.10417rem rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 0.36458rem;
  margin-left: 0.15625rem;
}
[data-v-3d56bb21] .input-with-select {
  width: 4.94792rem;
  margin-left: 0.15625rem;
  border-radius: 50px;
  margin-left: 30px;
}
[data-v-3d56bb21] .el-input__inner {
  border: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #eff1f3;
}
[data-v-3d56bb21] .Filter .el-input-group__prepend {
  border: 0;
  background-color: #f1f1f2;
  height: 0.20833rem;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
[data-v-3d56bb21] .Filter .el-input-group > .el-input__inner {
  border: 0;
  background-color: #f1f1f2;
  color: #000;
  text-align: center;
  padding-right: 0.52083rem;
  height: 0.20833rem;
}
.search[data-v-3d56bb21] {
  width: 0.75521rem;
  height: 0.20833rem;
  background: #15104b;
  border-radius: 50px;
  color: #fff;
  margin-left: 0.15625rem;
  border: 1px solid #f0f3fb;
  font-size: 0.08333rem;
}
.search[data-v-3d56bb21]:hover {
  background: #3c3772;
}
.pagebox[data-v-3d56bb21] {
  width: 6.875rem;
  margin-top: 0.15625rem;
}