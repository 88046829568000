.member-tips[data-v-44516c78] .tips-dialog.el-dialog__wrapper {
  z-index: 100010 !important;
}
.member-tips[data-v-44516c78] .tips-dialog .el-dialog {
  background: none;
}
.member-tips[data-v-44516c78] .tips-dialog .el-dialog .el-dialog__header {
  display: none;
}
.member-tips[data-v-44516c78] .tips-dialog .el-dialog .tips-wrap {
  padding: 0.25rem 0.20833rem;
  font-size: 0.10417rem;
  text-align: center;
}
.member-tips[data-v-44516c78] .tips-dialog .el-dialog .dialog-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 0.15625rem;
}
.member-tips[data-v-44516c78] .tips-dialog .el-dialog .footer-btn {
  width: 1.04167rem;
  height: 0.21875rem;
  border-radius: 30px;
  font-size: 0.08333rem;
}
.member-tips[data-v-44516c78] .tips-dialog .el-dialog .members_video {
  font-size: 0.20833rem;
  color: #fff;
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.member-tips[data-v-44516c78] .tips-dialog .el-dialog .become_video {
  font-size: 0.14583rem;
  color: #fff;
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 0.15625rem;
}