@charset "UTF-8";
[data-v-4b8079b3] .el-tabs {
  border-radius: 10px;
}
.particulars[data-v-4b8079b3] {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 6.875rem;
  border-radius: 10px;
  font-family: "Regular";
}
.activeName[data-v-4b8079b3] {
  width: 7.29167rem;
  border-radius: 10px;
  line-height: 0.10417rem;
  position: relative;
  padding-bottom: 0.16667rem;
  margin-top: 0.15625rem;
  font-family: "Regular";
}
[data-v-4b8079b3] .el-tabs__nav-scroll {
  height: 0.41667rem;
  padding: 0.10417rem 0;
  background: -webkit-linear-gradient(bottom, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  font-family: "Regular";
}
[data-v-4b8079b3] .el-tabs__nav {
  margin-left: 0.16667rem;
  font-family: "Regular";
}
.activeName .Inventorylist[data-v-4b8079b3] {
  position: absolute;
  top: 0.10417rem;
  right: 0.10417rem;
  display: inline-block;
  width: 0.9375rem;
  height: 0.20833rem;
  border-radius: 50px;
  color: #3F6AFF;
  font-size: 0.08333rem;
  border: 2px solid #3F6AFF;
  z-index: 9999999;
}
.activeName .Inventorylist[data-v-4b8079b3]:hover {
  background: #3f6aff;
  color: #fff;
}
.activeName .InventorylistClose[data-v-4b8079b3] {
  position: absolute;
  top: 0.10417rem;
  right: 1.30208rem;
  display: inline-block;
  width: 0.9375rem;
  height: 0.20833rem;
  border-radius: 50px;
  color: #3F6AFF;
  font-size: 0.08333rem;
  border: 2px solid #3F6AFF;
}
.activeName .InventorylistClose[data-v-4b8079b3]:hover {
  background: #3f6aff;
  color: #fff;
}
[data-v-4b8079b3] .el-tabs__item.is-active {
  font-size: 0.08333rem;
  color: #6788ff;
  font-family: "Regular";
}
.activeName .first .paging[data-v-4b8079b3] {
  padding-top: 0.96875rem;
  padding-right: 0.18229rem;
  padding-left: 0.16667rem;
}

/* 标签页内容区的总长度 */
[data-v-4b8079b3] .activeName .el-tabs__content {
  background-color: #fff;
  font-family: "Regular";
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.pagebox[data-v-4b8079b3] {
  width: 100%;
  height: 0.45833rem;
  padding-bottom: 0.02083rem;
}
.pagebox .el-pagination[data-v-4b8079b3] {
  float: left;
  margin-top: 0.13542rem;
}
.page_sign[data-v-4b8079b3] {
  height: 0.14583rem;
  line-height: 0.14583rem;
  color: #585858;
  font-size: 0.08333rem;
  float: right;
  margin-top: 0.14583rem;
  padding-right: 0.18229rem;
}
.page_sign input[data-v-4b8079b3] {
  width: 0.23438rem;
  height: 0.14583rem;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  margin-left: 0.07813rem;
}
.page_sign span[data-v-4b8079b3] {
  display: inline-block;
  cursor: pointer;
}
[data-v-4b8079b3] .el-table th,
.el-table tr[data-v-4b8079b3] {
  height: 0.36979rem;
}
[data-v-4b8079b3] .el-table__row {
  height: 0.44792rem !important;
}
[data-v-4b8079b3] .el-pagination .el-select .el-input .el-input__inner {
  border-radius: 20px;
  height: 0.18229rem;
  line-height: 0.18229rem;
}
[data-v-4b8079b3] .el-table__expanded-cell[class*=cell] {
  width: 6.875rem !important;
  padding-left: 1.53125rem !important;
  padding-top: 0.125rem !important;
  padding-bottom: 0.0625rem !important;
}
.activeName .particulars1[data-v-4b8079b3] {
  height: 0.47917rem;
  margin-bottom: 0.125rem;
  width: 4.16667rem;
}
[data-v-4b8079b3] .el-tabs__header {
  margin: 0 !important;
  background-color: #fff;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
[data-v-4b8079b3] .status.el-button.el-button--primary {
  background: rgba(191, 204, 249, 0.15);
  color: #6788ff;
  border: none;
}
[data-v-4b8079b3] .status.el-button.el-button--primary .seven:hover {
  background: #f9f9fa;
  color: #fff;
}
[data-v-4b8079b3] .status.el-button--primary.el-button:hover {
  background: #e9efff;
  color: #6788ff;
}

/* 标签页下划线 */
[data-v-4b8079b3] .el-tabs__active-bar {
  height: 0.01563rem !important;
  background: #6788ff;
}
.el-table[data-v-4b8079b3]::before {
  background: #dbdbe4;
}
[data-v-4b8079b3] .el-table thead {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Regular";
}
[data-v-4b8079b3] .el-table {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Regular";
}

/* 新增弹框 */
[data-v-4b8079b3] .el-dialog__wrapper {
  height: 5.03125rem;
}
[data-v-4b8079b3] .el-dialog {
  margin-top: 25vh !important;
  width: 4.21875rem !important;
  height: 1.46875rem !important;
  background: #ffffff !important;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14) !important;
  border-radius: 21px !important;
  overflow: hidden;
  font-family: "Regular";
}
.Add-Store[data-v-4b8079b3] {
  background-color: #ffffff;
  width: 4.21875rem;
  height: 0.28646rem;
  line-height: 0.28646rem;
  color: #333333;
  padding-left: 0.21875rem;
  font-size: 0.08333rem;
  border-bottom: 1px solid #e4e6ef;
}
[data-v-4b8079b3] .el-dialog__header {
  padding: 0 !important;
  font-family: "Regular";
}
[data-v-4b8079b3] .el-dialog__body {
  padding: 0 !important;
  font-family: "Regular";
}
[data-v-4b8079b3] .el-dialog__headerbtn {
  position: absolute !important;
  top: 0.10417rem !important;
  right: 0.16667rem !important;
  padding: 0 !important;
  background: 0 0 !important;
  border: none !important;
  outline: 0 !important;
  cursor: pointer !important;
  font-size: 0.08333rem !important;
  font-family: "Regular";
}
[data-v-4b8079b3] .el-dialog__headerbtn .el-dialog__close {
  color: #fff !important;
  font-family: "Regular";
}
[data-v-4b8079b3] .el-input__inner:focus {
  border-color: #c0c4cc;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-4b8079b3] .el-tabs__nav-wrap::after {
  background: #eff1f3 !important;
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}
.Filter_box[data-v-4b8079b3] {
  width: 7.29167rem;
  height: 0.33854rem;
  background: #fff;
  border-radius: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.search_ProductName[data-v-4b8079b3] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
[data-v-4b8079b3] .search_ProductName .el-input__inner {
  width: 2.08333rem;
  height: 0.20833rem;
  border-radius: 50px;
  margin-top: 0.05208rem;
  margin-left: 0.52083rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-4b8079b3] .search_ProductName .el-button {
  width: 0.78125rem;
  height: 0.20833rem;
  border-radius: 50px;
  margin-top: 0.05208rem;
  background: #15104b;
  color: #fff;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-4b8079b3] .search_ProductName .el-button:hover {
  background: #3C3772 !important;
  color: #fff;
}
.search_Warehouse[data-v-4b8079b3] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
[data-v-4b8079b3] .search_Warehouse .el-input__inner {
  width: 2.08333rem;
  height: 0.20833rem;
  border-radius: 50px;
  margin-top: 0.05208rem;
  margin-left: 0.52083rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-4b8079b3] .search_Warehouse .el-button:nth-child(1) {
  width: 0.78125rem;
  height: 0.20833rem;
  border-radius: 50px;
  margin-top: 0.05208rem;
  background: #15104b;
  color: #fff;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-4b8079b3] .search_Warehouse .el-button:nth-child(2) {
  width: 0.78125rem;
  height: 0.20833rem;
  border-radius: 50px;
  margin-top: 0.05208rem;
  background: #15104b;
  color: #fff;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.13021rem;
}
[data-v-4b8079b3] .search_Warehouse .el-button:nth-child(2):hover {
  background: #3C3772;
  color: #fff;
}
[data-v-4b8079b3] .myselect .el-scrollbar {
  width: 1.30208rem;
}
[data-v-4b8079b3] .search_Warehouse .el-select-dropdown {
  width: 1.30208rem;
}