.dialog-wrap[data-v-4367db4b] {
  padding: 0 0.26042rem 0 0.15625rem;
}
.dialog-footer[data-v-4367db4b] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.dialog-footer .wid200[data-v-4367db4b] {
  width: 1.04167rem;
}
.dialog-footer .cancelBtn[data-v-4367db4b] {
  background-color: #fff;
  border-radius: 25px;
  color: #15104b;
  height: 0.20833rem;
  font-weight: 400;
  cursor: pointer;
  font-size: 0.08333rem;
  text-align: center;
  margin-right: 0.20833rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.dialog-footer .cancelBtn[data-v-4367db4b]:hover {
  opacity: 0.9;
}