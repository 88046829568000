
.container[data-v-1cc39212] {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.loading[data-v-1cc39212] {
  width: 2.04688rem;
  height: 0.36458rem;
  margin-bottom: 0.20833rem;
}
.loading_text[data-v-1cc39212] {
  width: 100%;
  text-align: center;
  font-size: 0.15625rem;
  color: #aaaaaa;
  line-height: 0.17708rem;
}
