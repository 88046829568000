@charset "UTF-8";
.particulars[data-v-19061ae4] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.particulars .main[data-v-19061ae4] {
  width: 7.29167rem;
  padding: 0 0 0.16667rem;
}
.particulars .searchbox[data-v-19061ae4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  height: 0.39063rem;
  background: #ffffff;
  box-shadow: 0 0 0.05208rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-left: 0.10417rem;
  margin-bottom: 0.10417rem;
}
.particulars .searchbox .search_input[data-v-19061ae4] {
  display: block;
  width: 3.38542rem;
  height: 0.20833rem;
  background: #f1f2f4;
  border-radius: 30px;
  margin-right: 0.10417rem;
}
.particulars .searchbox .search_input .search_icon[data-v-19061ae4] {
  display: inline-block;
  margin-top: 0.07813rem;
  margin-right: 0.125rem;
  margin-left: 0.125rem;
}
.particulars .searchbox .search_input input[data-v-19061ae4] {
  background: transparent !important;
  width: calc(100% - 0.36458rem);
  font-size: 0.08333rem;
  color: #585858;
  padding-right: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.particulars .searchbox .topbox div[data-v-19061ae4] {
  min-width: 0.52083rem;
  height: 0.20833rem;
  margin-right: 0.10417rem;
}
.particulars .searchbox .topbox .Synchronize a[data-v-19061ae4] {
  color: #fff;
  font-size: 0.08333rem;
}
.particulars .PSwords[data-v-19061ae4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  height: 0.3125rem;
  background: #d3dfff;
  border-radius: 10px;
  padding-left: 0.10417rem;
  margin-bottom: 0.10417rem;
}
.particulars .PSwords p[data-v-19061ae4] {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.particulars .records[data-v-19061ae4] {
  width: 100%;
  border-radius: 10px !important;
  overflow: hidden;
  position: relative;
}
.particulars .productDetails[data-v-19061ae4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.particulars .productDetails .productImg[data-v-19061ae4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  /* 将 .productImg 设置为 Flexbox 布局 */
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.particulars .productDetails .productImg img[data-v-19061ae4] {
  width: 0.52083rem;
  height: 0.52083rem;
  object-fit: contain;
}
.particulars .productDetails .productAttribute[data-v-19061ae4] {
  font-size: 0.0625rem;
  margin-left: 0.07813rem;
}
.particulars .productDetails .productAttribute p[data-v-19061ae4]:nth-child(1) {
  color: #3f6aff;
  font-size: 0.0625rem;
}
.particulars .ViewOrderDetailsBtn .el-button[data-v-19061ae4] {
  color: #bc0c34;
  font-size: 0.08333rem;
}
.particulars .FileQuestionBtn[data-v-19061ae4] {
  margin-top: 0.10417rem;
}
.particulars .FileQuestionBtn .el-button[data-v-19061ae4] {
  width: 0.65104rem;
  height: 0.20833rem;
  background: #15104b;
  color: #fff;
  border-radius: 50px;
  font-size: 0.07292rem;
}
[data-v-19061ae4] .mydialog {
  width: 3.125rem;
  height: auto;
}
[data-v-19061ae4] .mydialog .line {
  width: 100%;
  height: 0.00521rem;
  background: #e5e5e5;
  margin-top: -0.10417rem;
}
[data-v-19061ae4] .mydialog .OperationFailed {
  font-size: 0.09375rem;
  font-weight: 400;
  margin-top: 0.10417rem;
  color: #000;
}
[data-v-19061ae4] .mydialog .NoticeClick {
  font-size: 0.09375rem;
  font-weight: 400;
  margin-top: 0.10417rem;
  word-break: break-word;
}
[data-v-19061ae4] .mydialog .NoticeClick span:nth-child(1) {
  font-size: 0.10417rem;
  color: #999999;
}
[data-v-19061ae4] .mydialog .NoticeClick span:nth-child(2) {
  color: #999999;
}
[data-v-19061ae4] .mydialog .FunctionButton {
  text-align: center;
  margin-top: 0.20833rem;
}
[data-v-19061ae4] .mydialog .FunctionButton .el-button:nth-child(1) {
  width: 0.83333rem;
  border: 1px solid #15104b;
  color: #15104b;
  background: #fff;
  font-size: 0.08333rem;
  font-weight: 600;
}
[data-v-19061ae4] .mydialog .FunctionButton .el-button:nth-child(2) {
  width: 0.83333rem;
  border: 1px solid #15104b;
  color: #fff;
  background: #15104b;
  font-size: 0.08333rem;
  font-weight: 600;
  margin-left: 0.15625rem;
}
[data-v-19061ae4] .records .el-tabs__content {
  background-color: #fff;
  border-radius: 10px;
}