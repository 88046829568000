.select-scene-wrapper[data-v-03488d38] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0.05208rem 0;
}
.select-scene-wrapper .select-scene-header[data-v-03488d38] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.select-scene-wrapper .select-scene-header span[data-v-03488d38] {
  font-size: 0.07292rem;
  margin-right: 0.04167rem;
}
.select-scene-wrapper .help-text[data-v-03488d38] {
  font-size: 0.0625rem;
  color: #666;
  padding-top: 0.02604rem;
}