.agent_box[data-v-36e03f16] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.agent_box .agent_set[data-v-36e03f16] {
  width: 1.97917rem;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  left: calc(60% - 2.08333rem);
  top: calc(50% - 1.5625rem);
  margin-top: 0.83333rem;
  background-color: #5c5a86;
  height: 1.30208rem;
  border-radius: 10px;
  position: relative;
}
.agent_box .agent_top p:nth-child(1) img[data-v-36e03f16] {
  margin-top: 0.33854rem;
  width: 1.79688rem;
  margin-left: 0.07813rem;
}
.agent_box .agent_down[data-v-36e03f16] {
  width: 1.97917rem;
  height: 1.04167rem;
  margin-top: 0.15625rem;
}
.agent_box .agent_down p[data-v-36e03f16]:nth-child(1) {
  text-align: center;
  margin-top: 0.10417rem;
  font-size: 0.10417rem;
  color: #fff;
}
.agent_box .agent_down p[data-v-36e03f16]:nth-child(2) {
  text-align: center;
  font-size: 0.10417rem;
  color: #fff;
}
.agent_box .member_close_btn[data-v-36e03f16] {
  position: absolute;
  top: 0.04167rem;
  right: 0.05208rem;
}
.agent_box .el-icon-remove-outline[data-v-36e03f16] {
  color: #fff;
  font-size: 0.11458rem;
  cursor: pointer;
}