.modal-body[data-v-4de0d476] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  min-height: 2.08333rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: auto;
  position: relative;
  z-index: 99999;
}
.modal-body .cropper-container[data-v-4de0d476] {
  display: block;
  width: 100%;
  min-height: 2.08333rem;
  max-height: 2.08333rem;
  overflow: hidden;
}
.modal-body .cropper-container img[data-v-4de0d476] {
  display: block;
  max-width: 100%;
}
.modal-body .zoom-container[data-v-4de0d476] {
  position: absolute;
  right: 0.05208rem;
  bottom: 0.05208rem;
}