
.NodataImg[data-v-372af478] {
  padding-bottom: 0.15625rem;
}
.NodataImg img[data-v-372af478] {
  width: 0.88542rem;
  height: 0.88542rem;
  margin-top: 0.41667rem;
}
[data-v-372af478] .el-table th.el-table__cell > .cell {
  word-break: keep-all;
}
[data-v-372af478] .el-range-editor.el-input__inner {
  width: 2.86458rem;
  border-radius: 10px;
}
[data-v-372af478] .mydialog {
  width: 3.90625rem;
  border-radius: 10px;
}
.orderDate[data-v-372af478] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.dialog_time[data-v-372af478] {
  margin-top: 0.15625rem;
  margin-left: 0.10417rem;
}
.dialog_time p[data-v-372af478] {
  font-size: 0.09375rem;
}
.dialog_selectTime[data-v-372af478] {
  margin-top: 0.10417rem;
  margin-left: 0.07813rem;
}
.pageSizeInput[data-v-372af478] .el-input__inner {
  width: 0.52083rem !important;
  height: 0.18229rem;
  margin-top: -0.15625rem;
  border-radius: 20px;
}
.record_page[data-v-372af478] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
}
/* 充值页面 */
.records[data-v-372af478] {
  width: 6.875rem;
  border-radius: 10px !important;
  overflow: hidden;
  padding-bottom: 0.16667rem;
  margin-top: 0.15625rem;
  position: relative;
}
[data-v-372af478] .records .el-tabs__content {
  background-color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.recordsClass[data-v-372af478] {
  width: 6.875rem;
  border-radius: 10px !important;
  overflow: hidden;
  margin: auto;
  height: 0.26042rem;
}
.InputRecords[data-v-372af478] {
  height: 0.26042rem;
}
.InputRecords[data-v-372af478] .el-input__inner {
  height: 0.26042rem;
}
.recharge[data-v-372af478] {
  background-color: #ffffff !important;
  /*border-radius: 10px !important;*/
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.banner[data-v-372af478] {
  margin-left: 0.10417rem;
}
[data-v-372af478] .el-tabs__item {
  font-size: 0.08333rem !important;
  color: #000000;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
[data-v-372af478] .el-tabs__item.is-active {
  color: #6788ff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
[data-v-372af478] .el-tabs__header {
  margin: 0;
  background-color: #fff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
[data-v-372af478] .el-tabs__nav-scroll {
  height: 0.41667rem;
  padding: 0.07813rem 0;
  background: -webkit-linear-gradient(bottom, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
[data-v-372af478] .el-tabs__nav {
  margin-left: 0.16667rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* 标签页下划线 */
[data-v-372af478] .el-tabs__active-bar {
  height: 0.01563rem !important;
  background-color: #6788ff;
  border-radius: 3px;
}
[data-v-372af478] .el-tabs .el-tabs--top .el-tabs__item:hover {
  background: #6788ff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* 标签页头部高度 */
[data-v-372af478] .records .recharge .el-table th,[data-v-372af478] .el-table tr {
  height: 0.36458rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

/* 内容区的高度 */
[data-v-372af478] .records .recharge .el-table__row {
  height: 0.41667rem !important;
}
.records .recharge .paging[data-v-372af478] {
  margin-top: 0.05208rem;
  padding-right: 0.18229rem;
  padding-left: 0.16667rem;
}
[data-v-372af478] .records .el-tabs__content {
  border-radius: 0 0 10px !important;
}
.records .recharge .Get1[data-v-372af478] {
  display: inline-block;
  position: absolute;
  bottom: 0.05208rem;
  right: 0.57292rem;
  width: 0.18229rem;
  height: 0.14583rem;
  background: #ffffff;
  border: 1px solid #000000;
  opacity: 0.3;
  border-radius: 5px;
  line-height: 0.14583rem;
  text-align: center;
  margin-right: 0.10938rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.records .recharge .Get2[data-v-372af478] {
  position: absolute;
  bottom: 0.07813rem;
  right: 0.3125rem;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  color: #000000;
  opacity: 0.6;
}
/* 分页切换 */
.pagebox[data-v-372af478] {
  width: 100%;
  height: 0.36458rem;
  padding-bottom: 0.02083rem;
  background-color: #fff;
  border-bottom-left-radius: 10px;
}
.pagebox .el-pagination[data-v-372af478] {
  float: left;
  margin-top: 0.13542rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.page_sign[data-v-372af478] {
  height: 0.14583rem;
  line-height: 0.14583rem;
  color: #585858;
  font-size: 0.08333rem;
  float: right;
  margin-top: 0.14583rem;
  padding-right: 0.18229rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.page_sign input[data-v-372af478] {
  width: 0.23438rem;
  height: 0.14583rem;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.page_sign span[data-v-372af478] {
  display: inline-block;
  margin-right: 0.05208rem;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* Winners搜索*/
.Filter[data-v-372af478] {
  width: 6.875rem;
  height: 0.36458rem;
  background: #f9fafb;
  box-shadow: 0 0 0.10417rem 0 rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 0.36458rem;
  /*margin: auto;
    margin-top: 80px;*/
}
[data-v-372af478] .Filter .input-with-select {
  width: 3.125rem;
  height: 0.15625rem;
  background: #f5f7fa !important;
  border-radius: 50px;
  margin-left: 0.15625rem;
}
[data-v-372af478] .Filter .el-input-group__prepend {
  border: 0;
  background-color: #f1f1f2;
  height: 0.20833rem;
}
[data-v-372af478] .Filter .el-input-group > .el-input__inner {
  border: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  background-color: #f1f1f2;
  color: #000;
  text-align: center;
  padding-right: 0.52083rem;
  height: 0.20833rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
[data-v-372af478] .Filter [class*=' el-icon-'],
[class^='el-icon-'][data-v-372af478] {
  color: #000;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Filter .search[data-v-372af478] {
  display: inline-block;
  width: 0.75521rem;
  height: 0.20833rem;
  background: #15104b;
  border-radius: 50px;
  color: #fff;
  font-size: 0.08333rem;
  line-height: 0.20833rem;
  text-align: center;
  margin-left: 0.15625rem;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  border: 1px solid #f0f3fb;
}
.Filter .search[data-v-372af478]:hover {
  background: #3c3772;
}
.Filter .searchFilter[data-v-372af478] {
  display: inline-block;
  width: 0.75521rem;
  height: 0.20833rem;
  background: #15104b;
  border-radius: 50px;
  color: #fff;
  font-size: 0.08333rem;
  line-height: 0.20833rem;
  text-align: center;
  margin-left: 0.15625rem;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  border: 1px solid #f0f3fb;
}
.Filter .searchFilter[data-v-372af478]:hover {
  background: #3c3772;
}
.BackTop[data-v-372af478] {
  position: fixed;
  bottom: 0;
  right: 0.10417rem;
  cursor: pointer;
}
.BackTop img[data-v-372af478] {
  width: 0.26042rem;
  height: 0.26042rem;
}
.PaymentOrderExport[data-v-372af478] {
  position: absolute;
  top: 0.10417rem;
  right: 0.26042rem;
  border-radius: 50px;
  width: 0.9375rem;
  font-size: 0.08333rem;
  border: 2px solid #3f6aff;
}
.PaymentOrderExport[data-v-372af478]:hover {
  background: #3f6aff !important;
  color: #fff !important;
}
.currency_style[data-v-372af478] {
  position: absolute;
  top: 0.18229rem;
  left: 0.96354rem;
  font-family: 'Regular';
  font-size: 0.07292rem;
  font-weight: 600;
}
