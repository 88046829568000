@charset "UTF-8";
.index-group-box[data-v-449f894a] {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 0.34375rem;
  -webkit-user-select: none;
  user-select: none;
}
.scrollX[data-v-449f894a] {
  width: 0.16667rem;
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.scrollX[data-v-449f894a]:hover {
  cursor: pointer;
  background-color: #65447d;
}
.index-group-boxIn[data-v-449f894a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  scroll-behavior: smooth;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-box-flex: 0;
  -webkit-flex: none;
          flex: none;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
  margin-left: 0.16667rem;
  margin-right: 0.16667rem;
}
.index-group-boxIn[data-v-449f894a]::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
.group-card-n[data-v-449f894a] {
  cursor: pointer;
  margin-right: 0.09375rem;
  width: 0.34375rem;
  min-width: 0.34375rem;
  height: 0.34375rem;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  /* 更简洁的方式合并 border-color 和 border-width */
  box-sizing: border-box;
  /* 保证边框宽度包含在元素总宽度内 */
}
.group-card-n.group-card-s[data-v-449f894a] {
  cursor: pointer;
  margin-right: 0.09375rem;
  width: 0.34375rem;
  min-width: 0.34375rem;
  height: 0.34375rem;
  border-radius: 4px;
  border: 2px solid #15104b;
  /* 更简洁的方式合并 border-color 和 border-width */
  box-sizing: border-box;
  /* 保证边框宽度包含在元素总宽度内 */
}
.left-button[data-v-449f894a] {
  left: 0;
  width: 0.08333rem;
}
.right-button[data-v-449f894a] {
  right: 0;
  width: 0.08333rem;
}
.scrollX[data-v-449f894a]:hover {
  background-color: transparent;
}
.left-button img[data-v-449f894a],
.right-button img[data-v-449f894a] {
  width: 0.08333rem;
  height: 100%;
}