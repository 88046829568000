.flex_center[data-v-ec4d326c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.shopifyContent[data-v-ec4d326c] {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background: url(../img/Upgradetopro.1d280ae0.png) no-repeat;
  background-size: cover;
  color: #ffffff;
  padding: 0.0625rem 0.125rem 0.125rem 0.125rem;
}
.shopifyContent .title[data-v-ec4d326c] {
  font-size: 0.09375rem;
}
.shopifyContent .diver[data-v-ec4d326c] {
  background: #efefef;
  width: 100%;
  height: 0.00521rem;
  margin-top: 0.05208rem;
}
.shopifyContent .diver1[data-v-ec4d326c] {
  background: #ffffff;
  opacity: 0.3;
}
.shopifyContent .payInfo[data-v-ec4d326c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  padding-left: 0.02604rem;
}
.shopifyContent .payInfo .item[data-v-ec4d326c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  height: 0.26042rem;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  font-size: 0.07292rem;
}
.shopifyContent .payInfo .item .title[data-v-ec4d326c] {
  width: 0.625rem;
  margin-right: 0.05208rem;
  text-align: left;
  font-size: 0.07292rem;
}
.shopifyContent .payInfo .item div[data-v-ec4d326c]:nth-child(2) {
  text-align: right;
  width: calc(100% - 0.625rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shopifyContent .totalSum[data-v-ec4d326c] {
  font-size: 0.16667rem;
  font-weight: 700;
  margin-top: 0.16667rem;
  text-align: center;
  width: 100%;
}