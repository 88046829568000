.form-input-container[data-v-81a2fb62] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  gap: 0.04167rem;
}
.form-input-container[data-v-81a2fb62] .el-input__inner {
  background: transparent;
  padding: 0.00521rem 0.08333rem;
  border-radius: 20px;
  height: 0.20833rem;
}
.form-input-container .design-form-header[data-v-81a2fb62] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.form-input-container .design-form-header span[data-v-81a2fb62] {
  font-size: 0.07292rem;
  margin-right: 0.04167rem;
}
.form-input-container .help-text[data-v-81a2fb62] {
  font-size: 0.0625rem;
  color: #666;
}