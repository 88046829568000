.header[data-v-45b5746c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  min-height: 0.29167rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 0.08333rem;
}
.header .title[data-v-45b5746c] {
  font-size: 0.09375rem;
  font-weight: 500;
  text-align: left;
  color: #15104b;
}
[data-v-45b5746c] .el-form-item__label {
  padding-right: 0.05208rem !important;
  padding-bottom: 0 !important;
}
[data-v-45b5746c] .el-input.is-disabled .el-input__inner {
  color: #606266 !important;
}