.header[data-v-6e24baa3] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  min-height: 0.29167rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 0.125rem;
}
.header .title[data-v-6e24baa3] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  font-size: 0.09375rem;
  font-weight: 500;
  text-align: left;
  color: #15104b;
}
.order-item-list[data-v-6e24baa3] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.125rem 0.08333rem 0;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  margin-top: 0.08333rem;
}
.order-item-list .left-product[data-v-6e24baa3] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 40%;
}
.order-item-list .left-product .product-item[data-v-6e24baa3] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0.08333rem;
}
.order-item-list .left-product .product-item img[data-v-6e24baa3] {
  width: 0.375rem;
  height: 0.375rem;
}
.order-item-list .left-product .product-item .product-info[data-v-6e24baa3] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0.05208rem;
}
.order-item-list .left-product .product-item .product-info .product-name[data-v-6e24baa3] {
  font-size: 0.07292rem;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.order-item-list .left-product .product-item .product-info .product-desc[data-v-6e24baa3] {
  font-size: 0.07292rem;
  font-weight: 400;
  color: #666;
}
.order-item-list .left-product .product-item .product-info .product-desc.exclude[data-v-6e24baa3] {
  text-decoration: line-through;
  color: #c9c9ca;
}
.order-item-list .right-view[data-v-6e24baa3] {
  width: 60%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 0.16667rem;
}
.order-item-list .right-view span[data-v-6e24baa3] {
  font-size: 0.07292rem;
}