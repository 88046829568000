.flex_center[data-v-2defda86], .detail_container .money_container .money_item .inner_item2 .btn[data-v-2defda86], .detail_container .money_container .money_item .inner_item1 .btns .btn[data-v-2defda86], .detail_container .money_container .money_item .inner_item1 .btns[data-v-2defda86], .detail_container .banner[data-v-2defda86] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.detail_container[data-v-2defda86] {
  width: 7.29167rem;
  font-family: "Regular";
}
.detail_container .banner[data-v-2defda86] {
  height: 0.33333rem;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  font-size: 0.10417rem;
  font-weight: 600;
  border-radius: 9px;
  margin-bottom: 0.25rem;
  background: #93df89;
}
.detail_container .money_container[data-v-2defda86] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.detail_container .money_container .money_item[data-v-2defda86] {
  width: 25%;
  padding: 0 0.125rem;
}
.detail_container .money_container .money_item .inner_item[data-v-2defda86] {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 1.04167rem;
  background: #fff;
  border-radius: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0.07292rem 0 0.07292rem 0;
}
.detail_container .money_container .money_item .inner_item1[data-v-2defda86] {
  height: 1.19792rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: transparent;
  margin-top: -0.15625rem;
  padding: 0;
}
.detail_container .money_container .money_item .inner_item1 .save_icon[data-v-2defda86] {
  width: 0.9375rem;
  margin-top: 0.05208rem;
}
.detail_container .money_container .money_item .inner_item1 .img_icon[data-v-2defda86] {
  width: 0.52083rem;
  height: 0.625rem;
  position: absolute;
  right: 0;
  top: 0.19792rem;
}
.detail_container .money_container .money_item .inner_item1 .btns[data-v-2defda86] {
  width: 1.5625rem;
  height: 0.33333rem;
  background: #fff;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-size: 0.09375rem;
  font-weight: 600;
  color: #15104b;
  border-radius: 60px;
  padding: 0.02083rem;
}
.detail_container .money_container .money_item .inner_item1 .btns .btn[data-v-2defda86] {
  cursor: pointer;
  height: 100%;
  width: 49%;
  border-radius: 60px;
}
.detail_container .money_container .money_item .inner_item1 .btns .active[data-v-2defda86] {
  background: #15104b;
  color: #fff;
}
.detail_container .money_container .money_item .inner_item2[data-v-2defda86] {
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.detail_container .money_container .money_item .inner_item2 img[data-v-2defda86] {
  width: 0.54688rem;
  height: 0.54688rem;
  position: absolute;
  left: -0.26042rem;
  top: -0.3125rem;
}
.detail_container .money_container .money_item .inner_item2 .status[data-v-2defda86] {
  font-size: 0.125rem;
  font-weight: 600;
  color: #15104b;
}
.detail_container .money_container .money_item .inner_item2 .sum[data-v-2defda86] {
  font-size: 0.20833rem;
  font-weight: 700;
  color: #15104b;
}
.detail_container .money_container .money_item .inner_item2 .price_text[data-v-2defda86] {
  color: #d70000;
  text-decoration: line-through;
  font-size: 0.08333rem;
}
.detail_container .money_container .money_item .inner_item2 .type[data-v-2defda86] {
  font-size: 0.09375rem;
  font-weight: 400;
  color: #15104b;
}
.detail_container .money_container .money_item .inner_item2 .btn[data-v-2defda86] {
  width: 1.08333rem;
  height: 0.20833rem;
  color: #fff;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.08333rem;
  border: 2px solid #15104b;
  color: #15104b;
  background: #fff;
}
.detail_container .money_container .money_item .inner_item2 .btn1[data-v-2defda86] {
  background: #15104b;
  border: none;
  color: #fff;
}
.detail_container .money_container .money_item .inner_item2 .btn3[data-v-2defda86] {
  background: #eff1f3;
  color: #15104b;
}
.detail_container .money_container .money_item .active_item[data-v-2defda86] {
  border: 2px solid #15104b;
}
.detail_container .data_container[data-v-2defda86] {
  margin-bottom: 0.20833rem;
  margin-top: 0.125rem;
  padding-bottom: 0.08333rem;
  padding-left: 0.08333rem;
  width: 100%;
  background: #fff;
  border-radius: 32px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.detail_container .data_container .data_item[data-v-2defda86] {
  width: 25%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.detail_container .data_container .data_item .data_item_title[data-v-2defda86] {
  padding: 0 0.125rem;
  font-family: "Regular";
  height: 0.10417rem;
  margin-top: 0.10417rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  font-size: 0.09375rem;
  font-weight: 700;
  color: #1b1c27;
  height: 0.20833rem;
  line-height: 0.10938rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.detail_container .data_container .value_item[data-v-2defda86] {
  border-left: 1px solid #d9d9d9;
}
.detail_container .data_container .value_item .data_item_title[data-v-2defda86] {
  font-family: "Regular";
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 0.08333rem;
  font-weight: 400;
  color: #1b1c27;
}
.detail_container .data_container .value_item .data_item_title img[data-v-2defda86] {
  width: 0.10417rem;
  height: 0.10417rem;
}
.red_color1[data-v-2defda86] {
  color: #ff0000;
}
.red_color2[data-v-2defda86] {
  color: #d70000;
}