@charset "UTF-8";
.particulars[data-v-9f89defe] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
  font-family: "Regular";
}
.Filter[data-v-9f89defe] {
  width: 6.875rem;
  height: 0.36458rem;
  background: #f9fafb;
  box-shadow: 0 0 0.10417rem 0 rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 0.36458rem;
  font-family: "Regular";
}
.Filter .paymentOrder_search[data-v-9f89defe] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.Filter .paymentOrder_search .store_style[data-v-9f89defe] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.Filter .paymentOrder_search .store_style .store_word p[data-v-9f89defe] {
  font-size: 0.07292rem;
  margin-left: 0.15625rem;
}
.Filter .paymentOrder_search .store_style .store_select[data-v-9f89defe] {
  margin-left: 0.07813rem;
}
.Filter .paymentOrder_search .store_style .store_select[data-v-9f89defe] .el-input__inner {
  width: 1.35417rem;
  border-radius: 30px;
}
.Filter .paymentOrder_search .orderNo_style[data-v-9f89defe] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.Filter .paymentOrder_search .orderNo_style .orderNo_word p[data-v-9f89defe] {
  font-size: 0.07292rem;
  margin-left: 0.15625rem;
}
.Filter .paymentOrder_search .orderNo_style .orderNo_input[data-v-9f89defe] {
  margin-left: 0.07813rem;
}
.Filter .paymentOrder_search .orderNo_style .orderNo_input[data-v-9f89defe] .el-input__inner {
  width: 1.25rem;
  border-radius: 30px;
}
.Filter .paymentOrder_search .time_style[data-v-9f89defe] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.Filter .paymentOrder_search .time_style .time_selectTime[data-v-9f89defe] {
  margin-left: 0.07813rem;
}
.Filter .paymentOrder_search .time_style[data-v-9f89defe] .el-range-editor.el-input__inner {
  width: 1.5625rem;
  border-radius: 30px;
}
.Filter .paymentOrder_search .time_style[data-v-9f89defe] .el-date-editor .el-range-separator {
  width: 15%;
}
.Filter .paymentOrder_search .time_style .time_word p[data-v-9f89defe] {
  font-size: 0.07292rem;
  margin-left: 0.15625rem;
}
.Filter .search_btn[data-v-9f89defe] {
  margin-left: 0.26042rem;
  margin-top: 0.07813rem;
}
.pod_list[data-v-9f89defe] {
  width: 6.875rem;
  border-radius: 10px !important;
  overflow: hidden;
  padding-bottom: 0.16667rem;
  margin-top: 0.15625rem;
  position: relative;
}
.pagebox[data-v-9f89defe] {
  width: 100%;
  height: 0.36458rem;
  padding-bottom: 0.02083rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.pagebox .page-box[data-v-9f89defe] {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.el-dropdown-menu__item--divided[data-v-9f89defe]:before {
  margin: 0;
}
.source-tab[data-v-9f89defe] {
  width: 100%;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.source-tab[data-v-9f89defe] .el-table th,
.source-tab[data-v-9f89defe] .el-table tr {
  height: 0.36458rem;
  font-weight: 500;
}
.source-tab .content[data-v-9f89defe] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  box-sizing: border-box;
  padding-left: 0.125rem;
}
.source-tab .content img[data-v-9f89defe] {
  width: 0.52083rem;
  height: 0.52083rem;
}
.source-tab .content .description[data-v-9f89defe] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  min-width: 0;
  padding-left: 0.04167rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  font-size: 0.07292rem;
}
.source-tab .content .description .title[data-v-9f89defe] {
  max-height: 0.1875rem;
  width: 100%;
  line-height: 0.09375rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* 定义文本的行数 */
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  color: #444444;
}
.source-tab .content .description .info[data-v-9f89defe] {
  font-size: 0.07292rem;
  color: #999999;
}
.source-tab .content .result[data-v-9f89defe] {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.template_img img[data-v-9f89defe] {
  width: 0.52083rem;
  height: 0.52083rem;
}
.operate_class[data-v-9f89defe] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.operate_class p[data-v-9f89defe] {
  cursor: pointer;
}
.operate_class .tc[data-v-9f89defe] {
  text-align: left;
}
.operate_class .hoverAc[data-v-9f89defe]:hover {
  color: #3f6aff;
}
.hide_btn p[data-v-9f89defe] {
  margin: 0.02604rem 0;
}