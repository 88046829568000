
.IconQuestion[data-v-429e56e8] {
  position: absolute;
  top: 0.01042rem;
  left: 1.35417rem;
}
[data-v-429e56e8] .IconQuestion .el-icon-question {
  font-size: 0.10417rem;
  color: #3F6AFF;
}
.DisplayInfo[data-v-429e56e8] {
  width: 1.875rem;
  height: 0.28646rem;
  background: #93df89;
  border-radius: 10px;
  /*margin-left: 140px;*/
  position: absolute;
  left: 1.48438rem;
  top: -0.07813rem;
  z-index: 9;
}
.DisplayInfo p[data-v-429e56e8]:nth-child(1) {
  font-size: 0.07292rem;
  color: #fff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  padding-top: 0.02604rem;
  padding-left: 0.05208rem;
}
.DisplayInfo p[data-v-429e56e8]:nth-child(2) {
  font-size: 0.07292rem;
  color: #fff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  padding-left: 0.05208rem;
}
.member_title[data-v-429e56e8] {
  width: 0.0625rem;
  height: 0.0625rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOBAMAAADtZjDiAAAALVBMVEUAAACfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoaqfoapgOQNlAAAADnRSTlMAmIGmkqvxuolo93l4dQWiX7wAAABSSURBVAjXY0hlAAExhjgBIMX0nIHlIZDWM2Bg8BNgYHoMZAMFQFygwMnHYLW87xrANOc7BTCt1/0QRDE9BWoB6wVpAesFCcgFAGnGpwzpYLVWANBXEYKHOC/hAAAAAElFTkSuQmCC) no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: right;
  margin-top: 0;
  cursor: pointer;
  /* background: orange; */
  position: absolute;
  top: 0.07813rem;
  right: 0.07813rem;
  color: #fff;
}
.MonthlyRed[data-v-429e56e8] {
  position: absolute;
  top: 0;
  left: 1.17188rem;
  cursor: pointer;
}
.MonthlyRed span img[data-v-429e56e8] {
  width: 0.13542rem;
  height: 0.14063rem;
}
.MonthlyRed span img[data-v-429e56e8]:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
[data-v-429e56e8] .el-progress-bar__outer {
  height: 0.04167rem;
}
[data-v-429e56e8] .el-empty__description p {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.member_box[data-v-429e56e8] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.member_set[data-v-429e56e8] {
  width: 1.97917rem;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  /* overflow: hidden;*/
  left: calc(60% - 2.08333rem);
  top: calc(50% - 2.34375rem);
  margin-top: 0.83333rem;
  /*background-color: #fff;*/
  background-color: #2a293e;
  height: 2.60417rem;
  border-radius: 10px;
  position: relative;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.member_top[data-v-429e56e8] {
  width: 1.19792rem;
  height: 1.14583rem;
  /* background: orangered; */
  margin-left: 0.26042rem;
}
.member_top img[data-v-429e56e8] {
  width: 100%;
  height: 100%;
  margin-top: 0.18229rem;
  margin-left: 0.10417rem;
}
.member_down[data-v-429e56e8] {
  width: 1.97917rem;
  /*height: 200px;
  /* background: orange; */
  margin-top: 0;
}
.member_down p[data-v-429e56e8]:nth-child(1) {
  text-align: center;
  margin-top: 0.26042rem;
  font-size: 0.09375rem;
  /*font-weight: 600;*/
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  color: #fff;
  word-break: normal;
}
.member_down p[data-v-429e56e8]:nth-child(2) {
  text-align: center;
  margin-top: 0.10417rem;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  color: #fff;
  word-break: normal;
}
.Bindstore p[data-v-429e56e8]:nth-child(1) {
  color: #fff;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-top: 0.15625rem;
  text-align: center;
  line-height: 0.20833rem;
  word-break: normal;
}
.member_btn[data-v-429e56e8] {
  margin-left: -0.13021rem;
  width: 0.78125rem;
  height: 0.23438rem;
  line-height: 0.23438rem;
  border-radius: 50px;
  margin-top: 0.10417rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.member_btn[data-v-429e56e8] .el-button:nth-child(1) {
  margin-left: 0.44271rem;
  height: 0.23438rem;
  margin-bottom: -0.78125rem;
  border-radius: 50px;
  font-size: 0.08333rem;
  color: #fff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  background: #93df89;
  border: 1px solid #93df89;
}
.member_btn[data-v-429e56e8] .el-button:nth-child(2) {
  margin-left: 0.15625rem;
  height: 0.23438rem;
  border-radius: 50px;
  font-size: 0.08333rem;
  color: #fff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  background: #93df89;
  border: 1px solid #93df89;
}
.member_btn[data-v-429e56e8] .el-button:nth-child(1):hover {
  background: #bff4b8;
  color: #fff;
}
.member_btn[data-v-429e56e8] .el-button:nth-child(2):hover {
  background: #bff4b8;
  color: #fff;
}
.speed-progress[data-v-429e56e8] {
  width: auto;
  height: 0.3125rem;
}
.progress-fulfilled span[data-v-429e56e8]:nth-child(1) {
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.progress-fulfilled span[data-v-429e56e8]:nth-child(2) {
  font-size: 0.07292rem;
  margin-left: 0.15625rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.progress-fulfilled span[data-v-429e56e8]:nth-child(3) {
  font-size: 0.07292rem;
  margin-left: 0.02604rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.progress-fulfilled span[data-v-429e56e8]:nth-child(4) {
  font-size: 0.07292rem;
  margin-left: 0.03125rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-429e56e8] .el-progress__text {
  font-size: 0.07292rem;
  color: #606266;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.05208rem;
  line-height: 1;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  /* display: none; */
}

/* 搜索 */
.particulars[data-v-429e56e8] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  /*background-image: linear-gradient(to bottom right, #373296, #7665d2);
  background-image: url('../../assets/imgs/Christmas/XueHua.png');*/
  min-width: 6.875rem;
}
.Filter[data-v-429e56e8] {
  width: 6.875rem;
  height: 0.48958rem;
  background: #f9fafb;
  box-shadow: 0 0 0.10417rem 0 rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 0.48958rem;
  margin: auto;
}
[data-v-429e56e8] .Filter .input-with-select {
  width: 2.93229rem;
  height: 0.20833rem;
  background: #f5f7fa !important;
  border-radius: 10px;
  margin-left: 0.15625rem;
}
[data-v-429e56e8] .Filter .el-input-group__prepend {
  border: 0;
  background-color: #f1f1f2;
  height: 0.23958rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-429e56e8] .Filter .el-input-group > .el-input__inner {
  border: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  background-color: #f1f1f2;
  color: #000;
  text-align: center;
  padding-right: 0.52083rem;
  height: 0.23958rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-429e56e8] .Filter [class*=" el-icon-"],
[class^="el-icon-"][data-v-429e56e8] {
  color: #000;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Filter .search[data-v-429e56e8] {
  display: inline-block;
  width: 0.75521rem;
  height: 0.23958rem;
  background: rgba(102, 135, 255, 0.1);
  border-radius: 50px;
  color: rgb(102, 135, 255);
  font-size: 0.08333rem;
  line-height: 0.23958rem;
  text-align: center;
  margin-left: 0.15625rem;
  border: 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Filter .Advanced[data-v-429e56e8] {
  display: inline-block;
  width: 0.75521rem;
  height: 0.23958rem;
  background: rgba(102, 135, 255, 0.1);
  border-radius: 10px;
  color: rgb(102, 135, 255);
  font-size: 0.08333rem;
  line-height: 0.23958rem;
  text-align: center;
  margin-left: 0.15625rem;
  border: 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* <!-- 内容 --> */
.activeName[data-v-429e56e8] {
  width: 6.875rem;
  border-radius: 10px !important;
  position: relative;
  padding-bottom: 0.10417rem;
}
.breadCrumb[data-v-429e56e8] {
  padding-top: 0.3125rem;
  padding-left: 0.10417rem;
}
[data-v-429e56e8] .el-tabs__nav-scroll {
  height: 0.41667rem;
  padding: 0.07813rem 0;
  background: -webkit-linear-gradient(bottom, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}
[data-v-429e56e8] .el-tabs__header {
  margin: 0;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-429e56e8] .el-tabs__nav {
  margin-left: 0.16667rem;
}

/* 提示文案 */
.PromptStatement[data-v-429e56e8] {
  position: absolute;
  top: 0.07813rem;
  left: 2.65625rem;
}
.PromptStatement p[data-v-429e56e8] {
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  line-height: 0.13021rem;
}

/* 订单按钮 */
.activeName .orders[data-v-429e56e8] {
  position: absolute;
  top: 0.10417rem;
  right: 0.16667rem;
  font-family: "Regular";
  font-weight: 500;
  color: #fff;
  border-radius: 50px;
  height: 0.20833rem;
}
.activeName .speed-progress[data-v-429e56e8] {
  position: absolute;
  top: 0.13542rem;
  left: 1.27604rem;
}
[data-v-429e56e8] .el-tabs__item {
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-429e56e8] .el-tabs__item.is-active {
  color: #6788ff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 标签页下划线 */
[data-v-429e56e8] .el-tabs__active-bar {
  height: 0.01563rem !important;
  background-color: #6788ff;
  border-radius: 10px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 标签页的标签头 */
[data-v-429e56e8] .activeName #tab-first {
  padding-left: 0.08333rem !important;
  padding-right: 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 标签页内容区的总长度 */
[data-v-429e56e8] .activeName .el-tabs__content {
  background-color: #fff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
[data-v-429e56e8] .activeName #tab-second {
  padding-left: 0.45833rem !important;
  padding-right: 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-429e56e8] .el-tabs__nav-wrap::after {
  background-color: #eff1f3 !important;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 标签页头部高度 */
[data-v-429e56e8] .el-table th,
.el-table tr[data-v-429e56e8] {
  height: 0.36458rem;
  font-weight: 500;
}
[data-v-429e56e8] .el-table thead {
  font-size: 0.07292rem;
  color: rgb(0, 0, 0, 0.8);
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 内容区的高度 */
[data-v-429e56e8] .el-table__row {
  height: 0.73958rem !important;
}

/* content */
[data-v-429e56e8] .el-table_1_column_1 {
  padding-top: 0.125rem !important;
}
.content img[data-v-429e56e8] {
  position: absolute;
  top: 0.125rem;
  left: 0.58333rem;
  width: 0.52083rem;
  height: 0.52083rem;
  border: none !important;
  border-color: none !important;
  background: none !important;
}
.bakingpackaging[data-v-429e56e8] {
  position: absolute;
  top: 0.17188rem;
  left: 1.32292rem;
  font-size: 0.07292rem;
  color: #19a1e7;
  font-weight: 500;
  cursor: pointer;
  width: auto;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.search_btn[data-v-429e56e8] {
  position: absolute;
  top: 0.18229rem;
  left: 2.34375rem;
  font-size: 0.07292rem;
  color: #19a1e7;
  font-weight: 500;
  cursor: pointer;
  background: url("http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715591496627_copy-picture.png") no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  width: 0.0625rem;
  height: 0.0625rem;
  border: 0;
  padding: 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  z-index: 999;
}
.Blue[data-v-429e56e8] {
  position: absolute;
  top: 0.5625rem;
  left: 1.32292rem;
  font-weight: 500;
  color: rgb(0, 0, 0, 0.6);
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Cancel[data-v-429e56e8] {
  position: absolute;
  top: 0.49479rem;
  left: 2.86979rem;
  font-weight: 400;
  color: rgb(63, 66, 84, 0.6);
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.btn[data-v-429e56e8] {
  width: 0.75521rem;
  height: 0.19792rem;
  font-size: 0.07292rem;
  border-width: 2px;
  border-style: solid;
  word-wrap: break-word;
}
.btn[data-v-429e56e8]:hover{
  background: #3f6aff;
  color: #fff !important;
}
[data-v-429e56e8] .el-table_2_column_4 {
  padding-top: 0.125rem !important;
}
[data-v-429e56e8] .el-table__row .el-table_1_column_2 {
  font-size: 0.07292rem;
  font-weight: 400;
  color: #1d1d1d;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-429e56e8] .el-table .el-table__cell {
  padding-top: 0.14583rem;
}

/* address */
[data-v-429e56e8] .el-table__row .el-table_1_column_3 {
  color: #f81b17 !important;
  font-size: 0.07292rem;
  font-weight: 400;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 详情图 */
.Details-figure[data-v-429e56e8] {
  width: 6.875rem;
  border-radius: 10px;
  margin: auto;
  position: relative;
  background: #f9fafb;
}
.pagebox[data-v-429e56e8] {
  width: 100%;
  height: 0.36458rem;
  padding-bottom: 0.02083rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.pagebox .el-pagination[data-v-429e56e8] {
  float: left;
  margin-top: 0.13542rem;
}
.page_sign[data-v-429e56e8] {
  height: 0.14583rem;
  line-height: 0.14583rem;
  color: #585858;
  font-size: 0.08333rem;
  float: right;
  margin-top: 0.14583rem;
  padding-right: 0.18229rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.page_sign input[data-v-429e56e8] {
  width: 0.23438rem;
  height: 0.14583rem;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.page_sign span[data-v-429e56e8] {
  display: inline-block;
  cursor: pointer;
  margin-right: 0.0625rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 流览图 */

/* <!-- Wrapper for slides 轮播图片 --> */
.focus[data-v-429e56e8] {
  padding-top: 0.26042rem;
  width: 6.875rem;
  height: 4.16667rem;
}
.focus[data-v-429e56e8] {
  padding-top: 0.26042rem;
  width: 6.875rem;
  height: 4.16667rem;
}
.banner .img[data-v-429e56e8] {
  width: 2.80729rem;
  height: 2.80729rem;
  margin-left: 0.33333rem;
  padding-top: 0;
}
.img[data-v-429e56e8] {
  float: left;
}
.banner[data-v-429e56e8] {
  overflow: hidden;
}
.trans[data-v-429e56e8] {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.view[data-v-429e56e8] {
  width: 2.5rem;
  height: 1.57292rem;
  border: 8px solid orangered;
  overflow: hidden;
  position: relative;
}
.indicators[data-v-429e56e8] {
  margin-left: 0.3125rem;
  margin-top: 0.11458rem;
}
.indicators img[data-v-429e56e8] {
  width: 0.47396rem;
  height: 0.46875rem;
  margin-left: 0.05729rem;
}
.con[data-v-429e56e8] {
  width: 10% !important;
  height: 10% !important;
}
.prev[data-v-429e56e8] {
  position: absolute;
  top: 35%;
  left: 0.33333rem;
  background-color: rgb(245 247 250 / 1%);
  border: 0;
}
.next[data-v-429e56e8] {
  position: absolute;
  top: 35%;
  left: 3.00521rem;
  background-color: rgb(245 247 250 / 1%);
  border: 0;
}

/* mobilemouse */
.mobilemouse[data-v-429e56e8] {
  font-size: 0.08333rem;
  font-weight: 400;
  color: rgb(136, 136, 136, 0.6);
  margin-top: 0.10417rem;
  margin-left: 0.66146rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* <!-- 简介 --> */
.synopsis[data-v-429e56e8] {
  position: absolute;
  top: 0.26042rem;
  left: 3.60938rem;
  font-size: 0.10417rem;
  width: 2.92708rem;
}
.intro[data-v-429e56e8] {
  font-size: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  /*font-family: Regular;-webkit-font-smoothing: antialiased;*/
  font-weight: 300;
  color: #191919;
}
.price[data-v-429e56e8] {
  margin-top: 0.1875rem;
}
.price span[data-v-429e56e8]:nth-child(1) {
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #555656;
  line-height: 0.13021rem;
}
.price span[data-v-429e56e8]:nth-child(2) {
  font-size: 0.1875rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #000000;
  line-height: 0.13021rem;
  margin-left: 0.30208rem;
}
.measure[data-v-429e56e8] {
  margin-top: 0.125rem;
}
.measure span[data-v-429e56e8] {
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #555656;
}
[data-v-429e56e8] .measure .el-radio-button__inner {
  display: inline-block;
  width: 0.3125rem;
  height: 0.15625rem;
  line-height: 0.15625rem !important;
  border-radius: 50px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.measure .s[data-v-429e56e8] {
  margin-left: 0.35417rem;
}
.measure .m[data-v-429e56e8] {
  margin-left: 0.125rem;
  display: inline-block;
  width: 0.3125rem;
  height: 0.15625rem;
  line-height: 0.15625rem;
  border-radius: 7px;
  padding: 0;
}
.measure .xl[data-v-429e56e8] {
  margin-left: 0.125rem;
  display: inline-block;
  width: 0.3125rem;
  height: 0.15625rem;
  line-height: 0.15625rem;
  border-radius: 7px;
  padding: 0;
}
.picture[data-v-429e56e8] {
  margin-top: 0.10938rem;
}
.picture span[data-v-429e56e8] {
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #555656;
}
[data-v-429e56e8] .picture .el-radio-button__inner {
  width: 0.42188rem;
  height: 0.42188rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  border-top-width: 0px;
  padding-left: 0;
  /* background-color: #F9FAFB !important; */
  border: 0 !important;
}
.picture .image1[data-v-429e56e8] {
  margin-left: 0.26563rem;
}
.picture .image2[data-v-429e56e8] {
  margin-left: 0.04167rem;
}
.picture .image3[data-v-429e56e8] {
  margin-left: 0.04167rem;
}
.address[data-v-429e56e8] {
  margin-top: 0.10938rem;
}
.address span[data-v-429e56e8] {
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #555656;
}
[data-v-429e56e8] .address .el-radio-button__inner {
  width: 0.39063rem;
  height: 0.15625rem;
  border-radius: 50px !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0.02604rem !important;
}
.address .US[data-v-429e56e8] {
  margin-left: 0.16146rem;
}
.address .GB[data-v-429e56e8] {
  margin-left: 0.07292rem;
}
.address .ES[data-v-429e56e8] {
  margin-left: 0.07292rem;
}

/* storyline */
.storyline[data-v-429e56e8] {
  font-size: 0.10938rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #000000;
  margin-top: 0.21354rem;
  height: 0.44271rem;
  width: 2.83854rem;
  overflow: hidden;
}
.View[data-v-429e56e8] {
  display: inline-block;
  margin-top: 0.39583rem;
  margin-left: 1.16146rem;
  font-size: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #6788ff;
  cursor: pointer;
}
.Product button[data-v-429e56e8] {
  display: inline-block;
  margin-top: 0.36458rem;
  margin-left: 0.80208rem;
  width: 1.30208rem;
  height: 0.33333rem;
  background: -webkit-linear-gradient(318deg, #5e5ee0, #6a8aff);
  background: linear-gradient(132deg, #5e5ee0, #6a8aff);
  border-radius: 10px;
  border: 0;
  font-size: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #ffffff;
}
.product-details[data-v-429e56e8] {
  width: 6.875rem;
  border-radius: 10px;
  margin: auto;
  margin-top: 0.16667rem;
  position: relative;
  background: #f9fafb;
}
.product-details img[data-v-429e56e8] {
  width: 6.16667rem;
  height: 10.88021rem;
  margin: 0.35417rem;
}
.el-tabs__active-bar[data-v-429e56e8] {
  top: 0.15625rem;
  border-radius: 20px !important;
  border: 1px solid blue;
}
.Agent_box[data-v-429e56e8] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Agent_set[data-v-429e56e8] {
  width: 1.97917rem;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  /* overflow: hidden;*/
  left: calc(60% - 2.08333rem);
  top: calc(50% - 1.5625rem);
  margin-top: 0.83333rem;
  /*background-color: #fff;*/
  background-color: #5c5a86;
  height: 1.30208rem;
  border-radius: 10px;
  position: relative;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Agent_top[data-v-429e56e8] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.Agent_top p:nth-child(1) img[data-v-429e56e8] {
  margin-top: 0.33854rem;
  width: 1.79688rem;
  margin-left: 0.07813rem;
}
.Agent_top p[data-v-429e56e8]:nth-child(2) {
  margin-top: 0.33854rem;
  color: #fff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Agent_down[data-v-429e56e8] {
  width: 1.97917rem;
  height: 1.04167rem;
  /* background: orange; */
  margin-top: 0.15625rem;
}
.Agent_down p[data-v-429e56e8]:nth-child(1) {
  text-align: center;
  margin-top: 0.10417rem;
  font-size: 0.10417rem;
  /*font-weight: 600;*/
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  color: #fff;
}
.Agent_down p[data-v-429e56e8]:nth-child(2) {
  text-align: center;
  font-size: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  color: #fff;
}
.member_Close_btn[data-v-429e56e8] {
  position: absolute;
  top: 0.04167rem;
  right: 0.05208rem;
}
[data-v-429e56e8] .member_Close_btn .el-icon-remove-outline {
  color: #fff;
  font-size: 0.11458rem;
  cursor: pointer;
}
