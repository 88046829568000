.design-form-header[data-v-2518a360] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.design-form-header span[data-v-2518a360] {
  font-size: 0.07292rem;
  margin-right: 0.04167rem;
}
.image-wrap[data-v-2518a360] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  gap: 0.05208rem;
}
.help-text[data-v-2518a360] {
  font-size: 0.0625rem;
  color: #666;
}
.upload-container[data-v-2518a360] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 0.33854rem;
  height: 0.33854rem;
  background: transparent;
  cursor: pointer;
  border-radius: 4px;
  border: 1px dashed #ccc;
}
.upload-container[data-v-2518a360]:hover {
  border: 1px solid #ccc;
}
.upload-container img[data-v-2518a360] {
  width: 0.19792rem;
  height: 0.19792rem;
}
.image-preview-container[data-v-2518a360] {
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  width: 0.33854rem;
  height: 0.33854rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
}
.image-preview-container img[data-v-2518a360] {
  width: 93%;
  height: 93%;
  object-fit: contain;
}
.image-preview-container.selected[data-v-2518a360] {
  border: 1px solid #333;
}
.image-preview-container .select-icon[data-v-2518a360] {
  display: none;
  position: absolute;
  top: 0.01563rem;
  right: 0.01563rem;
  font-size: 0.10417rem;
}
.image-preview-container.selected .select-icon[data-v-2518a360] {
  display: block;
}