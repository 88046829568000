
.particulars[data-v-0662c759] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
}
/* 搜索框 */
.Filter[data-v-0662c759] {
  width: 6.875rem;
  height: 0.48958rem;
  background: #f9fafb;
  box-shadow: 0 0 0.10417rem 0 rgba(55, 55, 89, 0.1);
  border-radius: 10px;
  line-height: 0.48958rem;
}
[data-v-0662c759] .Filter .input-with-select {
  width: 2.93229rem;
  height: 0.20833rem;
  background: #f5f7fa !important;
  border-radius: 10px;
  margin-left: 0.15625rem;
}
[data-v-0662c759] .Filter .el-input-group__prepend {
  border: 0;
  background-color: #f1f1f2;
  height: 0.23958rem;
}
[data-v-0662c759] .Filter .el-input-group > .el-input__inner {
  border: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  background-color: #f1f1f2;
  color: #000;
  text-align: center;
  padding-right: 0.52083rem;
  height: 0.23958rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
[data-v-0662c759] .Filter [class*=' el-icon-'],
[class^='el-icon-'][data-v-0662c759] {
  color: #000;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Filter .search[data-v-0662c759] {
  display: inline-block;
  width: 0.75521rem;
  height: 0.23958rem;
  background: rgba(102, 135, 255, 0.1);
  border-radius: 50px;
  color: #6788ff;
  font-size: 0.08333rem;
  line-height: 0.23958rem;
  text-align: center;
  margin-left: 0.15625rem;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Filter .Advanced[data-v-0662c759] {
  display: inline-block;
  width: 0.75521rem;
  height: 0.23958rem;
  background: rgba(102, 135, 255, 0.1);
  border-radius: 10px;
  color: rgb(102, 135, 255);
  font-size: 0.08333rem;
  line-height: 0.23958rem;
  text-align: center;
  margin-left: 0.15625rem;
  border: 0;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* 详情区域 */
.activeName[data-v-0662c759] {
  width: 6.875rem;
  border-radius: 10px !important;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
}
[data-v-0662c759] .el-tabs__nav {
  margin-left: 0.29167rem;
}
[data-v-0662c759] .el-tabs__nav-scroll {
  text-align: center;
  height: 0.41667rem;
  padding: 0.07813rem 0;
  background: -webkit-linear-gradient(bottom, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}
[data-v-0662c759] .el-tabs__item {
  padding: 0 0.20833rem;
  font-size: 0.08333rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
[data-v-0662c759] .el-tabs__item.is-active {
  color: #6788ff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
[data-v-0662c759] .el-tabs__header {
  margin: 0;
}
[data-v-0662c759] .el-table td.is-center:nth-child(1) {
  text-align: left;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
[data-v-0662c759] .el-table_1_column_1 {
  padding-left: 0.05208rem;
}
/* 表头的高度 */
[data-v-0662c759] .el-table__header-wrapper {
  height: 0 !important;
}
/* 表格每一行的高都 */
[data-v-0662c759] .el-table__row {
  height: 0.33333rem !important;
}
[data-v-0662c759] .el-table td,
.el-table th.is-leaf[data-v-0662c759] {
  border-bottom: 1px solid #f2f2f2;
}
/*.content {
  position: relative;
}*/
.dot[data-v-0662c759] {
  width: 0.04167rem;
  height: 0.04167rem;
  background: #ffa800;
  border-radius: 50%;
  position: absolute;
  top: 0.03125rem;
  left: 0.01563rem;
}
.Dot[data-v-0662c759] {
  width: 0.04167rem;
  height: 0.04167rem;
  background: #ffa800;
  border-radius: 50%;
  position: absolute;
  top: 0.03125rem;
  left: 0.01563rem;
}
.Cancel[data-v-0662c759] {
  margin-left: 0.08333rem;
  font-size: 0.07292rem;
  font-weight: 500;
  /* color: rgb(0, 0, 0, 0.3); */
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.unread[data-v-0662c759] {
  margin-left: 0.08333rem;
  font-size: 0.07292rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  color: rgb(0, 0, 0, 0.8);
}
.pagebox[data-v-0662c759] {
  width: 100%;
  height: 0.36458rem;
  padding-bottom: 0.02083rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.pagebox .el-pagination[data-v-0662c759] {
  float: left;
  margin-top: 0.13542rem;
}
.page_sign[data-v-0662c759] {
  height: 0.14583rem;
  line-height: 0.14583rem;
  color: #585858;
  font-size: 0.08333rem;
  float: right;
  margin-top: 0.14583rem;
  padding-right: 0.18229rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.page_sign input[data-v-0662c759] {
  width: 0.23438rem;
  height: 0.14583rem;
  border: 1px solid #858585;
  border-radius: 8px;
  text-align: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.page_sign span[data-v-0662c759] {
  display: inline-block;
  margin-right: 0.05208rem;
  cursor: pointer;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Seconds[data-v-0662c759] {
  margin-left: 0.15625rem;
}
