.header[data-v-41808d61] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  min-height: 0.29167rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 0.08333rem;
}
.header .title[data-v-41808d61] {
  font-size: 0.09375rem;
  font-weight: 500;
  text-align: left;
  color: #15104b;
}
.list-view[data-v-41808d61] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.list-view .list-item[data-v-41808d61] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0.05208rem;
}
.list-view .list-item .left-product[data-v-41808d61] {
  min-width: 40%;
  padding-right: 0.08333rem;
}
.list-view .list-item .quantity-input[data-v-41808d61] {
  width: 0.52083rem;
  margin-right: 0.08333rem;
}