.el-select-dropdown[data-v-847d22dc] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.TaxPage[data-v-847d22dc] {
  width: 7.29167rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.TaxPage .mainPage .balanceNum[data-v-847d22dc] {
  text-align: center;
  width: 100%;
  margin-bottom: 0.15625rem;
  color: #15104B;
  font-size: 0.15625rem;
  font-weight: 500;
}
.TaxPage .mainPage .balanceNum span[data-v-847d22dc] {
  font-size: 0.15625rem;
  font-weight: 500;
}
.TaxPage .mainPage .searchBox[data-v-847d22dc] {
  width: 100%;
  height: 0.46875rem;
  background: #ffffff;
  border-radius: 10px;
  padding: 0 0.125rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 0.15625rem;
}
.TaxPage .mainPage .searchBox .searchItems[data-v-847d22dc] {
  width: calc(100% - 1.04167rem);
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.TaxPage .mainPage .searchBox .searchItems .searchItem[data-v-847d22dc] {
  margin-right: 0.15625rem;
}
.TaxPage .mainPage .searchBox .searchItems .searchItem .label[data-v-847d22dc] {
  margin-right: 0.05208rem;
  color: #15104B;
  font-size: 0.07292rem;
}
.TaxPage .mainPage .searchBox .searchItems .searchItem[data-v-847d22dc] .el-input__inner {
  width: 2.08333rem;
  border-radius: 30px;
}
.TaxPage .mainPage .searchBox .searchItems .searchItem[data-v-847d22dc] .el-range-separator {
  width: 10%;
}
.TaxPage .mainPage .table[data-v-847d22dc] .el-table {
  border-radius: 10px;
}
.TaxPage .mainPage .table[data-v-847d22dc] .el-table thead tr th {
  height: 0.36458rem;
}
.TaxPage .mainPage .table[data-v-847d22dc] .el-table thead tr th .cell {
  color: #15104B;
  font-size: 0.08333rem;
  font-weight: 700;
}
.TaxPage .pagebox[data-v-847d22dc] {
  width: 100%;
  height: 0.36458rem;
  padding-top: 0.125rem;
  margin-bottom: 0.125rem;
}