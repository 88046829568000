.email .StepOneBox[data-v-6ff6a97e] {
  padding-top: 0.10417rem;
  position: relative;
  /*&::after {
    content: '';
    position: absolute;
    left: 30px;
    width: calc(100% - 70px);
    height: 2px;
    background-color: #e5e5e5;
    bottom: -30px;
  }*/
}
.email .StepOneBox .step-box .steps[data-v-6ff6a97e] {
  width: 100%;
  height: 0.23438rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.email .StepOneBox .step-box .steps .step[data-v-6ff6a97e] {
  font-size: 0.08333rem;
  color: #999999;
  font-weight: 600;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.email .StepOneBox .step-box .steps .active[data-v-6ff6a97e] {
  color: #3f6aff;
}
.email .StepOneBox .step-box .steps .step_line[data-v-6ff6a97e] {
  width: 0.64063rem;
  height: 0.05729rem;
  background: url("http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715581469092_step-line.png") no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  margin-left: 0.05208rem;
  margin-right: 0.05208rem;
}
.email .StepOneBox .step-form[data-v-6ff6a97e] {
  margin-top: 0.10417rem;
  padding: 0.10417rem 0.20833rem 0.10417rem 0.15625rem;
}
.email .StepOneBox .step-form .rule-form .option[data-v-6ff6a97e] .el-form-item__error {
  margin-left: 0.10417rem;
}
.email .StepOneBox .step-form .rule-form .option.is-error[data-v-6ff6a97e] .el-input__inner {
  border-color: #bc0c34 !important;
}
.email .StepOneBox .step-form .rule-form .option .option-inner-input[data-v-6ff6a97e] .el-input__inner {
  height: 0.22917rem;
  border-radius: 50px;
  border: 1px solid #cccccc;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.email .StepOneBox .step-form .rule-form .option .option-inner-input[data-v-6ff6a97e] .el-input__inner:focus {
  border-color: #3f6aff;
}
.email .StepOneBox .step-form .rule-form .option .option-inner[data-v-6ff6a97e] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.email .StepOneBox .step-form .rule-form .option .option-inner p[data-v-6ff6a97e] {
  width: 0.10417rem;
  color: #bc0c34;
}
.email .StepOneBox .step-form .rule-form[data-v-6ff6a97e] .el-form-item__error {
  color: #bc0c34;
}
.email .StepOneBox .step-form .rule-form .next-btn[data-v-6ff6a97e] {
  width: 1.19792rem;
  height: 0.22917rem;
  border-radius: 50px;
  font-size: 0.08333rem;
  color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0.10417rem auto;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.email .StepOneBox .step-form .rule-form .next-btn[data-v-6ff6a97e]:hover {
  background: #3c3772 !important;
  color: #fff;
}
.email .StepOneBox .step-form .login[data-v-6ff6a97e] {
  text-align: center;
  margin-top: -0.05208rem;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #999999;
}
.email .StepOneBox .step-form .login .change[data-v-6ff6a97e] {
  color: #3f6aff;
  cursor: pointer;
}
.email .StepOneBox .step-two .order-quantity .order-quantity-des[data-v-6ff6a97e] {
  color: #858585;
  font-size: 0.06771rem;
  font-style: italic;
  margin-top: 0;
  padding-left: 0.20833rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.email .StepOneBox .step-two .order-quantity .how-many[data-v-6ff6a97e] {
  padding: 0 0.20833rem;
}
.email .StepOneBox .step-two .order-quantity .how-many .order-quantity-select[data-v-6ff6a97e] {
  width: 100%;
  height: 0.22917rem;
  margin-top: 0.05208rem;
  font-size: 0.06771rem;
  font-family: Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #cccccc;
  border-radius: 50px;
}
.email .StepOneBox .step-two .order-quantity .how-many .order-quantity-select[data-v-6ff6a97e] .el-input__inner {
  border: none;
  padding-left: 0.07813rem;
}
.email .StepOneBox .step-two .order-quantity .how-many .order-quantity-select .order-quantity-select-option[data-v-6ff6a97e] {
  border: #ccc;
  outline: none;
  font-size: 0.06771rem;
  font-family: Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
}
.email .StepOneBox .step-two .select-box[data-v-6ff6a97e] {
  padding: 0 0.20833rem;
  margin-top: 0.10417rem;
}
.email .StepOneBox .step-two .select-box .select-box-quantity[data-v-6ff6a97e] {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  border-radius: 50px;
  border: 1px solid #666666;
}
.email .StepOneBox .step-two .select-box .select-box-quantity .select[data-v-6ff6a97e] {
  height: 0.22917rem;
  position: relative;
}
.email .StepOneBox .step-two .select-box .select-box-quantity .select[data-v-6ff6a97e]::after {
  content: "";
  position: absolute;
  height: 80%;
  top: 10%;
  right: 0;
  width: 0.00521rem;
  background: #cccccc;
  -webkit-filter: blur(0.0026rem);
          filter: blur(0.0026rem);
}
.email .StepOneBox .step-two .select-box .select-box-quantity .select .select-inner[data-v-6ff6a97e] {
  color: #000;
  height: 0.22917rem;
  padding-left: 0.07813rem;
}
.email .StepOneBox .step-two .select-box .select-box-quantity .select .select-inner[data-v-6ff6a97e] .el-input__inner {
  background: none;
  border-radius: 0px;
  height: 0.22917rem;
  width: 0.52083rem;
}
.email .StepOneBox .step-two .select-box .select-box-quantity .mobile-number[data-v-6ff6a97e] {
  padding-left: 0.05208rem;
  font-size: 0.07292rem;
}
.email .StepOneBox .step-two .select-box .select-box-quantity .icon-info[data-v-6ff6a97e] {
  position: absolute;
  top: 0.05208rem;
  right: -0.15625rem;
  cursor: pointer;
  font-size: 0.10417rem;
}
.email .StepOneBox .step-two .select-box .select-box-quantity .display-info[data-v-6ff6a97e] {
  position: absolute;
  top: 0.20833rem;
  right: -0.15625rem;
  color: #fff;
  z-index: 9999;
  background: #93df89;
  border-radius: 10px;
  padding: 0.03125rem 0.05208rem;
  line-height: 0.09375rem;
}
.email .StepOneBox .step-two .select-box .please-enter-phone[data-v-6ff6a97e] {
  width: auto;
  margin-top: 0.05208rem;
  color: red;
}
.email .StepOneBox .step-two .code-box[data-v-6ff6a97e] {
  padding: 0 0.20833rem;
  margin-top: 0.05208rem;
  position: relative;
}
.email .StepOneBox .step-two .code-box .code-box-group[data-v-6ff6a97e] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.email .StepOneBox .step-two .code-box .code-box-group .code-input .code-input-verifyCode[data-v-6ff6a97e] {
  color: #8b8b8b;
  font-family: Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
}
.email .StepOneBox .step-two .code-box .code-box-group .code-input .code-input-verifyCode[data-v-6ff6a97e] .el-input__inner {
  width: 0.97917rem;
  height: 0.22917rem;
  line-height: 0.22917rem;
  outline: 0;
  border: 1px solid #666666;
  color: #b8b8b8;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  border-radius: 50px;
}
.email .StepOneBox .step-two .code-box .code-box-group .code-send[data-v-6ff6a97e] {
  width: 0.67708rem;
  height: 0.22917rem;
  font-size: 0.08333rem;
  background: #efeff7;
  border-radius: 50px;
  color: #5e5ee0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: 0.05208rem;
}
.email .StepOneBox .step-two .code-box .code-box-group .code-send[data-v-6ff6a97e]:hover {
  background: #f9f9fa;
  color: #5e5ee0;
}
.email .StepOneBox .step-two .code-box .code-box-group .unable-verification[data-v-6ff6a97e] {
  color: #4c8bf5;
  font-size: 0.07292rem;
  cursor: pointer;
  font-style: italic;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  width: 0.67708rem;
  height: 0.22917rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.email .StepOneBox .step-two .code-box .code-box-group .pay-box[data-v-6ff6a97e] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.email .StepOneBox .step-two .code-box .code-box-group .pay-box .ValidationBox[data-v-6ff6a97e] {
  vertical-align: middle;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 1.04167rem;
}
.email .StepOneBox .step-two .code-box .unable-receive-two[data-v-6ff6a97e] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  color: #858585;
  position: absolute;
  top: 0.23438rem;
  font-weight: 600;
}
.email .StepOneBox .step-two .info[data-v-6ff6a97e] {
  padding: 0.05208rem 0.20833rem 0.10417rem 0.20833rem;
  margin: 0.05208rem auto 0.05208rem;
}
.email .StepOneBox .step-two .info[data-v-6ff6a97e] .el-input__inner {
  border-radius: 50px;
  height: 0.23438rem;
  border: 1px solid #cccccc;
}
.email .StepOneBox .step-two .agree[data-v-6ff6a97e] {
  width: 2.29167rem;
  line-height: 1.3;
  font-size: 0.07292rem;
  color: #b8b8b8;
  padding-left: 0.05208rem;
  margin: 0 auto;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  word-break: normal;
}
.email .StepOneBox .step-two .agree a[data-v-6ff6a97e] {
  color: #4c8bf5;
}
.email .StepOneBox .step-two .agree[data-v-6ff6a97e] .el-checkbox__inner {
  -webkit-transform: translateY(-0.01042rem);
          transform: translateY(-0.01042rem);
  display: inline-block;
  width: 0.07292rem;
  height: 0.07292rem;
  margin-right: 0.125rem;
  cursor: pointer;
  border: 1px solid #b2b2b2;
  border-radius: 50%;
  vertical-align: middle;
}
.email .StepOneBox .step-two .step-btn[data-v-6ff6a97e] {
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  padding: 0 0.20833rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 0.10417rem;
}
.email .StepOneBox .step-two .step-btn .last-btn[data-v-6ff6a97e] {
  width: 1.04167rem;
  height: 0.21875rem;
  line-height: 0.21875rem;
  border-radius: 50px;
  font-size: 0.07292rem;
  color: #5e5ee0;
  text-align: center;
  cursor: pointer;
  background: #efeff7;
  border: 1px solid #f9f9fa;
}
.email .StepOneBox .step-two .step-btn .last-btn[data-v-6ff6a97e]:hover {
  background: #f9f9fa;
  color: #000 !important;
  opacity: 0.8;
}
.email .StepOneBox .step-two .step-btn .next-btn[data-v-6ff6a97e] {
  width: 1.04167rem;
  height: 0.22917rem;
  line-height: 0.21875rem;
  border-radius: 50px;
  font-size: 0.07292rem;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.email .StepOneBox .step-two .step-btn .next-btn[data-v-6ff6a97e]:hover {
  background: #3c3772 !important;
  color: #fff;
}
.display-info[data-v-6ff6a97e] {
  font-size: 0.07292rem;
}