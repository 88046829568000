
.loading_text[data-v-9af88104] {
  text-align: center;
  margin-top: 0.10417rem;
}
.loading_text img[data-v-9af88104] {
  width: 1.82292rem;
  height: 1.30208rem;
}
.successful_box[data-v-9af88104] {
  text-align: center;
  margin-top: 0.13021rem;
}
.successful_box p[data-v-9af88104] {
  text-align: center;
  font-size: 0.13542rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.USAdrop_box[data-v-9af88104] {
  text-align: center;
}
.USAdrop_box p[data-v-9af88104] {
  text-align: center;
  font-size: 0.10417rem;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-top: 0.05208rem;
  color: #4d4d4d;
}
.Confirm_btn[data-v-9af88104] {
  text-align: center;
}
.Confirm_btn button[data-v-9af88104] {
  border: 0;
  margin-top: 0.26042rem;
  width: 0.78125rem;
  height: 0.20833rem;
  line-height: 0.20833rem;
  background: #15104b;
  color: #fff;
  border-radius: 50px;
  font-size: 0.08333rem;
}
.Confirm_btn button[data-v-9af88104]:hover {
  background: #3c3772;
  color: #fff;
}
.headerBg_Img[data-v-9af88104] {
  margin-top: 0.26042rem;
  margin-left: 0.41667rem;
}
.headerBg_Img img[data-v-9af88104] {
  width: 0.67708rem;
  height: 0.67708rem;
}
