@charset "UTF-8";
/* 提现弹出框 */
.WithdrawalDialog[data-v-866f5c2c] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.WithdrawalDialog .Withdrawal_content[data-v-866f5c2c] {
  width: 3.125rem;
  height: auto;
  background: #fff;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  position: absolute;
  left: calc(50% - 1.04167rem);
  top: calc(50% - 1.71875rem);
  padding-bottom: 0.16667rem;
}
.Withdrawal_title[data-v-866f5c2c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.Payouts_title[data-v-866f5c2c] {
  margin-top: 0.13021rem;
  margin-left: 0.26042rem;
}
.Payouts_title p[data-v-866f5c2c] {
  font-size: 0.09375rem;
}
.Payouts_icon_close[data-v-866f5c2c] {
  margin-top: 0.13021rem;
  margin-right: 0.26042rem;
}
[data-v-866f5c2c] .Payouts_icon_close .el-icon-close {
  font-size: 0.10417rem;
  cursor: pointer;
}
.line_line[data-v-866f5c2c] {
  width: 100%;
  height: 0.00781rem;
  background: #e5e5e5;
  margin-top: 0.07813rem;
}
.SelectTypeBox[data-v-866f5c2c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-left: 0.26042rem;
  margin-top: 0.15625rem;
}
.walletAccountType[data-v-866f5c2c] {
  margin-top: 0.05208rem;
}
.walletAccountType span[data-v-866f5c2c] {
  font-size: 0.08333rem;
}
.walletAccountType span[data-v-866f5c2c]:nth-child(2) {
  margin-left: 0.05208rem;
}
.selectedOptionType[data-v-866f5c2c] {
  margin-left: 0.28646rem;
  width: 1.5625rem;
}
[data-v-866f5c2c] .selectedOptionType .el-input__inner {
  border-radius: 50px;
  width: 1.5625rem;
}
.walletAccountEmail[data-v-866f5c2c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-left: 0.26042rem;
  margin-top: 0.07813rem;
}
.walletAccountEmailWord[data-v-866f5c2c] {
  margin-top: 0.05208rem;
}
.walletAccountEmailWord span[data-v-866f5c2c] {
  font-size: 0.08333rem;
}
.walletAccountEmailWord span[data-v-866f5c2c]:nth-child(2) {
  margin-left: 0.05208rem;
}
[data-v-866f5c2c] .walletPleaseEnterStoreNameIpt .el-input__inner {
  width: 1.5625rem;
  border-radius: 50px;
  margin-left: 0.20833rem;
}
.walletAmount[data-v-866f5c2c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-left: 0.26042rem;
  margin-top: 0.07813rem;
}
.walletAmountWord[data-v-866f5c2c] {
  margin-top: 0.05208rem;
}
.walletAmountWord p[data-v-866f5c2c] {
  font-size: 0.08333rem;
}
[data-v-866f5c2c] .walletAmountIpt .el-input__inner {
  width: 1.5625rem;
  border-radius: 50px;
  margin-left: 0.52083rem;
}
.walletConfirm button[data-v-866f5c2c] {
  width: 1.04167rem;
  height: 0.23438rem;
  font-size: 0.09375rem;
  border-radius: 50px;
  margin-top: 0.20833rem;
  margin-left: 0.9375rem;
}
.walletConfirmTwo button[data-v-866f5c2c] {
  width: 1.04167rem;
  height: 0.23438rem;
  font-size: 0.09375rem;
  border-radius: 50px;
  margin-top: 0.20833rem;
  margin-left: 1.04167rem;
}
.walletAccountNameWord[data-v-866f5c2c] {
  margin-top: 0.05208rem;
}
.walletAccountNameWord p[data-v-866f5c2c] {
  font-size: 0.08333rem;
}
[data-v-866f5c2c] .walletAccountName .el-input__inner {
  width: 1.5625rem;
  border-radius: 50px;
  margin-left: 0.26042rem;
}
[data-v-866f5c2c] .walletAccountIpt .el-input__inner {
  width: 1.5625rem;
  border-radius: 50px;
  margin-left: 0.52083rem;
}
.walletSwiftBicWord[data-v-866f5c2c] {
  margin-top: 0.05208rem;
}
.walletSwiftBicWord p[data-v-866f5c2c] {
  font-size: 0.08333rem;
}
[data-v-866f5c2c] .walletSwiftBicIpt .el-input__inner {
  width: 1.5625rem;
  border-radius: 50px;
  margin-left: 0.44271rem;
}
.walletCountryWord[data-v-866f5c2c] {
  margin-top: 0.05208rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.walletCountryWord p[data-v-866f5c2c] {
  font-size: 0.08333rem;
}
.walletCountryWordPersonalCorporate[data-v-866f5c2c] {
  margin-left: 0.05208rem;
}
[data-v-866f5c2c] .walletCountryIpt .el-input__inner {
  width: 1.5625rem;
  border-radius: 50px;
  margin-left: 0.54688rem;
}
.walletAmountWords[data-v-866f5c2c] {
  margin-top: 0.05208rem;
}
.walletAmountWords p[data-v-866f5c2c] {
  font-size: 0.08333rem;
}
[data-v-866f5c2c] .walletAmountIpts .el-input__inner {
  width: 1.5625rem;
  border-radius: 50px;
  margin-left: 0.54688rem;
}
[data-v-866f5c2c] .walletPersonalSelect .el-input__inner {
  width: 1.5625rem;
  border-radius: 50px;
  margin-left: 0.13021rem;
}