
.particulars[data-v-2f7a2aa6] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
}
.activeName[data-v-2f7a2aa6] {
  width: 6.875rem;
  position: relative;
}
.FAQTitle[data-v-2f7a2aa6] {
  text-align: center;
  margin-top: 0.20833rem;
}
.FAQTitle p[data-v-2f7a2aa6] {
  font-size: 0.15625rem;
  font-weight: 600;
  color: #21224e;
  font-family: "Regular";
}
.FAQTitleDetails[data-v-2f7a2aa6] {
  width: 6.875rem;
  height: auto;
  background: #fff;
  border-radius: 10px;
  margin-top: 0.07813rem;
}
.FAQTitleDetails[data-v-2f7a2aa6] .el-collapse {
  width: 6.66667rem;
  height: auto;
  margin-left: 0.10417rem;
}
.FAQTitleDetails[data-v-2f7a2aa6] .el-collapse-item__header {
  font-size: 0.08333rem;
  font-family: "Regular";
  font-weight: 600;
}
.withUSAdrop_words[data-v-2f7a2aa6] {
  font-size: 0.07292rem;
  font-family: "Regular";
  line-height: 0.18229rem;
}
