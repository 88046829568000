
.particulars[data-v-68c67f90] {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
}
.activeName[data-v-68c67f90] {
  width: 6.875rem;
  border-radius: 10px;
  line-height: 0.10417rem;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
}
[data-v-68c67f90] .el-tabs__nav-scroll {
  height: 0;
}
[data-v-68c67f90] .el-tabs__nav {
  margin-left: 0.16667rem;
}
[data-v-68c67f90] .el-tabs__item.is-active {
  font-size: 0.08333rem;
  color: #6788ff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
}
/* 标签页下划线 */
[data-v-68c67f90] .el-tabs__active-bar {
  height: 0.01563rem !important;
  background: #6788ff;
}
[data-v-68c67f90] .el-tabs__header {
  margin: 0;
}
[data-v-68c67f90] .el-tabs__nav-wrap::after {
  background-color: rgb(255 255 255 / 50%);
}
/* 按钮 */
.activeName .orders[data-v-68c67f90] {
  position: absolute;
  top: 0.10417rem;
  right: 0.16667rem;
  width: 0.92708rem;
  height: 0.20833rem;
  background: -webkit-linear-gradient(318deg, #34c2eb, #69d3e3);
  background: linear-gradient(132deg, #34c2eb, #69d3e3);
  border-radius: 10px;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #ffffff;
  border: 0;
}
.bigbox[data-v-68c67f90] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
/* box */
.box[data-v-68c67f90] {
  margin-top: 0.34375rem;
  margin-left: 0.16667rem;
  width: 2.05729rem;
  height: 1.30208rem;
  background: #f6f6fa;
  box-shadow: 0 0 0.09375rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
}
/* 视频 */
.box .video[data-v-68c67f90] {
  padding-top: 0.26042rem;
  margin-left: 0.125rem;
  border-radius: 10px !important;
}
/* 简介 */
.synopsis[data-v-68c67f90] {
  margin-top: 0.01563rem;
  margin-left: 0.125rem;
  width: 1.78125rem;
  height: 0.26042rem;
  line-height: 0.41667rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #191919;
  line-height: 0.13021rem;
  text-align: left;
  font-size: 0.07292rem;
  display: -webkit-box;
 -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* 分页 */
.pagebox[data-v-68c67f90] {
  width: 6.875rem;
  margin-top: 0.15625rem;
}
