.particulars[data-v-87defef2] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
}
.particulars .main[data-v-87defef2] {
  width: 7.29167rem;
  padding: 0 0 0.16667rem;
}
.particulars .orderTitle span[data-v-87defef2]:nth-child(1) {
  font-size: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.particulars .orderTitle span[data-v-87defef2]:nth-child(2) {
  font-size: 0.10417rem;
  font-weight: 600;
  margin-left: 0.05208rem;
  color: #3f6aff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.particulars .orderTitle span[data-v-87defef2]:nth-child(3) {
  font-size: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.05208rem;
}
.particulars .orderTitle span[data-v-87defef2]:nth-child(4) {
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.05208rem;
}
.particulars .orderTitle span[data-v-87defef2]:nth-child(5) {
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.05208rem;
  color: #ff0000;
}
.particulars .orderTitle button[data-v-87defef2] {
  margin-left: 0.07813rem;
}
.particulars .RecipientInformationBox[data-v-87defef2] {
  width: 5.20833rem;
  height: auto;
  margin-top: 0.15625rem;
  border: 1px solid #e5e5e5;
}
.particulars .RecipientInformationBox .RecipientInformation[data-v-87defef2] {
  width: 100%;
  height: 0.20833rem;
  background: #FFFFFF;
  border-bottom: 1px solid #e5e5e5;
}
.particulars .RecipientInformationBox .RecipientInformation p[data-v-87defef2] {
  color: #3f6aff;
  font-size: 0.08333rem;
  line-height: 0.20833rem;
  margin-left: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.particulars .RecipientInformationBox .AddressOne[data-v-87defef2] {
  width: 100%;
  height: 0.20833rem;
  background: #f4f7ff;
}
.particulars .RecipientInformationBox .AddressOne span[data-v-87defef2] {
  font-size: 0.08333rem;
  line-height: 0.20833rem;
  margin-left: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.particulars .RecipientInformationBox .AddressTwo[data-v-87defef2] {
  width: 100%;
  height: 0.20833rem;
  background: #F4F7FF;
}
.particulars .RecipientInformationBox .AddressTwo span[data-v-87defef2] {
  font-size: 0.08333rem;
  line-height: 0.20833rem;
  margin-left: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.particulars .RecipientInformationBox .ProductName[data-v-87defef2] {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  background: #ffffff;
}
.particulars .RecipientInformationBox .ProductName .SupportTicketProductName[data-v-87defef2] {
  width: 0.78125rem;
  line-height: 0.20833rem;
  margin-left: 0.07813rem;
  font-weight: 600;
  font-size: 0.07292rem;
}
.particulars .RecipientInformationBox .ProductName .detailProductName[data-v-87defef2] {
  margin-left: 0.07813rem;
  font-weight: 600;
  font-size: 0.07292rem;
  margin-top: 0.02604rem;
  line-height: 0.15625rem;
}
.particulars .RecipientInformationBox .TableContentBox[data-v-87defef2] {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.particulars .RecipientInformationBox .TableContentBox .left-main[data-v-87defef2] {
  width: 2.60417rem;
  height: auto;
  border-right: 2px solid #e5e5e5;
}
.particulars .RecipientInformationBox .TableContentBox .left-main .left-content[data-v-87defef2] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.particulars .RecipientInformationBox .TableContentBox .left-main .The-recipient[data-v-87defef2] {
  width: 1.30208rem;
  height: 0.20833rem;
  background: #F4F7FF;
  color: #000;
  font-size: 0.08333rem;
  line-height: 0.20833rem;
  padding-left: 0.07813rem;
  border-bottom: 1px solid #e5e5e5;
}
.particulars .RecipientInformationBox .TableContentBox .left-main .The-content[data-v-87defef2] {
  width: 1.30208rem;
  height: 0.20833rem;
  background: #fff;
  color: #666666;
  font-size: 0.08333rem;
  line-height: 0.20833rem;
  padding-left: 0.07813rem;
  border-bottom: 1px solid #e5e5e5;
}
.particulars .RecipientInformationBox .AddressMain[data-v-87defef2] {
  width: 100%;
  height: auto;
}
.particulars .RecipientInformationBox .AddressMain .AddressOneContent[data-v-87defef2] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  background: #F4F7FF;
  width: 100%;
  height: 0.20833rem;
  line-height: 0.20833rem;
  padding-left: 0.07813rem;
  font-size: 0.08333rem;
}
.particulars .RecipientInformationBox .AddressMain .AddressTwoContent[data-v-87defef2] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  background: #fff;
  width: 100%;
  height: 0.20833rem;
  line-height: 0.20833rem;
  padding-left: 0.07813rem;
  font-size: 0.08333rem;
}
.particulars .RecipientInformationBox .ProductDetailBox[data-v-87defef2] {
  width: 100%;
}
.particulars .RecipientInformationBox .ProductDetail[data-v-87defef2] {
  width: 100%;
  height: 0.20833rem;
  background: #f4f7ff;
}
.particulars .RecipientInformationBox .ProductDetail span[data-v-87defef2]:nth-child(1) {
  color: #3f6aff;
  font-size: 0.08333rem;
  line-height: 0.20833rem;
  margin-left: 0.07813rem;
}
.particulars .RecipientInformationBox .ProductDetail span[data-v-87defef2]:nth-child(2) {
  color: #3f6aff;
  font-size: 0.08333rem;
  line-height: 0.20833rem;
  margin-left: 0.07813rem;
}
.particulars .RecipientInformationBox .ProductDetail span[data-v-87defef2]:nth-child(3) {
  color: #ff0000;
  font-size: 0.08333rem;
  line-height: 0.20833rem;
  margin-left: 0.07813rem;
}
.particulars .RecipientInformationBox .ProductDetail span[data-v-87defef2]:nth-child(4) {
  color: #3f6aff;
  font-size: 0.08333rem;
  line-height: 0.20833rem;
}
.particulars .RecipientInformationBox .ProductSkuBox[data-v-87defef2] {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  background: #f4f7ff;
  border-bottom: 1px solid #ccc;
}
.particulars .RecipientInformationBox .ProductSkuBox .ProductSku[data-v-87defef2] {
  width: 2.60417rem;
  height: 0.26042rem;
  line-height: 0.26042rem;
  font-size: 0.08333rem;
  margin-left: 0.07813rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.particulars .RecipientInformationBox .ProductSkuBox .ProductSkuName[data-v-87defef2] {
  width: 0.78125rem;
}
.particulars .RecipientInformationBox .ProductSkuBox .Quantity[data-v-87defef2] {
  width: 2.60417rem;
  height: 0.26042rem;
  line-height: 0.26042rem;
  font-size: 0.08333rem;
  margin-left: 0.07813rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.particulars .LogisticTransitNo[data-v-87defef2] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: auto;
  background: #F4F7FF;
}
.particulars .LogisticTransitNo .SupportTicketLogisticTransitNoName[data-v-87defef2] {
  height: 0.26042rem;
  line-height: 0.26042rem;
  padding-left: 0.05208rem;
  font-size: 0.08333rem;
}
.particulars .LogisticTransitNo .orderDetailsLogisticTransitNo[data-v-87defef2] {
  height: 0.26042rem;
  line-height: 0.26042rem;
  padding-left: 0.05208rem;
  font-size: 0.08333rem;
}