.particulars[data-v-4ad7e1cd] {
  width: 100%;
  position: relative;
  background: #ffffff;
  min-height: 100vh;
  font-family: "Regular";
}
.activeName[data-v-4ad7e1cd] {
  margin: auto;
  padding-bottom: 0.16667rem;
  width: 6.875rem;
  border-radius: 10px !important;
  position: relative;
  background-color: #fff;
  font-family: "Regular";
}
.Bgrd[data-v-4ad7e1cd] {
  width: 100%;
  height: 0.67708rem;
  position: relative;
  background: -webkit-linear-gradient(318deg, #1b1941, #22224b, #202251);
  background: linear-gradient(132deg, #1b1941, #22224b, #202251);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.guide_FillInYou p[data-v-4ad7e1cd] {
  color: #fff;
  font-size: 0.15625rem;
  font-weight: 700;
  position: absolute;
  left: 2.60417rem;
  top: -0.72917rem;
}
.Contact[data-v-4ad7e1cd] {
  position: absolute;
  top: 0.59896rem;
  left: 1.45833rem;
  font-size: 0.08333rem;
}
.Contact span[data-v-4ad7e1cd]:nth-child(1) {
  color: #4c8bf5;
}
.Contact span[data-v-4ad7e1cd]:nth-child(2) {
  display: block;
  position: absolute;
  top: 0;
  right: -0.15625rem;
  font-size: 0.11458rem;
  color: #4c8bf5;
}
.Contact[data-v-4ad7e1cd]:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.Confirm[data-v-4ad7e1cd] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.Confirm .link_button button[data-v-4ad7e1cd] {
  width: 0.78125rem;
  height: 0.20833rem;
  line-height: 0.20833rem;
  background: #bc0c34;
  border-radius: 50px;
  border: 0;
  margin-top: 0.10417rem;
  margin-left: 1.82292rem;
  color: #ffffff;
  font-size: 0.08333rem;
}
.Confirm .link_button button[data-v-4ad7e1cd]:hover {
  color: #fff;
  opacity: 0.8;
}
.Confirm .bulid_shopify_btn[data-v-4ad7e1cd] {
  width: 1.30208rem;
  height: 0.20833rem;
  line-height: 0.20833rem;
  background: #93df89;
  border-radius: 50px;
  border: 0;
  margin-top: 0.10417rem;
  color: #ffffff;
  font-size: 0.08333rem;
  text-align: center;
  margin-left: 0.15625rem;
}
.Confirm[data-v-4ad7e1cd] .el-step__head {
  margin-left: 0.26042rem !important;
}
.Confirm[data-v-4ad7e1cd] .ConfirmQuestion .el-icon-question {
  font-size: 0.10417rem;
  cursor: pointer;
  margin-left: 0.05208rem;
  margin-top: 0.16667rem;
}
.Confirm[data-v-4ad7e1cd] .ConfirmQuestion .el-icon-question:hover {
  font-size: 0.11458rem;
  cursor: pointer;
}
.Confirm .skipe_words[data-v-4ad7e1cd] {
  width: 0.26042rem;
  height: 0.15625rem;
  margin-top: 0.14583rem;
  margin-left: 0.07813rem;
  color: #4c8bf5;
  cursor: pointer;
  font-style: italic;
  font-size: 0.08333rem;
}
.Confirm .skipe_words[data-v-4ad7e1cd]:hover {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  font-size: 0.10417rem;
}
.Img[data-v-4ad7e1cd] {
  margin-top: 0.26042rem;
  margin-left: 0.88542rem;
}
.Img img[data-v-4ad7e1cd] {
  width: 5.20833rem;
  height: 2.60417rem;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
[data-v-4ad7e1cd] .el-step__icon {
  position: relative;
  z-index: 1;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  width: 0.15625rem;
  height: 0.15625rem;
  font-size: 0.07292rem;
  box-sizing: border-box;
  background: #fff;
  -webkit-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
}
.LinkYourStoreContent[data-v-4ad7e1cd] {
  text-align: center;
}
.LinkYourStoreContent p[data-v-4ad7e1cd] {
  font-size: 0.15625rem;
  color: #bc0c34;
  margin-top: 0.26042rem;
}