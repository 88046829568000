@charset "UTF-8";
.referenceWord[data-v-6a470766] {
  margin-top: 0.07813rem;
  color: #32cd32;
  font-size: 0.09375rem;
  word-break: normal !important;
}
.SourcingPriceWord[data-v-6a470766] {
  margin-top: 0.10417rem;
  color: #ff8100;
  font-size: 0.09375rem;
  word-break: normal;
}
.NewQuotesContent[data-v-6a470766] {
  margin-top: 0.09375rem;
}
.NewQuotesContent[data-v-6a470766] .NewQuotesContent .el-textarea.is-disabled .el-textarea__inner {
  background: #fff;
  color: red;
  font-size: 0.10417rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  border-radius: 50px;
}
.Product_btn[data-v-6a470766] {
  width: 1.30208rem;
  height: 0.26042rem;
  margin-top: 0.02604rem;
}
.Product_btn button[data-v-6a470766] {
  display: inline-block;
  width: 1.04167rem;
  height: 0.20833rem;
  border-radius: 45px;
  border: 0;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #ffffff;
}
.Product_btn button[data-v-6a470766]:hover {
  background: #67689a;
  color: #fff;
}
.indicators .displayX[data-v-6a470766] {
  display: none;
}
.particulars[data-v-6a470766] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
  background: #eff1f3;
}
.activeName[data-v-6a470766] {
  width: 6.875rem;
  background: #f9fafb;
  box-shadow: 0 0 0.10417rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  padding-bottom: 0.15625rem;
}

/* 详情图 */
.Details-figure[data-v-6a470766] {
  width: 6.875rem;
  border-radius: 10px;
  margin: auto;
  position: relative;
  background: #f9fafb;
  overflow: hidden;
}
.focus[data-v-6a470766] {
  padding-top: 0.26042rem;
  /* <!-- Wrapper for slides 轮播图片 --> */
  width: 3.25rem;
  float: left;
}
.banner .img[data-v-6a470766] {
  width: 2.91667rem;
  height: 2.91667rem;
  position: relative;
}
.img[data-v-6a470766] {
  float: left;
}
.banner[data-v-6a470766] {
  width: 2.91667rem;
  height: 2.91667rem;
  margin-left: 0.33333rem;
  position: relative;
}
[data-v-6a470766] .el-carousel__container {
  height: 2.91667rem;
}
.trans[data-v-6a470766] {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.indicators[data-v-6a470766] {
  margin-left: 0.3125rem;
  margin-top: 0.11458rem;
  height: 0.52083rem;
}
.indicators img[data-v-6a470766] {
  width: 0.52083rem;
  height: 0.52083rem;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  margin-left: 0.18229rem;
  margin-top: 0.05208rem;
}
.indicators .con[data-v-6a470766] {
  width: 0.46875rem;
  height: 0.46875rem;
  margin-left: 0.05729rem;
}
.prev[data-v-6a470766] {
  position: absolute;
  top: 3.54167rem;
  left: 0.15625rem;
  background-color: rgba(245, 247, 250, 0.01);
  border: 0;
}
.next[data-v-6a470766] {
  position: absolute;
  top: 3.54167rem;
  left: 3.28125rem;
  background-color: rgba(245, 247, 250, 0.01);
  border: 0;
}
.synopsis[data-v-6a470766] {
  /* <!-- 简介 --> */
  font-size: 0.10417rem;
  width: 2.91667rem;
  float: right;
  margin-right: 0.33854rem;
  padding-top: 0.26042rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.intro[data-v-6a470766] {
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #191919;
}
.price[data-v-6a470766] {
  margin-top: 0.1875rem;
  color: #93df89;
}
.price .price span[data-v-6a470766]:nth-child(1) {
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #93df89;
  line-height: 0.13021rem;
  width: 0.80729rem;
}
.price .price span[data-v-6a470766]:nth-child(2) {
  font-size: 0.15625rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  line-height: 0.13021rem;
}
.price .price span[data-v-6a470766]:nth-child(3) {
  font-size: 0.15625rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  line-height: 0.13021rem;
}
.price .price span[data-v-6a470766]:nth-child(4) {
  font-size: 0.15625rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  line-height: 0.13021rem;
}
.measure[data-v-6a470766] {
  margin-top: 0.1875rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.measure .measure span[data-v-6a470766] {
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #555656;
}
.labelname[data-v-6a470766] {
  width: 0.52083rem;
  height: 0.18229rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-6a470766] .measure .el-radio-button__inner {
  display: inline-block;
  height: 0.15625rem;
  line-height: 0.14583rem !important;
  border-radius: 50px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  border: 1px solid #dcdfe6;
  margin-right: 0.125rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-6a470766] .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #606266;
  background-color: #fff;
  border-color: #6788ff;
  color: #6788ff;
  box-shadow: none;
  border-width: 2px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-6a470766] .el-radio-button__inner:hover {
  color: #6788ff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-6a470766] .el-radio-button__inner {
  -webkit-transition: all 0s linear;
  transition: all 0s linear;
}
[data-v-6a470766] .el-radio-button:first-child .el-radio-button__inner {
  border-width: 2px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  margin-left: 0.04167rem;
}
.picture[data-v-6a470766] {
  margin-top: 0.10938rem;
}
.picture .picture span[data-v-6a470766] {
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #555656;
  display: inline-block;
  margin-bottom: 0.05208rem;
}
[data-v-6a470766] .picture .el-radio-button__inner {
  width: 0.42188rem;
  height: 0.42188rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  border-top-width: 0px;
  padding-left: 0;
  border: 2px solid #dcdfe6;
  margin-right: 0.125rem;
  margin-bottom: 0.05208rem;
}
.picture .el-radio-button__inner img[data-v-6a470766] {
  width: 0.40104rem;
  height: 0.40104rem;
}
[data-v-6a470766] .picture .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #606266;
  background-color: #fff;
  border-color: #6788ff;
  color: #6788ff;
  box-shadow: none;
  border-width: 2px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-6a470766] .picture .el-radio-button__inner:hover {
  color: #6788ff;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-6a470766] .picture .el-radio-button__inner {
  -webkit-transition: all 0s linear;
  transition: all 0s linear;
}
[data-v-6a470766] .picture .el-radio-button:first-child .el-radio-button__inner {
  border-width: 2px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.address[data-v-6a470766] {
  margin-top: 0.10938rem;
}
.address .address span[data-v-6a470766] {
  font-size: 0.09375rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #555656;
}
[data-v-6a470766] .address .el-radio-button__inner {
  display: inline-block;
  width: 0.39063rem;
  height: 0.15625rem;
  line-height: 0.14583rem !important;
  border-radius: 50px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  border: 1px solid #dcdfe6;
}
.address .sellcountry[data-v-6a470766] {
  margin-right: 0.07292rem;
}
.activeContent[data-v-6a470766] {
  width: 6.875rem;
  background: #f9fafb;
  box-shadow: 0 0 0.10417rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  padding-bottom: 0;
}
.activeContent h3[data-v-6a470766] {
  border-bottom: 2px solid #ccc;
  width: 100%;
  padding-left: 0.23438rem;
  font-size: 0.11458rem;
}
.storyline[data-v-6a470766] {
  font-size: 0.07292rem;
  /* storyline */
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #000000;
  margin-top: 0.10417rem;
  line-height: 0.15625rem;
  padding-top: 0.41667rem;
  padding-left: 0.15625rem;
  padding: 0 1rem;
  padding-top: 80px;
}
[data-v-6a470766] .storyline div {
  text-align: left !important;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-6a470766] .storyline img {
  max-width: 2.86458rem;
  height: auto;
  text-align: left;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
[data-v-6a470766] .storyline p {
  width: 100% !important;
  text-align: left;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-6a470766] .storyline .detailmodule_html {
  width: 100% !important;
  text-align: center;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.product-details[data-v-6a470766] {
  width: 6.875rem;
  border-radius: 10px;
  margin: auto;
  margin-top: 0.16667rem;
  position: relative;
  background: #f9fafb;
}
.product-details .product-details img[data-v-6a470766] {
  width: 6.16667rem;
  height: 10.88021rem;
  margin: 0.35417rem;
}
.el-tabs__active-bar[data-v-6a470766] {
  top: 0.15625rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  border-radius: 20px !important;
}
.Satisfaction[data-v-6a470766] {
  /*客户报价满意度*/
  width: 100%;
  height: 0.20833rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin-top: 0.18229rem;
}
.Satisfaction .Satisfaction p[data-v-6a470766]:nth-child(1) {
  font-size: 0.08333rem;
  margin-top: 0.04167rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.Satisfaction .Satisfaction p[data-v-6a470766]:nth-child(2) {
  font-size: 0.08333rem;
  margin-left: 0.10417rem;
  line-height: 0;
  border-radius: 10px;
  color: red;
  border: 2px solid #6788ff;
}
[data-v-6a470766] .Satisfaction .el-button {
  border: none;
  background: none;
  color: #6788ff;
  text-align: center;
}
.Satisfaction p[data-v-6a470766]:nth-child(3) {
  font-size: 0.08333rem;
  margin-left: 0.10417rem;
  line-height: 0.20833rem;
  border-radius: 10px;
  color: red;
  border: 2px solid #6788ff;
  text-align: center;
}