
.particulars[data-v-78250402] {
  position: relative;
  background: #eff1f3;
  /*background-image: linear-gradient(to bottom right, #373296, #7665d2);*/
  min-width: 6.875rem;
}
.particulars.dark[data-v-78250402] {
  background: #1f1e2e !important;
}
