.ad-login[data-v-489e8d84] {
  width: 100%;
  height: 100%;
  padding-top: 0.48958rem;
  background: url("http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715580786555_loginBg.png") no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.ad-login .login-info[data-v-489e8d84] {
  margin-right: 0.41667rem;
  text-align: left;
  margin-top: -6%;
}
.ad-login .login-info .logo1[data-v-489e8d84] {
  width: 1.17188rem;
  height: 0.98438rem;
  margin-bottom: 0.20833rem;
}
.ad-login .login-info .btnContent[data-v-489e8d84] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.ad-login .login-info .btnContent .btnInfo[data-v-489e8d84] {
  width: 1.4375rem;
  height: 0.98958rem;
  text-align: center;
  background: #e0e3f2;
  border-radius: 16px;
  margin-right: 0.125rem;
  padding: 0.20833rem 0.14583rem;
}
.ad-login .login-info .btnContent .btnInfo .title[data-v-489e8d84] {
  color: #15104b;
  font-weight: 700;
  font-size: 0.08333rem;
  margin-bottom: 0.07292rem;
}
.ad-login .login-info .btnContent .btnInfo .word[data-v-489e8d84] {
  color: #5c5c90;
  font-size: 0.07292rem;
}
.ad-login .login-content[data-v-489e8d84] {
  width: 2.61979rem;
  height: 3.6875rem;
  margin-top: -6%;
  background: #fff;
  border-radius: 30px;
  padding: 0.10417rem 0.20833rem;
  position: relative;
}
.ad-login .login-content .logo2[data-v-489e8d84] {
  width: 45%;
  height: 0.52083rem;
  image-rendering: crisp-edges;
}
.ad-login .login-content .login-head[data-v-489e8d84] {
  font-size: 0.125rem;
  color: #000000;
  font-weight: 500;
  margin-top: 0.15625rem;
  margin-bottom: 0.20833rem;
  text-align: center;
}
.ad-login .login-content .forget[data-v-489e8d84] {
  width: 100%;
  text-align: right;
  position: absolute;
  top: 0.22917rem;
}
.ad-login .login-content .option[data-v-489e8d84] {
  width: 2.1875rem;
  height: 0.52083rem;
  margin: 0 auto 0.05208rem;
  padding-left: 0.01563rem;
}
.ad-login .login-content .option[data-v-489e8d84] .el-input__inner {
  border-radius: 50px;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 0.21875rem;
  border: 1px solid #cccccc;
}
.ad-login .login-content .login-bottom[data-v-489e8d84] {
  margin-top: 0.41667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.ad-login .login-content .login-bottom .el-button[data-v-489e8d84] {
  width: 1.17708rem;
  height: 0.22917rem;
  background: #15104b;
  font-size: 0.08333rem;
  margin-bottom: 0.04167rem;
  color: #ffffff;
  border-radius: 30px;
}
.ad-login .login-content .login-bottom .small[data-v-489e8d84] {
  font-size: 0.07292rem;
  color: #999999;
}
.ad-login .login-content .demo-ruleForm[data-v-489e8d84] .el-form-item {
  margin-bottom: 0 !important;
}
.ad-login .login-content .demo-ruleForm[data-v-489e8d84] .el-form-item .el-form-item__content {
  margin-left: 0 !important;
}