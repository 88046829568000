.layout[data-v-5f2c575d] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.layout .mainContent[data-v-5f2c575d] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  max-height: 100vh;
  width: calc(100% - 1.25rem);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.layout .mainContent .container[data-v-5f2c575d] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  height: calc(100vh - 0.36458rem);
  background-color: #eff1f3;
  padding-top: 0.20833rem;
  padding-left: 0.3125rem;
  overflow-y: scroll;
}