@charset "UTF-8";
[data-v-6a0a1abb] .enter-passwordOne .el-input__inner {
  outline: none;
  background: none;
  border: 1px solid #ccc;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
[data-v-6a0a1abb] .enter-passwordTwo .el-input__inner {
  outline: none;
  background: none;
  border: 1px solid #ccc;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.mt40[data-v-6a0a1abb] {
  margin-bottom: 0.20833rem !important;
}
.mr10[data-v-6a0a1abb] {
  margin-right: 0.05208rem !important;
}
.enter-password input[data-v-6a0a1abb] {
  width: 2.76042rem;
  height: 0.30208rem;
  border: 1px solid #000000;
  border-radius: 38px;
  padding-left: 0.125rem;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #999999;
}
.content[data-v-6a0a1abb] {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 1.45833rem;
  background: #15104B;
}
.content .reset[data-v-6a0a1abb] {
  border-radius: 20px;
  width: 4.375rem;
  background: #ffffff;
  margin: 0 auto;
  box-shadow: 0 0 0.29688rem 0 rgba(0, 0, 0, 0.13);
}
.content .reset .resetpasswords .registration[data-v-6a0a1abb] {
  height: 0.41667rem;
  font-size: 0.10417rem;
  border-radius: 20px 20px 0 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  color: #333333;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-bottom: 1px solid #E4E6EF;
}
.content .reset .resetpasswords .restContent[data-v-6a0a1abb] {
  padding: 0.26042rem 0 0.20833rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.content .reset .resetpasswords .restContent .resetPad[data-v-6a0a1abb] {
  width: 100%;
  height: 0.25rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  font-size: 0.08333rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.content .reset .resetpasswords .restContent .resetPad button[data-v-6a0a1abb] {
  width: 1.04167rem;
  height: 0.21875rem;
}
.content .reset .resetpasswords .restContent .resetPad button[data-v-6a0a1abb]:nth-child(1) {
  margin-right: 0.20833rem;
  font-weight: 600;
}
.NEXT[data-v-6a0a1abb] {
  width: 4.38021rem;
  height: 0.25rem;
  text-align: center;
  line-height: 0.25rem;
  margin-top: 0.1875rem;
}
.NEXT button[data-v-6a0a1abb] {
  width: 1.73958rem;
  height: 0.21875rem;
  line-height: 0.21875rem;
  background: -webkit-linear-gradient(318deg, #5e5ee0, #6a8aff);
  background: linear-gradient(132deg, #5e5ee0, #6a8aff);
  border-radius: 10px;
  color: #fff;
  border: 0;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}

/* 重置密码1 */
.reset1[data-v-6a0a1abb] {
  width: 4.38021rem;
  height: 2.60938rem;
  background: #ffffff;
  position: absolute;
  top: auto;
  left: auto;
  box-shadow: 0 0 0.29688rem 0 rgba(0, 0, 0, 0.13);
  border-radius: 20px;
  overflow: hidden;
}
.reset1 .retrieve[data-v-6a0a1abb] {
  margin-top: 0.23438rem;
  margin-left: 0.80208rem;
  margin-left: 154px;
  font-size: 0.10417rem;
  font-weight: 400;
  color: #000000;
  opacity: 0.8;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
}
.reset1 .retrieve1[data-v-6a0a1abb] {
  margin-top: 0.10938rem;
  margin-left: 0.80208rem;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #000000;
  opacity: 0.4;
}
.reset1 .enter-password input[data-v-6a0a1abb] {
  margin-top: 0.1875rem;
  margin-left: 0.80208rem;
  width: 2.75521rem;
  height: 0.30208rem;
  border: 1px solid #000000;
  border-radius: 38px;
  padding-left: 0.125rem;
  font-size: 0.08333rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #000000;
}
.reset1 .enter-password button[data-v-6a0a1abb] {
  position: relative;
  top: 0;
  left: -0.3125rem;
  display: inline-block;
  border: 0;
  line-height: 0.09375rem;
  text-align: center;
}
.reset1 .NEXT[data-v-6a0a1abb] {
  margin-top: 0.1875rem;
}
.reset1 .NEXT button[data-v-6a0a1abb] {
  height: 0.21875rem;
  line-height: 0.21875rem;
}