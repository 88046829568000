@charset "UTF-8";
.particulars[data-v-7fe95536] {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
  font-family: "Regular";
}
.active_name[data-v-7fe95536] {
  width: 6.875rem;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  padding-bottom: 0.15625rem;
}
.active_name > .title[data-v-7fe95536] {
  font-size: 0.16667rem;
  text-align: center;
  margin-bottom: 0.10417rem;
}
.active_name > .banner[data-v-7fe95536] {
  width: 100%;
  height: 1.66667rem;
  border-radius: 10px;
  box-shadow: 0.01042rem 0.03646rem 0.04583rem 0 #00000040;
}
.active_name > .banner .banner-img[data-v-7fe95536] {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.active_name > .category[data-v-7fe95536] {
  height: 0.52083rem;
  padding: 0 0.10417rem;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.active_name > .category .category-item[data-v-7fe95536] {
  border-radius: 22px;
  background-color: #15104b;
  color: #fff;
  min-width: 1.04167rem;
  height: 0.17708rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  padding: 0 0.08333rem;
  font-size: 0.07292rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.active_name > .category .category-item[data-v-7fe95536]:hover {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
}
.active_name > .category .active-tab[data-v-7fe95536] {
  /*color: #3f6aff;
  text-decoration: underline;*/
  background: #3C3772;
  color: #fff;
}
.active_name > .content[data-v-7fe95536] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.15625rem;
  padding: 0 0.10417rem;
  /* 为左右两边添加20px的内边距 */
}
.active_name > .content .content-item[data-v-7fe95536] {
  height: 2.08333rem;
  box-sizing: border-box;
  box-shadow: 0.01042rem 0.03646rem 0.04583rem 0 #00000040;
  background-color: #fff;
  border-radius: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  /* 保持圆角效果 */
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.active_name > .content .content-item[data-v-7fe95536]:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}
.active_name > .content .box[data-v-7fe95536] {
  width: 100%;
  height: 55%;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  box-sizing: border-box;
  overflow: hidden;
}
.active_name > .content .box .box_video[data-v-7fe95536] {
  width: 2.1875rem;
  height: 1.14583rem;
}
.active_name > .content .box .box_video img[data-v-7fe95536] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active_name > .content .info[data-v-7fe95536] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  min-height: 0;
  box-sizing: border-box;
  padding: 0.15625rem 0.10417rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.active_name > .content .title[data-v-7fe95536] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
          flex: 1;
  min-height: 0;
  box-sizing: border-box;
  font-size: 0.10417rem;
  line-height: 0.15104rem;
  font-weight: 500;
  color: #000;
  -webkit-align-content: center;
          align-content: center;
  display: -webkit-inline-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.active_name > .content .group[data-v-7fe95536] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  width: 1.97917rem;
  height: 0.26042rem;
  border: 2px solid #CFCFCF;
  cursor: pointer;
  color: #15104b;
  font-weight: bold;
  border-radius: 6px;
}
.active_name > .content .group p[data-v-7fe95536] {
  text-align: center;
  font-size: 0.10417rem;
  font-weight: 500;
  margin-left: 0.67708rem;
}
[data-v-7fe95536] .el-progress__text {
  display: none;
}
.progress-container[data-v-7fe95536] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.progress-label[data-v-7fe95536] {
  margin-top: 0.03125rem;
  font-size: 0.10417rem;
}