
.particulars[data-v-37043e95] {
  width: 100%;
  min-height: 100vh;
  background: #eff1f3;
  min-width: 6.875rem;
  padding-bottom: 0.16667rem;
}
.activeName[data-v-37043e95] {
  width: 6.875rem;
  border-radius: 10px;
  line-height: 0.10417rem;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
}
[data-v-37043e95] .el-tabs__nav-scroll {
  height: 0.41667rem;
  padding: 0.07813rem 0;
  background: -webkit-linear-gradient(bottom, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}
[data-v-37043e95] .el-tabs__nav {
  margin-left: 0.16667rem;
}
[data-v-37043e95] .el-tabs__item.is-active {
  font-size: 0.08333rem;
  color: #6788ff;
  font-family: "Regular";
  font-weight: 500;
}
/* 标签页下划线 */
[data-v-37043e95] .el-tabs__active-bar {
  height: 0.01563rem !important;
  background: #6788ff;
}
[data-v-37043e95] .el-tabs__header {
    margin: 0;
}
[data-v-37043e95] .el-tabs__nav-wrap::after {
    background: #eff1f3;
}
[data-v-37043e95] .el-pager li.active {
  font-family: "Regular";
}
[data-v-37043e95] .el-table th,
.el-table tr[data-v-37043e95] {
  height: 0.33333rem;
  font-weight: 500;
}
[data-v-37043e95] .el-table__row {
  height: 0.44792rem !important;
}
[data-v-37043e95] .el-table thead { /* 头部颜色 */
  font-size: 0.09375rem;
  font-family: "Regular";
  font-weight: 500;
  color: rgb(0, 0, 0, .8);
}
/* 列表字体颜色 */
[data-v-37043e95] .el-table .cell {
  font-size: 0.07292rem;
  font-family: "Regular";
  font-weight: 400;
  color: rgb(0, 0, 0, .8);
}
/* 分页 */
.pagebox[data-v-37043e95] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 0.36458rem;
  padding-bottom: 0.02083rem;
}
