.flex[data-v-873568ac], .payment .payWays .card[data-v-873568ac] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.flex_start[data-v-873568ac] {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.payment .payWays[data-v-873568ac] {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 0.3125rem;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 0 0.15625rem;
  margin-top: 0.20833rem;
}
.payment .payWays .card[data-v-873568ac] {
  width: 1.35417rem;
  height: 0.26042rem;
  background: #d3dfff;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-position: center;
}
.payment .payWays .card img[data-v-873568ac] {
  height: 80%;
}
.payment .payWays .checkbox[data-v-873568ac] .el-checkbox__input {
  margin-top: -0.13021rem;
  margin-right: 0.05208rem;
}
.payment .payWays .checkbox[data-v-873568ac] .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #3f6aff;
  border-color: #3f6aff;
}
.payment .payWays .choosed[data-v-873568ac] {
  background: #3f6aff;
}