@charset "UTF-8";
.particulars[data-v-6cb0d5dc] {
  width: 100%;
  min-height: 100vh;
  position: relative;
  min-width: 6.875rem;
  background-color: #eff1f3 !important;
  font-family: "Regular";
}
.activeName[data-v-6cb0d5dc] {
  padding-bottom: 0.05208rem;
  width: 6.875rem;
  position: relative;
  overflow: hidden;
}
.nobg[data-v-6cb0d5dc], .btn .nobg[data-v-6cb0d5dc] {
  border-style: solid;
  border-width: 2px;
  color: #3f6aff !important;
}
.btn[data-v-6cb0d5dc] {
  position: absolute;
  top: 0.10417rem;
  right: 0.16667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.btn .nobg[data-v-6cb0d5dc] {
  margin-right: 0.10417rem;
}
.btn .nobg[data-v-6cb0d5dc]:hover {
  background: #3f6aff;
  color: #fff !important;
}
.appendBtn[data-v-6cb0d5dc] {
  width: 0.46875rem;
  margin-left: -0.21354rem;
  height: 100%;
}
[data-v-6cb0d5dc] .el-input .el-input__count .el-input__count-inner {
  padding: 0;
}
.check_type[data-v-6cb0d5dc] {
  width: 100%;
  height: 0.3125rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.Aliexpress_Product[data-v-6cb0d5dc] {
  width: 1.04167rem;
  height: 0.20833rem;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
}
.Aliexpress_Product a[data-v-6cb0d5dc] {
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
  color: #000;
}
.Aliexpress_Product[data-v-6cb0d5dc]:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.Aliexpress_Product span[data-v-6cb0d5dc]:nth-child(1) {
  line-height: 0.20833rem;
  margin-left: 0.20833rem;
  color: #15104b;
  font-size: 0.08333rem;
}
.Aliexpress_Product span[data-v-6cb0d5dc]:nth-child(2) {
  margin-left: 0.10417rem;
  color: #15104b;
  font-weight: 3.125rem;
  line-height: 0.20833rem;
}
.Import_List[data-v-6cb0d5dc] {
  width: 1.04167rem;
  height: 0.20833rem;
  background: #15104b;
  color: #ffffff !important;
  margin-left: 0.07813rem;
  border-radius: 50px;
  cursor: pointer;
}
.Import_List a[data-v-6cb0d5dc] {
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
  color: #000;
}
.Import_List[data-v-6cb0d5dc]:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.Import_List span[data-v-6cb0d5dc]:nth-child(1) {
  line-height: 0.20833rem;
  margin-left: 0.26042rem;
  color: #ffffff;
  font-size: 0.07292rem;
}
.Import_List span[data-v-6cb0d5dc]:nth-child(2) {
  margin-left: 0.14583rem;
  color: #ffffff;
}
.My_Product[data-v-6cb0d5dc] {
  width: 1.04167rem;
  height: 0.20833rem;
  background: #ffffff;
  margin-left: 0.18229rem;
  border-radius: 50px;
  cursor: pointer;
}
.My_Product a[data-v-6cb0d5dc] {
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
  color: #000;
}
.My_Product[data-v-6cb0d5dc]:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.My_Product span[data-v-6cb0d5dc]:nth-child(1) {
  line-height: 0.20833rem;
  margin-left: 0.07813rem;
  color: #15104b;
  font-size: 0.07292rem;
}
.My_Product span[data-v-6cb0d5dc]:nth-child(2) {
  margin-left: 0.02604rem;
  color: #15104b;
}
.MonthlyRed[data-v-6cb0d5dc] {
  position: absolute;
  top: 0;
  left: 1.14583rem;
  cursor: pointer;
}
.MonthlyRed span img[data-v-6cb0d5dc] {
  width: 0.13021rem;
  height: 0.13021rem;
}
.MonthlyRed span img[data-v-6cb0d5dc]:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.member_box[data-v-6cb0d5dc] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.member_box .member_set[data-v-6cb0d5dc] {
  width: 1.97917rem;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  left: calc(60% - 2.08333rem);
  top: calc(50% - 2.34375rem);
  margin-top: 0.83333rem;
  background-color: #2a293e;
  height: 2.60417rem;
  border-radius: 10px;
  position: relative;
}
.member_box .member_top[data-v-6cb0d5dc] {
  width: 1.19792rem;
  height: 1.14583rem;
  margin-left: 0.26042rem;
}
.member_box .member_top img[data-v-6cb0d5dc] {
  width: 100%;
  height: 100%;
  margin-top: 0.18229rem;
  margin-left: 0.10417rem;
}
.member_box .member_down[data-v-6cb0d5dc] {
  width: 1.97917rem;
  margin-top: 0;
}
.member_box .member_down p[data-v-6cb0d5dc]:nth-child(1) {
  text-align: center;
  margin-top: 0.26042rem;
  font-size: 0.09375rem;
  color: #fff;
}
.member_box .member_down p[data-v-6cb0d5dc]:nth-child(2) {
  text-align: center;
  margin-top: 0.10417rem;
  font-size: 0.08333rem;
  color: #fff;
}
.member_box .Bindstore p[data-v-6cb0d5dc]:nth-child(1) {
  color: #fff;
  font-size: 0.07292rem;
  margin-top: 0.05208rem;
  text-align: center;
  line-height: 0.20833rem;
  word-break: normal;
}
.member_box .member_btn[data-v-6cb0d5dc] {
  margin-left: 0.28646rem;
  width: 0.78125rem;
  height: 0.23438rem;
  line-height: 0.23438rem;
  border-radius: 50px;
  margin-top: 0.07813rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.member_box .member_btn[data-v-6cb0d5dc] .el-button:nth-child(1) {
  margin-left: 0.02604rem;
  height: 0.23438rem;
  margin-bottom: -0.78125rem;
  border-radius: 50px !important;
  font-size: 0.08333rem;
  color: #fff;
  background: #93df89;
  border: 1px solid #93df89;
}
.member_box .member_btn[data-v-6cb0d5dc] .el-button:nth-child(2) {
  margin-left: 0.15625rem;
  height: 0.23438rem;
  border-radius: 50px;
  font-size: 0.08333rem;
  color: #fff;
  background: #93df89;
  border: 1px solid #93df89;
}
.member_box .member_btn[data-v-6cb0d5dc] .el-button:nth-child(1):hover {
  background: #bff4b8;
  color: #fff;
}
.member_box .member_btn[data-v-6cb0d5dc] .el-button:nth-child(2):hover {
  background: #bff4b8;
  color: #fff;
}
.speed_progress[data-v-6cb0d5dc] {
  width: 1.11979rem;
  height: 0.3125rem;
  position: absolute;
  top: 0.11458rem;
  left: 2.68229rem;
}
.speed_progress .progress_fulfilled span[data-v-6cb0d5dc]:nth-child(1) {
  font-size: 0.07292rem;
}
.speed_progress .progress_fulfilled span[data-v-6cb0d5dc]:nth-child(2) {
  font-size: 0.07292rem;
  margin-left: 0.15625rem;
}
.speed_progress .progress_fulfilled span[data-v-6cb0d5dc]:nth-child(3) {
  font-size: 0.07292rem;
  margin-left: 0.02604rem;
}
.speed_progress .progress_fulfilled span[data-v-6cb0d5dc]:nth-child(4) {
  font-size: 0.07292rem;
  margin-left: 0.03125rem;
}
[data-v-6cb0d5dc] .el-progress__text {
  font-size: 0.07292rem;
  color: #ccc !important;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.05208rem;
  line-height: 1;
  background: #ccc !important;
}
.compare_box[data-v-6cb0d5dc] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.compare_box .compare_price[data-v-6cb0d5dc] {
  width: 1.5625rem;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  overflow: hidden;
  position: absolute;
  left: calc(69% - 0.05208rem);
  top: calc(50% - 1.5625rem);
  margin-top: 0.83333rem;
  background-color: #fff;
  height: 0.52083rem;
  border-radius: 5px;
}
.compare_box .compare_price[data-v-6cb0d5dc] .el-input__inner {
  width: 100%;
  height: 0.15625rem;
  margin-top: -0.15625rem;
}
.compare_box .pay_title_compare_price[data-v-6cb0d5dc] {
  width: 100%;
  height: 0.15625rem;
  line-height: 0.15625rem;
  font-size: 0.09375rem;
  font-weight: 500;
  color: #333333;
  padding-left: 0.21875rem;
  padding-right: 0.16667rem;
  background: #ffffff;
}
.compare_box .pay_title_compare_price span[data-v-6cb0d5dc] {
  width: 0.05208rem;
  height: 0.05208rem;
  line-height: 0.05208rem;
  display: block;
  background: url("http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715926448006_close.png") no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: right;
  margin-top: 0.05208rem;
  cursor: pointer;
}
.compare_box .pay_compare_items[data-v-6cb0d5dc] {
  width: 2.39063rem;
  height: 0.88542rem;
  font-size: 0.10417rem;
  font-weight: 400;
  color: #000000;
  background-color: #fff;
  margin-top: 0.16667rem;
  margin-left: 0.16146rem;
}
.compare_box .text_comparePrice[data-v-6cb0d5dc] {
  width: 1.27604rem;
  height: 0.15625rem;
  margin-top: -0.15625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.price_compare_btn[data-v-6cb0d5dc] {
  text-align: center;
  float: right;
}
.price_compare_btn button[data-v-6cb0d5dc] {
  width: 0.3125rem;
  height: 0.13021rem;
  color: #fff;
  border-radius: 5px;
  font-size: 0.07292rem;
  font-family: "Regular";
  padding: 0 0.01042rem !important;
}
.pay_box[data-v-6cb0d5dc] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.pay_box .pay_price[data-v-6cb0d5dc] {
  width: 1.5625rem;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  overflow: hidden;
  position: absolute;
  left: calc(60% - 0.41667rem);
  top: calc(50% - 1.5625rem);
  margin-top: 0.83333rem;
  background-color: #fff;
  height: 0.52083rem;
  border-radius: 5px;
}
.pay_box .pay_price[data-v-6cb0d5dc] .el-input__inner {
  width: 100%;
  height: 0.15625rem;
  margin-top: -0.15625rem;
}
.pay_box .pay_title_price[data-v-6cb0d5dc] {
  width: 100%;
  height: 0.15625rem;
  line-height: 0.15625rem;
  font-size: 0.09375rem;
  font-weight: 500;
  color: #333333;
  padding-left: 0.21875rem;
  padding-right: 0.06771rem;
  background: #ffffff;
}
.pay_box .pay_title_price span[data-v-6cb0d5dc] {
  width: 0.05208rem;
  height: 0.05208rem;
  line-height: 0.05208rem;
  display: block;
  background: url("http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715926448006_close.png") no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: right;
  margin-top: 0.05208rem;
  cursor: pointer;
}
.pay_box .price_btn[data-v-6cb0d5dc] {
  height: 0.15625rem;
  line-height: 0.15625rem;
  text-align: center;
  float: right;
}
.pay_box .price_btn button[data-v-6cb0d5dc] {
  width: 0.3125rem;
  height: 0.13021rem;
  color: #fff;
  font-size: 0.07292rem;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  padding: 0 0.01042rem !important;
}
.pay_box .text_price[data-v-6cb0d5dc] {
  width: 1.27604rem;
  height: 0.15625rem;
  margin-top: -0.15625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.itembox[data-v-6cb0d5dc] {
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 0.07813rem;
  position: relative;
}
[data-v-6cb0d5dc] .el-tabs__nav-scroll {
  height: 0.41667rem;
  padding: 0.07813rem 0;
  background: -webkit-linear-gradient(bottom, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}
[data-v-6cb0d5dc] .el-tabs__header {
  margin: 0;
}
[data-v-6cb0d5dc] .el-tabs__nav {
  margin-left: 0.16667rem;
}
[data-v-6cb0d5dc] .el-tabs__item {
  font-size: 0.08333rem !important;
  font-weight: 500 !important;
  padding: 0 !important;
  padding-right: 0.3125rem !important;
}
[data-v-6cb0d5dc] .el-tabs__active-bar {
  height: 0.01563rem !important;
  background-color: #6788ff;
  border-radius: 10px;
}
[data-v-6cb0d5dc] .el-tabs__nav-wrap::after {
  background-color: #eff1f3 !important;
}
.first[data-v-6cb0d5dc] {
  width: 6.875rem;
  background-color: #fff;
}
.details[data-v-6cb0d5dc] {
  height: 1.64063rem;
  position: relative;
}
.img[data-v-6cb0d5dc] {
  display: inline-block;
  width: 1.30208rem;
  height: 1.30208rem;
  padding-top: 0.15625rem;
  margin-left: 0.16667rem;
}
.img img[data-v-6cb0d5dc] {
  width: 1.35417rem;
}
.Burner[data-v-6cb0d5dc] {
  position: absolute;
  top: 0.26042rem;
  left: 1.97917rem;
  height: 0.52083rem;
  font-size: 0.07292rem;
}
.Burner[data-v-6cb0d5dc] .el-input {
  width: 4.6875rem;
  margin-top: 0.08333rem;
}
.Burner[data-v-6cb0d5dc] .el-input__inner {
  height: 0.25rem !important;
  border-radius: 12px !important;
}
.Burner[data-v-6cb0d5dc] .el-input .el-input__count {
  position: absolute;
  top: 0.18229rem;
  left: -0.41667rem;
}
.Burner .remaining[data-v-6cb0d5dc] {
  margin-top: 0.02604rem;
  margin-left: 3.54167rem;
  display: inline-block;
  font-size: 0.0625rem;
  font-weight: 400;
  color: #010101;
  opacity: 0.4;
}
.URL[data-v-6cb0d5dc] {
  /* URL */
  position: absolute;
  top: 0.9375rem;
  left: 1.97917rem;
  height: 0.52083rem;
  font-size: 0.07292rem;
}
.URL[data-v-6cb0d5dc] .el-input {
  width: 4.66146rem;
  margin-top: 0.08333rem;
}
.URL[data-v-6cb0d5dc] .el-input__inner {
  height: 0.25rem !important;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.URL[data-v-6cb0d5dc] .el-input-group__append {
  padding: 0;
  border: 0;
  background-color: #ffffff;
}
.pagebox[data-v-6cb0d5dc] {
  width: 100%;
  height: 0.45833rem;
  padding-bottom: 0.02083rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
.el-table[data-v-6cb0d5dc]::before {
  background: #dbdbe4;
}
[data-v-6cb0d5dc] .w-e-text-container {
  border: 0 !important;
}
[data-v-6cb0d5dc] .w-e-text {
  padding: 0 0.05208rem;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
}
.Variants[data-v-6cb0d5dc] {
  position: relative;
}
.Variants .paging[data-v-6cb0d5dc] {
  padding-top: 0.15625rem;
  padding-right: 0.18229rem;
  padding-left: 0.16667rem;
}
.Variants .headportrait[data-v-6cb0d5dc] {
  color: #6788ff;
}
[data-v-6cb0d5dc] .Variants .el-table td,
.el-table th.is-leaf[data-v-6cb0d5dc] {
  text-align: center !important;
}
[data-v-6cb0d5dc] .Variants .el-table th > .cell {
  text-align: center !important;
}
[data-v-6cb0d5dc] .el-tabs__content {
  background-color: #fff !important;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
[data-v-6cb0d5dc] .el-select .el-input__inner {
  font-weight: 100;
}
.Variants .headportrait span[data-v-6cb0d5dc] {
  display: block;
  width: 100%;
  height: 0.01042rem;
  background-color: #6788ff;
}
[data-v-6cb0d5dc] .Variants .frame .el-input__inner {
  text-align: center;
  width: 0.72917rem;
  border-radius: 25px;
}
[data-v-6cb0d5dc] .Variants .el-table th,
.el-table tr[data-v-6cb0d5dc] {
  height: 0.33333rem;
}
[data-v-6cb0d5dc] .el-table thead {
  color: rgba(0, 0, 0, 0.8);
}
[data-v-6cb0d5dc] .el-table {
  color: rgba(0, 0, 0, 0.8);
}

/* 表单高度 */
[data-v-6cb0d5dc] .el-table__row {
  height: 0.67708rem;
}
[data-v-6cb0d5dc] .el-table__body-wrapper .is-scrolling-none {
  height: 100% !important;
}
[data-v-6cb0d5dc] .el-tabs__item.is-active {
  color: #6788ff;
}
[data-v-6cb0d5dc] .el-tabs__active-bar {
  color: #6788ff;
}

/*fourth  */
.fourth[data-v-6cb0d5dc] {
  margin-bottom: 0.26042rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding: 0.10417rem;
}
.upload[data-v-6cb0d5dc] {
  width: 1.30208rem !important;
  display: contents;
}
[data-v-6cb0d5dc] .upload .el-upload--picture-card {
  width: 1.30208rem !important;
  height: 1.25rem !important;
  line-height: 1.25rem !important;
}
.select_demo[data-v-6cb0d5dc] {
  height: 32vw;
  overflow-y: auto;
  width: 5.36458rem;
}
[data-v-6cb0d5dc] .el-upload-list--picture-card .el-upload-list__item {
  width: 1.30208rem !important;
  height: 1.25rem !important;
}
[data-v-6cb0d5dc] .el-upload-list--picture-card {
  display: none;
}
.bakingpackaging[data-v-6cb0d5dc] {
  width: 0.46875rem;
  height: 0.46875rem;
}
.select_li[data-v-6cb0d5dc] {
  position: relative;
  float: left;
  width: 0.83333rem;
  height: 0.83333rem;
  cursor: pointer;
  background-position: center 0 !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  margin-bottom: 0.05208rem;
  margin-left: 0.05208rem;
}
.select_li img[data-v-6cb0d5dc] {
  width: 100%;
  height: 100%;
}
.select_box[data-v-6cb0d5dc],
.select_box_active[data-v-6cb0d5dc] {
  position: absolute;
  width: 0.14583rem;
  height: 0.14583rem;
  left: 0;
  top: 0;
  font-size: 0.05208rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.select_box[data-v-6cb0d5dc] {
  background: #04bd00;
}
.el-icon-check[data-v-6cb0d5dc] {
  font-size: 0.10417rem !important;
  color: #ffffff;
}
.pagebox[data-v-6cb0d5dc] {
  /* 分页 */
  width: 6.875rem;
  height: 0.41667rem;
  background-color: #fff;
  border-radius: 10px;
}
.paging[data-v-6cb0d5dc] {
  width: 100%;
  padding-bottom: 0.15625rem;
}
.paybox[data-v-6cb0d5dc] {
  /* 弹出框 */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}
.paybox .pay_content[data-v-6cb0d5dc] {
  width: 2.60417rem;
  box-shadow: 0 0 0.25521rem 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  overflow: hidden;
  position: absolute;
  left: calc(58% - 2.1875rem);
  top: calc(50% - 1.97917rem);
  margin-top: 0.83333rem;
  background-color: #fff;
  height: 1.04167rem;
}
.paybox .pay_title[data-v-6cb0d5dc] {
  width: 100%;
  height: 0.17188rem;
  line-height: 0.41667rem;
  font-size: 0.09375rem;
  font-weight: 500;
  color: #333333;
  padding-left: 0.21875rem;
  padding-right: 0.16667rem;
  background: #ffffff;
}
.paybox .pay_title span[data-v-6cb0d5dc] {
  width: 0.07813rem;
  height: 0.08333rem;
  display: block;
  background: url("http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715926448006_close.png") no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: right;
  margin-top: 0.13021rem;
  cursor: pointer;
}
.paybox .pay_items[data-v-6cb0d5dc] {
  width: 2.39063rem;
  height: 0.88542rem;
  font-size: 0.10417rem;
  font-weight: 400;
  color: #000000;
  background-color: #fff;
  margin-top: 0.16667rem;
  margin-left: 0.16146rem;
}
.paybox .updated[data-v-6cb0d5dc] {
  margin-bottom: 0.15625rem;
  text-align: center;
}
.paybox .updated[data-v-6cb0d5dc] .el-input__inner {
  border-radius: 20px;
}
.paybox .updated span[data-v-6cb0d5dc] {
  font-size: 0.08333rem;
}
.paybox .updated[data-v-6cb0d5dc] .el-select {
  text-align: center;
  font-weight: 900;
  width: 1.5625rem;
}
.paybox .according[data-v-6cb0d5dc] {
  width: 100%;
  height: 0.26042rem;
  line-height: 0.26042rem;
  text-align: center;
}
.paybox .according button[data-v-6cb0d5dc] {
  width: 0.65104rem;
  height: 0.19792rem;
}
.el-input .el-input__count .el-input__count-inner[data-v-6cb0d5dc] {
  padding: 0;
}