.particulars[data-v-4496b94d] {
  width: 100%;
  position: relative;
  background: #ffffff;
  min-height: 100vh;
  font-family: "Regular";
}
.Bgrd[data-v-4496b94d] {
  width: 100%;
  height: 0.67708rem;
  position: relative;
  background: -webkit-linear-gradient(318deg, #1b1941, #22224b, #202251);
  background: linear-gradient(132deg, #1b1941, #22224b, #202251);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: "Regular";
}
.activeName[data-v-4496b94d] {
  margin: auto;
  padding-bottom: 0.16667rem;
  width: 6.875rem;
  border-radius: 10px !important;
  position: relative;
  background-color: #fff;
  font-family: "Regular";
}
.CreditBack_box[data-v-4496b94d] {
  width: 100%;
  height: 2.60417rem;
}
.Credit_card[data-v-4496b94d] {
  padding-top: 0.15625rem;
  font-size: 0.10417rem;
  margin-left: 2.34375rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.Payment_id[data-v-4496b94d] {
  font-size: 0.10417rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-left: 1.82292rem;
  margin-top: 0.07813rem;
}
.Payment_id p[data-v-4496b94d]:nth-child(2) {
  margin-left: 0.13021rem;
  border-bottom: 1px solid #000;
}
.Payment_Status[data-v-4496b94d] {
  font-size: 0.10417rem;
  margin-left: 1.82292rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-left: 350px;
  margin-top: 0.07813rem;
}
.Payment_Status p[data-v-4496b94d]:nth-child(2) {
  margin-left: 0.02604rem;
  line-height: 0.18229rem;
  border-bottom: 1px solid #000;
}
.Payment_Amount[data-v-4496b94d] {
  font-size: 0.10417rem;
  margin-left: 1.82292rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-left: 350px;
  margin-top: 0.07813rem;
}
.Payment_Amount p[data-v-4496b94d]:nth-child(2) {
  margin-left: 0.02604rem;
  line-height: 0.18229rem;
  border-bottom: 1px solid #000;
}
.Payment_CardNumber[data-v-4496b94d] {
  font-size: 0.10417rem;
  margin-left: 1.82292rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-left: 350px;
  margin-top: 0.07813rem;
}
.Payment_CardNumber p[data-v-4496b94d]:nth-child(2) {
  margin-left: 0.02604rem;
  line-height: 0.18229rem;
  border-bottom: 1px solid #000;
}
.Payment_CardType[data-v-4496b94d] {
  font-size: 0.10417rem;
  margin-left: 1.82292rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-left: 350px;
  margin-top: 0.07813rem;
}
.Payment_CardType p[data-v-4496b94d]:nth-child(2) {
  margin-left: 0.02604rem;
  line-height: 0.18229rem;
  border-bottom: 1px solid #000;
}
.Payment_Desription[data-v-4496b94d] {
  font-size: 0.10417rem;
  margin-left: 1.82292rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-left: 350px;
  margin-top: 0.07813rem;
}
.Payment_Desription p[data-v-4496b94d]:nth-child(2) {
  margin-left: 0.02604rem;
  line-height: 0.18229rem;
  border-bottom: 1px solid #000;
}
.Confirm button[data-v-4496b94d] {
  width: 1.30208rem;
  height: 0.23958rem;
  background: #474882;
  border-radius: 10px;
  border: 0;
  margin-top: 0.20833rem;
  margin-bottom: 0.10417rem;
  color: #ffffff;
  margin-left: 2.86458rem;
}